import { COLORS } from '../../theme/colors';
import { Icon } from './types';

export const Cross: Icon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.2329 11.9993L18.7414 6.4989C18.906 6.33424 18.9985 6.11091 18.9985 5.87804C18.9985 5.64517 18.906 5.42184 18.7414 5.25717C18.5767 5.09251 18.3534 5 18.1206 5C17.8877 5 17.6644 5.09251 17.4998 5.25717L12 10.7663L6.50024 5.25717C6.33559 5.09251 6.11228 5 5.87944 5C5.64659 5 5.42328 5.09251 5.25864 5.25717C5.09399 5.42184 5.00149 5.64517 5.00149 5.87804C5.00149 6.11091 5.09399 6.33424 5.25864 6.4989L10.7671 11.9993L5.25864 17.4996C5.17668 17.5809 5.11164 17.6776 5.06725 17.7842C5.02285 17.8907 5 18.005 5 18.1205C5 18.2359 5.02285 18.3502 5.06725 18.4568C5.11164 18.5633 5.17668 18.66 5.25864 18.7413C5.33992 18.8233 5.43663 18.8884 5.54318 18.9327C5.64972 18.9771 5.76401 19 5.87944 19C5.99486 19 6.10915 18.9771 6.2157 18.9327C6.32225 18.8884 6.41895 18.8233 6.50024 18.7413L12 13.2322L17.4998 18.7413C17.581 18.8233 17.6778 18.8884 17.7843 18.9327C17.8909 18.9771 18.0051 19 18.1206 19C18.236 19 18.3503 18.9771 18.4568 18.9327C18.5634 18.8884 18.6601 18.8233 18.7414 18.7413C18.8233 18.66 18.8884 18.5633 18.9328 18.4568C18.9771 18.3502 19 18.2359 19 18.1205C19 18.005 18.9771 17.8907 18.9328 17.7842C18.8884 17.6776 18.8233 17.5809 18.7414 17.4996L13.2329 11.9993Z"
        fill={props.color ?? COLORS.texts.default}
      />
    </svg>
  );
};
