import { useQuery } from '@tanstack/react-query';

import { ScoresWithDate } from '../../types/scores';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

const getPatientScores = async (
  patientId: string,
): Promise<ScoresWithDate[]> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.SCORES, {
    pathParameters: { ':patientId': patientId },
  });
};

export const usePatientScores = (patientId: string) =>
  useQuery<ScoresWithDate[]>(['scores', patientId], () =>
    getPatientScores(patientId),
  );
