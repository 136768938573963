import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { LabeledText } from '../../../../../../../components';
import { formatDateFrench } from '../../../../../../../services/date/formatDateFrench';
import {
  ComplementaryTreatmentHistory,
  ComplementaryTreatmentHistoryData,
} from '../../../../../../../types/complementaryTreatment';
import { join } from '../../../../../../../utils';
import { isTreatmentAge } from '../../utils';

const isHistoryDataKey = (
  key: string,
): key is keyof ComplementaryTreatmentHistoryData => {
  return ['prescriber', 'startDate', 'endDate', 'details'].includes(key);
};

interface ComplementaryTreatmentHistoricalInformationProps {
  history: ComplementaryTreatmentHistory[];
}

export const ComplementaryTreatmentHistoricalInformation: FunctionComponent<
  ComplementaryTreatmentHistoricalInformationProps
> = ({ history }) => {
  const { t } = useTranslation();

  const elements = history.map((modification, index) => {
    const isCreation = index === history.length - 1;

    const changedSinceLast: Record<
      keyof ComplementaryTreatmentHistoryData,
      boolean
    > = {
      prescriber: true,
      startDate: true,
      endDate: true,
      details: true,
    };

    if (!isCreation) {
      const previousModification = history[index + 1];

      for (const key in modification.data) {
        if (!isHistoryDataKey(key)) continue;

        changedSinceLast[key] =
          modification.data[key] !== previousModification.data[key];
      }
    }

    const modificationDate = (
      <LabeledText
        label={t(
          `followup.complementaryTreatments.complementaryTreatmentModal.historyTab.${
            isCreation ? 'creationDate' : 'modificationDate'
          }`,
        )}
        text={formatDateFrench(modification.updatedAt)}
        className="information-modal-horizontal-element-1"
      />
    );

    const prescriber = (
      <LabeledText
        textClassName={
          changedSinceLast.prescriber ? '' : 'information-modal-unchanged'
        }
        label={t(
          'followup.complementaryTreatments.complementaryTreatmentModal.inCommon.prescriber',
        )}
        text={t(`followup.prescriber.${modification.data.prescriber}`)}
        className="information-modal-horizontal-element-1"
      />
    );

    const startDate = (
      <LabeledText
        textClassName={
          changedSinceLast.startDate ? '' : 'information-modal-unchanged'
        }
        label={t('followup.inCommon.startDate')}
        text={
          isTreatmentAge(modification.data.startDate)
            ? t(`followup.treatmentAge.${modification.data.startDate}`)
            : formatDateFrench(modification.data.startDate)
        }
      />
    );

    const endDate = (
      <LabeledText
        textClassName={
          changedSinceLast.endDate ? '' : 'information-modal-unchanged'
        }
        label={t('followup.inCommon.endDate')}
        text={
          modification.data.endDate === null
            ? t(
                'followup.complementaryTreatments.complementaryTreatmentModal.inCommon.therapyInProgress',
              )
            : formatDateFrench(modification.data.endDate)
        }
      />
    );

    const details = (
      <LabeledText
        textClassName={
          changedSinceLast.details ? '' : 'information-modal-unchanged'
        }
        label={t(
          'followup.complementaryTreatments.complementaryTreatmentModal.inCommon.therapyDetails',
        )}
        text={
          modification.data.details === null || modification.data.details === ''
            ? '-'
            : modification.data.details
        }
      />
    );

    return (
      <div
        key={modification.updatedAt}
        className="information-modal-tab-container"
        data-testid="history-modification-container"
      >
        <div className="information-modal-horizontal-section">
          {modificationDate}
          {prescriber}
        </div>
        <div className="information-modal-horizontal-section">
          {startDate}
          {endDate}
        </div>
        {details}
      </div>
    );
  });

  const divider = <div className="information-modal-divider" />;

  return join(elements, divider);
};
