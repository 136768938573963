import './ModifyConsultationNoteModal.scss';

import { FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ArrowRight,
  Button,
  ButtonClass,
  LongTextInput,
  Modal,
  ModalProps,
} from '../../../../../../components';
import { useUpdateConsultationNote } from '../../../../../../services/api/consultation-notes';
import { ConsultationNote } from '../../../../../../types/consultationNotes';

interface ModifyConsultationNoteProps extends Omit<ModalProps, 'header'> {
  consultationNote: ConsultationNote;
  patientId: string;
}

interface ModifyConsultationNoteForm {
  text: string;
}

export const ModifyConsultationNoteModal: FunctionComponent<
  ModifyConsultationNoteProps
> = ({ opened, onClose, consultationNote, patientId }) => {
  const { t } = useTranslation();

  const {
    mutate: updateConsultationNote,
    isLoading: isUpdateConsultationNoteLoading,
    isError: isUpdateConsultationNoteError,
  } = useUpdateConsultationNote(patientId, consultationNote.id, onClose);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm<ModifyConsultationNoteForm>({
    mode: 'onBlur',
    defaultValues: { text: consultationNote.text },
  });

  return (
    <Modal
      header={t('clinicalFile.consultationNotes.modifyConsultationNoteTitle')}
      opened={opened}
      onClose={onClose}
      testId="modify-consultation-note-modal"
      hasError={isUpdateConsultationNoteError}
      errorMessage={t('errors.unknownError.message')}
    >
      <div className="modify-consultation-note-modal-container ">
        <Controller
          control={control}
          name="text"
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <LongTextInput
              inputTestId={'text-input-modify-consultation-note'}
              label={`${t('clinicalFile.consultationNotes.sectionTitle')} :`}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <Button
          data-testid={'modify-consultation-note-save-button'}
          buttonClass={ButtonClass.PRIMARY}
          text={t('save')}
          onClick={handleSubmit((data) => {
            updateConsultationNote(data);
          })}
          RightIcon={ArrowRight}
          disabled={!isValid || !isDirty}
          isLoading={isUpdateConsultationNoteLoading}
        />
      </div>
    </Modal>
  );
};
