import { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ModalDropDown } from '../../../../PatientDetails/routes/Followup/components/EditionModals/ModalDropDown/ModalDropDown';
import { AddPatientForm } from '../AddPatientModal';
import { SexData } from '../data';

interface SexInputProps {
  control: Control<AddPatientForm>;
  testId: string;
}

export const SexInput: FunctionComponent<SexInputProps> = ({
  control,
  testId,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="sex"
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <ModalDropDown
            data-testid={`${testId}-sex-input`}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            label={t('patientList.addPatientModal.patientInformation.sex')}
            isValid={error === undefined}
            data={SexData(t)}
            containerClassName="add-patient-input-on-same-row add-patient-sex-input"
          />
        );
      }}
      rules={{ required: true }}
    />
  );
};
