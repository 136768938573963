import './router.scss';

import * as Sentry from '@sentry/react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import type { RouteObject } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import { ActivityIndicator, ErrorApiMessage } from '../components';
import { useHasHcpConsentedToLegalNotice } from '../services/api/hcp';
import { LegalNoticeConsent } from './LegalNoticeConsent/LegalNoticeConsent';
import { Login } from './Login/Login';
import { PatientDetails } from './PatientDetails/PatientDetails';
import { ClinicalFile, Followup } from './PatientDetails/routes';
import { Characterization } from './PatientDetails/routes/Characterization/Characterization';
import { PatientList } from './PatientList/PatientList';
import { ResetPassword } from './ResetPassword/ResetPassword';

const CheckUserHasConsented: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    isError,
    data: consents,
    refetch,
  } = useHasHcpConsentedToLegalNotice();

  if (isLoading) {
    return <ActivityIndicator />;
  }

  if (isError) {
    return (
      <ErrorApiMessage
        title={t('apiMessage.serverError.errorOccurred')}
        subtitle={t('apiMessage.serverError.tryAgainLater')}
        className="consent-api-message"
      />
    );
  }

  if (!consents.hasConsentedToLegalMentions) {
    return <LegalNoticeConsent refetch={refetch} />;
  }

  return <Outlet />;
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const authenticatedRouter = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <CheckUserHasConsented />,
    children: [
      {
        path: '/',
        element: <PatientList />,
      },
      {
        path: '/patient/:patientId',
        element: <PatientDetails />,
        children: [
          {
            path: '/patient/:patientId/followup',
            element: <Followup />,
          },
          {
            path: '/patient/:patientId/clinical-file',
            element: <ClinicalFile />,
          },
          {
            path: '/patient/:patientId/characterization',
            element: <Characterization />,
          },
        ],
      },
    ],
  },
]);

export const unauthenticatedRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/*',
    element: <Navigate to="/" replace />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
];

export const unauthenticatedRouter = sentryCreateBrowserRouter(
  unauthenticatedRoutes,
);
