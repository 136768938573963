import './ConsultationNotesSection.scss';

import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActivityIndicator,
  ErrorApiMessage,
} from '../../../../../../components';
import { useGetConsultationNotes } from '../../../../../../services/api/consultation-notes';
import { ConsultationNote } from '../../../../../../types/consultationNotes';
import { ModifyConsultationNoteModal } from '../ModifyConsultationNoteModal/ModifyConsultationNoteModal';
import { ConsultationNoteCard } from './components/ConsultationNoteCard';

interface ConsultationNotesProps {
  patientId: string;
}

export const ConsultationNotesSection: FunctionComponent<
  ConsultationNotesProps
> = ({ patientId }) => {
  const { t } = useTranslation();

  const {
    data: consultationNotes,
    isLoading: isGetConsultationNotesLoading,
    isError: isGetConsultationNotesError,
  } = useGetConsultationNotes(patientId);

  const hasConsultationNotes =
    consultationNotes !== undefined && consultationNotes?.length > 0;

  const [selectedConsultationNote, setSelectedConsultationNote] =
    useState<ConsultationNote | null>(null);

  return (
    <>
      <div className="clinical-file-section consultation-note-section">
        {isGetConsultationNotesLoading ? (
          <ActivityIndicator />
        ) : isGetConsultationNotesError ? (
          <ErrorApiMessage
            title={t('apiMessage.serverError.errorOccurred')}
            subtitle={t('apiMessage.serverError.tryAgainLater')}
            className="consultation-note-error-message"
          />
        ) : (
          <>
            <h2 className="clinical-file-section-title">
              {t('clinicalFile.consultationNotes.sectionTitle')}
            </h2>
            {!hasConsultationNotes ? (
              <p className="p1">
                {t('clinicalFile.consultationNotes.noConsultationNote')}
              </p>
            ) : (
              consultationNotes.map((note) => (
                <ConsultationNoteCard
                  key={note.createdAt.toString()}
                  consultationNote={note}
                  onButtonClick={() => setSelectedConsultationNote(note)}
                />
              ))
            )}
          </>
        )}
      </div>
      {selectedConsultationNote !== null && (
        <ModifyConsultationNoteModal
          consultationNote={selectedConsultationNote}
          opened={true}
          onClose={() => setSelectedConsultationNote(null)}
          patientId={patientId}
        />
      )}
    </>
  );
};
