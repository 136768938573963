import { useTranslation } from 'react-i18next';

import { Button, ButtonClass } from '../../../../../../../components';

type SubmitButtonProps = {
  onClick: () => void;
  isLoading: boolean;
  isEnabled: boolean;
};
export const SubmitButton = ({
  onClick,
  isLoading,
  isEnabled,
}: SubmitButtonProps) => {
  const { t } = useTranslation();
  return (
    <div className="save-button-medicine-edition">
      <Button
        buttonClass={ButtonClass.PRIMARY}
        text={t('save')}
        onClick={onClick}
        isLoading={isLoading}
        disabled={!isEnabled}
      />
    </div>
  );
};
