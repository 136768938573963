import { FunctionComponent } from 'react';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Calendar } from '../../../../../../../../components';
import { DatePickerInput } from '../../DatePicker/DatePickerInput';
import { MedicineTreatmentForm } from '../types';

interface DateInputProps {
  control: Control<MedicineTreatmentForm>;
  watch: UseFormWatch<MedicineTreatmentForm>;
  type: 'START' | 'END';
}

export const DateInput: FunctionComponent<DateInputProps> = ({
  control,
  watch,
  type,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={type === 'START' ? 'startDateOrAge' : 'endDate'}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        const isDateEmpty = value === null;
        const startDate = type === 'END' ? watch('startDateOrAge') : undefined;
        return (
          <DatePickerInput
            label={
              type === 'START'
                ? t(
                    'followup.medicineTreatments.medicineTreatmentEditionModal.initiationDate',
                  )
                : t(
                    'followup.medicineTreatments.medicineTreatmentEditionModal.endDate',
                  )
            }
            onChange={onChange}
            minDate={startDate ?? undefined}
            onBlur={onBlur}
            value={value}
            clearable={!isDateEmpty}
            rightSection={isDateEmpty ? <Calendar /> : null}
            styles={{
              rightSection: {
                pointerEvents: isDateEmpty ? 'none' : 'all',
              },
            }}
            isValid={type === 'START' ? error === undefined : undefined}
          />
        );
      }}
      rules={{ required: type === 'START' ? true : false }}
    />
  );
};
