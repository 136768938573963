import './MedicineEditionModal.scss';

import { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ArrowRight,
  Button,
  ButtonClass,
  Modal,
} from '../../../../../../../components';
import {
  useModifyMedicineTreatment,
  useNewMedicineTreatment,
} from '../../../../../../../services/api/medicine-treatments';
import { toInteger, toPositiveFloat } from '../../../../../../../utils';
import { isTreatmentAge } from '../../utils';
import { AutocompleteMedicineName } from './MedicineEditionModalFields/AutocompleteMedicineName';
import { DailyDosageInput } from './MedicineEditionModalFields/DailyDosageInput';
import { DailyDosageToolTip } from './MedicineEditionModalFields/DailyDosageToolTip';
import { DailyDoseUnit } from './MedicineEditionModalFields/DailyDoseUnit';
import { DateInput } from './MedicineEditionModalFields/DateInput';
import { DetailsInput } from './MedicineEditionModalFields/DetailsInput';
import { TreatmentTypeDropDown } from './MedicineEditionModalFields/TreatmentTypeDropDown';
import { MedicineEditionModalProps, MedicineTreatmentForm } from './types';

export const MedicineEditionModal: FunctionComponent<
  MedicineEditionModalProps
> = ({
  opened,
  onClose,
  title,
  defaultValue,
  type,
  testId,
  patientId,
  setIsBackendError,
}) => {
  const { t } = useTranslation();

  const {
    control,
    trigger,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isValid, touchedFields, isDirty: wasFormEdited },
  } = useForm<MedicineTreatmentForm>({
    mode: 'onBlur',
  });

  const {
    mutate: addMedicineTreatmentMutation,
    isLoading: isPostingMedicineTreatment,
  } = useNewMedicineTreatment(patientId, onClose, () =>
    setIsBackendError(true),
  );

  const {
    mutate: modifyMedicineTreatmentMutation,
    isLoading: isModifyingMedicineTreatment,
  } = useModifyMedicineTreatment(
    patientId,
    defaultValue?.id ?? '',
    onClose,
    () => setIsBackendError(true),
  );

  // Reset was used to be able to add default values
  // TECHNICAL DEBT - not urgent, not important
  useEffect(() => {
    reset({
      medicineCisCode: defaultValue?.medicine.cisCode.toString(),
      dailyDosage: defaultValue?.dailyDosage?.toString(),
      dailyDosageUnit: defaultValue?.dailyDosageUnit,
      type: defaultValue?.type,
      startDateOrAge:
        defaultValue?.startDate === undefined
          ? new Date()
          : defaultValue.startDate !== null &&
              !isTreatmentAge(defaultValue.startDate)
            ? new Date(defaultValue.startDate)
            : null,
      endDate:
        defaultValue?.endDate !== undefined && defaultValue.endDate !== null
          ? new Date(defaultValue.endDate)
          : null,
      details:
        defaultValue?.details !== null && defaultValue?.details !== undefined
          ? defaultValue.details
          : null,
    });
  }, [defaultValue, reset, opened]);

  const isDailyDosageValid =
    touchedFields.dailyDosage === undefined ||
    (touchedFields.dailyDosage && errors.dailyDosage === undefined);

  const dailyDosageTooltip = (
    <DailyDosageToolTip isDailyDosageValid={isDailyDosageValid} />
  );

  const isDailyDoseRequired = type === 'MODIFY' && !!defaultValue?.dailyDosage;

  const dailyDoseContent = (
    <div className="daily-dose-input-container">
      <DailyDosageInput
        control={control}
        dailyDosageTooltip={dailyDosageTooltip}
        isRequired={isDailyDoseRequired}
        trigger={trigger}
      />
      <DailyDoseUnit
        control={control}
        isRequired={isDailyDoseRequired}
        trigger={trigger}
      />
    </div>
  );

  const treatmentTypeDropDown = <TreatmentTypeDropDown control={control} />;

  const startDateInput = (
    <DateInput control={control} type="START" watch={watch} />
  );

  const endDateInput = <DateInput control={control} type="END" watch={watch} />;

  const autocompleteMedicineName = (
    <AutocompleteMedicineName
      control={control}
      defaultValue={defaultValue}
      type={type}
    />
  );

  const detailsInput = <DetailsInput control={control} />;

  const saveButton = (
    <div className="save-button-medicine-edition">
      <Button
        buttonClass={ButtonClass.PRIMARY}
        text={t('save')}
        RightIcon={ArrowRight}
        onClick={() => {
          setIsBackendError(false);
          handleSubmit((data) => {
            const formattedData = {
              ...data,
              startDate: data.startDateOrAge?.toISOString() ?? null,
              endDate: data.endDate?.toISOString() ?? null,
              dailyDosage: toPositiveFloat(data.dailyDosage) ?? null,
              dailyDosageUnit: data.dailyDosageUnit ?? null,
              medicineCisCode: toInteger(data.medicineCisCode) ?? 0,
            };
            type === 'ADD'
              ? addMedicineTreatmentMutation(formattedData)
              : modifyMedicineTreatmentMutation(formattedData);
          })();
        }}
        isLoading={isPostingMedicineTreatment || isModifyingMedicineTreatment}
        disabled={!isValid || (type === 'MODIFY' && !wasFormEdited)}
      />
    </div>
  );

  return (
    <Modal
      opened={opened}
      header={title}
      onClose={onClose}
      testId={testId}
      classNames={{
        overlay: 'edition-modal-overlay',
        inner: 'edition-modal-inner',
      }}
    >
      <div className="edition-modal-tab-container">
        {autocompleteMedicineName}
        {dailyDoseContent}
        <div className="treatment-inputs-container">
          {treatmentTypeDropDown}
          {startDateInput}
          {endDateInput}
        </div>
        {detailsInput}
        {saveButton}
      </div>
    </Modal>
  );
};
