import './EventIconTooltip.scss';

import { ComponentProps, FunctionComponent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { LineAlt, Minus, Plus, Tooltip } from '../../../../../../../components';
import { formatDateFrench } from '../../../../../../../services/date/formatDateFrench';
import { Color, COLORS } from '../../../../../../../theme/colors';
import { Event, EventPerception } from '../../../../../../../types/events';

interface EventIconToolTipProps extends ComponentProps<'div'> {
  event: Event;
}

const PerceptionIcons: Record<
  EventPerception,
  { icon: ReactElement; color: Color }
> = {
  NEGATIVE: {
    icon: <Minus width={16} height={16} />,
    color: COLORS.tertiary.lightPeach,
  },
  NEUTRAL: {
    icon: <LineAlt width={16} height={16} />,
    color: COLORS.tertiary.yellow,
  },
  POSITIVE: {
    icon: <Plus width={16} height={16} />,
    color: COLORS.tertiary.lightGreen,
  },
};

export const EventIconToolTip: FunctionComponent<EventIconToolTipProps> = ({
  event,
  ...props
}) => {
  const { t } = useTranslation();

  const date = formatDateFrench(event.date.toString());

  const tooltipContent = (
    <div className="tooltip-content">
      <p className="event-tooltip-title">{t('followup.eventTypeTitle')}</p>
      <p className="p2">{t(`followup.eventTypes.${event.type}`)}</p>
      <p className="event-tooltip-title">{t('followup.eventDate')}</p>
      <p className="p2">{date}</p>
      <p className="event-tooltip-title">{t('followup.precision')}</p>
      <p className="tooltip-details">{event.details}</p>
    </div>
  );

  return (
    <Tooltip
      title={''}
      description={tooltipContent}
      position="top"
      style={{ width: 'auto', minWidth: 225 }}
    >
      <div
        {...props}
        className="event-icon"
        data-testid={`event-icon-${event.perception}`}
        style={{
          ...(props.style ?? {}),
          backgroundColor: PerceptionIcons[event.perception].color,
        }}
      >
        {PerceptionIcons[event.perception].icon}
      </div>
    </Tooltip>
  );
};
