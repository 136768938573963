import './DiagnosesSection.scss';

import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActivityIndicator,
  ErrorApiMessage,
  IconButton,
  Plus,
} from '../../../../../../components';
import { useGetPatientDiagnoses } from '../../../../../../services/api/diagnosis';
import { COLORS } from '../../../../../../theme/colors';
import { PatientDiagnosis as PatientDiagnosisType } from '../../../../../../types/diagnosis';
import { join } from '../../../../../../utils';
import { CreateDiagnosisModal } from '../CreateOrEditDiagnosisModal/CreateDiagnosisModal';
import { EditDiagnosisModal } from '../CreateOrEditDiagnosisModal/EditDiagnosisModal';
import { PatientDiagnosis } from '../PatientDiagnosis/PatientDiagnosis';

interface DiagnosesSectionProps {
  patientId: string;
}

export const DiagnosesSection: FunctionComponent<DiagnosesSectionProps> = ({
  patientId,
}) => {
  const { t } = useTranslation();

  const [isCreateDiagnosisModalOpen, setIsCreateDiagnosisModalOpen] =
    useState<boolean>(false);

  const [editedPatientDiagnosis, setEditedPatientDiagnosis] =
    useState<PatientDiagnosisType | null>(null);

  const {
    isLoading,
    data: patientDiagnoses,
    isError,
  } = useGetPatientDiagnoses(patientId);

  return (
    <>
      <div className="clinical-file-section diagnoses-section">
        <div className="clinical-file-section-title-and-button-container">
          <h2 className="clinical-file-section-title">
            {t('clinicalFile.diagnoses.sectionTitle')}
          </h2>
          {!isLoading && !isError && (
            <IconButton
              className="clinical-file-add-button"
              Icon={Plus}
              color={COLORS.white}
              onClick={() => setIsCreateDiagnosisModalOpen(true)}
              data-testid="add-diagnosis-button"
            />
          )}
        </div>
        {isLoading ? (
          <ActivityIndicator testId="patient-diagnoses-loader" />
        ) : isError ? (
          <ErrorApiMessage
            title={t('apiMessage.serverError.errorOccurred')}
            subtitle={t('apiMessage.serverError.tryAgainLater')}
            className="patient-diagnoses-error-message"
          />
        ) : patientDiagnoses === undefined || patientDiagnoses.length === 0 ? (
          <p className="p1">{t('clinicalFile.diagnoses.noDiagnosesText')}</p>
        ) : (
          <div className="patient-diagnoses-container">
            {join(
              patientDiagnoses.map((patientDiagnosis) => (
                <PatientDiagnosis
                  patientDiagnosis={patientDiagnosis}
                  onClickEdit={() =>
                    setEditedPatientDiagnosis(patientDiagnosis)
                  }
                  key={`${patientDiagnosis.patientId}-${patientDiagnosis.diagnosis.id}`}
                />
              )),
              <div className="patient-diagnosis-divider" />,
            )}
          </div>
        )}
      </div>
      <CreateDiagnosisModal
        patientId={patientId}
        opened={isCreateDiagnosisModalOpen}
        onClose={() => setIsCreateDiagnosisModalOpen(false)}
        testId="create-diagnosis-modal"
      />
      {editedPatientDiagnosis !== null && (
        <EditDiagnosisModal
          patientId={patientId}
          opened={true}
          onClose={() => setEditedPatientDiagnosis(null)}
          patientDiagnosis={editedPatientDiagnosis}
        />
      )}
    </>
  );
};
