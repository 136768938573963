import './TextInput.scss';

import { ComponentProps, FunctionComponent, ReactNode, useRef } from 'react';

import { COLORS } from '../../theme/colors';

export interface TextInputProps
  extends Omit<ComponentProps<'input'>, 'defaultValue'> {
  label?: string;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  inputTestId: string;
  isValid: boolean;
  errorMessage?: string;
  tooltip?: ReactNode;
  containerClassName?: string;
}

export const TextInput: FunctionComponent<TextInputProps> = ({
  label,
  name,
  rightIcon,
  leftIcon,
  inputTestId,
  isValid,
  errorMessage,
  tooltip,
  containerClassName,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div className={`text-input-wrapper ${containerClassName ?? ''}`}>
      <div
        className="text-input-container"
        onClick={() => {
          if (inputRef.current !== null) inputRef.current.focus();
        }}
        style={
          !isValid
            ? {
                borderColor: COLORS.statuses.error,
                color: COLORS.statuses.error,
              }
            : {}
        }
      >
        {leftIcon}
        <div className="text-input-content">
          {label !== undefined && (
            <label htmlFor={name} className="text-input-label">
              {label}
              {tooltip}
            </label>
          )}
          <input
            {...props}
            name={name}
            ref={inputRef}
            className="text-input-content-to-write"
            data-testid={inputTestId}
          />
        </div>
        {rightIcon}
      </div>
      {!isValid && errorMessage !== undefined && (
        <p
          className="text-input-error-message"
          data-testid="text-input-error-message"
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};
