import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LongTextInput } from '../../../../../../../components';
import { ComplementaryTreatmentForm } from './ComplementaryTreatmentEditionModal';

type DetailsInputProps = {
  control?: Control<ComplementaryTreatmentForm, unknown>;
};
export const COMPLEMENTARY_TREATMENT_DETAILS_ID =
  'details-input-complementary-treatment-edition';
export const DetailsInput = ({ control, ...props }: DetailsInputProps) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="details"
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <LongTextInput
            {...props}
            label={t(
              'followup.medicineTreatments.medicineTreatmentEditionModal.details',
            )}
            inputTestId={COMPLEMENTARY_TREATMENT_DETAILS_ID}
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? undefined}
            resizable={true}
          />
        );
      }}
    />
  );
};
