import type { TFunction } from 'i18next';

import { Prescriber } from '../../../../../../types/complementaryTreatment';
import {
  DosageUnit,
  TreatmentType,
} from '../../../../../../types/medicineTreatment';

type DosageUnitItem = {
  value: DosageUnit | 'NONE';
  label: string;
};

type TreatmentTypeItem = {
  value: TreatmentType;
  label: string;
};

export const dosageUnitData: DosageUnitItem[] = [
  { value: 'NONE', label: '\b' },
  { value: DosageUnit.GRAMS, label: 'g' },
  { value: DosageUnit.MILLIGRAMS, label: 'mg' },
  { value: DosageUnit.MICROGRAMS, label: 'µg' },
  { value: DosageUnit.UNITS, label: 'U' },
];

export const treatmentTypeData: TreatmentTypeItem[] = [
  { value: TreatmentType.BACKGROUND_TREATMENT, label: 'Traitement de fond' },
  { value: TreatmentType.CRISIS_TREATMENT, label: 'Traitement de crise' },
  { value: TreatmentType.ON_DEMAND, label: 'À la demande' },
];

type PrescriberTypeItem = {
  value: Prescriber;
  label: string;
};

export const PrescriberTypeData = (
  t: TFunction<'translation', undefined>,
): PrescriberTypeItem[] =>
  [
    Prescriber.DOCTOR,
    Prescriber.NURSE,
    Prescriber.PSYCHOLOGIST,
    Prescriber.OTHER,
  ].map((prescriber) => ({
    value: prescriber,
    label: t(`followup.prescriber.${prescriber}`),
  }));
