import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { useGetPatientComplementaryTreatments } from '../../../../../../services/api/complementary-treatments';
import { ComplementaryTreatment } from '../../../../../../types/complementaryTreatment';
import { FilterStatus } from '../Filters/TreatmentFilters';

export const useComplementaryTreatments = (
  patientId: string,
): {
  isLoading: boolean;
  isError: boolean;
  groupedComplementaryTreatments: Record<
    FilterStatus,
    ComplementaryTreatment[]
  >;
  isComplementaryTreatmentModalOpen: boolean;
  complementaryTreatmentFilter: FilterStatus;
  setComplementaryTreatmentFilter: (value: FilterStatus) => void;
  selectedComplementaryTreatment: ComplementaryTreatment | null;
  setSelectedComplementaryTreatment: (
    value: ComplementaryTreatment | null,
  ) => void;
  complementaryTreatmentToModify: ComplementaryTreatment | null;
  setComplementaryTreatmentToModify: (
    value: ComplementaryTreatment | null,
  ) => void;
  areAllComplementaryTreatmentsDisplayed: boolean;
  setAreAllComplementaryTreatmentsDisplayed: (value: boolean) => void;
  isAddComplementaryTreatmentModalOpened: boolean;
  setIsAddComplementaryTreatmentModalOpened: (value: boolean) => void;
} => {
  const [complementaryTreatmentFilter, setComplementaryTreatmentFilter] =
    useState<FilterStatus>('ONGOING');

  const [complementaryTreatmentToModify, setComplementaryTreatmentToModify] =
    useState<ComplementaryTreatment | null>(null);

  const [
    areAllComplementaryTreatmentsDisplayed,
    setAreAllComplementaryTreatmentsDisplayed,
  ] = useState(false);

  const {
    isLoading,
    data: complementaryTreatments,
    isError,
  } = useGetPatientComplementaryTreatments(patientId ?? '');

  const [
    isAddComplementaryTreatmentModalOpened,
    setIsAddComplementaryTreatmentModalOpened,
  ] = useState(false);

  const yesterday = dayjs().subtract(1, 'day');

  const ongoingComplementaryTreatments = (complementaryTreatments ?? []).filter(
    (complementaryTreatment) => {
      return (
        complementaryTreatment.endDate === null ||
        dayjs(complementaryTreatment.endDate).isAfter(yesterday, 'day')
      );
    },
  );
  const stoppedComplementaryTreatments = (complementaryTreatments ?? []).filter(
    (medicineTreatment) => {
      return (
        medicineTreatment.endDate !== null &&
        (dayjs(medicineTreatment.endDate).isSame(yesterday, 'day') ||
          dayjs(medicineTreatment.endDate).isBefore(yesterday, 'day'))
      );
    },
  );

  const groupedComplementaryTreatments: Record<
    FilterStatus,
    ComplementaryTreatment[]
  > = {
    ALL: complementaryTreatments ?? [],
    ONGOING: ongoingComplementaryTreatments,
    STOPPED: stoppedComplementaryTreatments,
  };

  const [
    selectedComplementaryTreatmentId,
    setSelectedComplementaryTreatmentId,
  ] = useState<string | null>(null);

  const selectedComplementaryTreatment =
    groupedComplementaryTreatments.ALL.find(
      (treatment) => treatment.id === selectedComplementaryTreatmentId,
    ) ?? null;
  const setSelectedComplementaryTreatment = (
    treatment: ComplementaryTreatment | null,
  ) =>
    setSelectedComplementaryTreatmentId(
      treatment === null ? null : treatment.id,
    );

  const isComplementaryTreatmentModalOpen =
    selectedComplementaryTreatmentId !== null;

  useEffect(() => {
    if (ongoingComplementaryTreatments.length === 0) {
      setComplementaryTreatmentFilter('ALL');
    } else {
      setComplementaryTreatmentFilter('ONGOING');
    }
  }, [ongoingComplementaryTreatments.length]);

  return {
    isLoading,
    isError,
    groupedComplementaryTreatments,
    isComplementaryTreatmentModalOpen,
    complementaryTreatmentFilter,
    setComplementaryTreatmentFilter,
    selectedComplementaryTreatment,
    setSelectedComplementaryTreatment,
    complementaryTreatmentToModify,
    setComplementaryTreatmentToModify,
    areAllComplementaryTreatmentsDisplayed,
    setAreAllComplementaryTreatmentsDisplayed,
    isAddComplementaryTreatmentModalOpened,
    setIsAddComplementaryTreatmentModalOpened,
  };
};
