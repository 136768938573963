import type { SelectItem, SelectProps } from '@mantine/core';
// eslint-disable-next-line no-restricted-imports
import { Select } from '@mantine/core';

import { COLORS } from '../../theme/colors';
import { AngleDown } from '../icons';

interface DropDownProps<DataType extends SelectItem> extends SelectProps {
  data: DataType[];
}

export const DropDown = <DataType extends SelectItem>({
  data,
  ...props
}: DropDownProps<DataType>): JSX.Element => {
  return (
    <Select
      data={data}
      {...props}
      styles={{
        item: {
          textAlign: 'center',
          '&[data-selected]': {
            '&, &:hover': {
              backgroundColor: COLORS.white,
              color: COLORS.primary._500,
            },
          },
        },
        input: {
          '&:focus-within': {
            borderColor: COLORS.grey.grey70,
          },
          minWidth: 250,
        },
        rightSection: { pointerEvents: 'none' },
        dropdown: { borderRadius: 8 },
      }}
      radius={8}
      rightSection={<AngleDown />}
    />
  );
};
