import './LongTextInput.scss';

import { ComponentProps, FunctionComponent, useRef } from 'react';

interface LongTextInputProps
  extends Omit<ComponentProps<'textarea'>, 'defaultValue'> {
  label?: string;
  inputTestId: string;
  resizable?: boolean;
}

export const LongTextInput: FunctionComponent<LongTextInputProps> = ({
  label,
  inputTestId,
  name,
  resizable = true,
  style,
  className,
  ...props
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  return (
    <div
      className={`long-text-input-container ${
        label === undefined
          ? 'long-text-input-container-without-label'
          : 'long-text-input-container-with-label'
      }`}
      onClick={() => {
        if (inputRef.current !== null) inputRef.current.focus();
      }}
    >
      {label && (
        <label htmlFor={name} className="long-text-input-label">
          {label}
        </label>
      )}

      <textarea
        {...props}
        name={name}
        ref={inputRef}
        className={`long-text-input ${className ?? ''}`}
        data-testid={inputTestId}
        style={{ resize: resizable ? 'vertical' : 'none', ...(style ?? {}) }}
      />
    </div>
  );
};
