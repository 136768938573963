import type { SelectItem } from '@mantine/core';
import type { UseQueryResult } from '@tanstack/react-query';
import { ReactElement, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { AutocompleteInput, AutocompleteInputProps } from './AutocompleteInput';

const SEARCH_WORD_DEBOUNCE_DELAY_IN_MS = 200;

export interface SearchableInputProps<Item extends SelectItem, ApiResult>
  extends Omit<
    AutocompleteInputProps<Item>,
    'filter' | 'limit' | 'isLoading' | 'data'
  > {
  queryHook: (searchWord: string) => UseQueryResult<ApiResult, unknown>;
  convertApiResultToItems: (apiResult: ApiResult) => Item[];
}

export const SearchableInput = <Item extends SelectItem, ApiResult>({
  value,
  onChange,
  queryHook,
  convertApiResultToItems,
  ...props
}: SearchableInputProps<Item, ApiResult>): ReactElement => {
  const [searchWord, setSearchWord] = useState<string>(value ?? '');
  const [debouncedSearchWord] = useDebounce(
    searchWord,
    SEARCH_WORD_DEBOUNCE_DELAY_IN_MS,
  );

  const { data, isLoading } = queryHook(debouncedSearchWord);

  const options =
    !isLoading && data !== undefined ? convertApiResultToItems(data) : [];

  const [selectedItem, setSelectedItem] = useState<Item | null>(
    options.find((item) => item.value === value) ?? null,
  );

  return (
    <AutocompleteInput
      {...props}
      isLoading={isLoading}
      data={options}
      value={selectedItem?.label ?? searchWord}
      filter={() => true}
      onChange={(newSearchWordOrValue) => {
        const foundItem = options.find(
          (item) => item.value === newSearchWordOrValue,
        );

        setSearchWord(newSearchWordOrValue ?? '');
        setSelectedItem(foundItem ?? null);
        onChange?.(foundItem?.value ?? null);
      }}
    />
  );
};
