import { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from '../../../../../components';
import { AddPatientForm } from '../AddPatientModal';

interface LastNameInputProps {
  control: Control<AddPatientForm>;
  testId: string;
}

export const LastNameInput: FunctionComponent<LastNameInputProps> = ({
  control,
  testId,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="lastName"
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <TextInput
            inputTestId={`${testId}-lastname-input`}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            label={t('patientList.addPatientModal.patientInformation.lastname')}
            isValid={error === undefined}
          />
        );
      }}
      rules={{ required: true }}
    />
  );
};
