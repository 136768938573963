import './Charts.scss';

import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ActivityIndicator,
  DropDown,
  ErrorApiMessage,
} from '../../../../../components';
import { Information } from '../../../../../components/icons/Information';
import { usePatientScores } from '../../../../../services/api/scores';
import { Color, COLORS } from '../../../../../theme/colors';
import { Scores, ScoresWithDate } from '../../../../../types/scores';
import { SpiderChart } from './components/SpiderChart';
import {
  getSpiderChartBackgrounds,
  SPIDER_CHART_AXES,
  SPIDER_CHART_MAX_VALUE,
  SPIDER_CHART_MIN_VALUE,
} from './components/SpiderChart.config';
import { SpiderChartPosition } from './types';

export const Charts: FunctionComponent = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const { t } = useTranslation();
  const [selectedPreviousData, setSelectedPreviousData] =
    useState<ScoresWithDate | null>(null);
  const {
    data: scoresWithDates,
    isLoading,
    isError,
  } = usePatientScores(patientId ?? '');

  useEffect(() => {
    if (scoresWithDates !== undefined) {
      setSelectedPreviousData(scoresWithDates[0]);
    }
  }, [scoresWithDates]);

  const hasData =
    scoresWithDates === undefined ? false : scoresWithDates.length > 0;

  const hasPreviousData =
    hasData && scoresWithDates !== undefined
      ? scoresWithDates.length > 1
      : false;

  const scoresOfDate = (selectedDate: string): ScoresWithDate | undefined =>
    hasData && scoresWithDates !== undefined
      ? scoresWithDates.find(({ date }) => date === selectedDate)
      : undefined;

  if (isLoading) {
    return <ActivityIndicator />;
  }

  const legendItems: { color: Color; text: string }[] = [
    {
      color: COLORS.tertiary.lightGreen,
      text: t('characterization.legend.noDegradation'),
    },
    {
      color: COLORS.tertiary.lightYellow,
      text: t('characterization.legend.slightDegradation'),
    },
    {
      color: COLORS.tertiary.lightOrange,
      text: t('characterization.legend.moderateDegradation'),
    },
    {
      color: COLORS.tertiary.lightPeach,
      text: t('characterization.legend.severeDegradation'),
    },
  ];

  const legendItemElements = legendItems.map(({ color, text }) => (
    <div key={text} className="characterization-legend-label-container">
      <div
        className="characterization-legend-circle"
        style={{ backgroundColor: color }}
      />
      <p className="p2">{text}</p>
    </div>
  ));

  const backgrounds = getSpiderChartBackgrounds();

  const formatScoresDate = (scoresWithDate: ScoresWithDate) => {
    return new Date(scoresWithDate.date).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  interface SelectItemWithScore {
    date: string;
    scores: Scores;
    value: string;
    label: string;
  }

  const formatedScoreWithDatesSelection: SelectItemWithScore[] =
    hasData && scoresWithDates !== undefined
      ? scoresWithDates
          .slice(0, scoresWithDates.length - 1)
          .map((scoresWithDate) => {
            return {
              value: scoresWithDate.date,
              label: t('characterization.legend.scoresOfDate', {
                date: formatScoresDate(scoresWithDate),
              }),
              ...scoresWithDate,
            };
          })
      : [];

  const formattedLatestScoresDate =
    hasData && scoresWithDates !== undefined
      ? formatScoresDate(scoresWithDates[scoresWithDates.length - 1])
      : '';

  const spiderCharts = [
    SpiderChartPosition.TOP_LEFT,
    SpiderChartPosition.TOP_RIGHT,
    SpiderChartPosition.BOTTOM_LEFT,
    SpiderChartPosition.BOTTOM_RIGHT,
  ].map((position) => {
    return (
      <div className="characterization-spider-chart-container" key={position}>
        <SpiderChart
          minValue={SPIDER_CHART_MIN_VALUE}
          maxValue={SPIDER_CHART_MAX_VALUE}
          scoreTypes={SPIDER_CHART_AXES[position]}
          background={backgrounds[position]}
          latestData={
            hasData && scoresWithDates !== undefined
              ? scoresWithDates[scoresWithDates.length - 1].scores
              : undefined
          }
          previousData={
            hasPreviousData && selectedPreviousData !== null
              ? selectedPreviousData.scores
              : undefined
          }
        />
      </div>
    );
  });

  const errorMessage = (
    <ErrorApiMessage
      title={t('apiMessage.serverError.errorOccurred')}
      subtitle={t('apiMessage.serverError.tryAgainLater')}
      className="server-error-characterization"
    />
  );

  const noDataMessage = (
    <div className="no-characterization-data shadow-medium">
      <Information color={COLORS.texts.default} />
      <p className="no-characterization-data-title">
        {t('apiMessage.noDataAvailable')}
      </p>
    </div>
  );

  const datesDropDown = (
    <DropDown
      data={formatedScoreWithDatesSelection}
      value={selectedPreviousData?.date}
      onChange={(value) => {
        if (value === null) {
          setSelectedPreviousData(null);
        } else {
          setSelectedPreviousData(scoresOfDate(value) ?? null);
        }
      }}
      icon={<div className="characterization-previous-scoring-symbol" />}
    />
  );

  return (
    <div className="characterization-container">
      <div className="characterization-legend">
        <div className="characterization-legend-items-container">
          {legendItemElements}
        </div>
        {hasData && (
          <div className="characterization-legend-items-container">
            <div className="characterization-legend-label-container">
              <div className="characterization-latest-scoring-symbol" />
              <p className="p2">
                {t('characterization.legend.scoresOfDate', {
                  date: formattedLatestScoresDate,
                })}
              </p>
            </div>
            {hasPreviousData && datesDropDown}
          </div>
        )}
      </div>
      {!hasData && !isError && noDataMessage}
      {isError ? (
        errorMessage
      ) : (
        <div
          className={`characterization-spider-charts-layout-container ${
            !isError && !hasData ? 'grey-zone' : ''
          }`}
        >
          {spiderCharts}
        </div>
      )}
    </div>
  );
};
