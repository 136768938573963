import { FunctionComponent } from 'react';

import edolLogo from '../../assets/Logo_eDOL_V3.png';
import qoLibriLogo from '../../assets/Logo_QoLibri.png';
import { useDoctorMe } from '../../services/api/hcp';

export const APPLICATION_LOGO_TEST_ID = 'application-logo-test-id';

export const ApplicationLogo: FunctionComponent = () => {
  const { data: hcp } = useDoctorMe();

  const isQoLibriOnly = hcp !== undefined && hcp.isQoLibri && !hcp.isEDOL;

  return (
    <img
      src={isQoLibriOnly ? qoLibriLogo : edolLogo}
      alt="application logo"
      height={45}
      width={118}
      data-testid={APPLICATION_LOGO_TEST_ID}
    />
  );
};
