import { FunctionComponent } from 'react';

import { Back } from './Back';
import { Front } from './Front';
import { Lines } from './Lines';
import { SquaresBack } from './SquaresBack';
import { SquaresFront } from './SquaresFront';

type BodyMapType = 'FRONT' | 'BACK';

interface BodySchemaProps {
  type: BodyMapType;
  colorMap: Record<number, string>;
}

export const BodySchema: FunctionComponent<BodySchemaProps> = ({
  type,
  colorMap,
}) => {
  return (
    <svg
      data-testid={`body-map-${type}`}
      viewBox="0 0 269 607"
      width="100%"
      height="100%"
    >
      <Lines />

      {type === 'FRONT' ? (
        <>
          <SquaresFront colors={colorMap} />
          <Front />
        </>
      ) : (
        <>
          <SquaresBack colors={colorMap} />
          <Back />
        </>
      )}
    </svg>
  );
};
