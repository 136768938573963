import type { DatePickerInputProps } from '@mantine/dates';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Calendar } from '../../../../../../../components';
import { DatePickerInput } from '../DatePicker/DatePickerInput';
import { ComplementaryTreatmentForm } from './ComplementaryTreatmentEditionModal';

type EndDateInputProps = {
  control?: Control<ComplementaryTreatmentForm, unknown>;
  startDate: Date | null;
} & DatePickerInputProps;

export const EndDateInput = ({
  control,
  startDate,
  ...props
}: EndDateInputProps) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="endDate"
      render={({ field: { onChange, onBlur, value } }) => {
        const isEndDateEmpty = value === null;
        return (
          <DatePickerInput
            {...props}
            label={t(
              'followup.medicineTreatments.medicineTreatmentEditionModal.endDate',
            )}
            minDate={startDate ?? undefined}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            clearable={!isEndDateEmpty}
            rightSection={isEndDateEmpty ? <Calendar /> : null}
            styles={{
              rightSection: {
                pointerEvents: isEndDateEmpty ? 'none' : 'all',
              },
            }}
          />
        );
      }}
    />
  );
};
