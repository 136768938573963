import { ChangeEvent, FunctionComponent } from 'react';

import { NOT_NUMBER_REGEX, NUMBER_REGEX } from '../../utils';
import { TextInput, TextInputProps } from '../TextInput/TextInput';

interface NumberInputProps extends Omit<TextInputProps, 'type'> {}

export const NumberInput: FunctionComponent<NumberInputProps> = ({
  onChange,
  ...props
}) => {
  return (
    <TextInput
      // we don't specify type="number" because it would prevent onChange from being
      // called every time the user writes a letter
      type="text"
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        if (onChange === undefined) return;

        const newValue = event.currentTarget.value;

        if (NUMBER_REGEX.test(newValue)) {
          onChange(event);
        } else {
          event.currentTarget.value = newValue.replaceAll(NOT_NUMBER_REGEX, '');

          onChange(event);
        }
      }}
      {...props}
    />
  );
};
