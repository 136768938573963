import './PasswordTextInput.scss';

import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput, TextInputProps } from '../../../../components';
import { Eye } from '../../../../components/icons/Eye';
import { EyeSlash } from '../../../../components/icons/EyeSlash';
import { COLORS } from '../../../../theme/colors';

type PasswordTextInputProps = Omit<TextInputProps, 'label' | 'errorMessage'>;

export const PasswordTextInput: FunctionComponent<PasswordTextInputProps> = ({
  inputTestId,
  ...props
}) => {
  const { t } = useTranslation();
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  return (
    <TextInput
      type={isPasswordVisible ? 'text' : 'password'}
      label={t('login.password')}
      name="password"
      inputTestId={inputTestId}
      rightIcon={
        <div
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          data-testid="password-input-icon"
          className="password-text-input-icon"
        >
          {isPasswordVisible ? (
            <EyeSlash color={`${COLORS.texts.default}`} />
          ) : (
            <Eye color={`${COLORS.texts.default}`} />
          )}
        </div>
      }
      {...props}
    />
  );
};
