export enum ScoreType {
  PROMIS_PAIN_INTERFERENCE = 'PROMIS_PAIN_INTERFERENCE',
  PROMIS_ANXIETY = 'PROMIS_ANXIETY',
  PROMIS_DEPRESSION = 'PROMIS_DEPRESSION',
  PROMIS_SLEEP_DISORDERS = 'PROMIS_SLEEP_DISORDERS',
  PROMIS_FATIGUE = 'PROMIS_FATIGUE',
  NEUROQOL_EMOTIONAL_DYSCONTROL = 'NEUROQOL_EMOTIONAL_DYSCONTROL',
  PROMIS_COGNITIVE_FUNCTIONS = 'PROMIS_COGNITIVE_FUNCTIONS',
  PROMIS_SOCIAL_SKILLS = 'PROMIS_SOCIAL_SKILLS',
  PROMIS_PHYSICAL_FUNCTION = 'PROMIS_PHYSICAL_FUNCTION',
  NEUROQOL_POSITIVE_AFFECT = 'NEUROQOL_POSITIVE_AFFECT',
  TSK4_KINESIOPHOBIA = 'TSK4_KINESIOPHOBIA',
  PCS4_CATASTROPHISM = 'PCS4_CATASTROPHISM',
  ETA_SF_ALEXITHYMIA = 'ETA_SF_ALEXITHYMIA',
  EPICES_PRECARITY = 'EPICES_PRECARITY',
  IPAQ_SF_PHYSICAL_ACTIVITY = 'IPAQ_SF_PHYSICAL_ACTIVITY',
  IN_HOUSE_MUSCULAR_STRENGTH = 'IN_HOUSE_MUSCULAR_STRENGTH',
  IN_HOUSE_FLEXIBILITY = 'IN_HOUSE_FLEXIBILITY',
  IN_HOUSE_ENDURANCE = 'IN_HOUSE_ENDURANCE',
  IN_HOUSE_BALANCE = 'IN_HOUSE_BALANCE',
}

export type Scores = Record<ScoreType, number>;
export interface ScoresWithDate {
  date: string;
  scores: Scores;
}
