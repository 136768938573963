import './Header.scss';

// eslint-disable-next-line no-restricted-imports
import { Header as MantineHeader } from '@mantine/core';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ArrowLeft,
  Button,
  ButtonClass,
  IconButton,
} from '../../../../components';
import { LegalLinks } from '../../../../molecules/LegalLinks/LegalLinks';
import { COLORS } from '../../../../theme/colors';
import { Patient } from '../../../../types/patient';
import { PatientDetailsPageName } from '../../types';

interface HeaderProps {
  patient: Patient;
  pageName: PatientDetailsPageName;
  onBackButtonClick: () => void;
  onLogOut: () => Promise<void>;
}

export const Header: FunctionComponent<HeaderProps> = ({
  patient,
  pageName,
  onBackButtonClick,
  onLogOut,
}) => {
  const { t } = useTranslation();
  const patientName = `${patient.lastName.toUpperCase()} ${patient.firstName}`;

  return (
    <MantineHeader height={72} className="patient-details-header" zIndex={103}>
      <div className="patient-details-header-content">
        <IconButton
          Icon={ArrowLeft}
          color={COLORS.texts.second}
          onClick={onBackButtonClick}
        />
        <div className="patient-details-title">
          <p className="p1-bold">{patientName}</p>
          <p className="p1-bold">/</p>
          <p className="p1-bold">{t(`pageName.${pageName}`)}</p>
        </div>
      </div>
      <div className="patient-details-header-right-section">
        <div className="patient-details-external-links-container">
          <LegalLinks />
        </div>
        <Button
          buttonClass={ButtonClass.SECONDARY}
          text={t('logOut')}
          onClick={onLogOut}
          data-testid="log-out-button"
          className="patient-details-log-out-button"
        />
      </div>
    </MantineHeader>
  );
};
