import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '../../../../../../../../components';
import {
  Information,
  SIZE_FOR_TOOLTIP,
} from '../../../../../../../../components/icons/Information';
import { COLORS } from '../../../../../../../../theme/colors';

interface DailyDosageToolTipProps {
  isDailyDosageValid: boolean;
}

export const DailyDosageToolTip: FunctionComponent<DailyDosageToolTipProps> = ({
  isDailyDosageValid,
}) => {
  const { t } = useTranslation();
  const dailyDosageTooltipDescription = (
    <ul className="daily-dosage-input-tooltip-description">
      <li>
        {t(
          'followup.medicineTreatments.medicineTreatmentEditionModal.dailyDosageToolTip.hint',
        )}
      </li>
    </ul>
  );

  return (
    <Tooltip
      title={t(
        'followup.medicineTreatments.medicineTreatmentEditionModal.dailyDosageToolTip.information',
      )}
      description={dailyDosageTooltipDescription}
    >
      <div className="daily-dosage-input-icon-container">
        <Information
          color={
            isDailyDosageValid ? COLORS.primary._500 : COLORS.statuses.error
          }
          height={SIZE_FOR_TOOLTIP}
          width={SIZE_FOR_TOOLTIP}
        />
      </div>
    </Tooltip>
  );
};
