import './BodyMapColorLegend.scss';

import { FunctionComponent } from 'react';

interface BodyMapColorLegendProps {
  color: string;
  scale: string;
}

export const BodyMapColorLegend: FunctionComponent<BodyMapColorLegendProps> = ({
  color,
  scale,
}) => {
  return (
    <div className="body-map-legend" data-testid="body-map-color-legend">
      <p className="p2">{scale}</p>
      <div
        className="body-map-color-legend"
        style={{ backgroundColor: color }}
      />
    </div>
  );
};
