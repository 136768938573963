import { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from '../../../../../components';
import { AddPatientForm } from '../AddPatientModal';

interface FirstNameInputProps {
  control: Control<AddPatientForm>;
  testId: string;
}

export const FirstNameInput: FunctionComponent<FirstNameInputProps> = ({
  control,
  testId,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="firstName"
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <TextInput
            inputTestId={`${testId}-firstname-input`}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            label={t(
              'patientList.addPatientModal.patientInformation.firstname',
            )}
            isValid={error === undefined}
          />
        );
      }}
      rules={{ required: true }}
    />
  );
};
