import { t } from 'i18next';

import { DosageUnit } from '../../../../../types/medicineTreatment';

export const DAILY_DOSAGE_REPLACEMENT_TEXT = '-';

export const getDailyDosageLabel = (dailyDosage: number | null): string => {
  return dailyDosage !== null
    ? dailyDosage.toString()
    : DAILY_DOSAGE_REPLACEMENT_TEXT;
};

export const getDailyDosageUnitLabel = (
  dailyDosageUnit: DosageUnit | null,
): string => {
  return dailyDosageUnit !== null
    ? t(`followup.medicineTreatments.dosageUnit.${dailyDosageUnit}`)
    : DAILY_DOSAGE_REPLACEMENT_TEXT;
};

export const getDailyDosageAndUnitLabel = (
  dailyDosage: number | null,
  dailyDosageUnit: DosageUnit | null,
): string => {
  return dailyDosage !== null && dailyDosageUnit !== null
    ? `${dailyDosage} ${t(
        `followup.medicineTreatments.dosageUnit.${dailyDosageUnit}`,
      )}`
    : DAILY_DOSAGE_REPLACEMENT_TEXT;
};

export const getDailyDosageAndUnitLabelPerDay = (
  dailyDosage: number | null,
  dailyDosageUnit: DosageUnit | null,
): string => {
  return dailyDosage !== null && dailyDosageUnit !== null
    ? `${dailyDosage} ${t(
        `followup.medicineTreatments.dosageUnit.${dailyDosageUnit}`,
      )}${t('followup.medicineTreatments.medicineTreatmentTooltip.perDay')}`
    : DAILY_DOSAGE_REPLACEMENT_TEXT;
};
