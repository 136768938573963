import './BodyMapCard.scss';

import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DropDown } from '../../../../../../components';
import {
  BodyMapPartPainAnswer,
  QuestionnaireAnswer,
} from '../../../../../../types/questionnaire';
import {
  Status,
  StatusTag,
} from '../../../../../PatientList/components/StatusTag/StatusTag';
import { OldDatesWithAnswers } from '../BodyMaps';
import { BodySchema } from '../components/BodySchema';
import {
  BACK_BODY_MAP_QUESTION_ID,
  FACE_BODY_MAP_QUESTION_ID,
  HAS_MOUTH_PAIN_QUESTION_ID,
  HAS_PELVIC_PAIN_QUESTION_ID,
  MOUTH_PAIN_QUESTION_ID,
  PAIN_TYPES_QUESTION_ID,
  PELVIC_PAIN_QUESTION_ID,
  YES_NO_QUESTION_YES_SCORE,
} from './constants';

interface BodyMapCardProps {
  date: string;
  isMostRecent: boolean;
  oldDatesWithAnswers?: OldDatesWithAnswers[];
  bodyMapAnswer: QuestionnaireAnswer;
}

export const BodyMapCard: FunctionComponent<BodyMapCardProps> = ({
  date,
  isMostRecent,
  oldDatesWithAnswers,
  bodyMapAnswer,
}) => {
  const { t } = useTranslation();

  const [selectedBodyMapAnswer, setSelectedBodyMapAnswer] =
    useState<OldDatesWithAnswers | null>(null);

  const questionnaireAnswersOfId = (
    selectedId: string,
  ): OldDatesWithAnswers | undefined =>
    hasSeveralPreviousAnswers
      ? oldDatesWithAnswers.find(
          ({ bodyMapAnswer }) => bodyMapAnswer.id === selectedId,
        )
      : undefined;

  const hasSeveralPreviousAnswers =
    oldDatesWithAnswers !== undefined && oldDatesWithAnswers.length > 1;

  const patientBodyMapAnswer =
    hasSeveralPreviousAnswers && selectedBodyMapAnswer !== null
      ? selectedBodyMapAnswer.bodyMapAnswer
      : bodyMapAnswer;

  const faceMapDimensionAnswer = patientBodyMapAnswer.dimensionAnswers.find(
    (dimensionAnswer) => dimensionAnswer.dimension.id === 'SC_FACE_PAIN',
  );
  const backMapDimensionAnswer = patientBodyMapAnswer.dimensionAnswers.find(
    (dimensionAnswer) => dimensionAnswer.dimension.id === 'SC_BACK_PAIN',
  );
  const painTypeDimensionAnswer = patientBodyMapAnswer.dimensionAnswers.find(
    (dimensionAnswer) => dimensionAnswer.dimension.id === 'SC_PAIN_TYPE',
  );

  const hasFacePainAnswer = faceMapDimensionAnswer !== undefined;
  const hasBackPainAnswer = backMapDimensionAnswer !== undefined;
  const hasPainTypeAnswer = painTypeDimensionAnswer !== undefined;

  const facePainAnswer: BodyMapPartPainAnswer = hasFacePainAnswer
    ? (faceMapDimensionAnswer.answers.find(
        ({ questionId }) => questionId === FACE_BODY_MAP_QUESTION_ID,
      )?.value as BodyMapPartPainAnswer)
    : {};

  const backPainAnswer: BodyMapPartPainAnswer = hasBackPainAnswer
    ? (backMapDimensionAnswer.answers.find(
        ({ questionId }) => questionId === BACK_BODY_MAP_QUESTION_ID,
      )?.value as BodyMapPartPainAnswer)
    : {};

  const hasPatientMouthPain =
    hasPainTypeAnswer &&
    painTypeDimensionAnswer.answers.find(
      (questionAnswer) =>
        questionAnswer.questionId === HAS_MOUTH_PAIN_QUESTION_ID,
    )?.score === YES_NO_QUESTION_YES_SCORE;

  const hasPatientPelvicPain =
    hasPainTypeAnswer &&
    painTypeDimensionAnswer.answers.find(
      (questionAnswer) =>
        questionAnswer.questionId === HAS_PELVIC_PAIN_QUESTION_ID,
    )?.score === YES_NO_QUESTION_YES_SCORE;

  const patientMouthPain: string[] = hasPatientMouthPain
    ? (painTypeDimensionAnswer.answers.find(
        (questionAnswer) =>
          questionAnswer.questionId === MOUTH_PAIN_QUESTION_ID,
      )?.value as string[])
    : [];

  const patientPelvicPain: string[] = hasPatientPelvicPain
    ? (painTypeDimensionAnswer.answers.find(
        (questionAnswer) =>
          questionAnswer.questionId === PELVIC_PAIN_QUESTION_ID,
      )?.value as string[])
    : [];

  const patientPainTypes: string[] = hasPainTypeAnswer
    ? (painTypeDimensionAnswer.answers.find(
        (questionAnswer) =>
          questionAnswer.questionId === PAIN_TYPES_QUESTION_ID,
      )?.value as string[])
    : [];

  const mouthPainTypeDetailsContent = hasPatientMouthPain ? (
    <div className="pain-types-content">
      <p className="p3-bold">{t('characterization.mouthArea')}</p>
      <ul className="pain-details-list">
        {patientMouthPain.map((value) => (
          <li key={value}>
            <p className="p2">{value}</p>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <div className="no-pain-message" data-testid="no-mouth-pain-message">
      <p className="no-pain-text">{t('characterization.noMouthPain')}</p>
    </div>
  );

  const pelvicPainTypeDetailsContent = hasPatientPelvicPain ? (
    <div className="pain-types-content">
      <p className="p3-bold">{t('characterization.pelvicArea')}</p>
      <ul className="pain-details-list">
        {patientPelvicPain.map((value) => (
          <li key={value}>
            <p className="p2">{value}</p>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <div className="no-pain-message" data-testid="no-pelvic-pain-message">
      <p className="no-pain-text">{t('characterization.noPelvicPain')}</p>
    </div>
  );

  const painTypesDetailsContent = (
    <div className="pain-types-content" data-testId="pain-types-content">
      <p className="p3-bold">{t('characterization.painTypes')}</p>
      <ul className="pain-details-list">
        {patientPainTypes.map((value) => (
          <li key={value}>
            <p className="p2">{value}</p>
          </li>
        ))}
      </ul>
    </div>
  );

  const separator = <div className="body-map-details-separator"></div>;

  const bodyPainTypeDetails = (
    <div
      className="body-pain-type-details-container"
      data-testid="pain-type-details-section"
    >
      {mouthPainTypeDetailsContent}
      {separator}
      {pelvicPainTypeDetailsContent}
      {separator}
      {painTypesDetailsContent}
    </div>
  );

  return (
    <div
      className="body-map-card-container"
      data-testid={`body-map-card-${date}`}
    >
      <div className="body-map-card-header">
        {hasSeveralPreviousAnswers ? (
          <DropDown
            data-testid="body-map-dropdown"
            data={oldDatesWithAnswers}
            defaultValue={oldDatesWithAnswers[0].value}
            onChange={(value) => {
              if (value === null) {
                setSelectedBodyMapAnswer(null);
              } else {
                setSelectedBodyMapAnswer(
                  questionnaireAnswersOfId(value) ?? null,
                );
              }
            }}
          />
        ) : (
          <p className="p1">{date}</p>
        )}
        {isMostRecent && (
          <StatusTag
            text={t('characterization.mostRecent')}
            status={Status.SUCCESS}
          />
        )}
      </div>
      <div className="body-maps-container">
        <BodySchema type="FRONT" colorMap={facePainAnswer} />
        <BodySchema type="BACK" colorMap={backPainAnswer} />
      </div>
      {bodyPainTypeDetails}
    </div>
  );
};
