import './Header.scss';

// eslint-disable-next-line no-restricted-imports
import { Header as MantineHeader } from '@mantine/core';
import { signOut } from 'firebase/auth';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonClass } from '../../../../components';
import { auth } from '../../../../firebaseConfig';
import { ApplicationLogo } from '../../../../molecules/ApplicationLogo/ApplicationLogo';
import { LegalLinks } from '../../../../molecules/LegalLinks/LegalLinks';

export const Header: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <MantineHeader height={72} className="patient-list-header">
      <div className="patient-list-header-content">
        <ApplicationLogo />
      </div>
      <div className="patient-list-header-right-section">
        <div className="patient-list-external-links-container">
          <LegalLinks />
        </div>
        <Button
          buttonClass={ButtonClass.SECONDARY}
          text={t('logOut')}
          onClick={async () => {
            await signOut(auth);
          }}
          data-testid="log-out-button"
          className="patient-list-log-out-button"
        />
      </div>
    </MantineHeader>
  );
};
