export interface Patient {
  id: string;
  firebaseUid: string;
  createdAt: Date;
  firstName: string;
  lastName: string;
}

export enum Sex {
  WOMAN = 'WOMAN',
  MAN = 'MAN',
  OTHER = 'OTHER',
}

export enum PatientApplication {
  EDOL = 'EDOL',
  QOLIBRI = 'QOLIBRI',
}

export interface PatientDto {
  email: string;
  firstName: string;
  lastName: string;
  sex: Sex;
  birthYear: number;
  socialSecurityNumber: string;
  firstVisitDate: string;
  referringDoctorId: string;
  application: PatientApplication;
}
