import { FunctionComponent, ReactNode } from 'react';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from '../../../../../../../../components';
import { isPositiveFloat } from '../../../../../../../../utils';
import { MedicineTreatmentForm } from '../types';
import { DAILY_DOSE_UNIT_CONTROL_NAME } from './DailyDoseUnit';

export const DAILY_DOSAGE_INPUT_CONTROL_NAME = 'dailyDosage';

interface DailyDosageInputProps {
  control: Control<MedicineTreatmentForm>;
  trigger: UseFormTrigger<MedicineTreatmentForm>;
  dailyDosageTooltip: ReactNode;
  isRequired: boolean;
}

export const DailyDosageInput: FunctionComponent<DailyDosageInputProps> = ({
  control,
  trigger,
  dailyDosageTooltip,
  isRequired,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={DAILY_DOSAGE_INPUT_CONTROL_NAME}
      rules={{
        required: false,
        validate: {
          isPositiveFloat: (value) =>
            !value ||
            isPositiveFloat(value) ||
            t(
              'followup.medicineTreatments.medicineTreatmentEditionModal.errors.dailyDosePositiveFloat',
            ),
          isRequired: (value) =>
            !isRequired ||
            !!value ||
            t(
              'followup.medicineTreatments.medicineTreatmentEditionModal.errors.dailyDosePreviouslyFilled',
            ),
          dailyDoseUnitFilled: (value, formValues) =>
            !formValues.dailyDosageUnit ||
            !!value ||
            t(
              'followup.medicineTreatments.medicineTreatmentEditionModal.errors.dailyDoseRequired',
            ),
        },
      }}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { invalid, error },
      }) => {
        return (
          <TextInput
            type="text"
            label={t(
              'followup.medicineTreatments.medicineTreatmentEditionModal.dailyDose',
            )}
            inputTestId={'daily-dose-input'}
            isValid={!invalid}
            onChange={async (value) => {
              onChange(value);
              await trigger(DAILY_DOSE_UNIT_CONTROL_NAME);
            }}
            onBlur={onBlur}
            value={value?.toString()}
            tooltip={dailyDosageTooltip}
            errorMessage={error?.message}
          />
        );
      }}
    />
  );
};
