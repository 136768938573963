import { useQuery } from '@tanstack/react-query';

import { GroupedActivityRecords } from '../../types/activities';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

const getActivitiesDoneByPatient = async (
  patientId: string,
): Promise<GroupedActivityRecords> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.ACTIVITIES_DONE, {
    pathParameters: { ':patientId': patientId },
  });
};

export const useGetActivitiesDoneByPatient = (patientId: string) =>
  useQuery<GroupedActivityRecords>(
    ['activities', patientId],
    () => getActivitiesDoneByPatient(patientId),
    { retry: 0 },
  );
