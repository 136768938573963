import { COLORS } from '../../theme/colors';
import { Icon } from './types';

export const AngleDown: Icon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M17.0002 9.17C16.8128 8.98375 16.5594 8.87921 16.2952 8.87921C16.031 8.87921 15.7776 8.98375 15.5902 9.17L12.0002 12.71L8.46019 9.17C8.27283 8.98375 8.01938 8.87921 7.75519 8.87921C7.49101 8.87921 7.23756 8.98375 7.05019 9.17C6.95646 9.26297 6.88207 9.37357 6.8313 9.49543C6.78053 9.61729 6.75439 9.74799 6.75439 9.88C6.75439 10.012 6.78053 10.1427 6.8313 10.2646C6.88207 10.3864 6.95646 10.497 7.05019 10.59L11.2902 14.83C11.3832 14.9237 11.4938 14.9981 11.6156 15.0489C11.7375 15.0997 11.8682 15.1258 12.0002 15.1258C12.1322 15.1258 12.2629 15.0997 12.3848 15.0489C12.5066 14.9981 12.6172 14.9237 12.7102 14.83L17.0002 10.59C17.0939 10.497 17.1683 10.3864 17.2191 10.2646C17.2699 10.1427 17.296 10.012 17.296 9.88C17.296 9.74799 17.2699 9.61729 17.2191 9.49543C17.1683 9.37357 17.0939 9.26297 17.0002 9.17Z"
        fill={props.color ?? COLORS.texts.default}
      />
    </svg>
  );
};
