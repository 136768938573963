import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ArrowRight,
  Button,
  ButtonClass,
  LabeledText,
} from '../../../../../../../components';
import { formatDateFrench } from '../../../../../../../services/date/formatDateFrench';
import { MedicineTreatment } from '../../../../../../../types/medicineTreatment';
import { getDailyDosageAndUnitLabel } from '../../posology';
import { isTreatmentAge } from '../../utils';

interface MedicineTreatmentLatestInformationProps {
  medicineTreatment: MedicineTreatment;
  onClickModify: () => void;
}

export const MedicineTreatmentLatestInformation: FunctionComponent<
  MedicineTreatmentLatestInformationProps
> = ({ medicineTreatment, onClickModify }) => {
  const { t } = useTranslation();

  return (
    <div className="information-modal-tab-container">
      <LabeledText
        label={t(
          'followup.medicineTreatments.medicineTreatmentModal.recentInformationTab.medicineName',
        )}
        text={medicineTreatment.medicine.denomination}
      />

      <LabeledText
        label={t(
          'followup.medicineTreatments.medicineTreatmentModal.inCommon.dailyDosage',
        )}
        text={getDailyDosageAndUnitLabel(
          medicineTreatment.dailyDosage,
          medicineTreatment.dailyDosageUnit,
        )}
      />

      <LabeledText
        label={t(
          'followup.medicineTreatments.medicineTreatmentModal.inCommon.treatmentType',
        )}
        text={t(`followup.treatmentTypes.${medicineTreatment.type}`)}
      />

      <div className="information-modal-horizontal-section">
        <LabeledText
          label={t(
            'followup.medicineTreatments.medicineTreatmentModal.inCommon.startDate',
          )}
          text={
            isTreatmentAge(medicineTreatment.startDate)
              ? t(`followup.treatmentAge.${medicineTreatment.startDate}`)
              : formatDateFrench(medicineTreatment.startDate)
          }
        />

        <LabeledText
          label={t('followup.inCommon.endDate')}
          text={
            medicineTreatment.endDate === null
              ? t(
                  'followup.medicineTreatments.medicineTreatmentModal.recentInformationTab.treatmentInProgress',
                )
              : formatDateFrench(medicineTreatment.endDate)
          }
        />
      </div>

      <LabeledText
        label={t(
          'followup.medicineTreatments.medicineTreatmentModal.inCommon.note',
        )}
        text={
          medicineTreatment.details === null || medicineTreatment.details === ''
            ? '-'
            : medicineTreatment.details
        }
      />

      <div className="information-modal-modify-button">
        <Button
          buttonClass={ButtonClass.PRIMARY}
          text={t('followup.inCommon.modifyInformation')}
          RightIcon={ArrowRight}
          onClick={onClickModify}
          data-testid="information-modal-modify-button"
        />
      </div>
    </div>
  );
};
