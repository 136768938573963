import './BarometerChart.scss';

import dayjs from 'dayjs';
import { FunctionComponent, JSX, useEffect, useRef, useState } from 'react';
import type { PaddingProps } from 'victory';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryScatter,
} from 'victory';

import { Color, COLORS } from '../../../../../../../theme/colors';
import {
  BarometerAnswer,
  BarometerType,
} from '../../../../../../../types/barometer';
import emoticonHappy from './assets/smiley-happy.png';
import emoticonVeryBad from './assets/smiley-very-bad.png';

const BAROMETER_CHART_DEFAULT_HEIGHT_IN_PX = 230;
const BAROMETER_CHART_DEFAULT_PADDING: PaddingProps = {
  top: 15,
  bottom: 5,
  left: 20,
  right: 0,
};
const BAROMETER_RANGE_VALUES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const BAROMETER_Y_RANGE_BUFFER = 0.2;

const BAROMETER_TYPE_COLOR_MAPPING: Record<
  BarometerType,
  { strokeColor: Color; backgroundGradientId: string }
> = {
  [BarometerType.SLEEP]: {
    strokeColor: COLORS.secondary.blue,
    backgroundGradientId: 'blueGradient',
  },
  [BarometerType.MOBILITY]: {
    strokeColor: COLORS.secondary.pink,
    backgroundGradientId: 'pinkGradient',
  },
  [BarometerType.BODY_COMFORT]: {
    strokeColor: COLORS.secondary.orange,
    backgroundGradientId: 'orangeGradient',
  },
  [BarometerType.MORALE]: {
    strokeColor: COLORS.secondary.turquoise,
    backgroundGradientId: 'turquoiseGradient',
  },
  [BarometerType.SERENITY]: {
    strokeColor: COLORS.secondary.green,
    backgroundGradientId: 'greenGradient',
  },
};

interface BarometerChartProps {
  startDateIso: string;
  endDateIso: string;
  groupedAnswers: { type: BarometerType; answers: BarometerAnswer[] }[];
  shownBarometerTypes: Record<BarometerType, boolean>;
  height?: number;
  padding?: PaddingProps;
}

export const BarometerChart: FunctionComponent<BarometerChartProps> = ({
  startDateIso,
  endDateIso,
  groupedAnswers,
  shownBarometerTypes,
  height,
  padding,
}) => {
  const gradientDefinitions: JSX.Element[] = [];
  const chartAreas: JSX.Element[] = [];
  groupedAnswers.forEach(({ type, answers }) => {
    if (!shownBarometerTypes[type]) return;

    const data = answers.map((answer) => ({
      x: dayjs(answer.createdAt).startOf('day').toDate(),
      y: answer.value,
    }));
    const { backgroundGradientId, strokeColor } =
      BAROMETER_TYPE_COLOR_MAPPING[type];

    if (data.length > 1) {
      chartAreas.push(
        <VictoryArea
          key={type}
          interpolation="cardinal"
          scale={{ x: 'time', y: 'linear' }}
          data={data}
          style={{
            data: {
              stroke: strokeColor,
              strokeWidth: 1.5,
              fill: `url(#${backgroundGradientId})`,
            },
          }}
        />,
      );

      gradientDefinitions.push(
        <linearGradient
          key={type}
          id={backgroundGradientId}
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor={strokeColor} stopOpacity="0.15" />
          <stop offset="100%" stopColor={strokeColor} stopOpacity="0.05" />
        </linearGradient>,
      );
    } else {
      chartAreas.push(
        <VictoryScatter
          style={{ data: { fill: strokeColor } }}
          data={data}
          size={7}
        />,
      );
    }
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const updateChartWidth = () => {
    if (containerRef.current !== null) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  };

  useEffect(updateChartWidth, [containerRef]);

  useEffect(() => {
    window.addEventListener('resize', updateChartWidth);

    return () => window.removeEventListener('resize', updateChartWidth);
  }, []);

  return (
    <div className="barometer-chart-container" ref={containerRef}>
      <VictoryChart
        domain={{
          x: [new Date(startDateIso), new Date(endDateIso)],
          y: [
            BAROMETER_RANGE_VALUES[0] - BAROMETER_Y_RANGE_BUFFER,
            BAROMETER_RANGE_VALUES[BAROMETER_RANGE_VALUES.length - 1] +
              BAROMETER_Y_RANGE_BUFFER,
          ],
        }}
        padding={padding ?? BAROMETER_CHART_DEFAULT_PADDING}
        height={height ?? BAROMETER_CHART_DEFAULT_HEIGHT_IN_PX}
        width={containerWidth}
      >
        <defs>{gradientDefinitions}</defs>
        <VictoryAxis
          dependentAxis
          tickValues={BAROMETER_RANGE_VALUES}
          style={{
            tickLabels: {
              fontFamily: "'Be Vietnam Pro', sans-serif",
              fontSize: 11,
              fill: COLORS.texts.second,
              padding: 12,
            },
            axis: {
              stroke: COLORS.grey.grey70,
            },
            grid: { stroke: COLORS.grey.grey70 },
          }}
          tickLabelComponent={<VictoryLabel textAnchor="middle" />}
        />
        {chartAreas}
      </VictoryChart>
      <img
        src={emoticonHappy}
        width={16}
        height={16}
        className="barometer-chart-emoticon-happy"
      />
      <img
        src={emoticonVeryBad}
        width={16}
        height={16}
        className="barometer-chart-emoticon-very-bad"
      />
    </div>
  );
};
