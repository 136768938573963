import { Icon } from './types';

export const Loader: Icon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_7745_16857)">
        <path
          d="M5.47376 5.47376C5.17286 5.17286 4.68243 5.17107 4.40558 5.49423C3.04147 7.08654 2.20789 9.07333 2.03402 11.1759C1.83891 13.5352 2.48716 15.8872 3.86343 17.8135C5.2397 19.7397 7.25472 21.1152 9.54995 21.6952C11.8452 22.2752 14.2717 22.0222 16.3979 20.981C18.524 19.9399 20.2119 18.1782 21.161 16.0094C22.1102 13.8406 22.2592 11.4054 21.5814 9.13715C20.9037 6.86885 19.4432 4.91457 17.4598 3.62202C15.6923 2.47017 13.6117 1.91041 11.5174 2.01164C11.0924 2.03218 10.7931 2.42069 10.8463 2.84289C10.8995 3.26509 11.2851 3.56052 11.7104 3.54594C13.4418 3.4866 15.1567 3.96046 16.6184 4.91307C18.2962 6.00643 19.5316 7.65956 20.1049 9.57831C20.6783 11.4971 20.5522 13.557 19.7493 15.3915C18.9464 17.2261 17.5187 18.7163 15.7202 19.597C13.9217 20.4777 11.869 20.6918 9.9275 20.2012C7.98596 19.7105 6.28146 18.547 5.11728 16.9176C3.95309 15.2882 3.40473 13.2986 3.56977 11.3029C3.71357 9.56403 4.39077 7.91878 5.49928 6.58747C5.77156 6.26045 5.77466 5.77466 5.47376 5.47376Z"
          fill="#001D4A"
        />
      </g>
      <defs>
        <clipPath id="clip0_7745_16857">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
