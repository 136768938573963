import type { MantineThemeColorsOverride } from '@mantine/styles';

export type Color = `#${string}`;

export const COLORS = {
  primary: {
    _500: '#01809C',
  },
  secondary: {
    blue: '#568FFF',
    turquoise: '#3FD0CC',
    pink: '#E18DBF',
    orange: '#FFAE63',
    green: '#5FBC34',
  },
  tertiary: {
    blue: '#0056D7',
    turquoise: '#01809C',
    pink: '#BB1779',
    orange: '#BB5B02',
    yellow: '#FFEAB1',
    lightGreen: '#BDEBCE',
    lightBlue: '#C8DAFF',
    lightTurquoise: '#C2E5E3',
    lightYellow: '#FFFDCA',
    lightOrange: '#FFD9A5',
    lightPeach: '#FFB8A3',
  },
  texts: {
    default: '#28282B',
    second: '#707070',
    third: '#939395',
  },
  white: '#FFFFFF',
  black: '#000000',
  grey: {
    grey70: '#E7E9F2',
    grey500: '#706F6F',
    grey60Background: '#F0F1F3',
    grey50Background: '#F5F5F5',
  },
  statuses: {
    error: '#ff4e4e',
    errorLight: '#ffe1e1',
    warning: '#ffaa2b',
    warningLight: '#fff1de',
    success: '#26d989',
    successLight: '#EBFBF1',
  },
  pain: {
    red: '#FF8763',
    orange: '#FFC87D',
    yellow: '#FFEC41',
    blue: '#B6E4E2',
    green: '#A0F2BF',
  },
} satisfies Record<string, Color | Record<string, Color>>;

export enum ThemeColor {
  primaryBlue = 'primaryBlue',
  blue = 'blue',
  orange = 'orange',
  pink = 'pink',
  green = 'green',
  turquoise = 'turquoise',
}

export const MANTINE_COLORS: MantineThemeColorsOverride = {
  primaryBlue: new Array(10).fill(COLORS.primary._500),
  blue: new Array(10).fill(COLORS.secondary.blue),
  orange: new Array(10).fill(COLORS.secondary.orange),
  pink: new Array(10).fill(COLORS.secondary.pink),
  green: new Array(10).fill(COLORS.secondary.green),
  turquoise: new Array(10).fill(COLORS.secondary.turquoise),
} satisfies Record<ThemeColor, string[]>;
