import { useQuery } from '@tanstack/react-query';

import { QuestionnaireAnswer } from '../../types/questionnaire';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

const getQuestionnaireAnswers = async (
  patientId: string,
  questionnaireId: string,
): Promise<QuestionnaireAnswer[]> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.QUESTIONNAIRE, {
    pathParameters: {
      ':patientId': patientId,
      ':questionnaireId': questionnaireId,
    },
  });
};

export const useGetQuestionnaireAnswers = (
  patientId: string,
  questionnaireId: string,
) =>
  useQuery<QuestionnaireAnswer[]>(
    ['questionnaireAnswers', patientId, questionnaireId],
    () => getQuestionnaireAnswers(patientId, questionnaireId),
  );
