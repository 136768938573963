import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorApiMessage } from '../../../../../../components';
import { useGetActivitiesDoneByPatient } from '../../../../../../services/api/activities-done';
import { COLORS } from '../../../../../../theme/colors';
import {
  ActivityApproach,
  ActivityRecord,
} from '../../../../../../types/activities';
import { FourTimeMarkers } from '../../types';
import { ActivitiesToolTipContent } from '../TooltipContent/ActivitiesToolTipContent';
import { TreatmentCard } from '../TreatmentCard/TreatmentCard';
import { Loader } from '../utils';

interface ActivitiesProps {
  patientId: string;
  timeMarkers: FourTimeMarkers;
}

export const Activities: FunctionComponent<ActivitiesProps> = ({
  patientId,
  timeMarkers,
}) => {
  const { t } = useTranslation();

  const {
    data: groupedActivitiesDone,
    isLoading,
    isError,
  } = useGetActivitiesDoneByPatient(patientId);

  const noApproachActivities = groupedActivitiesDone?.NO_APPROACH ?? [];
  const initiationActivities = groupedActivitiesDone?.INITIATION ?? [];

  const activityApproaches: Record<
    ActivityApproach,
    { title: string; activities: ActivityRecord[] }
  > = {
    PSYCHOLOGICAL: {
      title: t('followup.activities.psychologicalActivities'),
      activities: groupedActivitiesDone?.PSYCHOLOGICAL ?? [],
    },
    BODY: {
      title: t('followup.activities.bodyActivities'),
      activities: groupedActivitiesDone?.BODY ?? [],
    },
    NO_APPROACH: {
      title: t('followup.activities.outOfProgramActivities'),
      activities: [...noApproachActivities, ...initiationActivities].sort(
        (activity1, activity2) =>
          new Date(activity1.createdAt).valueOf() -
          new Date(activity2.createdAt).valueOf(),
      ),
    },
  };

  const hasPatientDoneActivities =
    groupedActivitiesDone !== undefined &&
    groupedActivitiesDone.BODY.length +
      groupedActivitiesDone.INITIATION.length +
      groupedActivitiesDone.PSYCHOLOGICAL.length +
      groupedActivitiesDone.NO_APPROACH.length >
      0;

  const activityTreatmentCards = Object.entries(activityApproaches).map(
    ([approachType, value]) => {
      return (
        <TreatmentCard
          treatmentName={value.title}
          key={approachType}
          leftBound={timeMarkers[0]}
          rightBound={timeMarkers[3]}
          startDate={
            value.activities.length > 0
              ? value.activities[0].createdAt.toString()
              : null
          }
          endDate={null}
          color={COLORS.tertiary.lightBlue}
          testId="activity-lines-id"
          onClick={() => {}}
          onClickModify={() => {}}
          editable={false}
          startDateTooltipContent={<></>}
          endDateTooltipContent={<></>}
          modificationTooltips={value.activities.map(({ createdAt }) => {
            return {
              modificationDate: createdAt.toString(),
              tooltipContent: (
                <ActivitiesToolTipContent
                  date={createdAt.toString()}
                  activityNames={value.activities
                    .filter(
                      (activityRecord) =>
                        new Date(activityRecord.createdAt).getDate() ===
                        new Date(createdAt).getDate(),
                    )
                    .map(({ activity }) => {
                      return activity.name;
                    })}
                />
              ),
            };
          })}
          tooltipBorderColor={COLORS.secondary.blue}
          tooltipClassname={'tooltip-dot'}
        />
      );
    },
  );
  return (
    <div className="followup-title-and-card-container">
      <h2
        className="followup-section-title"
        data-testid="activities-section-title"
      >
        {t('followup.activities.activitiesSectionTitle')}
      </h2>
      {isLoading && <Loader testId="activities-loader" />}
      {isError ? (
        <ErrorApiMessage
          title={t('apiMessage.serverError.errorOccurred')}
          subtitle={t('apiMessage.serverError.tryAgainLater')}
          className="treatment-error"
        />
      ) : !hasPatientDoneActivities ? (
        <p className="p2" data-testid="no-activities-done-message">
          {t('followup.activities.noActivitiesDone')}
        </p>
      ) : (
        <>{activityTreatmentCards}</>
      )}
    </div>
  );
};
