import './Barometer.scss';

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActivityIndicator,
  ErrorApiMessage,
} from '../../../../../../components';
import {
  BarometerAnswer,
  BarometerType,
} from '../../../../../../types/barometer';
import { FourTimeMarkers } from '../../types';
import { BarometerChart } from './components/BarometerChart';
import {
  BarometerCheckboxes,
  BarometerCheckboxesProps,
} from './components/BarometerCheckboxes';
import { Events } from './components/Events';

interface BarometerProps extends BarometerCheckboxesProps {
  isError: boolean;
  isLoading: boolean;
  groupedBarometerAnswers: {
    type: BarometerType;
    answers: BarometerAnswer[];
  }[];
  timeMarkers: FourTimeMarkers;
}

export const Barometer: FunctionComponent<BarometerProps> = ({
  isError,
  isLoading,
  groupedBarometerAnswers,
  timeMarkers,
  shownBarometerTypes,
  setShownBarometerTypes,
}) => {
  const { t } = useTranslation();

  const barometerChart = (
    <BarometerChart
      startDateIso={timeMarkers[0].toISOString()}
      endDateIso={timeMarkers[timeMarkers.length - 1].toISOString()}
      groupedAnswers={groupedBarometerAnswers ?? []}
      shownBarometerTypes={shownBarometerTypes}
    />
  );

  return (
    <div className="followup-title-and-card-container">
      <h2 className="followup-section-title">
        {t('followup.barometersSectionTitle')}
      </h2>
      {isLoading ? (
        <div className="followup-loader-container">
          <ActivityIndicator testId="loader-barometer" />
        </div>
      ) : (
        <div className="barometer-chart-card shadow-medium">
          <div className="barometer-and-checkboxes-container">
            <BarometerCheckboxes
              isError={isError}
              shownBarometerTypes={shownBarometerTypes}
              setShownBarometerTypes={setShownBarometerTypes}
            />
            {isError ? (
              <ErrorApiMessage
                title={t('apiMessage.serverError.errorOccurred')}
                subtitle={t('apiMessage.serverError.tryAgainLater')}
                className="barometer-error"
              />
            ) : (
              barometerChart
            )}
          </div>
          <Events leftBound={timeMarkers[0]} rightBound={timeMarkers[3]} />
        </div>
      )}
    </div>
  );
};
