import './BodyMaps.scss';

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ActivityIndicator, ErrorApiMessage } from '../../../../../components';
import { Information } from '../../../../../components/icons/Information';
import { useGetQuestionnaireAnswers } from '../../../../../services/api/questionnaire';
import { formatDateFrench } from '../../../../../services/date/formatDateFrench';
import { COLORS } from '../../../../../theme/colors';
import { QuestionnaireAnswer } from '../../../../../types/questionnaire';
import { BodyMapCard } from './BodyMapCard/BodyMapCard';
import { BodyMapColorLegend } from './components/BodyMapColorLegend';

const questionnaireId = 'BODY_MAP_QUESTIONNAIRE';

export interface OldDatesWithAnswers {
  date: string;
  value: string;
  label: string;
  bodyMapAnswer: QuestionnaireAnswer;
}

export const BodyMaps: FunctionComponent = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const { t } = useTranslation();
  const {
    data: bodyMapAnswers,
    isError,
    isLoading,
  } = useGetQuestionnaireAnswers(patientId ?? '', questionnaireId);

  const hasPatientBodyMapAnswers =
    bodyMapAnswers !== undefined && bodyMapAnswers.length > 0;

  const hasPatientSeveralAnswers =
    hasPatientBodyMapAnswers && bodyMapAnswers.length > 1;

  const mostRecentDate = hasPatientBodyMapAnswers
    ? bodyMapAnswers[
        bodyMapAnswers.length - 1
      ].dimensionAnswers[0].createdAt.toString()
    : '';

  const mostRecentFormattedDate = formatDateFrench(mostRecentDate);

  const leastRecentDate = hasPatientSeveralAnswers
    ? bodyMapAnswers[0].dimensionAnswers[0].createdAt.toString()
    : '';

  const leastRecentFormattedDate = formatDateFrench(leastRecentDate);

  const oldDatesWithAnswers: OldDatesWithAnswers[] = hasPatientBodyMapAnswers
    ? bodyMapAnswers
        .slice(0, bodyMapAnswers.length - 1)
        .map((questionnaireAnswer) => {
          const date =
            questionnaireAnswer.dimensionAnswers[0].createdAt.toString();
          return {
            date,
            value: questionnaireAnswer.id,
            label: t('characterization.mapDate', {
              date: formatDateFrench(date),
            }),
            bodyMapAnswer: questionnaireAnswer,
          };
        })
    : [];

  const legend = (
    <div className="body-map-legend-container">
      <p className="p2">{t('characterization.painLegend')}</p>
      <div className="body-map-color-legend-container">
        <BodyMapColorLegend color={COLORS.pain.green} scale="1-2" />
        <BodyMapColorLegend color={COLORS.pain.blue} scale="3-4" />
        <BodyMapColorLegend color={COLORS.pain.yellow} scale="5-6" />
        <BodyMapColorLegend color={COLORS.pain.orange} scale="7-8" />
        <BodyMapColorLegend color={COLORS.pain.red} scale="9-10" />
      </div>
    </div>
  );

  const noDataMessage = (
    <div className="no-data-message-container" data-testid="no-data-message">
      <Information width={80} height={80} color={COLORS.statuses.warning} />
      <div className=" no-data-text-container">
        <h2 className="no-data-title">{t('characterization.noData')}</h2>
        <p className="p2">{t('characterization.patientHasNoBodyMapAnswers')}</p>
      </div>
    </div>
  );

  const errorMessage = (
    <ErrorApiMessage
      title={t('apiMessage.serverError.errorOccurred')}
      subtitle={t('apiMessage.serverError.tryAgainLater')}
      className="server-error-body-map"
    />
  );

  return (
    <div className="body-maps">
      {legend}
      {isError ? (
        errorMessage
      ) : isLoading ? (
        <ActivityIndicator />
      ) : !hasPatientBodyMapAnswers ? (
        noDataMessage
      ) : !hasPatientSeveralAnswers ? (
        <div className="body-map-cards-container">
          <BodyMapCard
            date={mostRecentFormattedDate}
            isMostRecent={false}
            bodyMapAnswer={bodyMapAnswers[bodyMapAnswers.length - 1]}
          />
        </div>
      ) : (
        <div className="body-map-cards-container">
          <BodyMapCard
            date={mostRecentFormattedDate}
            isMostRecent={true}
            bodyMapAnswer={bodyMapAnswers[bodyMapAnswers.length - 1]}
          />
          <BodyMapCard
            date={leastRecentFormattedDate}
            isMostRecent={false}
            oldDatesWithAnswers={oldDatesWithAnswers}
            bodyMapAnswer={bodyMapAnswers[0]}
          />
        </div>
      )}
    </div>
  );
};
