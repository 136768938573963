import './InformationModal.scss';

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { MedicineTreatment } from '../../../../../../types/medicineTreatment';
import { MedicineTreatmentHistoricalInformation } from './components/MedicineTreatmentHistoricalInformation';
import { MedicineTreatmentLatestInformation } from './components/MedicineTreatmentLatestInformation';
import {
  TreatmentInformationModal,
  TreatmentInformationModalProps,
} from './TreatmentInformationModal';

interface MedicineTreatmentInformationModalProps
  extends Omit<
    TreatmentInformationModalProps,
    'title' | 'latestInformation' | 'history' | 'testId'
  > {
  medicineTreatment: MedicineTreatment | null;
  onClickModify: () => void;
}

export const MedicineTreatmentInformationModal: FunctionComponent<
  MedicineTreatmentInformationModalProps
> = ({ medicineTreatment, onClickModify, ...modalProps }) => {
  const { t } = useTranslation();

  if (medicineTreatment === null) return null;

  return (
    <TreatmentInformationModal
      {...modalProps}
      title={t('followup.medicineTreatments.medicineTreatmentModal.title')}
      latestInformation={
        <MedicineTreatmentLatestInformation
          medicineTreatment={medicineTreatment}
          onClickModify={onClickModify}
        />
      }
      history={
        <MedicineTreatmentHistoricalInformation
          history={medicineTreatment.history}
        />
      }
      testId="medicine-treatment-modal"
    />
  );
};
