import { FunctionComponent } from 'react';

import { TextInput, TextInputProps } from '../../../../components';

type EmailTextInputProps = Omit<TextInputProps, 'rightIcon'>;

export const EmailTextInput: FunctionComponent<EmailTextInputProps> = ({
  inputTestId,
  label,
  ...props
}) => {
  return (
    <TextInput
      type="email"
      label={label}
      name="email"
      inputTestId={inputTestId}
      {...props}
    />
  );
};
