import { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from '../../../../../components';
import { AddPatientForm } from '../AddPatientModal';
import { isBetweenLowerAndHigherBound } from '../utils';

interface BirthYearInputProps {
  control: Control<AddPatientForm>;
  testId: string;
}

export const BirthYearInput: FunctionComponent<BirthYearInputProps> = ({
  control,
  testId,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="birthYear"
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <TextInput
            type="number"
            inputTestId={`${testId}-birth-year-input`}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            label={t(
              'patientList.addPatientModal.patientInformation.birthYear',
            )}
            isValid={error === undefined}
            containerClassName="add-patient-input-on-same-row"
            errorMessage={t(
              'patientList.addPatientModal.errorMessages.invalidBirthYear',
            )}
          />
        );
      }}
      rules={{
        required: true,
        validate: (value) => isBetweenLowerAndHigherBound(value.toString()),
      }}
    />
  );
};
