import { useMutation } from '@tanstack/react-query';
import type { AuthError } from 'firebase/auth';
import { AuthErrorCodes, sendPasswordResetEmail } from 'firebase/auth';

import { auth } from '../../firebaseConfig';
import { isFirebaseAuthError } from './errors';

export interface SendResetPasswordEmailData {
  email: string;
}

const sendResetPasswordEmailFn = async ({
  email,
}: SendResetPasswordEmailData): Promise<void> => {
  await sendPasswordResetEmail(auth, email);
};

const isUserDeletedFirebaseError = (error: unknown): error is AuthError => {
  return (
    isFirebaseAuthError(error) && error.code === AuthErrorCodes.USER_DELETED
  );
};

export const useResetPassword = (
  onSuccess: () => void,
  onError: () => void,
) => {
  const { mutate: sendResetPasswordEmailMutate, ...otherMutationResult } =
    useMutation(['sendResetPasswordEmail'], sendResetPasswordEmailFn);

  const onErrorHandle = (error: unknown) => {
    if (isUserDeletedFirebaseError(error)) {
      onSuccess(); //ignore firebase errors (eg: user does not exist) so user does not get any information on input email
    } else {
      onError();
    }
  };

  const sendResetPasswordEmail = (
    sendResetPasswordEmailData: SendResetPasswordEmailData,
  ) =>
    sendResetPasswordEmailMutate(sendResetPasswordEmailData, {
      onSuccess,
      onError: onErrorHandle,
    });

  return {
    sendResetPasswordEmail,
    ...otherMutationResult,
  };
};
