import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  ComplementaryTreatment,
  ComplementaryTreatmentDto,
} from '../../types/complementaryTreatment';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

type CreateComplementaryTreatmentProps = {
  complementaryTreatment: ComplementaryTreatmentDto;
  patientId: string;
};

const getPatientComplementaryTreatment = async (
  patientId: string,
): Promise<ComplementaryTreatment[]> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.COMPLEMENTARY_TREATMENT, {
    pathParameters: { ':patientId': patientId },
  });
};

const createComplementaryTreatment = async ({
  complementaryTreatment,
  patientId,
}: CreateComplementaryTreatmentProps): Promise<void> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.POST, ENDPOINTS.COMPLEMENTARY_TREATMENT, {
    data: complementaryTreatment,
    pathParameters: { ':patientId': patientId },
  });
};

export const useGetPatientComplementaryTreatments = (patientId: string) =>
  useQuery<ComplementaryTreatment[]>(
    ['complementaryTreatments', patientId],
    () => getPatientComplementaryTreatment(patientId),
    { retry: 0 },
  );

export const useCreateComplementaryTreatment = ({
  onSuccess,
  onError,
  patientId,
}: {
  onSuccess: () => void;
  onError?: (error: unknown) => void;
  patientId: string;
}) => {
  if (patientId === undefined) {
    throw new Error();
  }
  const queryClient = useQueryClient();
  return useMutation<void, unknown, ComplementaryTreatmentDto, unknown>(
    ['createComplementaryTreatment'],
    (complementaryTreatment: ComplementaryTreatmentDto) =>
      createComplementaryTreatment({ complementaryTreatment, patientId }),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        queryClient.invalidateQueries(['complementaryTreatments', patientId]);
      },
      onError,
    },
  );
};

const modifyComplementaryTreatment = async (
  patientId: string,
  dto: ComplementaryTreatmentDto,
  complementaryTreatmentId: string,
): Promise<void> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(
    HttpMethod.PUT,
    ENDPOINTS.UPDATE_COMPLEMENTARY_TREATMENT,
    {
      data: dto,
      pathParameters: {
        ':patientId': patientId,
        ':complementaryTreatmentId': complementaryTreatmentId,
      },
    },
  );
};

export const useModifyComplementaryTreatment = (
  patientId: string,
  complementaryTreatmentId: string,
  onSuccess: () => void,
  onError?: (error: unknown) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, ComplementaryTreatmentDto, unknown>(
    ['modifyComplementaryTreatment'],
    (data: ComplementaryTreatmentDto) =>
      modifyComplementaryTreatment(patientId, data, complementaryTreatmentId),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        queryClient.invalidateQueries(['complementaryTreatments', patientId]);
      },
      onError,
    },
  );
};
