import { ComplementaryTherapy } from './complementaryTherapy';
import { TreatmentAge } from './medicineTreatment';

export enum Prescriber {
  DOCTOR = 'DOCTOR',
  NURSE = 'NURSE',
  PSYCHOLOGIST = 'PSYCHOLOGIST',
  OTHER = 'OTHER',
}

export interface ComplementaryTreatment {
  id: string;
  patientId: string;
  therapy: ComplementaryTherapy;
  prescriber: Prescriber;
  startDate: string | TreatmentAge;
  endDate: string | null;
  details: string | null;
  history: ComplementaryTreatmentHistory[];
}

export interface ComplementaryTreatmentHistoryData
  extends Omit<
    ComplementaryTreatment,
    'id' | 'patientId' | 'history' | 'therapy'
  > {}

export interface ComplementaryTreatmentHistory {
  updatedBy: string;
  complementaryTreatmentId: string;
  data: ComplementaryTreatmentHistoryData;
  updatedAt: string;
}

export interface ComplementaryTreatmentDto {
  therapyId: string;
  prescriber: Prescriber;
  startDate: string | null;
  details: string | null;
  endDate: string | null;
}
