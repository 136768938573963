import { useQuery } from '@tanstack/react-query';

import { BarometerAnswer, BarometerType } from '../../types/barometer';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

const getPatientBarometerAnswers = async (
  patientId: string,
): Promise<{ type: BarometerType; answers: BarometerAnswer[] }[]> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.BAROMETER_ANSWERS, {
    pathParameters: { ':patientId': patientId },
  });
};

export const usePatientBarometerAnswers = (patientId: string) =>
  useQuery<{ type: BarometerType; answers: BarometerAnswer[] }[]>(
    ['barometerAnswers', patientId],
    () => getPatientBarometerAnswers(patientId),
    { retry: 0 },
  );
