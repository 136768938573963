import { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NumberInput } from '../../../../../components';
import { AddPatientForm } from '../AddPatientModal';
import { SOCIAL_SECURITY_NUMBER_REGEX } from '../regex';

interface SocialSecurityNumberInputProps {
  control: Control<AddPatientForm>;
  testId: string;
}

export const SocialSecurityNumberInput: FunctionComponent<
  SocialSecurityNumberInputProps
> = ({ control, testId }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="socialSecurityNumber"
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <NumberInput
            inputTestId={`${testId}-social-security-number-input`}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            pattern="[0-9]*"
            label={t(
              'patientList.addPatientModal.patientInformation.socialSecurityNumber',
            )}
            isValid={error === undefined}
            containerClassName="add-patient-input-on-same-row"
            errorMessage={t(
              'patientList.addPatientModal.errorMessages.invalidSocialSecurityNumber',
            )}
          />
        );
      }}
      rules={{
        required: true,
        validate: (value) =>
          SOCIAL_SECURITY_NUMBER_REGEX.test(value.toString()),
      }}
    />
  );
};
