import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { LabeledText } from '../../../../../../../components';
import { formatDateFrench } from '../../../../../../../services/date/formatDateFrench';
import {
  MedicineTreatmentHistory,
  MedicineTreatmentHistoryData,
} from '../../../../../../../types/medicineTreatment';
import { join } from '../../../../../../../utils';
import { getDailyDosageLabel, getDailyDosageUnitLabel } from '../../posology';
import { isTreatmentAge } from '../../utils';

const isHistoryDataKey = (
  key: string,
): key is keyof MedicineTreatmentHistoryData => {
  return [
    'dailyDosage',
    'dailyDosageUnit',
    'type',
    'startDate',
    'endDate',
    'details',
  ].includes(key);
};

interface MedicineTreatmentHistoricalInformationProps {
  history: MedicineTreatmentHistory[];
}

export const MedicineTreatmentHistoricalInformation: FunctionComponent<
  MedicineTreatmentHistoricalInformationProps
> = ({ history }) => {
  const { t } = useTranslation();

  const elements = history.map((modification, index) => {
    const isCreation = index === history.length - 1;

    const changedSinceLast: Record<
      keyof MedicineTreatmentHistoryData,
      boolean
    > = {
      dailyDosage: true,
      dailyDosageUnit: true,
      type: true,
      startDate: true,
      endDate: true,
      details: true,
    };

    if (!isCreation) {
      const previousModification = history[index + 1];

      for (const key in modification.data) {
        if (!isHistoryDataKey(key)) continue;

        changedSinceLast[key] =
          modification.data[key] !== previousModification.data[key];
      }
    }

    const modificationDate = (
      <LabeledText
        label={t(
          `followup.medicineTreatments.medicineTreatmentModal.historyTab.${
            isCreation ? 'creationDate' : 'modificationDate'
          }`,
        )}
        text={formatDateFrench(modification.updatedAt)}
        className="information-modal-horizontal-element-2"
      />
    );

    const dailyDosage = (
      <LabeledText
        textClassName={
          changedSinceLast.dailyDosage ? '' : 'information-modal-unchanged'
        }
        label={t(
          'followup.medicineTreatments.medicineTreatmentModal.inCommon.dailyDosage',
        )}
        text={getDailyDosageLabel(modification.data.dailyDosage)}
        className="information-modal-horizontal-element-1"
      />
    );

    const dailyDosageUnit = (
      <LabeledText
        textClassName={
          changedSinceLast.dailyDosageUnit ? '' : 'information-modal-unchanged'
        }
        label={t('followup.medicineTreatments.unit')}
        text={getDailyDosageUnitLabel(modification.data.dailyDosageUnit)}
        className="information-modal-horizontal-element-1"
      />
    );

    const type = (
      <LabeledText
        textClassName={
          changedSinceLast.type ? '' : 'information-modal-unchanged'
        }
        label={t(
          'followup.medicineTreatments.medicineTreatmentModal.inCommon.treatmentType',
        )}
        text={t(`followup.treatmentTypes.${modification.data.type}`)}
      />
    );

    const startDate = (
      <LabeledText
        textClassName={
          changedSinceLast.startDate ? '' : 'information-modal-unchanged'
        }
        label={t(
          'followup.medicineTreatments.medicineTreatmentModal.inCommon.startDate',
        )}
        text={
          isTreatmentAge(modification.data.startDate)
            ? t(`followup.treatmentAge.${modification.data.startDate}`)
            : formatDateFrench(modification.data.startDate)
        }
      />
    );

    const endDate = (
      <LabeledText
        textClassName={
          changedSinceLast.endDate ? '' : 'information-modal-unchanged'
        }
        label={t('followup.inCommon.endDate')}
        text={
          modification.data.endDate === null
            ? t(
                'followup.medicineTreatments.medicineTreatmentModal.recentInformationTab.treatmentInProgress',
              )
            : formatDateFrench(modification.data.endDate)
        }
      />
    );

    const details = (
      <LabeledText
        textClassName={
          changedSinceLast.details ? '' : 'information-modal-unchanged'
        }
        label={t(
          'followup.medicineTreatments.medicineTreatmentModal.inCommon.note',
        )}
        text={
          modification.data.details === null || modification.data.details === ''
            ? '-'
            : modification.data.details
        }
      />
    );

    return (
      <div
        key={modification.updatedAt}
        className="information-modal-tab-container"
        data-testid="history-modification-container"
      >
        <div className="information-modal-horizontal-section">
          {modificationDate}
          {dailyDosage}
          {dailyDosageUnit}
        </div>
        {type}
        <div className="information-modal-horizontal-section">
          {startDate}
          {endDate}
        </div>
        {details}
      </div>
    );
  });

  const divider = <div className="information-modal-divider" />;

  return join(elements, divider);
};
