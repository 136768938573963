import './InformationModal.scss';

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { ComplementaryTreatment } from '../../../../../../types/complementaryTreatment';
import { ComplementaryTreatmentHistoricalInformation } from './components/ComplementaryTreatmentHistoricalInformation';
import { ComplementaryTreatmentLatestInformation } from './components/ComplementaryTreatmentLatestInformation';
import {
  TreatmentInformationModal,
  TreatmentInformationModalProps,
} from './TreatmentInformationModal';

interface ComplementaryTreatmentInformationModalProps
  extends Omit<
    TreatmentInformationModalProps,
    'title' | 'latestInformation' | 'history' | 'testId'
  > {
  complementaryTreatment: ComplementaryTreatment | null;
  onClickModify: () => void;
}

export const ComplementaryTreatmentInformationModal: FunctionComponent<
  ComplementaryTreatmentInformationModalProps
> = ({ complementaryTreatment, onClickModify, ...modalProps }) => {
  const { t } = useTranslation();

  if (complementaryTreatment === null) return null;

  return (
    <TreatmentInformationModal
      {...modalProps}
      title={t(
        'followup.complementaryTreatments.complementaryTreatmentModal.title',
      )}
      latestInformation={
        <ComplementaryTreatmentLatestInformation
          complementaryTreatment={complementaryTreatment}
          onClickModify={onClickModify}
        />
      }
      history={
        <ComplementaryTreatmentHistoricalInformation
          history={complementaryTreatment.history}
        />
      }
      testId="complementary-treatment-modal"
    />
  );
};
