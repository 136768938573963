import './IconButton.scss';

import { ButtonHTMLAttributes, FunctionComponent } from 'react';

import { Color } from '../../theme/colors';
import { Icon } from '../icons/types';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  Icon: Icon;
  color: Color;
}

export const IconButton: FunctionComponent<IconButtonProps> = ({
  Icon,
  color,
  onClick,
  ...props
}) => (
  <button
    {...props}
    className={`icon-button shadow-medium ${props.className ?? ''}`}
    onClick={onClick}
  >
    <Icon color={color} />
  </button>
);
