import './TooltipContent.scss';

import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDateFrench } from '../../../../../../services/date/formatDateFrench';
import {
  ComplementaryTreatment,
  ComplementaryTreatmentHistoryData,
} from '../../../../../../types/complementaryTreatment';
import { isTreatmentAge } from '../utils';

interface ComplementaryTreatmentTooltipContentProps {
  complementaryTreatment: ComplementaryTreatment;
}

export const ComplementaryTreatmentEndDateTooltipContent: FunctionComponent<
  ComplementaryTreatmentTooltipContentProps
> = ({ complementaryTreatment }) => {
  const { t } = useTranslation();

  return (
    <div className="tooltip-content-container">
      <p className="tooltip-title">{t('followup.treatmentTooltip.endDate')}</p>
      <p className="tooltip-text">
        {complementaryTreatment.endDate !== null
          ? formatDateFrench(complementaryTreatment.endDate)
          : '-'}
      </p>
      {complementaryTreatment.details !== null &&
        complementaryTreatment.details !== '' && (
          <>
            <p className="tooltip-title">
              {t('followup.treatmentTooltip.precisions')}
            </p>
            <p className="tooltip-text">{complementaryTreatment.details}</p>
          </>
        )}
    </div>
  );
};

export const ComplementaryTreatmentStartDateTooltipContent: FunctionComponent<
  ComplementaryTreatmentTooltipContentProps
> = ({ complementaryTreatment }) => {
  const { t } = useTranslation();

  // default is the latest modification before the start date or on the same day
  // if there is none, take the oldest modification
  // if no modifications (should not happen), take the current state
  let displayedData: ComplementaryTreatmentHistoryData | ComplementaryTreatment;

  if (isTreatmentAge(complementaryTreatment.startDate)) {
    if (complementaryTreatment.history.length === 0) {
      displayedData = complementaryTreatment;
    } else {
      displayedData =
        complementaryTreatment.history[
          complementaryTreatment.history.length - 1
        ].data;
    }
  } else {
    const modificationsBeforeStartDate = complementaryTreatment.history.filter(
      (modification) => {
        const modificationDate = dayjs(modification.updatedAt);
        const startDate = dayjs(complementaryTreatment.startDate);

        return (
          modificationDate.isBefore(startDate, 'day') ||
          modificationDate.isSame(startDate, 'day')
        );
      },
    );

    if (modificationsBeforeStartDate.length > 0) {
      displayedData = modificationsBeforeStartDate[0].data;
    } else {
      if (complementaryTreatment.history.length === 0) {
        displayedData = complementaryTreatment;
      } else {
        displayedData =
          complementaryTreatment.history[
            complementaryTreatment.history.length - 1
          ].data;
      }
    }
  }

  return (
    <div className="tooltip-content-container">
      <p className="tooltip-title">
        {t('followup.treatmentTooltip.startDate')}
      </p>
      <p className="tooltip-text">
        {isTreatmentAge(complementaryTreatment.startDate)
          ? ''
          : formatDateFrench(complementaryTreatment.startDate)}
      </p>
      {displayedData.details !== null && displayedData.details !== '' && (
        <>
          <p className="tooltip-title">
            {t('followup.treatmentTooltip.precisions')}
          </p>
          <p className="tooltip-text">{displayedData.details}</p>
        </>
      )}
    </div>
  );
};

type ComplementaryTreatmentModificationTooltipContentProps = {
  date: string;
} & {
  details?: string | null;
};

export const ComplementaryTreatmentModificationTooltipContent: FunctionComponent<
  ComplementaryTreatmentModificationTooltipContentProps
> = ({ date, details }) => {
  const { t } = useTranslation();

  return (
    <div className="tooltip-content-container">
      <p className="tooltip-title">
        {t('followup.treatmentTooltip.modificationDate')}
      </p>
      <p className="tooltip-text">{formatDateFrench(date)}</p>

      <p className="tooltip-title">
        {t('followup.treatmentTooltip.precisions')}
      </p>
      <p className="tooltip-text">
        {details === null || details === '' ? '-' : details}
      </p>
    </div>
  );
};
