import { POSITIVE_FLOAT_REGEX } from './regex';

export const isPositiveFloat = (value: string): boolean => {
  if (value === undefined) return false;

  return POSITIVE_FLOAT_REGEX.test(value);
};

export const toPositiveFloat = (value: string): number | null => {
  return isPositiveFloat(value) ? parseFloat(value.replace(',', '.')) : NaN;
};
