import './ComplementTreatmentEditionModal.scss';

import type { TFunction } from 'i18next';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ComplementaryTherapy,
  TherapyCategory,
} from '../../../../../../../types/complementaryTherapy';
import { ModalDropDown } from '../ModalDropDown/ModalDropDown';
import { ComplementaryTreatmentForm } from './ComplementaryTreatmentEditionModal';

export const COMPLEMENTARY_THERAPIES_DROPDOWN_ID =
  'complementary-therapies-dropdown';

type ComplementaryTherapyDropdownProps = {
  complementaryTherapies: ComplementaryTherapy[];
  control?: Control<ComplementaryTreatmentForm, unknown>;
  disabled?: boolean;
};

export const ComplementaryTherapyDropdown = ({
  complementaryTherapies,
  control,
  disabled = false,
}: ComplementaryTherapyDropdownProps) => {
  const { t } = useTranslation();
  const formattedComplementTherapies = getComplementaryTherapiesCategories(
    complementaryTherapies,
    t,
  );

  return (
    <Controller
      control={control}
      name="therapyId"
      rules={{ required: true }}
      render={({ field: { onChange, onBlur, value } }) => (
        <ModalDropDown
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          data-testid={COMPLEMENTARY_THERAPIES_DROPDOWN_ID}
          label={t(
            'followup.complementaryTreatments.complementaryTreatmentEditionModal.complementaryTherapy',
          )}
          data={formattedComplementTherapies}
          dropdownPosition="bottom"
          disabled={disabled}
        />
      )}
    />
  );
};

const getComplementaryTherapiesCategories = (
  rawComplementaryTherapies: ComplementaryTherapy[],
  getTranslation: TFunction<'translation', undefined>,
): {
  value: string;
  label: string;
  group: string;
  id: string;
  name: string;
  category: TherapyCategory;
}[] => {
  return rawComplementaryTherapies.map((therapy) => ({
    ...therapy,
    value: therapy.id,
    label: therapy.name,
    group: getTranslation(
      `followup.complementaryTreatments.complementaryTreatmentEditionModal.categories.${therapy.category}`,
    ),
  }));
};
