import './ResetPassword.scss';
import '../../theme/shadows.scss';

import { AppShell } from '@mantine/core';
import { FunctionComponent, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  ButtonClass,
  Modal,
  SnackBar,
  SnackBarType,
  TextInput,
} from '../../components';
import { useResetPassword } from '../../services/auth/useResetPassword';
import { EMAIL_REGEX } from '../../utils';

export const ResetPassword: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<{ email: string }>({ mode: 'onBlur' });

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isSnackbarErrorVisible, setIsSnackbarErrorVisible] =
    useState<boolean>(false);

  const onSuccess = useCallback(() => setIsModalVisible(true), []);
  const onError = useCallback(() => setIsSnackbarErrorVisible(true), []);

  const { sendResetPasswordEmail } = useResetPassword(onSuccess, onError);

  return (
    <AppShell padding={0}>
      <div className="reset-p-background" data-testid="reset-password">
        <div className="reset-p-content shadow-strong">
          <div className="reset-p-container reset-p-container-gap-small">
            <h1 className="h1">{t('resetPassword.title')}</h1>
            <p className="p1">{t('resetPassword.subtitle')}</p>
          </div>

          <div className="reset-p-container reset-p-content-container reset-p-container-gap">
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <TextInput
                    type="email"
                    label={t('resetPassword.emailAddress')}
                    name="email"
                    inputTestId={'email-text-input'}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    isValid={errors.email === undefined}
                    errorMessage={t(
                      'resetPassword.errorMessage.invalidEmailAddress',
                    )}
                  />
                );
              }}
              rules={{
                required: true,
                validate: {
                  isValidEmail: (value) => EMAIL_REGEX.test(value),
                },
              }}
            />

            <Button
              buttonClass={ButtonClass.PRIMARY}
              className="reset-p-button"
              text={t('resetPassword.resetButton')}
              onClick={() => {
                setIsSnackbarErrorVisible(false);
                handleSubmit(sendResetPasswordEmail)();
              }}
              disabled={!isValid}
              data-testid="submit-button"
            />

            <div className="reset-p-container-left">
              <a
                className="reset-p-subtext reset-p-link"
                onClick={() => navigate('/')}
              >
                {t('resetPassword.returnToLogin')}
              </a>
            </div>
          </div>
        </div>

        {isModalVisible && (
          <Modal opened={isModalVisible} onClose={() => navigate('/')}>
            <div className="reset-p-container reset-p-container-gap">
              <p className="p1-bold reset-p-text">
                {t('resetPassword.confirmMessage.title')}
              </p>
              <p className="p1 reset-p-text">
                {t('resetPassword.confirmMessage.description')}
              </p>

              <Button
                buttonClass={ButtonClass.PRIMARY}
                text={t('resetPassword.confirmMessage.button')}
                onClick={() => navigate('/')}
              />
            </div>
          </Modal>
        )}

        {isSnackbarErrorVisible && (
          <SnackBar
            title={t('resetPassword.errorMessage.processError')}
            snackBarType={SnackBarType.ERROR}
          />
        )}
      </div>
    </AppShell>
  );
};
