import '../../../../../../../../theme/shadows.scss';
import './SearchableMedicineInput.scss';

import type { SelectItem } from '@mantine/core';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SearchableInput,
  SearchableInputProps,
  Tooltip,
} from '../../../../../../../../components';
import {
  Information,
  SIZE_FOR_TOOLTIP,
} from '../../../../../../../../components/icons/Information';
import { useSearchMedicines } from '../../../../../../../../services/api/medicine';
import { COLORS } from '../../../../../../../../theme/colors';
import { Medicine } from '../../../../../../../../types/medicine';

interface SearchableMedicinesInputProps
  extends Omit<
    SearchableInputProps<Medicine & SelectItem, Medicine>,
    'label' | 'tooltip' | 'queryHook' | 'convertApiResultToItems'
  > {}

export const SearchableMedicinesInput: FunctionComponent<
  SearchableMedicinesInputProps
> = ({ disabled, isValid, ...props }) => {
  const { t } = useTranslation();

  const tooltipDescription = (
    <ul className="searchable-medicine-input-tooltip-description">
      <li>
        {t(
          'followup.medicineTreatments.medicineTreatmentEditionModal.medicineDenominationToolTip.hint1',
        )}
      </li>
      <li>
        {t(
          'followup.medicineTreatments.medicineTreatmentEditionModal.medicineDenominationToolTip.hint2',
        )}
      </li>
    </ul>
  );

  const tooltip = (
    <Tooltip
      title={t(
        'followup.medicineTreatments.medicineTreatmentEditionModal.medicineDenominationToolTip.information',
      )}
      description={tooltipDescription}
    >
      <div className="searchable-medicine-input-icon-container">
        <Information
          color={
            disabled
              ? COLORS.texts.second
              : isValid
                ? COLORS.primary._500
                : COLORS.statuses.error
          }
          height={SIZE_FOR_TOOLTIP}
          width={SIZE_FOR_TOOLTIP}
        />
      </div>
    </Tooltip>
  );

  const convertApiResultToItems = useCallback(
    (medicines: Medicine[]) =>
      medicines.map((medicine) => ({
        ...medicine,
        value: medicine.cisCode.toString(),
        label: medicine.denomination,
      })),
    [],
  );

  return (
    <SearchableInput
      {...props}
      disabled={disabled}
      isValid={isValid}
      label={t(
        'followup.medicineTreatments.medicineTreatmentEditionModal.medicineDenomination',
      )}
      tooltip={tooltip}
      queryHook={useSearchMedicines}
      convertApiResultToItems={convertApiResultToItems}
    />
  );
};
