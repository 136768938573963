import { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Hcp } from '../../../../../types/hcp';
import { ModalDropDown } from '../../../../PatientDetails/routes/Followup/components/EditionModals/ModalDropDown/ModalDropDown';
import { AddPatientForm } from '../AddPatientModal';
import { ReferringDoctorData } from '../data';

interface ReferringDoctorInputProps {
  control: Control<AddPatientForm>;
  testId: string;
  doctors: Hcp[];
}

export const ReferringDoctorInput: FunctionComponent<
  ReferringDoctorInputProps
> = ({ control, testId, doctors }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="referringDoctorId"
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <ModalDropDown
            data-testid={`${testId}-referring-doctor-input`}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            label={t(
              'patientList.addPatientModal.patientFollowup.referringDoctor',
            )}
            isValid={error === undefined}
            data={doctors !== undefined ? ReferringDoctorData(doctors) : []}
            containerClassName="add-patient-input-on-same-row"
          />
        );
      }}
      rules={{ required: true }}
    />
  );
};
