import './StatusTag.scss';

import { FunctionComponent } from 'react';

import { Color, COLORS } from '../../../../theme/colors';

interface StatusTagProps {
  text: string;
  status: Status;
}

export enum Status {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  NEUTRAL = 'NEUTRAL',
}

const TAG_COLORS: Record<Status, { textColor: Color; backgroundColor: Color }> =
  {
    [Status.ERROR]: {
      textColor: COLORS.statuses.error,
      backgroundColor: COLORS.statuses.errorLight,
    },
    [Status.WARNING]: {
      textColor: COLORS.statuses.warning,
      backgroundColor: COLORS.statuses.warningLight,
    },
    [Status.SUCCESS]: {
      textColor: COLORS.statuses.success,
      backgroundColor: COLORS.statuses.successLight,
    },
    [Status.NEUTRAL]: {
      textColor: COLORS.texts.second,
      backgroundColor: COLORS.grey.grey50Background,
    },
  };

export const StatusTag: FunctionComponent<StatusTagProps> = ({
  text,
  status,
}) => {
  const { textColor, backgroundColor } = TAG_COLORS[status];
  return (
    <div className="status-tag" style={{ backgroundColor }}>
      <p className="p3" style={{ color: textColor }}>
        {text}
      </p>
    </div>
  );
};
