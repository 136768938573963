import type { SelectItem } from '@mantine/core';
import type { TFunction } from 'i18next';

import {
  Diagnosis,
  DiagnosisValidation,
} from '../../../../../../types/diagnosis';

export const getDiagnosesDropdownItems = (
  diagnoses: Diagnosis[],
): SelectItem[] =>
  diagnoses.map((diagnosis) => ({
    value: diagnosis.id,
    label: diagnosis.name,
  }));

export const getDiagnosisValidationDropdownItems = (
  t: TFunction,
): SelectItem[] =>
  [
    DiagnosisValidation.POTENTIAL,
    DiagnosisValidation.LIKELY,
    DiagnosisValidation.CERTAIN,
  ].map((validation) => ({
    value: validation,
    label: t(`clinicalFile.diagnosisValidation.${validation}`),
  }));
