import { COLORS } from '../../theme/colors';
import { Icon } from './types';

export const FastEmail: Icon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.79004 10.5H2.79004C2.52482 10.5 2.27047 10.6054 2.08293 10.7929C1.8954 10.9804 1.79004 11.2348 1.79004 11.5C1.79004 11.7652 1.8954 12.0196 2.08293 12.2071C2.27047 12.3947 2.52482 12.5 2.79004 12.5H4.79004C5.05526 12.5 5.30961 12.3947 5.49715 12.2071C5.68468 12.0196 5.79004 11.7652 5.79004 11.5C5.79004 11.2348 5.68468 10.9804 5.49715 10.7929C5.30961 10.6054 5.05526 10.5 4.79004 10.5ZM21.57 7.66002V7.60002C21.2835 7.24981 20.9215 6.96894 20.5111 6.77845C20.1007 6.58796 19.6525 6.49277 19.2 6.50002H11.27C10.5352 6.49342 9.82358 6.75673 9.27004 7.24002C8.75304 7.69555 8.41313 8.31872 8.31004 9.00002L7.43004 14C7.35437 14.4318 7.37424 14.8749 7.48827 15.2982C7.6023 15.7215 7.80771 16.1147 8.09004 16.45C8.3709 16.7843 8.72132 17.0532 9.11685 17.238C9.51237 17.4228 9.94347 17.519 10.38 17.52H18.32C19.0333 17.5308 19.727 17.2871 20.2768 16.8326C20.8266 16.3781 21.1965 15.7426 21.32 15.04L22.2 10.04C22.2693 9.62132 22.249 9.19268 22.1404 8.78241C22.0318 8.37215 21.8374 7.98959 21.57 7.66002ZM18.83 8.50002L15.43 11.26C15.229 11.422 14.9731 11.5 14.7159 11.4777C14.4587 11.4553 14.2201 11.3343 14.05 11.14L11.72 8.50002H18.83ZM19.31 14.67C19.2696 14.9048 19.1466 15.1174 18.9632 15.2696C18.7799 15.4218 18.5483 15.5035 18.31 15.5H10.38C10.2348 15.4988 10.0916 15.466 9.96036 15.4038C9.82911 15.3416 9.71298 15.2516 9.62004 15.14C9.52686 15.029 9.45893 14.8991 9.42094 14.7592C9.38296 14.6194 9.37582 14.4729 9.40004 14.33L10.2 9.80002L12.55 12.46C13.0615 13.0414 13.778 13.4025 14.5496 13.4677C15.3212 13.5329 16.0882 13.2973 16.69 12.81L20.13 10L19.31 14.67ZM5.79004 6.50002H2.79004C2.52482 6.50002 2.27047 6.60538 2.08293 6.79291C1.8954 6.98045 1.79004 7.2348 1.79004 7.50002C1.79004 7.76524 1.8954 8.01959 2.08293 8.20713C2.27047 8.39466 2.52482 8.50002 2.79004 8.50002H5.79004C6.05526 8.50002 6.30961 8.39466 6.49715 8.20713C6.68468 8.01959 6.79004 7.76524 6.79004 7.50002C6.79004 7.2348 6.68468 6.98045 6.49715 6.79291C6.30961 6.60538 6.05526 6.50002 5.79004 6.50002Z"
        fill={props.color ?? COLORS.texts.default}
      />
    </svg>
  );
};
