import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  CreatePatientDiagnosisDto,
  Diagnosis,
  PatientDiagnosis,
  UpdatePatientDiagnosisDto,
} from '../../types/diagnosis';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

const getAllDiagnoses = async (): Promise<Diagnosis[]> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.DIAGNOSIS);
};

export const useGetDiagnoses = () =>
  useQuery<Diagnosis[]>(['getAllDiagnoses'], getAllDiagnoses);

const createPatientDiagnosis = async (
  patientId: string,
  dto: CreatePatientDiagnosisDto,
): Promise<void> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.POST, ENDPOINTS.PATIENT_DIAGNOSIS, {
    pathParameters: { ':patientId': patientId },
    data: dto,
  });
};

export const useCreatePatientDiagnosis = (
  patientId: string,
  onSuccess: () => void,
  onError: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation<void, undefined, CreatePatientDiagnosisDto>(
    ['createPatientDiagnosis'],
    (dto: CreatePatientDiagnosisDto) => createPatientDiagnosis(patientId, dto),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        queryClient.invalidateQueries(['patientDiagnoses', patientId]);
      },
      onError,
    },
  );
};

const getPatientDiagnoses = async (
  patientId: string,
): Promise<PatientDiagnosis[]> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.PATIENT_DIAGNOSIS, {
    pathParameters: { ':patientId': patientId },
  });
};

export const useGetPatientDiagnoses = (patientId: string) =>
  useQuery<PatientDiagnosis[]>(['patientDiagnoses', patientId], () =>
    getPatientDiagnoses(patientId),
  );

const updatePatientDiagnosis = async (
  patientId: string,
  diagnosisId: string,
  dto: UpdatePatientDiagnosisDto,
): Promise<void> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.PUT, ENDPOINTS.UPDATE_PATIENT_DIAGNOSIS, {
    pathParameters: {
      ':patientId': patientId,
      ':diagnosisId': diagnosisId,
    },
    data: dto,
  });
};

export const useUpdatePatientDiagnosis = (
  patientId: string,
  diagnosisId: string,
  onSuccess: () => void,
  onError: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation<void, undefined, UpdatePatientDiagnosisDto>(
    ['updatePatientDiagnosis', patientId, diagnosisId],
    (dto: UpdatePatientDiagnosisDto) =>
      updatePatientDiagnosis(patientId, diagnosisId, dto),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        queryClient.invalidateQueries(['patientDiagnoses', patientId]);
      },
      onError,
    },
  );
};
