import './ConsultationReasonSection.scss';

import { FunctionComponent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ActivityIndicator,
  Button,
  ButtonClass,
  ErrorApiMessage,
  LongTextInput,
  SnackBar,
  SnackBarType,
} from '../../../../../../components';
import {
  usePatientConsultationReason,
  useUpdatePatientConsultationReason,
} from '../../../../../../services/api/consultation-reason';

interface ConsultationReasonSectionProps {
  patientId: string;
}

interface ConsultationReasonForm {
  text: string;
}

export const ConsultationReasonSection: FunctionComponent<
  ConsultationReasonSectionProps
> = ({ patientId }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, isDirty },
  } = useForm<ConsultationReasonForm>({
    mode: 'onBlur',
  });

  const {
    isLoading: isLoadingUpdate,
    mutate,
    isError: isUpdateError,
  } = useUpdatePatientConsultationReason(patientId);

  const {
    isLoading: isLoadingConsultationReason,
    data: consultationReasonData,
    isError: isConsultationReasonError,
  } = usePatientConsultationReason(patientId);

  useEffect(() => {
    if (consultationReasonData !== undefined) {
      reset({ text: consultationReasonData.consultationReason ?? '' });
    }
  }, [reset, consultationReasonData]);

  return (
    <>
      {isUpdateError && (
        <div className="consultation-reason-error-snack-bar-container">
          <SnackBar
            title={t('errors.unknownError.message')}
            snackBarType={SnackBarType.ERROR}
          />
        </div>
      )}
      <div className="clinical-file-section consultation-reason-section">
        <div className="consultation-reason-save-button-container">
          <h2 className="clinical-file-section-title">
            {t('clinicalFile.consultationReason.sectionTitle')}
          </h2>
          {!isLoadingConsultationReason && !isConsultationReasonError && (
            <Button
              buttonClass={ButtonClass.PRIMARY}
              text={t('save')}
              onClick={handleSubmit((form) => mutate({ text: form.text }))}
              disabled={!isValid || !isDirty}
              data-testid="consultation-reason-save-button"
              isLoading={isLoadingUpdate}
            />
          )}
        </div>
        {isLoadingConsultationReason ? (
          <ActivityIndicator testId="consultation-reason-loader" />
        ) : isConsultationReasonError ? (
          <ErrorApiMessage
            title={t('apiMessage.serverError.errorOccurred')}
            subtitle={t('apiMessage.serverError.tryAgainLater')}
            className="consultation-reason-error-message"
          />
        ) : (
          <Controller
            control={control}
            name="text"
            render={({ field: { value, onChange, onBlur } }) => (
              <LongTextInput
                inputTestId="consultation-reason-input"
                resizable={false}
                className="consultation-reason-text-input"
                rows={1}
                placeholder={t(
                  'clinicalFile.consultationReason.inputPlaceholder',
                )}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        )}
      </div>
    </>
  );
};
