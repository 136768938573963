import './MedicalHistorySection.scss';

import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActivityIndicator,
  ErrorApiMessage,
  IconButton,
  Plus,
} from '../../../../../../components';
import {
  useCreateMedicalHistory,
  useGetMedicalHistory,
} from '../../../../../../services/api/medical-history';
import { COLORS } from '../../../../../../theme/colors';
import {
  CreateMedicalHistoryDto,
  MedicalHistoryAntecedent,
  MedicalHistoryCategory,
} from '../../../../../../types/medicalHistory';
import { CreateMedicalHistoryModal } from '../CreateMedicalHistoryModal/CreateMedicalHistoryModal';
import { ORDERED_MEDICAL_HISTORY_CATEGORIES } from '../CreateMedicalHistoryModal/data';
import { EditMedicalHistoryModal } from '../CreateMedicalHistoryModal/EditMedicalHistoryModal';
import { MedicalHistory } from '../MedicalHistory/MedicalHistory';

interface MedicalHistoryProps {
  patientId: string;
}

type MedicalHistoryData = Partial<
  Record<
    MedicalHistoryCategory,
    {
      antecedents: MedicalHistoryAntecedent[];
      details: string | null;
    }
  >
>;

export const MedicalHistorySection: FunctionComponent<MedicalHistoryProps> = ({
  patientId,
}) => {
  const { t } = useTranslation();

  const [
    openedCreateMedicalHistoryModalIndex,
    setOpenedCreateMedicalHistoryModalIndex,
  ] = useState<number | null>(null);

  const [createMedicalHistoryData, setCreateMedicalHistoryData] =
    useState<MedicalHistoryData>({});

  const {
    data: medicalHistoryCategories,
    isLoading: isGetMedicalHistoryLoading,
    isError: isGetMedicalHistoryError,
  } = useGetMedicalHistory(patientId);

  const { mutate, isLoading: isSaveMedicalHistoryLoading } =
    useCreateMedicalHistory(
      patientId,
      () => setOpenedCreateMedicalHistoryModalIndex(null),

      () => setIsCreateError(true),
    );

  const [isCreateError, setIsCreateError] = useState<boolean>(false);

  const [selectedEditionModalCategory, setSelectedEditionModalCategory] =
    useState<MedicalHistoryCategory | null>(null);

  const saveMedicalHistory = (data: MedicalHistoryData) => {
    setIsCreateError(false);

    const dto: CreateMedicalHistoryDto = { history: [] };

    for (const [category, { antecedents, details }] of Object.entries(data)) {
      dto.history.push({
        category: category as MedicalHistoryCategory,
        antecedents,
        details,
      });
    }

    mutate(dto);
  };

  const sortedMedicalHistoryCategories = (medicalHistoryCategories ?? []).sort(
    (c1, c2) => {
      const c1Index = ORDERED_MEDICAL_HISTORY_CATEGORIES.findIndex(
        (category) => category === c1.category,
      );

      const c2Index = ORDERED_MEDICAL_HISTORY_CATEGORIES.findIndex(
        (category) => category === c2.category,
      );

      return c1Index - c2Index;
    },
  );
  return (
    <>
      <div className="clinical-file-section medical-history-section">
        {isGetMedicalHistoryLoading ? (
          <ActivityIndicator />
        ) : (
          <>
            <div className="clinical-file-section-title-and-button-container">
              <h2 className="clinical-file-section-title">
                {t('clinicalFile.medicalHistory.sectionTitle')}
              </h2>
              {medicalHistoryCategories?.length === 0 && (
                <IconButton
                  className="clinical-file-add-button"
                  Icon={Plus}
                  color={COLORS.white}
                  onClick={() => setOpenedCreateMedicalHistoryModalIndex(0)}
                  data-testid="add-medical-history-button"
                />
              )}
            </div>
            {isGetMedicalHistoryError ? (
              <ErrorApiMessage
                title={t('apiMessage.serverError.errorOccurred')}
                subtitle={t('apiMessage.serverError.tryAgainLater')}
                className="clinical-file-server-error"
              />
            ) : medicalHistoryCategories?.length === 0 ? (
              <p className="p1">
                {t('clinicalFile.medicalHistory.noMedicalHistoryText')}
              </p>
            ) : (
              <div className="medical-history-antecedents-container">
                {sortedMedicalHistoryCategories.map((medicalHistory) => (
                  <MedicalHistory
                    data-testid={`medical-history-${medicalHistory.category}`}
                    key={medicalHistory.category}
                    medicalHistory={medicalHistory}
                    onClick={() =>
                      setSelectedEditionModalCategory(medicalHistory.category)
                    }
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
      {ORDERED_MEDICAL_HISTORY_CATEGORIES.map((category, index) => (
        <CreateMedicalHistoryModal
          key={category}
          isLoading={isSaveMedicalHistoryLoading}
          isError={isCreateError}
          opened={openedCreateMedicalHistoryModalIndex === index}
          onClose={() => {
            setIsCreateError(false);
            setCreateMedicalHistoryData({});
            setOpenedCreateMedicalHistoryModalIndex(null);
          }}
          categoryIndex={index}
          onPressNext={() => {
            if (
              openedCreateMedicalHistoryModalIndex !== null &&
              openedCreateMedicalHistoryModalIndex !==
                ORDERED_MEDICAL_HISTORY_CATEGORIES.length - 1
            ) {
              setOpenedCreateMedicalHistoryModalIndex(
                openedCreateMedicalHistoryModalIndex + 1,
              );
            }
          }}
          onPressPrevious={
            openedCreateMedicalHistoryModalIndex === 0 ||
            openedCreateMedicalHistoryModalIndex === null
              ? undefined
              : () => {
                  setOpenedCreateMedicalHistoryModalIndex(
                    openedCreateMedicalHistoryModalIndex - 1,
                  );
                }
          }
          saveCurrentData={(modalData) => {
            setCreateMedicalHistoryData({
              ...createMedicalHistoryData,
              [category]: modalData,
            });

            if (
              openedCreateMedicalHistoryModalIndex ===
              ORDERED_MEDICAL_HISTORY_CATEGORIES.length - 1
            ) {
              saveMedicalHistory({
                ...createMedicalHistoryData,
                [category]: modalData,
              });
            }
          }}
        />
      ))}
      {selectedEditionModalCategory !== null &&
        medicalHistoryCategories !== undefined && (
          <EditMedicalHistoryModal
            patientId={patientId}
            categoryIndex={ORDERED_MEDICAL_HISTORY_CATEGORIES.findIndex(
              (category) => category === selectedEditionModalCategory,
            )}
            opened
            onClose={() => setSelectedEditionModalCategory(null)}
            defaultValues={medicalHistoryCategories.find(
              ({ category }) => category === selectedEditionModalCategory,
            )}
          />
        )}
    </>
  );
};
