import { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EMAIL_REGEX } from '../../../../../utils';
import { EmailTextInput } from '../../../../Login/components/EmailTextInput/EmailTextInput';
import { AddPatientForm } from '../AddPatientModal';

interface EmailInputProps {
  control: Control<AddPatientForm>;
  testId: string;
}

export const EmailInput: FunctionComponent<EmailInputProps> = ({
  control,
  testId,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="email"
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <EmailTextInput
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            label={t(
              'patientList.addPatientModal.patientInformation.emailAddress',
            )}
            isValid={error === undefined}
            inputTestId={`${testId}-email-input`}
            errorMessage={t(
              'patientList.addPatientModal.errorMessages.invalidEmailAddress',
            )}
          />
        );
      }}
      rules={{
        required: true,
        validate: (value) => EMAIL_REGEX.test(value),
      }}
    />
  );
};
