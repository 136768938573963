import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { usePatientMedicineTreatments } from '../../../../../../services/api/medicine-treatments';
import { MedicineTreatment } from '../../../../../../types/medicineTreatment';
import { FilterStatus } from '../Filters/TreatmentFilters';

export const useMedicineTreatments = (
  patientId: string,
): {
  isLoading: boolean;
  isError: boolean;
  displayedMedicineTreatments: MedicineTreatment[];
  groupedMedicineTreatments: Record<FilterStatus, MedicineTreatment[]>;
  areAllMedicineTreatmentsDisplayed: boolean;
  setAreAllMedicineTreatmentsDisplayed: (value: boolean) => void;
  medicineTreatmentFilter: FilterStatus;
  setMedicineTreatmentFilter: (value: FilterStatus) => void;
  selectedMedicineTreatment: MedicineTreatment | null;
  setSelectedMedicineTreatment: (value: MedicineTreatment | null) => void;
  medicineTreatmentToModify: MedicineTreatment | null;
  setMedicineTreatmentToModify: (value: MedicineTreatment | null) => void;
  isAddMedicineModalOpened: boolean;
  setIsAddMedicineModalOpened: (value: boolean) => void;
} => {
  const {
    isLoading,
    data: medicineTreatments,
    isError,
  } = usePatientMedicineTreatments(patientId);

  const [
    areAllMedicineTreatmentsDisplayed,
    setAreAllMedicineTreatmentsDisplayed,
  ] = useState(false);

  const [medicineTreatmentFilter, setMedicineTreatmentFilter] =
    useState<FilterStatus>('ONGOING');

  const [medicineTreatmentToModify, setMedicineTreatmentToModify] =
    useState<MedicineTreatment | null>(null);

  const [isAddMedicineModalOpened, setIsAddMedicineModalOpened] =
    useState(false);

  const today = dayjs();

  const ongoingMedicineTreatments = (medicineTreatments ?? []).filter(
    (medicineTreatment) => {
      return (
        medicineTreatment.endDate === null ||
        dayjs(medicineTreatment.endDate).isSame(today, 'day') ||
        dayjs(medicineTreatment.endDate).isAfter(today, 'day')
      );
    },
  );

  const stoppedMedicineTreatments = (medicineTreatments ?? []).filter(
    (medicineTreatment) => {
      return (
        medicineTreatment.endDate !== null &&
        dayjs(medicineTreatment.endDate).isBefore(today, 'day')
      );
    },
  );

  const groupedMedicineTreatments: Record<FilterStatus, MedicineTreatment[]> = {
    ALL: medicineTreatments ?? [],
    ONGOING: ongoingMedicineTreatments,
    STOPPED: stoppedMedicineTreatments,
  };

  useEffect(() => {
    if (ongoingMedicineTreatments.length === 0) {
      setMedicineTreatmentFilter('ALL');
    } else {
      setMedicineTreatmentFilter('ONGOING');
    }
  }, [ongoingMedicineTreatments.length]);

  const displayedMedicineTreatments =
    groupedMedicineTreatments[medicineTreatmentFilter];

  const [selectedMedicineTreatmentId, setSelectedMedicineTreatmentId] =
    useState<string | null>(null);

  const selectedMedicineTreatment =
    selectedMedicineTreatmentId === null
      ? null
      : groupedMedicineTreatments.ALL.find(
          (medicineTreatment) =>
            medicineTreatment.id === selectedMedicineTreatmentId,
        ) ?? null;

  const setSelectedMedicineTreatment = (
    medicineTreatment: MedicineTreatment | null,
  ) =>
    setSelectedMedicineTreatmentId(
      medicineTreatment !== null ? medicineTreatment.id : null,
    );

  return {
    isLoading,
    isError,
    displayedMedicineTreatments,
    groupedMedicineTreatments,
    areAllMedicineTreatmentsDisplayed,
    setAreAllMedicineTreatmentsDisplayed,
    medicineTreatmentFilter,
    setMedicineTreatmentFilter,
    selectedMedicineTreatment,
    setSelectedMedicineTreatment,
    medicineTreatmentToModify,
    setMedicineTreatmentToModify,
    isAddMedicineModalOpened,
    setIsAddMedicineModalOpened,
  };
};
