// eslint-disable-next-line no-restricted-imports
import './MonthPickerInput.scss';

import type { MonthPickerInputProps } from '@mantine/dates';
import { MonthPickerInput as MantineMonthPickerInput } from '@mantine/dates';
import { FunctionComponent } from 'react';

import { COLORS } from '../../../../theme/colors';

interface MonthPickerProps extends MonthPickerInputProps {
  label: string;
  isValid?: boolean;
}

export const MonthPickerInput: FunctionComponent<MonthPickerProps> = ({
  label,
  onChange,
  className,
  isValid = true,
  ...props
}) => {
  return (
    <div
      className={`month-picker-input-container ${className ?? ''}`}
      style={
        !isValid
          ? {
              borderColor: COLORS.statuses.error,
              color: COLORS.statuses.error,
            }
          : {}
      }
    >
      <label className="month-picker-label">{label}</label>
      <div className="month-picker-wrapper">
        <MantineMonthPickerInput
          classNames={{
            input: 'month-picker-input',
            wrapper: 'month-picker-wrapper',
            rightSection: 'month-picker-rightSection',
            calendar: 'month-picker-calendar',
          }}
          locale="fr"
          valueFormat="MMMM YYYY"
          onChange={(newValue: Date | null) => {
            if (onChange === undefined) return;

            if (newValue === undefined || newValue === null) {
              return onChange(null);
            }

            onChange(newValue);
          }}
          {...props}
        />
      </div>
    </div>
  );
};
