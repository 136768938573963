import './CreateMedicalHistoryModal.scss';

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useModifyMedicalHistory } from '../../../../../../services/api/medical-history';
import {
  CreateOrEditMedicalHistoryModal,
  CreateOrEditMedicalHistoryModalProps,
} from './CreateOrEditMedicalHistoryModal';
import { ORDERED_MEDICAL_HISTORY_CATEGORIES } from './data';

interface EditMedicalHistoryModalProps
  extends Omit<
    CreateOrEditMedicalHistoryModalProps,
    | 'shouldShowIndex'
    | 'isLoading'
    | 'isError'
    | 'onPressPrevious'
    | 'onSubmit'
    | 'saveButtonText'
  > {
  patientId: string;
}

export const EditMedicalHistoryModal: FunctionComponent<
  EditMedicalHistoryModalProps
> = ({ patientId, ...props }) => {
  const { t } = useTranslation();

  const category = ORDERED_MEDICAL_HISTORY_CATEGORIES[props.categoryIndex];

  const { mutate, isLoading, isError } = useModifyMedicalHistory(
    patientId,
    category,
    props.onClose,
  );

  return (
    <CreateOrEditMedicalHistoryModal
      {...props}
      onSubmit={mutate}
      shouldShowIndex={false}
      saveButtonText={t('save')}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
