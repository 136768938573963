import { useQuery } from '@tanstack/react-query';

import { Medicine } from '../../types/medicine';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

const searchMedicines = async (
  searchedMedicineName: string,
  signal?: AbortSignal,
): Promise<Medicine[]> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.SEARCH_MEDICINES, {
    queryParameters: {
      medicineName: searchedMedicineName,
    },
    signal,
  });
};

export const useSearchMedicines = (searchQuery: string) =>
  useQuery<Medicine[]>(['SearchMedicines', searchQuery], ({ signal }) =>
    searchMedicines(searchQuery, signal),
  );
