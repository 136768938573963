export enum BarometerType {
  MORALE = 'MORALE',
  SLEEP = 'SLEEP',
  MOBILITY = 'MOBILITY',
  BODY_COMFORT = 'BODY_COMFORT',
  SERENITY = 'SERENITY',
}

export interface BarometerAnswer {
  patientId: string;
  createdAt: string;
  type: BarometerType;
  value: number;
}
