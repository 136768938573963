import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { ExternalLink } from '../../components';
import { BalanceScale } from '../../components/icons/BalanceScale';
import { QuestionCircle } from '../../components/icons/QuestionCircle';
import { useDoctorVariables } from '../../services/api/hcp';

export const LEGAL_LINKS_CONTACT_EMAIL_TEST_ID = 'patient-list-contact-link';
export const LEGAL_LINKS_NOTICE_LINK_TEST_ID = 'patient-list-legal-notice-link';

export const LegalLinks: FunctionComponent = () => {
  const { t } = useTranslation();

  const { contactEmail, legalNoticeLink } = useDoctorVariables();

  return (
    <>
      <ExternalLink
        Icon={QuestionCircle}
        text={t('generalInformation.contact')}
        href={`mailto:${contactEmail}`}
        data-testid={LEGAL_LINKS_CONTACT_EMAIL_TEST_ID}
      />
      <ExternalLink
        Icon={BalanceScale}
        text={t('generalInformation.legalNotice')}
        href={legalNoticeLink}
        data-testid={LEGAL_LINKS_NOTICE_LINK_TEST_ID}
      />
    </>
  );
};
