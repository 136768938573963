import { COLORS } from '../../theme/colors';
import { Icon } from './types';

export const LineAlt: Icon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.7044 5.29555C18.6116 5.2019 18.5011 5.12757 18.3793 5.07684C18.2575 5.02612 18.1269 5 17.995 5C17.8631 5 17.7325 5.02612 17.6108 5.07684C17.489 5.12757 17.3785 5.2019 17.2856 5.29555L5.29555 17.2856C5.2019 17.3785 5.12757 17.489 5.07684 17.6108C5.02612 17.7325 5 17.8631 5 17.995C5 18.1269 5.02612 18.2575 5.07684 18.3793C5.12757 18.5011 5.2019 18.6116 5.29555 18.7044C5.38844 18.7981 5.49895 18.8724 5.62071 18.9232C5.74247 18.9739 5.87306 19 6.00497 19C6.13687 19 6.26747 18.9739 6.38922 18.9232C6.51098 18.8724 6.62149 18.7981 6.71438 18.7044L18.7044 6.71438C18.7981 6.62149 18.8724 6.51098 18.9232 6.38922C18.9739 6.26747 19 6.13687 19 6.00497C19 5.87306 18.9739 5.74247 18.9232 5.62071C18.8724 5.49895 18.7981 5.38844 18.7044 5.29555Z"
        fill={props.color ?? COLORS.texts.default}
      />
    </svg>
  );
};
