import { FunctionComponent } from 'react';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { dosageUnitData } from '../../data';
import { ModalDropDown } from '../../ModalDropDown/ModalDropDown';
import { MedicineTreatmentForm } from '../types';
import { DAILY_DOSAGE_INPUT_CONTROL_NAME } from './DailyDosageInput';

export const DAILY_DOSE_UNIT_CONTROL_NAME = 'dailyDosageUnit';

interface DailyDoseUnitProps {
  control: Control<MedicineTreatmentForm>;
  trigger: UseFormTrigger<MedicineTreatmentForm>;
  isRequired: boolean;
}

export const DailyDoseUnit: FunctionComponent<DailyDoseUnitProps> = ({
  control,
  trigger,
  isRequired,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={DAILY_DOSE_UNIT_CONTROL_NAME}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <ModalDropDown
            label={t('followup.medicineTreatments.unit')}
            data={dosageUnitData}
            onChange={async (value) => {
              onChange(value === 'NONE' ? null : value);
              await trigger(DAILY_DOSAGE_INPUT_CONTROL_NAME);
            }}
            onBlur={onBlur}
            value={value}
            isValid={error === undefined}
            errorMessage={error?.message}
          />
        );
      }}
      rules={{
        required: false,
        validate: {
          isRequired: (value) =>
            !isRequired ||
            value !== null ||
            t(
              'followup.medicineTreatments.medicineTreatmentEditionModal.errors.dailyDosePreviouslyFilled',
            ),
          dailyDoseFilled: (value, formValues) =>
            !formValues.dailyDosage ||
            !!value ||
            t(
              'followup.medicineTreatments.medicineTreatmentEditionModal.errors.dailyDoseUnitRequired',
            ),
        },
      }}
    />
  );
};
