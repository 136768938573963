import './Events.scss';

import { Dayjs } from 'dayjs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ActivityIndicator } from '../../../../../../../components';
import { Warning } from '../../../../../../../components/icons/Warning';
import { useGetPatientEvents } from '../../../../../../../services/api/events';
import { COLORS } from '../../../../../../../theme/colors';
import { getModificationTooltipLeftOffset } from '../../../utils';
import { EventIconToolTip } from './EventIconToolTip';

interface EventsProps {
  leftBound: Dayjs;
  rightBound: Dayjs;
}

export const Events: FunctionComponent<EventsProps> = ({
  leftBound,
  rightBound,
}) => {
  const { patientId } = useParams<{ patientId: string }>();
  const { t } = useTranslation();
  const {
    data: events,
    isLoading,
    isError,
  } = useGetPatientEvents(patientId ?? '');

  const hasPatientEvents = events !== undefined && events.length !== 0;

  const errorMessage = (
    <div className="event-error-message">
      <Warning color={COLORS.statuses.error} />
      <p className="p2">{t('followup.eventErrorMessage')}</p>
    </div>
  );

  return (
    <div className="events-section-container">
      <p className="p2 event-title">{t('followup.patientEvents')}</p>
      {isError ? (
        errorMessage
      ) : isLoading ? (
        <ActivityIndicator />
      ) : !hasPatientEvents ? (
        <div className="no-event-message">
          <p className="p2">{t('followup.noEvents')}</p>
        </div>
      ) : (
        <div className="events-container">
          <div className="event-line" />
          {events.map((event) => {
            const leftOffset = getModificationTooltipLeftOffset(
              leftBound,
              rightBound,
              event.date.toString(),
            );
            if (leftOffset === null) return null;
            return (
              <EventIconToolTip
                key={event.date.toString()}
                event={event}
                style={{
                  position: 'absolute',
                  left: `calc(${leftOffset}% - 6.5px)`,
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
