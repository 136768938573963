import { useMutation, useQuery } from '@tanstack/react-query';

import { Hcp, HcpRole, LegalMentionConsent } from '../../types/hcp';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

interface HcpDto {
  id: string;
  firebaseUid: string;
  firstName: string;
  lastName: string;
  centerId: number;
  role: HcpRole;
  isEDOL: boolean;
  isQoLibri: boolean;
}

const mapToHcp = (dto: HcpDto): Hcp => ({
  id: dto.id,
  firebaseUid: dto.firebaseUid,
  firstName: dto.firstName,
  lastName: dto.lastName,
  centerId: dto.centerId,
  role: dto.role,
  isEDOL: dto.isEDOL,
  isQoLibri: dto.isQoLibri,
});

const getDoctors = async (): Promise<Hcp[]> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.DOCTOR);
};

export const useDoctors = () => useQuery<Hcp[]>(['Hcp'], getDoctors);

const getDoctorMe = async (): Promise<HcpDto> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.DOCTOR_ME);
};

export const useDoctorMe = () =>
  useQuery<Hcp>(['me'], async () => mapToHcp(await getDoctorMe()));

export const useDoctorVariables = () => {
  const query = useDoctorMe();

  return query.data !== undefined && query.data.isQoLibri && !query.data.isEDOL
    ? {
        contactEmail: import.meta.env.VITE_QOLIBRI_CONTACT_EMAIL,
        legalNoticeLink: import.meta.env.VITE_QOLIBRI_LEGAL_NOTICE_LINK,
        termsOfUseLink: import.meta.env.VITE_QOLIBRI_TERMS_OF_USE_LINK,
        privacyPolicyLink: import.meta.env.VITE_QOLIBRI_PRIVACY_POLICY_LINK,
      }
    : {
        contactEmail: import.meta.env.VITE_EDOL_CONTACT_EMAIL,
        legalNoticeLink: import.meta.env.VITE_EDOL_LEGAL_NOTICE_LINK,
        termsOfUseLink: import.meta.env.VITE_EDOL_TERMS_OF_USE_LINK,
        privacyPolicyLink: import.meta.env.VITE_EDOL_PRIVACY_POLICY_LINK,
      };
};

const updateLegalNoticeConsent = async (): Promise<void> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.PUT, ENDPOINTS.LEGAL_NOTICE);
};

export const useLegalNoticeConsentUpdate = (
  onSuccess: () => void,
  onError?: (error: unknown) => void,
) => {
  return useMutation<void, unknown, void, unknown>(
    ['legalNoticeConsentUpdate'],
    updateLegalNoticeConsent,
    {
      onSuccess,
      onError,
    },
  );
};

const getHasHcpConsentedToLegalNotice =
  async (): Promise<LegalMentionConsent> => {
    const apiClient = ApiClient.getInstance();

    return apiClient.request(HttpMethod.GET, ENDPOINTS.LEGAL_NOTICE);
  };

export const useHasHcpConsentedToLegalNotice = () =>
  useQuery<LegalMentionConsent>(
    ['hasLegalNoticeBeenAccepted'],
    getHasHcpConsentedToLegalNotice,
  );
