import { Color, COLORS } from '../../../../../../theme/colors';

export const BODY_MAP_COLOR_MAPPING: Record<string, Color> = {
  RED: COLORS.pain.red,
  ORANGE: COLORS.pain.orange,
  YELLOW: COLORS.pain.yellow,
  BLUE: COLORS.pain.blue,
  GREEN: COLORS.pain.green,
  WHITE: COLORS.white,
};
