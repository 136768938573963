import dayjs, { Dayjs } from 'dayjs';

export interface Margins {
  left: number;
  right: number;
}

export const getModificationTooltipLeftOffset = (
  leftBound: Dayjs,
  rightBound: Dayjs,
  modificationDate: string,
): number | null => {
  const parsedDate = dayjs(modificationDate);

  if (
    parsedDate.isBefore(leftBound, 'day') ||
    parsedDate.isAfter(rightBound, 'day')
  ) {
    return null;
  }

  const monthsBetweenBounds: number = rightBound.diff(leftBound, 'month', true);

  return Math.trunc(
    (parsedDate.diff(leftBound, 'month', true) / monthsBetweenBounds) * 100,
  );
};
