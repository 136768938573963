import './ExternalLink.scss';

import { ComponentProps, FunctionComponent } from 'react';

import { COLORS } from '../../theme/colors';
import { Icon } from '../icons/types';

export interface ExternalLinkProps extends ComponentProps<'a'> {
  Icon: Icon;
  text: string;
}

export const ExternalLink: FunctionComponent<ExternalLinkProps> = ({
  text,
  Icon,
  href,
  ...props
}) => {
  return (
    <a
      {...props}
      href={href}
      className="external-link-container"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon color={COLORS.black} />
      <p className="link-text">{text}</p>
    </a>
  );
};
