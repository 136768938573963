import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PrescriberTypeData } from '../data';
import { ModalDropDown } from '../ModalDropDown/ModalDropDown';
import { ComplementaryTreatmentForm } from './ComplementaryTreatmentEditionModal';

type PrescriberInputProps = {
  control?: Control<ComplementaryTreatmentForm, unknown>;
};

export const PrescriberInput = ({
  control,
  ...props
}: PrescriberInputProps) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="prescriber"
      rules={{ required: true }}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <ModalDropDown
            {...props}
            label={t(
              'followup.complementaryTreatments.complementaryTreatmentEditionModal.prescriber',
            )}
            data={PrescriberTypeData(t)}
            className="prescriber-type-drop-down"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        );
      }}
    />
  );
};
