import './NoteNotSaveModal.scss';

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonClass, Modal, ModalProps } from '../../../../components';
import {
  HEIGHT_FOR_CAREFUL_MESSAGE,
  Warning,
  WIDTH_FOR_CAREFUL_MESSAGE,
} from '../../../../components/icons/Warning';
import { COLORS } from '../../../../theme/colors';

const NOTE_NOT_SAVED_MODAL_SIZE = 650;

interface NoteNotSaveModalProps extends Omit<ModalProps, 'header'> {
  onCancel: () => void;
  onDelete: () => void;
}

export const NoteNotSavedModal: FunctionComponent<NoteNotSaveModalProps> = ({
  opened,
  onClose,
  onCancel,
  onDelete,
}) => {
  const { t } = useTranslation();
  return (
    <Modal opened={opened} onClose={onClose} size={NOTE_NOT_SAVED_MODAL_SIZE}>
      <div className={'note-not-saved-message'}>
        <Warning
          color={COLORS.statuses.warning}
          width={WIDTH_FOR_CAREFUL_MESSAGE}
          height={HEIGHT_FOR_CAREFUL_MESSAGE}
        />
        <p className="note-not-saved-title">
          {t('clinicalFile.consultationNotes.noteNotSavedMessage')}
        </p>
        <p className="note-not-saved-subtitle">
          {t('clinicalFile.consultationNotes.whatToDoNext')}
        </p>
      </div>
      <div className="note-not-saved-buttons">
        <Button
          buttonClass={ButtonClass.SECONDARY}
          text={t('clinicalFile.consultationNotes.deleteNote')}
          onClick={onDelete}
        />
        <Button
          buttonClass={ButtonClass.PRIMARY}
          text={t('clinicalFile.consultationNotes.cancel')}
          onClick={onCancel}
        />
      </div>
    </Modal>
  );
};
