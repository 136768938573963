import { useEffect, useState } from 'react';

import { initI18n } from '../services/i18n/setup';
import { useAuthentication } from './useAuthentication';

export const useAppResources = () => {
  const [isI18nLoaded, setIsI18nLoaded] = useState(false);
  const [i18nError, setI18nError] = useState<unknown | null>(null);
  const { isAuthInitialized } = useAuthentication();

  useEffect(() => {
    initI18n()
      .then(() => setIsI18nLoaded(true))
      .catch((err) => setI18nError(err));
  });

  if (i18nError !== null) {
    console.warn(i18nError);
  }

  return isI18nLoaded && isAuthInitialized;
};
