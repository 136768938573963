import './ApiMessage.scss';

import { FunctionComponent } from 'react';

import { COLORS } from '../../theme/colors';
import { ButtonElement } from '../Button/Button';
import { Information, SIZE_FOR_API_MESSAGE } from '../icons/Information';
import { IconElement } from '../icons/types';
import {
  HEIGHT_FOR_CAREFUL_MESSAGE,
  Warning,
  WIDTH_FOR_CAREFUL_MESSAGE,
} from '../icons/Warning';

type ErrorApiMessageProps = Omit<ApiMessageProps, 'icon' | 'testID'>;
type InformationApiMessageProps = Omit<ApiMessageProps, 'icon' | 'testID'>;

interface ApiMessageProps {
  icon: IconElement;
  title: string;
  subtitle?: string;
  action?: ButtonElement;
  testID?: string;
  className?: string;
}

const ApiMessage: FunctionComponent<ApiMessageProps> = ({
  icon,
  title,
  subtitle,
  action,
  testID,
  className,
  ...props
}) => {
  return (
    <div {...props} data-testid={testID} className={`api-message ${className}`}>
      {icon}
      <p className="title">{title}</p>
      {subtitle && <p className="subtitle">{subtitle}</p>}
      {action}
    </div>
  );
};

export const ErrorApiMessage: FunctionComponent<ErrorApiMessageProps> = (
  props,
) => (
  <ApiMessage
    {...props}
    icon={
      <Warning
        color={COLORS.statuses.error}
        height={HEIGHT_FOR_CAREFUL_MESSAGE}
        width={WIDTH_FOR_CAREFUL_MESSAGE}
      />
    }
    testID="error-api-message"
  />
);

export const InformationApiMessage: FunctionComponent<
  InformationApiMessageProps
> = (props) => (
  <ApiMessage
    {...props}
    icon={
      <Information
        color={COLORS.primary._500}
        height={SIZE_FOR_API_MESSAGE}
        width={SIZE_FOR_API_MESSAGE}
      />
    }
    testID="information-api-message"
  />
);
