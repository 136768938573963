import './MedicineTreatments.scss';

import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AngleDown,
  Button,
  ButtonClass,
  ErrorApiMessage,
  Plus,
  SnackBar,
  SnackBarType,
} from '../../../../../../components';
import { AngleUp } from '../../../../../../components/icons/AngleUp';
import { COLORS } from '../../../../../../theme/colors';
import { MedicineTreatment } from '../../../../../../types/medicineTreatment';
import { FourTimeMarkers } from '../../types';
import { MedicineEditionModal } from '../EditionModals/MedicineTreatment/MedicineEditionModal';
import { TreatmentFilters } from '../Filters/TreatmentFilters';
import { MedicineTreatmentInformationModal } from '../InformationModals/MedicineTreatmentInformationModal';
import {
  MedicineTreatmentEndDateTooltipContent,
  MedicineTreatmentStartDateTooltipContent,
} from '../TooltipContent/MedicineTreatmentTooltipContent';
import { TreatmentCard } from '../TreatmentCard/TreatmentCard';
import { getMedicineTreatmentModificationTooltips, Loader } from '../utils';
import { useMedicineTreatments } from './useMedicineTreatments.hook';

const DEFAULT_NUMBER_OF_DISPLAYED_MEDICINE_TREATMENTS = 4;

interface MedicineTreatmentsProps {
  patientId: string;
  timeMarkers: FourTimeMarkers;
  isBackendError: boolean;
  setIsBackendError: (value: boolean) => void;
}

export const MedicineTreatments: FunctionComponent<MedicineTreatmentsProps> = ({
  patientId,
  timeMarkers,
  isBackendError,
  setIsBackendError,
}) => {
  const { t } = useTranslation();

  const {
    isLoading,
    isError,
    displayedMedicineTreatments,
    groupedMedicineTreatments,
    areAllMedicineTreatmentsDisplayed,
    setAreAllMedicineTreatmentsDisplayed,
    medicineTreatmentFilter,
    setMedicineTreatmentFilter,
    selectedMedicineTreatment,
    setSelectedMedicineTreatment,
    medicineTreatmentToModify,
    setMedicineTreatmentToModify,
    isAddMedicineModalOpened,
    setIsAddMedicineModalOpened,
  } = useMedicineTreatments(patientId);

  const medicineTreatmentCards = displayedMedicineTreatments.map(
    (medicineTreatment: MedicineTreatment) => {
      const today = dayjs();
      const isTreatmentFinished =
        medicineTreatment.endDate !== null &&
        dayjs(medicineTreatment.endDate).isBefore(today);
      return (
        <TreatmentCard
          treatmentName={medicineTreatment.medicine.denomination}
          key={medicineTreatment.id}
          leftBound={timeMarkers[0]}
          rightBound={timeMarkers[3]}
          startDate={medicineTreatment.startDate}
          endDate={medicineTreatment.endDate}
          color={
            isTreatmentFinished
              ? COLORS.grey.grey70
              : COLORS.tertiary.lightTurquoise
          }
          testId="medicine-treatment-lines-id"
          onClick={() => setSelectedMedicineTreatment(medicineTreatment)}
          onClickModify={() => setMedicineTreatmentToModify(medicineTreatment)}
          modifyButtonColor={COLORS.primary._500}
          endDateTooltipContent={
            <MedicineTreatmentEndDateTooltipContent
              medicineTreatment={medicineTreatment}
            />
          }
          startDateTooltipContent={
            <MedicineTreatmentStartDateTooltipContent
              medicineTreatment={medicineTreatment}
            />
          }
          modificationTooltips={getMedicineTreatmentModificationTooltips(
            medicineTreatment,
          )}
          tooltipBorderColor={COLORS.primary._500}
        />
      );
    },
  );

  const numberOfHiddenMedicineTreatments =
    displayedMedicineTreatments.length <=
    DEFAULT_NUMBER_OF_DISPLAYED_MEDICINE_TREATMENTS
      ? 0
      : displayedMedicineTreatments.length -
        DEFAULT_NUMBER_OF_DISPLAYED_MEDICINE_TREATMENTS;

  const showAllTreatmentsButtonLabel = areAllMedicineTreatmentsDisplayed
    ? t('followup.medicineTreatmentDisplayButton.mask')
    : t('followup.medicineTreatmentDisplayButton.seeAll', {
        numberOfHiddenMedicineTreatments,
      });

  const errorSnackbar = (
    <div className="snackbar-error-medicine-edition-modal-wrapper">
      <SnackBar
        title={t('errors.unknownError.message')}
        snackBarType={SnackBarType.ERROR}
        className="snackbar-error-medicine-edition-modal"
      />
    </div>
  );

  return (
    <>
      <div className="followup-title-and-card-container">
        <h2 className="followup-section-title">
          {t('followup.medicineTreatments.medicineTreatmentSectionTitle')}
        </h2>

        {isLoading && <Loader testId="activity-indicator-medicine" />}

        {isError ? (
          <ErrorApiMessage
            title={t('apiMessage.serverError.errorOccurred')}
            subtitle={t('apiMessage.serverError.tryAgainLater')}
            className="treatment-error"
          />
        ) : (
          <>
            <div className="treatment-header">
              <div className="filters-container">
                <TreatmentFilters
                  groupedTreatments={groupedMedicineTreatments}
                  currentFilterStatus={medicineTreatmentFilter}
                  setFilterStatus={setMedicineTreatmentFilter}
                  testIdPrefix="medicine-treatment"
                />
                {groupedMedicineTreatments.ALL.length === 0 && (
                  <p
                    className="no-treatment-message"
                    data-testid="no-medicine-treatment-message"
                  >
                    {t(
                      'followup.medicineTreatments.noMedicineTreatmentMessage',
                    )}
                  </p>
                )}
              </div>
              <Button
                buttonClass={ButtonClass.SECONDARY}
                text={t(
                  'followup.medicineTreatments.addMedicineTreatmentButton',
                )}
                onClick={() => setIsAddMedicineModalOpened(true)}
                LeftIcon={Plus}
                className="add-medicine-button"
              />
            </div>

            {areAllMedicineTreatmentsDisplayed
              ? medicineTreatmentCards
              : medicineTreatmentCards.slice(
                  0,
                  DEFAULT_NUMBER_OF_DISPLAYED_MEDICINE_TREATMENTS,
                )}

            {numberOfHiddenMedicineTreatments !== 0 && (
              <div className="medicine-treatment-limitation-button-container">
                <Button
                  buttonClass={ButtonClass.TERTIARY}
                  text={showAllTreatmentsButtonLabel}
                  onClick={() =>
                    setAreAllMedicineTreatmentsDisplayed(
                      !areAllMedicineTreatmentsDisplayed,
                    )
                  }
                  RightIcon={
                    areAllMedicineTreatmentsDisplayed ? AngleUp : AngleDown
                  }
                  data-testid="medicine-treatment-limitation-button"
                />
              </div>
            )}
          </>
        )}
      </div>
      <MedicineTreatmentInformationModal
        opened={selectedMedicineTreatment !== null}
        medicineTreatment={selectedMedicineTreatment}
        onClose={() => setSelectedMedicineTreatment(null)}
        data-testid="medicine-treatment-modal"
        onClickModify={() =>
          setMedicineTreatmentToModify(selectedMedicineTreatment)
        }
      />
      <MedicineEditionModal
        type={'MODIFY'}
        title={t(
          'followup.medicineTreatments.medicineTreatmentEditionModal.editAMedicine',
        )}
        onSubmit={() => {}}
        opened={medicineTreatmentToModify !== null}
        onClose={() => {
          setIsBackendError(false);
          setMedicineTreatmentToModify(null);
        }}
        defaultValue={
          medicineTreatmentToModify !== null
            ? medicineTreatmentToModify
            : undefined
        }
        patientId={patientId}
        testId="medicine-treatment-modification-modal"
        setIsBackendError={setIsBackendError}
      />
      <MedicineEditionModal
        type={'ADD'}
        title={t(
          'followup.medicineTreatments.medicineTreatmentEditionModal.addAMedicine',
        )}
        onSubmit={() => {}}
        opened={isAddMedicineModalOpened}
        onClose={() => {
          setIsBackendError(false);
          setIsAddMedicineModalOpened(false);
        }}
        patientId={patientId}
        testId="medicine-treatment-creation-modal"
        setIsBackendError={setIsBackendError}
      />
      {isBackendError && errorSnackbar}
    </>
  );
};
