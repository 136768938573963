import {
  MedicalHistoryAntecedent,
  MedicalHistoryCategory,
} from '../../../../../../types/medicalHistory';

export const ORDERED_MEDICAL_HISTORY_CATEGORIES: MedicalHistoryCategory[] = [
  MedicalHistoryCategory.OTHER_MEDICAL_OR_SURGICAL_HISTORY,
  MedicalHistoryCategory.MEDICATION_HISTORY,
  MedicalHistoryCategory.PSYCHIATRIC_HISTORY,
  MedicalHistoryCategory.ADDICTIVE_BEHAVIOUR_HISTORY,
  MedicalHistoryCategory.LIFE_HISTORY,
];

export const ANTECEDENTS_BY_CATEGORY: Record<
  MedicalHistoryCategory,
  MedicalHistoryAntecedent[]
> = {
  [MedicalHistoryCategory.OTHER_MEDICAL_OR_SURGICAL_HISTORY]: [
    MedicalHistoryAntecedent.NO_ANTECEDENTS,
    MedicalHistoryAntecedent.CARDIOVASCULAR,
    MedicalHistoryAntecedent.RESPIRATORY,
    MedicalHistoryAntecedent.NEUROLOGICAL,
    MedicalHistoryAntecedent.DIGESTIVE,
    MedicalHistoryAntecedent.UROLOGICAL_NEPHROLOGICAL,
    MedicalHistoryAntecedent.GENITAL,
    MedicalHistoryAntecedent.HEPATIC,
    MedicalHistoryAntecedent.ENT,
    MedicalHistoryAntecedent.OCULAR,
    MedicalHistoryAntecedent.DERMATOLOGICAL,
    MedicalHistoryAntecedent.IMMUNE,
    MedicalHistoryAntecedent.ENDOCRINE,
    MedicalHistoryAntecedent.HAEMATOLOGICAL,
    MedicalHistoryAntecedent.ALLERGIC,
    MedicalHistoryAntecedent.LOCOMOTOR_RHEUMATOLOGICAL,
    MedicalHistoryAntecedent.DONT_KNOW,
    MedicalHistoryAntecedent.OTHER,
  ],
  [MedicalHistoryCategory.MEDICATION_HISTORY]: [
    MedicalHistoryAntecedent.NO_ANTECEDENTS,
    MedicalHistoryAntecedent.PARACETAMOL,
    MedicalHistoryAntecedent.PARACETAMOL_PLUS_CODEINE_OR_TRAMADOL_OR_OPIUM,
    MedicalHistoryAntecedent.IBUPROFEN,
    MedicalHistoryAntecedent.IBUPROFEN_PLUS_CODEINE,
    MedicalHistoryAntecedent.GABAPENTINOIDS,
    MedicalHistoryAntecedent.OTHER_ANTI_EPILEPTICS,
    MedicalHistoryAntecedent.NSAIDS,
    MedicalHistoryAntecedent.ANTIDEPRESSANTS,
    MedicalHistoryAntecedent.WEAK_OPIOIDS,
    MedicalHistoryAntecedent.LOCAL_ANAESTHETICS_OR_PATCHES,
    MedicalHistoryAntecedent.STRONG_OPIOIDS,
    MedicalHistoryAntecedent.CAPSAICIN,
    MedicalHistoryAntecedent.STEROIDAL_ANTI_INFLAMMATORY_DRUGS,
    MedicalHistoryAntecedent.TRIPTANS,
    MedicalHistoryAntecedent.BETA_BLOCKERS,
    MedicalHistoryAntecedent.MEDICAL_CANNABINOIDS,
    MedicalHistoryAntecedent.DONT_KNOW,
    MedicalHistoryAntecedent.OTHER,
  ],
  [MedicalHistoryCategory.PSYCHIATRIC_HISTORY]: [
    MedicalHistoryAntecedent.NO_ANTECEDENTS,
    MedicalHistoryAntecedent.PSYCHIATRIC_HOSPITALIZATION,
    MedicalHistoryAntecedent.PSYCHIATRIC_CONSULTATION,
    MedicalHistoryAntecedent.DEPRESSION,
    MedicalHistoryAntecedent.POST_TRAUMATIC_STRESS,
    MedicalHistoryAntecedent.PSYCHOSIS,
    MedicalHistoryAntecedent.BIPOLAR_DISORDER,
    MedicalHistoryAntecedent.ANXIETY_DISORDERS,
    MedicalHistoryAntecedent.EATING_DISORDERS,
    MedicalHistoryAntecedent.DONT_KNOW,
    MedicalHistoryAntecedent.OTHER,
  ],
  [MedicalHistoryCategory.ADDICTIVE_BEHAVIOUR_HISTORY]: [
    MedicalHistoryAntecedent.NO_ANTECEDENTS,
    MedicalHistoryAntecedent.TOBACCO,
    MedicalHistoryAntecedent.ALCOHOL,
    MedicalHistoryAntecedent.CANNABIS,
    MedicalHistoryAntecedent.DRUGS,
    MedicalHistoryAntecedent.BEHAVIOURAL_ADDICTIONS,
    MedicalHistoryAntecedent.COCAINE,
    MedicalHistoryAntecedent.ECSTASY,
    MedicalHistoryAntecedent.HEROIN,
    MedicalHistoryAntecedent.DONT_KNOW,
    MedicalHistoryAntecedent.OTHER,
  ],
  [MedicalHistoryCategory.LIFE_HISTORY]: [
    MedicalHistoryAntecedent.NO_ANTECEDENTS,
    MedicalHistoryAntecedent.SOCIAL_OR_FAMILY_ISOLATION,
    MedicalHistoryAntecedent.DEATH_OF_A_CLOSE_RELATIVE,
    MedicalHistoryAntecedent.DIVORCE,
    MedicalHistoryAntecedent.LOSS_OF_EMPLOYMENT,
    MedicalHistoryAntecedent.BURN_OUT,
    MedicalHistoryAntecedent.ACCIDENT,
    MedicalHistoryAntecedent.PHYSICAL_VIOLENCE,
    MedicalHistoryAntecedent.SEXUAL_VIOLENCE,
    MedicalHistoryAntecedent.PSYCHOLOGICAL_VIOLENCE,
    MedicalHistoryAntecedent.DONT_KNOW,
    MedicalHistoryAntecedent.OTHER,
  ],
};
