import './ClinicalFile.scss';

import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

import { ConsultationNotesSection } from './components/ConsultationNotesSection/ConsultationNotesSection';
import { ConsultationReasonSection } from './components/ConsultationReasonSection/ConsultationReasonSection';
import { DiagnosesSection } from './components/DiagnosesSection/DiagnosesSection';
import { MedicalHistorySection } from './components/MedicalHistorySection/MedicalHistorySection';

export const ClinicalFile: FunctionComponent = () => {
  const { patientId } = useParams<{ patientId: string }>();

  return (
    <div className="clinical-file-container">
      <div className="clinical-file-sections-container">
        <MedicalHistorySection patientId={patientId ?? ''} />
        <DiagnosesSection patientId={patientId ?? ''} />
        <ConsultationReasonSection patientId={patientId ?? ''} />
      </div>
      <ConsultationNotesSection patientId={patientId ?? ''} />
    </div>
  );
};
