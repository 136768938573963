import { FunctionComponent, JSX } from 'react';
import { useTranslation } from 'react-i18next';
import type { TextAnchorType } from 'victory';
import {
  VictoryArea,
  VictoryChart,
  VictoryLabel,
  VictoryPolarAxis,
} from 'victory';

import { COLORS } from '../../../../../../theme/colors';
import { Scores, ScoreType } from '../../../../../../types/scores';

export const SPIDER_CHART_DEFAULT_WIDTH_IN_PX = 500;
export const SPIDER_CHART_DEFAULT_HEIGHT_IN_PX = 340;

const SPIDER_CHART_AXIS_ANGLE_OFFSET_IN_DEG = 90;
const DEGREES_IN_FULL_CIRCLE = 360;
const SPIDER_CHART_MIN_ANGLE = SPIDER_CHART_AXIS_ANGLE_OFFSET_IN_DEG;
const SPIDER_CHART_MAX_ANGLE =
  -DEGREES_IN_FULL_CIRCLE + SPIDER_CHART_AXIS_ANGLE_OFFSET_IN_DEG;

const getSpiderChartAxisLabelAnchor = (angle: number): TextAnchorType => {
  if (angle < 120) {
    // top
    return 'middle';
  } else if (angle < 240) {
    // left
    return 'end';
  } else if (angle < 300) {
    // bottom
    return 'middle';
  } else {
    // right
    return 'start';
  }
};

const processData = (
  data: Scores,
  types: ScoreType[],
  maxValue: number,
): { x: ScoreType; y: number }[] => {
  const scores = types.map((scoreType) => ({
    x: scoreType,
    y: data[scoreType] > maxValue ? maxValue : data[scoreType],
  }));
  // Victory is ordering the values anti clockwise in the spider chart
  return [scores[0], ...scores.slice(1).reverse()];
};

interface SpiderChartProps {
  minValue: number;
  maxValue: number;
  scoreTypes: ScoreType[];
  background: JSX.Element;
  latestData?: Scores;
  previousData?: Scores;
  width?: number;
  height?: number;
}

export const SpiderChart: FunctionComponent<SpiderChartProps> = ({
  minValue,
  maxValue,
  scoreTypes,
  background,
  latestData,
  previousData,
  width = SPIDER_CHART_DEFAULT_WIDTH_IN_PX,
  height = SPIDER_CHART_DEFAULT_HEIGHT_IN_PX,
}) => {
  const { t } = useTranslation();

  const axes = scoreTypes.map((scoreType, index) => {
    const angle =
      (index * DEGREES_IN_FULL_CIRCLE) / scoreTypes.length +
      SPIDER_CHART_AXIS_ANGLE_OFFSET_IN_DEG;
    const labelAnchor = getSpiderChartAxisLabelAnchor(angle);
    const axisName: string = t(
      `characterization.spiderCharts.axisNames.${scoreType}`,
    );

    return (
      <VictoryPolarAxis
        dependentAxis
        key={scoreType}
        label={axisName}
        axisAngle={angle}
        labelPlacement="vertical"
        style={{
          axis: { stroke: COLORS.white },
          axisLabel: {
            fontFamily: "'Be Vietnam Pro', sans-serif",
            fontSize: 14,
            textAnchor: labelAnchor,
            padding: 20,
          },
          grid: { stroke: 'none' },
          ticks: { stroke: 'transparent' },
          tickLabels: {
            fontFamily: "'Be Vietnam Pro', sans-serif",
            fontSize: 11,
            fill: index === 0 ? COLORS.white : 'transparent',
          },
        }}
        tickValues={[20, 30, 40, 50, 60, 70, 80]}
        tickLabelComponent={<VictoryLabel textAnchor="end" dx={-5} />}
        axisLabelComponent={<VictoryLabel verticalAnchor="middle" />}
      />
    );
  });

  const dataAreas: JSX.Element[] = [];

  if (latestData !== undefined) {
    dataAreas.push(
      <VictoryArea
        data={processData(latestData, scoreTypes, maxValue)}
        style={{
          data: {
            stroke: COLORS.texts.default,
            strokeWidth: 1,
            fill: COLORS.white,
            fillOpacity: 0.3,
          },
        }}
      />,
    );
  }
  if (previousData !== undefined) {
    dataAreas.push(
      <VictoryArea
        data={processData(previousData, scoreTypes, maxValue)}
        style={{
          data: {
            stroke: COLORS.texts.default,
            strokeWidth: 1,
            strokeDasharray: 2,
            fill: 'transparent',
          },
        }}
      />,
    );
  }

  return (
    <div>
      <VictoryChart
        polar
        width={width}
        height={height}
        domain={{ y: [minValue, maxValue] }}
        startAngle={SPIDER_CHART_MIN_ANGLE}
        endAngle={SPIDER_CHART_MAX_ANGLE}
      >
        {background}
        {axes}
        {dataAreas}
      </VictoryChart>
    </div>
  );
};
