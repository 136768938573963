import './LabeledText.scss';

import { ComponentProps, FunctionComponent } from 'react';

interface LabeledTextProps extends ComponentProps<'div'> {
  label: string;
  text: string;
  textClassName?: string;
}

export const LabeledText: FunctionComponent<LabeledTextProps> = ({
  label,
  text,
  textClassName,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`labeled-text-container ${props.className ?? ''}`}
    >
      <p className="labeled-text-label">{label}</p>
      <p className={`labeled-text-description ${textClassName ?? ''}`}>
        {text}
      </p>
    </div>
  );
};
