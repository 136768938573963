import './TooltipContent.scss';

import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDateFrench } from '../../../../../../services/date/formatDateFrench';
import {
  DosageUnit,
  MedicineTreatment,
  MedicineTreatmentHistoryData,
} from '../../../../../../types/medicineTreatment';
import { getDailyDosageAndUnitLabelPerDay } from '../posology';
import { isTreatmentAge } from '../utils';

interface MedicineTreatmentTooltipContentProps {
  medicineTreatment: MedicineTreatment;
}

export const MedicineTreatmentEndDateTooltipContent: FunctionComponent<
  MedicineTreatmentTooltipContentProps
> = ({ medicineTreatment }) => {
  const { t } = useTranslation();

  return (
    <div className="tooltip-content-container">
      <p className="tooltip-title">{t('followup.treatmentTooltip.endDate')}</p>
      <p className="tooltip-text">
        {medicineTreatment.endDate !== null
          ? formatDateFrench(medicineTreatment.endDate)
          : '-'}
      </p>
      <p className="tooltip-title">
        {t('followup.medicineTreatments.medicineTreatmentTooltip.posology')}
      </p>
      <p className="tooltip-text">
        {getDailyDosageAndUnitLabelPerDay(
          medicineTreatment.dailyDosage,
          medicineTreatment.dailyDosageUnit,
        )}
      </p>
      {medicineTreatment.details !== null &&
        medicineTreatment.details !== '' && (
          <>
            <p className="tooltip-title">
              {t('followup.treatmentTooltip.precisions')}
            </p>
            <p className="tooltip-text">{medicineTreatment.details}</p>
          </>
        )}
    </div>
  );
};

export const MedicineTreatmentStartDateTooltipContent: FunctionComponent<
  MedicineTreatmentTooltipContentProps
> = ({ medicineTreatment }) => {
  const { t } = useTranslation();

  // default is the latest modification before the start date or on the same day
  // if there is none, take the oldest modification
  // if no modifications (should not happen), take the current state
  let displayedData: MedicineTreatmentHistoryData | MedicineTreatment;

  if (isTreatmentAge(medicineTreatment.startDate)) {
    if (medicineTreatment.history.length === 0) {
      displayedData = medicineTreatment;
    } else {
      displayedData =
        medicineTreatment.history[medicineTreatment.history.length - 1].data;
    }
  } else {
    const modificationsBeforeStartDate = medicineTreatment.history.filter(
      (modification) => {
        const modificationDate = dayjs(modification.updatedAt);
        const startDate = dayjs(medicineTreatment.startDate);

        return (
          modificationDate.isBefore(startDate, 'day') ||
          modificationDate.isSame(startDate, 'day')
        );
      },
    );

    if (modificationsBeforeStartDate.length > 0) {
      displayedData = modificationsBeforeStartDate[0].data;
    } else {
      if (medicineTreatment.history.length === 0) {
        displayedData = medicineTreatment;
      } else {
        displayedData =
          medicineTreatment.history[medicineTreatment.history.length - 1].data;
      }
    }
  }

  return (
    <div className="tooltip-content-container">
      <p className="tooltip-title">
        {t('followup.treatmentTooltip.startDate')}
      </p>
      <p className="tooltip-text">
        {isTreatmentAge(medicineTreatment.startDate)
          ? ''
          : formatDateFrench(medicineTreatment.startDate)}
      </p>
      <p className="tooltip-title">
        {t('followup.medicineTreatments.medicineTreatmentTooltip.posology')}
      </p>
      <p className="tooltip-text">
        {getDailyDosageAndUnitLabelPerDay(
          displayedData.dailyDosage,
          displayedData.dailyDosageUnit,
        )}
      </p>
      {displayedData.details !== null && displayedData.details !== '' && (
        <>
          <p className="tooltip-title">
            {t('followup.treatmentTooltip.precisions')}
          </p>
          <p className="tooltip-text">{displayedData.details}</p>
        </>
      )}
    </div>
  );
};

type MedicineTreatmentModificationTooltipContentProps = { date: string } & {
  dailyDosage?: number | null | undefined;
  dailyDosageUnit?: DosageUnit | null | undefined;
  details?: string | null | undefined;
};

export const MedicineTreatmentModificationTooltipContent: FunctionComponent<
  MedicineTreatmentModificationTooltipContentProps
> = ({ date, dailyDosage, dailyDosageUnit, details }) => {
  const { t } = useTranslation();

  return (
    <div className="tooltip-content-container">
      <p className="tooltip-title">
        {t('followup.treatmentTooltip.modificationDate')}
      </p>
      <p className="tooltip-text">{formatDateFrench(date)}</p>
      {dailyDosage !== undefined && dailyDosageUnit !== undefined && (
        <>
          <p className="tooltip-title">
            {t('followup.medicineTreatments.medicineTreatmentTooltip.posology')}
          </p>
          <p className="tooltip-text">
            {getDailyDosageAndUnitLabelPerDay(dailyDosage, dailyDosageUnit)}
          </p>
        </>
      )}
      {details !== undefined && (
        <>
          <p className="tooltip-title">
            {t('followup.treatmentTooltip.precisions')}
          </p>
          <p className="tooltip-text">
            {details === null || details === '' ? '-' : details}
          </p>
        </>
      )}
    </div>
  );
};
