import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  ConsultationNote,
  CreateOrEditConsultationNoteDto,
} from '../../types/consultationNotes';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

const getConsultationNotes = async (
  patientId: string,
): Promise<ConsultationNote[]> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.CONSULTATION_NOTES, {
    pathParameters: { ':patientId': patientId },
  });
};

export const useGetConsultationNotes = (patientId: string) =>
  useQuery<ConsultationNote[]>(['getConsultationNotes', patientId], () =>
    getConsultationNotes(patientId),
  );

const saveConsultationNote = async (
  patientId: string,
  note: CreateOrEditConsultationNoteDto,
): Promise<void> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.POST, ENDPOINTS.CONSULTATION_NOTES, {
    pathParameters: { ':patientId': patientId },
    data: note,
  });
};

export const useSaveConsultationNote = (
  patientId: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, CreateOrEditConsultationNoteDto, unknown>(
    ['saveConsultationNote'],
    (dto: CreateOrEditConsultationNoteDto) =>
      saveConsultationNote(patientId, dto),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        queryClient.invalidateQueries(['getConsultationNotes', patientId]);
      },
      onError,
    },
  );
};

const updateConsultationNote = async (
  patientId: string,
  consultationNoteId: string,
  note: CreateOrEditConsultationNoteDto,
): Promise<void> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.PUT, ENDPOINTS.UPDATE_CONSULTATION_NOTE, {
    pathParameters: {
      ':patientId': patientId,
      ':consultationNoteId': consultationNoteId,
    },
    data: note,
  });
};

export const useUpdateConsultationNote = (
  patientId: string,
  consultationNoteId: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, CreateOrEditConsultationNoteDto, unknown>(
    ['updateConsultationNote'],
    (dto: CreateOrEditConsultationNoteDto) =>
      updateConsultationNote(patientId, consultationNoteId, dto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getConsultationNotes', patientId]);
        if (onSuccess) {
          onSuccess();
        }
      },
      onError,
    },
  );
};
