import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { UpdateConsultationReasonDto } from '../../types/consultationReason';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

const updatePatientConsultationReason = (
  patientId: string,
  dto: UpdateConsultationReasonDto,
): Promise<void> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.PUT, ENDPOINTS.CONSULTATION_REASON, {
    pathParameters: { ':patientId': patientId },
    data: dto,
  });
};

export const useUpdatePatientConsultationReason = (patientId: string) => {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, UpdateConsultationReasonDto>(
    ['updateConsultationReason', patientId],
    (dto: UpdateConsultationReasonDto) =>
      updatePatientConsultationReason(patientId, dto),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'getConsultationReason',
          patientId,
        ]);
      },
    },
  );
};

const getPatientConsultationReason = (
  patientId: string,
): Promise<{ consultationReason: string | null }> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.CONSULTATION_REASON, {
    pathParameters: { ':patientId': patientId },
  });
};

export const usePatientConsultationReason = (patientId: string) =>
  useQuery<{ consultationReason: string | null }>(
    ['getConsultationReason', patientId],
    () => getPatientConsultationReason(patientId),
  );
