import './PatientDiagnosis.scss';

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, LabeledText } from '../../../../../../components';
import { Pen } from '../../../../../../components/icons/Pen';
import { formatMonthAndYearFrench } from '../../../../../../services/date/formatDateFrench';
import { COLORS } from '../../../../../../theme/colors';
import { PatientDiagnosis as PatientDiagnosisType } from '../../../../../../types/diagnosis';
import { capitalize } from '../../../../../../utils';

interface PatientDiagnosisProps {
  patientDiagnosis: PatientDiagnosisType;
  onClickEdit: () => void;
}

export const PatientDiagnosis: FunctionComponent<PatientDiagnosisProps> = ({
  patientDiagnosis,
  onClickEdit,
}) => {
  const { t } = useTranslation();

  return (
    <div className="patient-diagnosis-container">
      <div className="patient-diagnosis-information-container">
        <p className="p1">{patientDiagnosis.diagnosis.name}</p>
        <div className="patient-diagnosis-line-container">
          <LabeledText
            label={t('clinicalFile.diagnoses.validationLabel')}
            text={t(
              `clinicalFile.diagnosisValidation.${patientDiagnosis.validation}`,
            )}
            className="patient-diagnosis-line-element"
          />
          <LabeledText
            label={t('clinicalFile.diagnoses.dateLabel')}
            text={capitalize(formatMonthAndYearFrench(patientDiagnosis.date))}
            className="patient-diagnosis-line-element"
          />
        </div>
        <LabeledText
          label={t('clinicalFile.diagnoses.detailsLabel')}
          text={
            patientDiagnosis.details === null || patientDiagnosis.details === ''
              ? '-'
              : patientDiagnosis.details
          }
        />
      </div>
      <IconButton
        onClick={onClickEdit}
        Icon={Pen}
        color={COLORS.primary._500}
        data-testid="patient-diagnosis-pen-button"
      />
    </div>
  );
};
