import { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';

import { MedicineTreatment } from '../../../../../../../../types/medicineTreatment';
import { SearchableMedicinesInput } from '../SearchableMedicinesInput/SearchableMedicinesInput';
import { MedicineTreatmentForm } from '../types';

interface AutocompleteMedicineNameProps {
  type: 'ADD' | 'MODIFY';
  control: Control<MedicineTreatmentForm>;
  defaultValue?: MedicineTreatment;
}

export const AutocompleteMedicineName: FunctionComponent<
  AutocompleteMedicineNameProps
> = ({ control, type, defaultValue }) => (
  <Controller
    control={control}
    name="medicineCisCode"
    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
      return (
        <SearchableMedicinesInput
          disabled={type === 'MODIFY'}
          onChange={onChange}
          onBlur={onBlur}
          value={
            type === 'MODIFY'
              ? defaultValue?.medicine.denomination
              : value?.toString()
          }
          isValid={error === undefined}
        />
      );
    }}
    rules={{ required: true }}
  />
);
