export interface Diagnosis {
  id: string;
  code: string;
  name: string;
}

export enum DiagnosisValidation {
  LIKELY = 'LIKELY',
  POTENTIAL = 'POTENTIAL',
  CERTAIN = 'CERTAIN',
}

export interface PatientDiagnosis {
  patientId: string;
  diagnosis: Diagnosis;
  validation: DiagnosisValidation;
  date: string;
  details: string | null;
  updatedAt: string;
}

export interface CreatePatientDiagnosisDto {
  diagnosisId: string;
  validation: DiagnosisValidation;
  date: string;
  details?: string | null;
}

export interface UpdatePatientDiagnosisDto
  extends Partial<Omit<CreatePatientDiagnosisDto, 'diagnosisId'>> {}
