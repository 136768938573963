const BIRTH_YEAR_LOWER_BOUND = 1900;
const BIRTH_YEAR_HIGHER_BOUND = new Date().getFullYear();

export const isBetweenLowerAndHigherBound = (value: string) => {
  const year = parseInt(value, 10);

  if (Number.isNaN(year)) return false;

  return BIRTH_YEAR_LOWER_BOUND <= year && year <= BIRTH_YEAR_HIGHER_BOUND;
};
