import './BarometerCheckboxes.scss';

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '../../../../../../../components';
import { ThemeColor } from '../../../../../../../theme/colors';
import { BarometerType } from '../../../../../../../types/barometer';
import { BAROMETER_TYPES } from '../constants';

const BAROMETER_TYPE_COLOR_NAME_MAPPING: Record<BarometerType, ThemeColor> = {
  [BarometerType.SLEEP]: ThemeColor.blue,
  [BarometerType.MOBILITY]: ThemeColor.pink,
  [BarometerType.BODY_COMFORT]: ThemeColor.orange,
  [BarometerType.MORALE]: ThemeColor.turquoise,
  [BarometerType.SERENITY]: ThemeColor.green,
};

export interface BarometerCheckboxesProps {
  isError: boolean;
  shownBarometerTypes: Record<BarometerType, boolean>;
  setShownBarometerTypes: (newTypes: Record<BarometerType, boolean>) => void;
}

export const BarometerCheckboxes: FunctionComponent<
  BarometerCheckboxesProps
> = ({ isError, shownBarometerTypes, setShownBarometerTypes }) => {
  const { t } = useTranslation();

  return (
    <div className="barometer-checkboxes-container">
      {BAROMETER_TYPES.map((type) => {
        return (
          <div
            key={type}
            className={`barometer-checkbox-and-text-container ${
              isError ? 'barometer-checkbox-and-text-container-disabled' : ''
            }`}
          >
            <Checkbox
              disabled={isError}
              color={BAROMETER_TYPE_COLOR_NAME_MAPPING[type]}
              checked={shownBarometerTypes[type]}
              onChange={(value) =>
                setShownBarometerTypes({
                  ...shownBarometerTypes,
                  [type]: value,
                })
              }
            />
            <p className="p2 barometer-checkbox-label">
              {t(`followup.barometerTypes.${type}`)}
            </p>
          </div>
        );
      })}
    </div>
  );
};
