import './ComplementTreatmentEditionModal.scss';

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ActivityIndicator,
  ErrorApiMessage,
  Modal,
  ModalProps,
} from '../../../../../../../components';
import { useAllComplementaryTherapies } from '../../../../../../../services/api/complementary-therapy';
import {
  useCreateComplementaryTreatment,
  useModifyComplementaryTreatment,
} from '../../../../../../../services/api/complementary-treatments';
import {
  ComplementaryTreatment,
  ComplementaryTreatmentDto,
} from '../../../../../../../types/complementaryTreatment';
import { isTreatmentAge } from '../../utils';
import { ComplementaryTherapyDropdown } from './ComplementaryTherapyDropdown';
import { DetailsInput } from './DetailsInput';
import { EndDateInput } from './EndDateInput';
import { PrescriberInput } from './PrescriberInput';
import { StartDateInput } from './StartDateInput';
import { SubmitButton } from './SubmitButton';

type ComplementaryTreatmentEditionModalProps = Omit<
  ModalProps,
  'header' | 'defaultValue'
> & {
  title: string;
  patientId: string;
  setIsBackendError: (value: boolean) => void;
  type: 'ADD' | 'MODIFY';
  defaultValue?: ComplementaryTreatment;
};

export interface ComplementaryTreatmentForm
  extends Omit<ComplementaryTreatmentDto, 'startDate' | 'endDate'> {
  startDate: Date | null;
  endDate: Date | null;
}

export const COMPLEMENTARY_TREATMENT_START_DATE_ID =
  'complementary-treatment-start-date';
export const COMPLEMENTARY_TREATMENT_END_DATE_ID =
  'complementary-treatment-end-date';
export const COMPLEMENTARY_TREATMENT_PRESCRIBER_ID =
  'complementary-treatment-prescriber';

export const ComplementaryTreatmentEditionModal = ({
  opened,
  onClose,
  title,
  patientId,
  setIsBackendError,
  type,
  defaultValue,
}: ComplementaryTreatmentEditionModalProps) => {
  const {
    isLoading: isLoadingComplementaryTherapies,
    data: complementaryTherapies,
    isError: isComplementaryTherapiesError,
  } = useAllComplementaryTherapies();

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { isValid, isDirty: wasFormModified },
  } = useForm<ComplementaryTreatmentForm>({
    mode: 'onBlur',
  });

  const { t } = useTranslation();
  const {
    mutate: addComplementaryTreatmentMutation,
    isLoading: isLoadingComplementaryTreatmentCreation,
  } = useCreateComplementaryTreatment({
    onSuccess: onClose,
    patientId,
    onError: () => setIsBackendError(true),
  });

  const {
    mutate: modifyComplementaryTreatmentMutation,
    isLoading: isLoadingComplementaryTreatmentModification,
  } = useModifyComplementaryTreatment(
    patientId,
    defaultValue?.id ?? '',
    onClose,
    () => setIsBackendError(true),
  );

  // Reset was used to be able to add default values
  // TECHNICAL DEBT - not urgent, not important
  useEffect(() => {
    reset({
      startDate:
        defaultValue?.startDate === undefined
          ? new Date()
          : defaultValue.startDate !== null &&
              !isTreatmentAge(defaultValue.startDate)
            ? new Date(defaultValue.startDate)
            : null,
      endDate:
        defaultValue?.endDate !== undefined && defaultValue.endDate !== null
          ? new Date(defaultValue.endDate)
          : null,
      details:
        defaultValue?.details !== null && defaultValue?.details !== undefined
          ? defaultValue.details
          : null,
      prescriber: defaultValue?.prescriber,
      therapyId: defaultValue?.therapy.id,
    });
  }, [
    defaultValue?.details,
    defaultValue?.endDate,
    defaultValue?.prescriber,
    defaultValue?.startDate,
    defaultValue?.therapy.id,
    opened,
    reset,
  ]);

  if (isLoadingComplementaryTherapies)
    return (
      <Modal opened={opened} header={title} onClose={onClose}>
        <div className="followup-loader-container">
          <ActivityIndicator />
        </div>
      </Modal>
    );

  if (isComplementaryTherapiesError)
    return (
      <Modal opened={opened} header={title} onClose={onClose}>
        <ErrorApiMessage
          title={t('apiMessage.serverError.errorOccurred')}
          subtitle={t('apiMessage.serverError.tryAgainLater')}
          className="complementary-therapy-error"
        />
      </Modal>
    );

  const onSubmit = (treatment: ComplementaryTreatmentForm) => {
    const formattedTreatment = {
      ...treatment,
      startDate: treatment.startDate?.toISOString() ?? null,
      endDate: treatment.endDate?.toISOString() ?? null,
    };
    type === 'ADD'
      ? addComplementaryTreatmentMutation(formattedTreatment)
      : modifyComplementaryTreatmentMutation(formattedTreatment);
  };

  return (
    <Modal
      opened={opened}
      header={title}
      onClose={onClose}
      classNames={{
        overlay: 'edition-modal-overlay',
        inner: 'edition-modal-inner',
      }}
    >
      <div className="edition-modal-tab-container">
        <ComplementaryTherapyDropdown
          complementaryTherapies={complementaryTherapies}
          control={control}
          disabled={type === 'MODIFY'}
        />
        <div className="dates-prescriber-container">
          <PrescriberInput
            control={control}
            data-testid={COMPLEMENTARY_TREATMENT_PRESCRIBER_ID}
          />
          <StartDateInput
            control={control}
            data-testid={COMPLEMENTARY_TREATMENT_START_DATE_ID}
          />
          <EndDateInput
            control={control}
            startDate={watch('startDate')}
            data-testid={COMPLEMENTARY_TREATMENT_END_DATE_ID}
          />
        </div>
        <DetailsInput control={control} />
        <SubmitButton
          onClick={handleSubmit(onSubmit)}
          isLoading={
            isLoadingComplementaryTreatmentCreation ||
            isLoadingComplementaryTherapies ||
            isLoadingComplementaryTreatmentModification
          }
          isEnabled={isValid && !(type === 'MODIFY' && !wasFormModified)}
        />
      </div>
    </Modal>
  );
};
