import { FunctionComponent, useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreatePatientDiagnosis } from '../../../../../../services/api/diagnosis';
import { stripTimezone } from '../../../../../../utils';
import {
  CreateOrEditDiagnosisForm,
  CreateOrEditDiagnosisModal,
  CreateOrEditDiagnosisModalProps,
} from './CreateOrEditDiagnosisModal';

interface CreateDiagnosisModalProps
  extends Omit<
    CreateOrEditDiagnosisModalProps,
    | 'title'
    | 'isLoadingCreateOrEdit'
    | 'isCreateOrEditError'
    | 'onSubmit'
    | 'defaultValues'
    | 'isAutocompleteDisabled'
  > {
  patientId: string;
}

export const CreateDiagnosisModal: FunctionComponent<
  CreateDiagnosisModalProps
> = ({ patientId, onClose, ...props }) => {
  const { t } = useTranslation();

  const defaultValues: DefaultValues<CreateOrEditDiagnosisForm> = {
    date: new Date(),
  };

  const [isCreatePatientDiagnosisError, setIsCreatePatientDiagnosisError] =
    useState<boolean>(false);

  const onCloseModal = () => {
    setIsCreatePatientDiagnosisError(false);
    onClose();
  };

  const {
    isLoading: isLoadingCreatePatientDiagnosis,
    mutate: createPatientDiagnosis,
  } = useCreatePatientDiagnosis(patientId, onCloseModal, () =>
    setIsCreatePatientDiagnosisError(true),
  );

  const onSubmit = (formData: CreateOrEditDiagnosisForm) => {
    const dto = {
      ...formData,
      date: stripTimezone(formData.date).toISOString(),
    };

    createPatientDiagnosis(dto);
  };

  return (
    <CreateOrEditDiagnosisModal
      {...props}
      onClose={onCloseModal}
      title={t('clinicalFile.createOrEditDiagnosisModal.createTitle')}
      isLoadingCreateOrEdit={isLoadingCreatePatientDiagnosis}
      isCreateOrEditError={isCreatePatientDiagnosisError}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      isAutocompleteDisabled={false}
    />
  );
};
