import type { TFunction } from 'i18next';

import { Hcp } from '../../../../types/hcp';
import { Sex } from '../../../../types/patient';

type SexItem = {
  value: Sex;
  label: string;
};

export const SexData = (t: TFunction): SexItem[] =>
  [Sex.WOMAN, Sex.MAN, Sex.OTHER].map((sex) => ({
    value: sex,
    label: t(`patientList.addPatientModal.patientInformation.sexType.${sex}`),
  }));

type ReferringDoctorItem = {
  value: string;
  label: string;
};

export const ReferringDoctorData = (doctors: Hcp[]): ReferringDoctorItem[] =>
  doctors?.map((doctor) => {
    return {
      value: doctor.id,
      label: [doctor.firstName, doctor.lastName].join(' '),
    };
  });
