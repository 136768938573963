import { FunctionComponent, useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdatePatientDiagnosis } from '../../../../../../services/api/diagnosis';
import { PatientDiagnosis } from '../../../../../../types/diagnosis';
import { stripTimezone } from '../../../../../../utils';
import {
  CreateOrEditDiagnosisForm,
  CreateOrEditDiagnosisModal,
  CreateOrEditDiagnosisModalProps,
} from './CreateOrEditDiagnosisModal';

interface EditDiagnosisModalProps
  extends Omit<
    CreateOrEditDiagnosisModalProps,
    | 'title'
    | 'isLoadingCreateOrEdit'
    | 'isCreateOrEditError'
    | 'onSubmit'
    | 'defaultValues'
    | 'isAutocompleteDisabled'
  > {
  patientId: string;
  patientDiagnosis: PatientDiagnosis;
}

export const EditDiagnosisModal: FunctionComponent<EditDiagnosisModalProps> = ({
  patientId,
  onClose,
  patientDiagnosis,
  ...props
}) => {
  const { t } = useTranslation();

  const defaultValues: DefaultValues<CreateOrEditDiagnosisForm> = {
    diagnosisId: patientDiagnosis.diagnosis.id,
    validation: patientDiagnosis.validation,
    date: new Date(patientDiagnosis.date),
    details: patientDiagnosis.details,
  };

  const [isEditPatientDiagnosisError, setIsEditPatientDiagnosisError] =
    useState<boolean>(false);

  const onCloseModal = () => {
    setIsEditPatientDiagnosisError(false);
    onClose();
  };

  const {
    isLoading: isLoadingUpdatePatientDiagnosis,
    mutate: updatePatientDiagnosis,
  } = useUpdatePatientDiagnosis(
    patientId,
    patientDiagnosis.diagnosis.id,
    onCloseModal,
    () => setIsEditPatientDiagnosisError(true),
  );

  const onSubmit = (formData: CreateOrEditDiagnosisForm) => {
    const dto = {
      ...formData,
      date: stripTimezone(formData.date).toISOString(),
    };

    updatePatientDiagnosis(dto);
  };

  return (
    <CreateOrEditDiagnosisModal
      {...props}
      onClose={onCloseModal}
      title={t('clinicalFile.createOrEditDiagnosisModal.editTitle')}
      isLoadingCreateOrEdit={isLoadingUpdatePatientDiagnosis}
      isCreateOrEditError={isEditPatientDiagnosisError}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      isAutocompleteDisabled={true}
    />
  );
};
