import './ActivityIndicator.css';

import { FunctionComponent } from 'react';

import { Loader } from '../icons';

interface ActivityIndicatorProps {
  testId?: string;
}

export const ActivityIndicator: FunctionComponent<ActivityIndicatorProps> = ({
  testId,
}) => {
  return (
    <Loader className="rotate" data-testid={testId ?? 'activity-indicator'} />
  );
};
