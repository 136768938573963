import './TreatmentCard.scss';
import '../../../../../../theme/shadows.scss';

import dayjs, { Dayjs } from 'dayjs';
import { FunctionComponent, ReactElement } from 'react';

import { IconButton } from '../../../../../../components';
import { Pen } from '../../../../../../components/icons/Pen';
import { Color, COLORS } from '../../../../../../theme/colors';
import { TreatmentAge } from '../../../../../../types/medicineTreatment';
import { getModificationTooltipLeftOffset } from '../../utils';
import {
  TooltipType,
  TreatmentTooltip,
} from '../TreatmentTooltip/TreatmentTooltip';
import { isTreatmentAge } from '../utils';

export interface ModificationTooltipProps {
  modificationDate: string;
  tooltipContent: ReactElement;
}

interface TreatmentProps {
  treatmentName: string;
  leftBound: Dayjs;
  rightBound: Dayjs;
  startDate: string | TreatmentAge | null;
  endDate: string | null;
  color: Color;
  testId?: string;
  onClick: () => void;
  onClickModify: () => void;
  editable?: boolean;
  modifyButtonColor?: Color;
  endDateTooltipContent?: ReactElement;
  startDateTooltipContent?: ReactElement;
  modificationTooltips?: ModificationTooltipProps[];
  tooltipBorderColor?: Color;
  tooltipClassname?: string;
}

interface Margins {
  left: number;
  right: number;
}

const getEndDateTooltipRightOffset = (
  leftBound: Dayjs,
  rightBound: Dayjs,
  endDate: string | null,
): number | null => {
  if (endDate === null) return null;

  const parsedEndDate = dayjs(endDate);

  if (
    parsedEndDate.isBefore(leftBound, 'day') ||
    parsedEndDate.isAfter(rightBound, 'day')
  ) {
    return null;
  }

  const monthsBetweenBounds: number = rightBound.diff(leftBound, 'month', true);

  return Math.trunc(
    (rightBound.diff(parsedEndDate, 'month', true) / monthsBetweenBounds) * 100,
  );
};

const getStartDateTooltipLeftOffset = (
  leftBound: Dayjs,
  rightBound: Dayjs,
  startDate: string | TreatmentAge | null,
): number | null => {
  if (startDate === null) return null;
  if (isTreatmentAge(startDate)) return null;

  const parsedStartDate = dayjs(startDate);

  if (
    parsedStartDate.isBefore(leftBound, 'day') ||
    parsedStartDate.isAfter(rightBound, 'day')
  ) {
    return null;
  }

  const monthsBetweenBounds: number = rightBound.diff(leftBound, 'month', true);

  return Math.trunc(
    (parsedStartDate.diff(leftBound, 'month', true) / monthsBetweenBounds) *
      100,
  );
};

export const calculateMargins = (
  leftBound: Dayjs,
  rightBound: Dayjs,
  startDate: string | TreatmentAge | null,
  endDate: string | null,
): Margins | undefined => {
  // returns undefined if the line should not be displayed at all

  const margins: Margins = { left: 0, right: 0 };
  const monthsBetweenBounds: number = rightBound.diff(leftBound, 'month', true);

  if (endDate !== null) {
    const parsedEndDate: Dayjs = dayjs(endDate);
    if (rightBound.isAfter(parsedEndDate)) {
      margins.right = Math.trunc(
        (rightBound.diff(parsedEndDate, 'month', true) / monthsBetweenBounds) *
          100,
      );
    }
    if (leftBound.isAfter(parsedEndDate)) return undefined; // no line to display
  }

  if (startDate !== null && !isTreatmentAge(startDate)) {
    const parsedStartDate: Dayjs = dayjs(startDate);
    if (leftBound.isBefore(parsedStartDate)) {
      margins.left = Math.trunc(
        (parsedStartDate.diff(leftBound, 'month', true) / monthsBetweenBounds) *
          100,
      );
    }
    if (rightBound.isBefore(parsedStartDate)) return undefined; // no line to display
  }

  if (endDate === null && startDate === null) {
    return undefined;
  }
  /**
   * additional check to come for the patient creation date when start date is of type TreatmentAge (add else case here)
   * for now left margin is 0
   */

  return margins;
};

const getLineEdgeStyles = (margins: Margins | undefined): string => {
  if (margins === undefined) return 'hidden';
  const classNames: string[] = [];
  if (margins.left !== 0) {
    classNames.push('rounded-left');
  }
  if (margins.right !== 0) {
    classNames.push('rounded-right');
  }
  return classNames.join(' ');
};

export const TreatmentCard: FunctionComponent<TreatmentProps> = ({
  treatmentName,
  leftBound,
  rightBound,
  startDate,
  endDate,
  color,
  testId,
  onClick,
  onClickModify,
  editable = true,
  modifyButtonColor,
  endDateTooltipContent,
  startDateTooltipContent,
  modificationTooltips = [],
  tooltipBorderColor,
  tooltipClassname,
}) => {
  const margins: Margins | undefined = calculateMargins(
    leftBound,
    rightBound,
    startDate,
    endDate,
  );

  const endDateTooltipRightOffset = getEndDateTooltipRightOffset(
    leftBound,
    rightBound,
    endDate,
  );

  const startDateTooltipLeftOffset = getStartDateTooltipLeftOffset(
    leftBound,
    rightBound,
    startDate,
  );

  return (
    <div
      className={`treatment-card shadow-medium ${
        editable ? '' : 'treatment-card-no-button'
      }`}
      onClick={onClick}
    >
      <div className="treatment-content">
        <p className="treatment-name">{treatmentName}</p>
        <div className="line-container">
          <div
            className={`line ${getLineEdgeStyles(margins)}`}
            data-testid={testId ?? 'treatment-timeline'}
            style={{
              backgroundColor: color,
              marginLeft: margins?.left.toString().concat('%'),
              marginRight: margins?.right.toString().concat('%'),
            }}
          />
          {startDateTooltipLeftOffset !== null && (
            <TreatmentTooltip
              type={TooltipType.START_DATE}
              tooltipContent={startDateTooltipContent}
              style={{
                position: 'absolute',
                left: `calc(${startDateTooltipLeftOffset}% - 6.5px)`,
              }}
            />
          )}
          {endDateTooltipRightOffset !== null && (
            <TreatmentTooltip
              type={TooltipType.END_DATE}
              tooltipContent={endDateTooltipContent}
              style={{
                position: 'absolute',
                right: `calc(${endDateTooltipRightOffset}% - 6.5px)`,
              }}
            />
          )}
          {modificationTooltips.map(({ modificationDate, tooltipContent }) => {
            const leftOffset = getModificationTooltipLeftOffset(
              leftBound,
              rightBound,
              modificationDate,
            );

            if (leftOffset === null) return null;
            return (
              <TreatmentTooltip
                key={modificationDate}
                type={TooltipType.MODIFICATION}
                tooltipContent={tooltipContent}
                style={{
                  position: 'absolute',
                  left: `calc(${leftOffset}% - 6.5px)`,
                }}
                borderColor={tooltipBorderColor}
                className={tooltipClassname}
              />
            );
          })}
        </div>
      </div>
      {editable && (
        <IconButton
          className="pen-button-container"
          Icon={Pen}
          color={modifyButtonColor ?? COLORS.primary._500}
          onClick={(event) => {
            event.stopPropagation();
            onClickModify();
          }}
          data-testid={`${testId}-pen-modify-button`}
        />
      )}
    </div>
  );
};
