import './Tag.scss';

import { CSSProperties, FunctionComponent } from 'react';

import { Color, COLORS } from '../../theme/colors';
interface TagProps {
  text: string;
  status: TagStatus;
  onClick?: () => void;
  testId?: string;
}

enum TagStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DISABLED = 'DISABLED',
}

const TAG_COLORS: Record<
  TagStatus,
  {
    textColor: Color;
    backgroundColor: Color;
    borderColor: Color;
    cursor: CSSProperties['cursor'];
  }
> = {
  [TagStatus.ACTIVE]: {
    textColor: COLORS.white,
    backgroundColor: COLORS.primary._500,
    borderColor: COLORS.primary._500,
    cursor: 'pointer',
  },
  [TagStatus.INACTIVE]: {
    textColor: COLORS.primary._500,
    backgroundColor: COLORS.white,
    borderColor: COLORS.primary._500,
    cursor: 'pointer',
  },
  [TagStatus.DISABLED]: {
    textColor: COLORS.texts.third,
    backgroundColor: COLORS.white,
    borderColor: COLORS.texts.third,
    cursor: 'auto',
  },
};

export const getTagStatus = (
  conditionToDisable: boolean,
  conditionToActivate: boolean,
): TagStatus => {
  if (conditionToDisable) return TagStatus.DISABLED;
  if (conditionToActivate) return TagStatus.ACTIVE;
  return TagStatus.INACTIVE;
};

export const Tag: FunctionComponent<TagProps> = ({
  text,
  status,
  onClick,
  testId,
}) => {
  const { textColor, backgroundColor, borderColor, cursor } =
    TAG_COLORS[status];
  return (
    <div
      className="tag"
      style={{ backgroundColor, borderColor, cursor }}
      onClick={status === TagStatus.DISABLED ? () => undefined : onClick}
      data-testid={testId ?? 'tag'}
    >
      <p className="p3" style={{ color: textColor }}>
        {text}
      </p>
    </div>
  );
};
