/* eslint-disable @typescript-eslint/no-explicit-any */
import { auth } from '../../firebaseConfig';

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

type QueryParameters = Record<string, string | number | boolean>;

type Path = `/${string}`;

interface Endpoint {
  path: Path;
  parameters?: string[];
}

export const ENDPOINTS = {
  PATIENTS: { path: '/patient' },
  PATIENT: { path: '/patient/:patientId', parameters: [':patientId'] },
  LEGAL_NOTICE: { path: '/hcp/has-consented-to-legal-notice' },
  SEARCH_MEDICINES: { path: '/medicine/search' },
  MEDICINE_TREATMENTS: {
    path: '/patient/:patientId/medicine-treatments',
    parameters: [':patientId'],
  },
  MEDICINE_TREATMENT: {
    path: '/patient/:patientId/medicine-treatment',
    parameters: [':patientId'],
  },
  UPDATE_MEDICINE_TREATMENT: {
    path: '/patient/:patientId/medicine-treatment/:medicineTreatmentId',
    parameters: [':patientId', ':medicineTreatmentId'],
  },
  COMPLEMENTARY_TREATMENT: {
    path: '/patient/:patientId/complementary-treatment',
    parameters: [':patientId'],
  },
  UPDATE_COMPLEMENTARY_TREATMENT: {
    path: '/patient/:patientId/complementary-treatment/:complementaryTreatmentId',
    parameters: [':patientId', ':complementaryTreatmentId'],
  },
  MEDICAL_HISTORY: {
    path: '/patient/:patientId/medical-history',
    parameters: [':patientId'],
  },
  UPDATE_MEDICAL_HISTORY: {
    path: '/patient/:patientId/medical-history/:category',
    parameters: [':patientId', ':category'],
  },
  BAROMETER_ANSWERS: {
    path: '/barometer/:patientId/barometer-answers',
    parameters: [':patientId'],
  },
  SCORES: {
    path: '/score/:patientId',
    parameters: [':patientId'],
  },
  COMPLEMENTARY_THERAPIES: { path: '/complementary-therapies' },
  DOCTOR: { path: '/hcp/doctor' },
  DOCTOR_ME: { path: '/hcp/me' },
  DIAGNOSIS: { path: '/diagnosis' },
  PATIENT_DIAGNOSIS: {
    path: '/patient/:patientId/patient-diagnosis',
    parameters: [':patientId'],
  },
  UPDATE_PATIENT_DIAGNOSIS: {
    path: '/patient/:patientId/patient-diagnosis/:diagnosisId',
    parameters: [':patientId', ':diagnosisId'],
  },
  CONSULTATION_NOTES: {
    path: '/patient/:patientId/consultation-notes',
    parameters: [':patientId'],
  },
  CONSULTATION_REASON: {
    path: '/patient/:patientId/consultation-reason',
    parameters: [':patientId'],
  },
  UPDATE_CONSULTATION_NOTE: {
    path: '/patient/:patientId/consultation-notes/:consultationNoteId',
    parameters: [':patientId', ':consultationNoteId'],
  },
  ACTIVITIES_DONE: {
    path: '/patient/:patientId/activities-done',
    parameters: [':patientId'],
  },
  QUESTIONNAIRE: {
    path: '/questionnaire/:patientId/:questionnaireId',
    parameters: [':patientId', ':questionnaireId'],
  },
  EVENTS: {
    path: '/patient/:patientId/one-off-events',
    parameters: [':patientId'],
  },
} satisfies Record<string, Endpoint | Record<string, Endpoint>>;

export class ApiClient {
  private static API_BASE_URL = import.meta.env.VITE_API_URL;

  private static instance?: ApiClient = undefined;

  constructor() {
    if (ApiClient.instance !== undefined) {
      throw new Error(
        'Call ApiClient.getInstance() instead of new ApiClient() to get the singleton instance',
      );
    }

    ApiClient.instance = this;
    return this;
  }

  public static getInstance() {
    if (ApiClient.instance !== undefined) {
      return ApiClient.instance;
    }

    return new ApiClient();
  }

  private resolvePath(
    endpoint: Endpoint,
    pathParameters?: Record<string, string>,
  ) {
    let path: Path = endpoint.path;

    if (endpoint.parameters === undefined) {
      return path;
    }

    if (pathParameters === undefined) {
      throw new Error(
        `Needed parameters ${endpoint.parameters} were not provided in the request`,
      );
    }

    endpoint.parameters.forEach((parameterName) => {
      if (!(parameterName in pathParameters)) {
        throw new Error(
          `Needed parameter "${parameterName}" for endpoint ${path} was not provided in the request params`,
        );
      }

      path = path.replace(parameterName, pathParameters[parameterName]) as Path;
    });

    return path;
  }

  private resolveQueryParameters(queryParameters: QueryParameters): string {
    const queryParametersArray = Object.entries(queryParameters).map(
      ([key, value]) => `${key}=${encodeURIComponent(value)}`,
    );

    return queryParametersArray.join('&');
  }

  public async getResponseBody(response: Response): Promise<any> {
    return (response.headers.get('Content-Type') ?? '').includes(
      'application/json',
    )
      ? await response.json()
      : undefined;
  }

  public async buildErrorMessage(
    response: Response,
    method: HttpMethod,
    path: string,
    queryParameters: QueryParameters | undefined,
  ): Promise<string> {
    const body = await this.getResponseBody(response);

    return (
      `Error ${response.status} ${response.statusText} ` +
      `for ${method} request on path ${path}` +
      `${
        queryParameters === undefined
          ? ''
          : ` with parameters ${JSON.stringify(queryParameters)}`
      }` +
      `${body === undefined ? '' : ` - Error: ${JSON.stringify(body)}`}`
    );
  }

  public async request(
    method: HttpMethod,
    endpoint: Endpoint,
    {
      pathParameters,
      data,
      queryParameters,
      signal,
    }: {
      pathParameters?: Record<string, string>;
      data?: Record<string, any>;
      queryParameters?: QueryParameters;
      signal?: AbortSignal;
    } = {},
  ): Promise<any> {
    const headers: Record<string, string> = {};

    if (auth.currentUser !== null) {
      const idToken = await auth.currentUser.getIdToken();
      headers.Authorization = `Bearer ${idToken}`;
    }
    if (data !== undefined) {
      headers['Content-Type'] = 'application/json';
    }
    const body = data === undefined ? undefined : JSON.stringify(data);

    const queryParametersString =
      queryParameters !== undefined
        ? `?${this.resolveQueryParameters(queryParameters)}`
        : '';

    const path = this.resolvePath(endpoint, pathParameters);
    const url = `${ApiClient.API_BASE_URL}${path}${queryParametersString}`;

    const response = await fetch(url, {
      method,
      headers,
      body,
      signal,
    });

    if (response.ok) {
      return this.getResponseBody(response);
    } else {
      throw new Error(
        await this.buildErrorMessage(response, method, path, queryParameters),
      );
    }
  }
}
