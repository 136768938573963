import type { SelectItem } from '@mantine/core';
import { ReactElement, useState } from 'react';

import { AutocompleteInput, AutocompleteInputProps } from './AutocompleteInput';

interface FilterableInputProps<T extends SelectItem>
  extends Omit<AutocompleteInputProps<T>, 'filter' | 'isLoading'> {}

export const FilterableInput = <T extends SelectItem>({
  data,
  value,
  onChange,
  ...props
}: FilterableInputProps<T>): ReactElement => {
  const [searchWord, setSearchWord] = useState<string>(value ?? '');
  const [selectedItem, setSelectedItem] = useState<T | null>(
    data.find((item) => item.value === value) ?? null,
  );

  return (
    <AutocompleteInput
      {...props}
      data={data}
      value={selectedItem?.label ?? searchWord}
      onChange={(newSearchWordOrValue) => {
        const foundItem = data.find(
          (item) => item.value === newSearchWordOrValue,
        );

        setSearchWord(newSearchWordOrValue ?? '');
        setSelectedItem(foundItem ?? null);
        onChange?.(foundItem?.value ?? null);
      }}
      filter={(value, item) =>
        item.label.toLowerCase().includes(value.toLowerCase())
      }
      isLoading={false}
    />
  );
};
