import './AutocompleteInput.scss';

import type { AutocompleteProps, SelectItem } from '@mantine/core';
// eslint-disable-next-line no-restricted-imports
import { Autocomplete } from '@mantine/core';
import { forwardRef, ReactElement, useRef } from 'react';

import { COLORS } from '../../theme/colors';
import { ActivityIndicator } from '../ActivityIndicator/ActivityIndicator';

const MAX_DROPDOWN_HEIGHT = 235;
const DEFAULT_NUMBER_OF_ITEMS_TO_DISPLAY = 20;

export interface AutocompleteInputProps<T extends SelectItem>
  extends AutocompleteProps {
  disabled: boolean;
  isValid: boolean;
  isLoading: boolean;
  label: string;
  data: T[];
  tooltip?: ReactElement;
  onChange: (newValue: string | null) => void;
  'data-testid'?: string;
}

const AutocompleteItem = forwardRef<HTMLDivElement, SelectItem>(
  function AutocompleteItem({ label, ...otherProps }: SelectItem, ref) {
    return (
      <div ref={ref} className="autocomplete-item" {...otherProps}>
        {label}
      </div>
    );
  },
);

export const AutocompleteInput = <T extends SelectItem>({
  disabled,
  isValid,
  isLoading,
  label,
  tooltip,
  limit,
  ...props
}: AutocompleteInputProps<T>): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={
        disabled
          ? 'disabled-autocomplete-input-container'
          : 'autocomplete-input-container'
      }
      style={
        !isValid && !disabled
          ? {
              borderColor: COLORS.statuses.error,
              color: COLORS.statuses.error,
            }
          : {}
      }
      onClick={() => {
        if (inputRef.current !== null) inputRef.current.focus();
      }}
      data-testid={
        props['data-testid'] !== undefined
          ? `${props['data-testid']}-container`
          : undefined
      }
    >
      <div className="autocomplete-input-label-container">
        <label
          htmlFor={'autocomplete-input'}
          className="autocomplete-input-label"
        >
          {label}
        </label>
        {tooltip}
      </div>
      <Autocomplete
        {...props}
        name={'autocomplete-input'}
        ref={inputRef}
        dropdownPosition="bottom"
        maxDropdownHeight={MAX_DROPDOWN_HEIGHT}
        itemComponent={AutocompleteItem}
        classNames={{
          input: 'autocomplete-input',
          dropdown: 'autocomplete-dropdown',
        }}
        rightSection={isLoading && <ActivityIndicator />}
        limit={limit ?? DEFAULT_NUMBER_OF_ITEMS_TO_DISPLAY}
      />
    </div>
  );
};
