import { Button, ButtonClass, Plus } from '../../../../../../components';

type AddComplementaryTreatmentButtonProps = {
  name: string;
  onClick: () => void;
};
export const AddComplementaryTreatmentButton = ({
  name,
  onClick,
}: AddComplementaryTreatmentButtonProps) => {
  return (
    <Button
      buttonClass={ButtonClass.SECONDARY}
      text={name}
      onClick={onClick}
      LeftIcon={Plus}
      className="add-medicine-button"
    />
  );
};
