import { COLORS } from '../../../../../../theme/colors';

/* eslint-disable max-lines */
export const Lines = () => {
  return (
    <>
      <path
        d="M134.183 13.2038C124.461 13.2038 116.953 11.7916 113.504 9.6091L114.651 7.8031C117.218 9.4293 123.562 11.0641 134.183 11.0641C142.734 11.0641 150.548 9.9172 154.091 8.1455L155.05 10.0627C151.173 12.0056 143.179 13.2038 134.183 13.2038Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.181 20.649C123.14 20.649 112.93 19.348 106.861 17.174L107.58 15.1627C113.426 17.26 123.372 18.518 134.181 18.518C144.144 18.518 153.328 17.465 159.37 15.6334L159.995 17.679C153.756 19.562 144.349 20.649 134.181 20.649Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.182 29.5251C121.531 29.5251 110.234 27.8471 104.705 25.1511L105.646 23.2251C110.824 25.7501 122.019 27.3851 134.182 27.3851C145.882 27.3851 156.563 25.9131 162.058 23.5341L162.905 25.4931C157.162 27.9841 146.155 29.5251 134.182 29.5251Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.182 39.965C121.095 39.965 109.284 38.124 104.08 35.266L105.107 33.391C110.012 36.087 121.429 37.825 134.174 37.825C146.147 37.825 157.274 36.224 162.521 33.759L163.428 35.694C157.847 38.33 146.635 39.965 134.182 39.965Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.182 50.2032C117.372 50.2032 99.0646 47.3532 96.5996 41.9012L98.5506 41.0192C100.083 44.4092 114.137 48.0632 134.182 48.0632C152.464 48.0632 165.55 44.9992 169.034 41.9692L170.437 43.5872C165.884 47.5412 151.317 50.2032 134.182 50.2032Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.183 62.536C117.194 62.536 102.105 57.794 95.7539 50.459L97.3719 49.0551C103.243 55.8341 118.041 60.396 134.183 60.396C150.257 60.396 165.03 55.868 170.944 49.124L172.553 50.536C166.159 57.828 151.104 62.536 134.183 62.536Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.183 73.5161C125.521 73.5161 117.364 72.7291 111.219 71.2911L111.707 69.2021C117.698 70.5971 125.675 71.3681 134.183 71.3681C142.87 71.3681 151.266 70.5291 157.232 69.0651L157.745 71.1451C151.532 72.6771 143.161 73.5161 134.183 73.5161Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.182 84.0442C126.676 84.0442 119.632 83.4622 113.795 82.3662L114.189 80.2612C119.812 81.3222 126.907 81.9042 134.174 81.9042C141.517 81.9042 148.673 81.3052 154.33 80.2272L154.732 82.3322C148.955 83.4282 141.654 84.0442 134.182 84.0442Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.181 94.024C122.755 94.024 112.348 92.655 106.348 90.352L107.109 88.349C112.801 90.523 123.175 91.876 134.181 91.876C145.154 91.876 155.51 90.532 161.21 88.367L161.972 90.369C155.955 92.663 145.565 94.024 134.181 94.024Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.183 105.611C116.252 105.611 99.4847 102.35 90.4297 97.0951L91.4997 95.2461C100.247 100.321 116.603 103.471 134.183 103.471C151.985 103.471 168.444 100.262 177.131 95.0831L178.227 96.9241C169.223 102.281 152.353 105.611 134.183 105.611Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.181 116.773C108.35 116.773 84.8129 112.34 71.2129 104.911L72.2399 103.036C85.5399 110.303 108.692 114.642 134.181 114.642C159.549 114.642 183.412 110.123 196.455 102.857L197.5 104.723C183.951 112.263 160.285 116.773 134.181 116.773Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.181 127.17C103.361 127.17 75.1418 122.753 60.5488 115.65L61.4818 113.724C75.8088 120.699 103.669 125.03 134.181 125.03C164.762 125.03 192.647 120.682 206.958 113.69L207.899 115.615C193.315 122.745 165.07 127.17 134.181 127.17Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.181 143.685C97.5917 143.685 65.5387 137.531 54.4297 128.373L55.7897 126.721C66.3777 135.451 98.6107 141.545 134.181 141.545C170.137 141.545 202.473 135.366 212.812 126.524L214.199 128.151C203.337 137.437 171.182 143.685 134.181 143.685Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M124.271 338.295L122.131 338.286L123.27 28.454C123.073 7.59581 133.267 2.15231 133.694 1.92981L134.67 3.83841C134.576 3.88981 125.221 9.01661 125.401 28.454L124.271 338.295Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M116.535 71.8581C112.889 64.1031 112.187 33.2911 112.298 28.4381C112.735 10.4563 122.235 4.49934 122.637 4.25964L123.741 6.09125C123.656 6.14265 114.84 11.7658 114.429 28.4981C114.318 33.0941 115.028 63.6671 118.461 70.9591L116.535 71.8581Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M107.615 60.852C104.91 57.882 104.85 42.039 104.867 38.89L107.007 38.898C106.973 46.379 107.632 57.685 109.198 59.405L107.615 60.852Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M111.631 605.07L109.534 604.642C113.291 586.137 117.554 541.237 117.768 538.995C117.562 535.691 117.228 529.683 116.8 522.1C115.671 501.807 113.967 471.14 112.461 452.576C110.415 427.489 109.448 390.464 108.661 360.713C108.413 351.17 108.173 342.157 107.925 334.531C107.514 321.898 107.719 302.495 107.908 283.743C108.164 259.067 108.421 233.545 107.334 222.623C104.672 195.997 111.965 92.0911 112.033 91.0391L114.164 91.1931C114.087 92.2371 106.812 195.962 109.457 222.409C110.561 233.45 110.296 259.033 110.039 283.768C109.842 302.504 109.645 321.881 110.056 334.471C110.304 342.097 110.535 351.118 110.792 360.662C111.571 390.387 112.547 427.378 114.584 452.405C116.099 471.003 117.802 501.678 118.932 521.988C119.36 529.632 119.693 535.674 119.899 538.952L119.907 539.038L119.899 539.123C119.856 539.577 115.482 586.069 111.631 605.07Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M102.976 591.581L100.879 591.17L109.052 549.18C109.386 547.485 109.343 545.774 108.933 544.088C103.917 523.409 97.9089 474.991 100.22 453.046C100.947 446.139 99.8939 435.903 96.9159 420.822C94.4079 408.129 91.0439 359.189 91.2069 337.698C91.2929 326.597 92.2679 312.252 93.3129 297.069C95.1009 270.99 97.1209 241.427 95.3839 218.044C92.9359 185.093 101.966 92.9051 102.051 91.9801L104.182 92.1941C104.088 93.1191 95.0839 185.118 97.5149 217.89C99.2609 241.427 97.2329 271.067 95.4439 297.214C94.4079 312.364 93.4239 326.674 93.3469 337.715C93.1929 358.633 96.5819 408.086 99.0209 420.403C102.085 435.903 103.112 446.037 102.359 453.26C100.074 475 106.04 523.041 111.021 543.574C111.5 545.56 111.552 547.58 111.158 549.582L102.976 591.581Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M83.3671 184.044L81.373 183.274C82.246 181.014 82.802 173.457 83.573 162.998C84.061 156.365 84.6171 148.841 85.4041 140.342C87.4761 118.081 91.173 96.906 91.216 96.692L93.321 97.06C93.287 97.274 89.598 118.355 87.536 140.531C86.748 149.013 86.192 156.519 85.704 163.143C84.891 174.193 84.3601 181.485 83.3671 184.044Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M73.457 148.93C73.457 144.779 76.539 131.967 79.808 118.392C81.563 111.109 83.369 103.577 84.533 98.005L86.63 98.441C85.466 104.047 83.643 111.596 81.888 118.897C78.798 131.753 75.597 145.045 75.597 148.939H73.457V148.93Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M22.6086 318.487L20.7598 317.417C29.2417 302.782 44.2883 243.511 46.2483 229.012C47.9091 216.73 53.0101 201.778 53.5571 200.194C53.7631 197.387 56.2451 164.025 59.9171 151.16C63.5461 138.468 76.9231 101.022 77.0601 100.646L79.0721 101.365C78.9351 101.741 65.5831 139.127 61.9711 151.751C58.2731 164.684 55.6971 200.135 55.6721 200.485L55.6121 200.768C55.5601 200.922 50.0741 216.593 48.3541 229.303C46.3338 244.316 31.3986 303.321 22.6086 318.487Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M75.1424 154.949C53.9504 148.281 52.0075 136.667 51.9395 136.179L54.0535 135.862C54.0705 135.974 55.9964 146.681 75.7844 152.912L75.1424 154.949Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M78.8649 170.85C49.1389 162.899 48.7969 149.496 48.7969 148.931H50.9369H49.8669L50.9369 148.922C50.9369 149.042 51.4849 161.316 79.4209 168.779L78.8649 170.85Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M80.9192 185.776C52.4952 181.976 46.8632 164.875 46.6406 164.148L48.6862 163.506C48.7372 163.668 54.2242 180.041 81.2102 183.653L80.9192 185.776Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M77.5994 197.108C50.8104 196.056 45.3241 180.367 45.1016 179.7L47.1304 179.023C47.1384 179.058 48.4394 182.798 52.8134 186.607C56.8704 190.133 64.4704 194.455 77.6854 194.969L77.5994 197.108Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M69.9395 205.532C47.6855 205.532 44.6303 193.857 44.502 193.326L46.5817 192.822C46.6159 192.941 49.8945 204.847 74.4585 203.238L74.5955 205.369C72.9605 205.48 71.4115 205.532 69.9395 205.532Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M70.7351 215.96C45.5382 214.976 42.8507 204.055 42.748 203.593L44.8363 203.122C44.862 203.216 47.4721 212.913 70.8211 213.82L70.7351 215.96Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M59.6517 224.199C52.7877 224.199 48.0717 222.196 45.0756 220.211C40.5736 217.223 38.8875 213.663 38.8105 213.509L40.7534 212.61C41.0016 213.132 47.0527 225.414 67.6367 221.203L68.0648 223.3C64.9578 223.934 62.1597 224.199 59.6517 224.199Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M56.5278 239.09C41.7466 239.09 34.4801 225.832 34.3945 225.669L36.2861 224.659C36.6883 225.404 46.3257 242.873 66.6358 234.87L67.4238 236.864C63.4178 238.448 59.7888 239.09 56.5278 239.09Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M53.0184 253.99C38.2369 253.99 30.9704 240.732 30.8848 240.57L32.7763 239.56C33.1786 240.304 42.8159 257.773 63.1264 249.77L63.9134 251.765C59.9084 253.34 56.2794 253.99 53.0184 253.99Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M48.1226 268.898C38.5705 268.898 32.2112 263.386 28.916 259.509L30.5508 258.122C34.4194 262.676 42.9013 269.685 56.2186 265.414L56.8696 267.451C53.7026 268.47 50.7836 268.898 48.1226 268.898Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M45.0419 281.085C36.9366 281.085 31.1166 277.122 27.4277 273.399L28.9512 271.893C33.1022 276.086 40.2147 280.606 50.5111 278.32L50.9731 280.409C48.8681 280.879 46.8991 281.085 45.0419 281.085Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M42.7809 295.151C32.5103 295.151 25.937 288.826 22.7617 284.735L24.4478 283.425C27.7258 287.645 34.9324 294.458 46.4014 292.737L46.718 294.851C45.3572 295.048 44.0391 295.151 42.7809 295.151Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M45.5897 308.366C27.7872 308.366 18.0557 299.2 13.8105 293.696L15.5051 292.387C19.5535 297.642 28.9426 306.423 46.1717 306.218L46.1974 308.358C45.9921 308.366 45.7866 308.366 45.5897 308.366Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M40.9756 324.08C20.3658 324.08 10.1036 308.546 6.58594 301.716L8.48604 300.74C11.9096 307.39 22.0861 322.779 42.6361 321.906L42.7217 324.046C42.1311 324.072 41.5491 324.08 40.9756 324.08Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M36.2764 323.387L34.7871 321.847C36.5074 320.186 39.1607 300.381 40.727 282.193L40.7698 281.74L41.1207 281.457C43.0636 279.874 49.0633 263.167 57.6313 223.616L57.7163 223.222L58.0413 222.983C60.8833 220.877 61.6193 211.848 62.0133 206.995C62.1923 204.718 62.2953 203.605 62.5013 203.015C62.9803 201.585 69.3313 170.876 73.4823 150.574L75.5793 151.002C74.5173 156.197 65.1793 201.816 64.5203 203.708C64.4013 204.067 64.2723 205.642 64.1443 207.166C63.5533 214.432 62.7233 221.716 59.6423 224.438C58.1363 231.362 48.0963 276.793 42.8239 282.767C42.2077 289.888 39.3747 320.392 36.2764 323.387Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M14.0937 311.531L13.623 309.442C15.2663 309.066 19.503 303.768 21.985 300.122L23.7567 301.328C22.6698 302.912 17.1493 310.846 14.0937 311.531Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M83.4706 379.428C78.8576 357.098 81.3226 310.914 82.6576 286.111C83.0256 279.221 83.2906 274.239 83.2826 272.048C83.2136 257.746 86.2346 224.469 86.2606 224.127L88.3916 224.324C88.3576 224.658 85.3536 257.832 85.4216 272.04C85.4306 274.291 85.1656 279.298 84.7966 286.23C83.4786 310.94 81.0136 356.944 85.5756 379.001L83.4706 379.428Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M144.642 338.297L143.503 28.4651C143.683 9.01876 134.328 3.88335 134.234 3.84065L135.21 1.94055C135.646 2.16305 145.831 7.60655 145.643 28.4731L146.781 338.289L144.642 338.297Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M157.282 605.068C153.422 586.067 149.057 539.583 149.014 539.113L149.006 539.027L149.014 538.941C149.22 535.663 149.562 529.621 149.982 521.978C151.111 501.676 152.815 470.992 154.329 452.394C156.366 427.367 157.342 390.376 158.121 360.651C158.369 351.108 158.609 342.086 158.857 334.46C159.259 321.87 159.063 302.493 158.866 283.758C158.609 259.022 158.344 233.44 159.448 222.399C162.092 195.952 154.817 92.2261 154.74 91.1821L156.871 91.0281C156.948 92.0721 164.232 195.986 161.57 222.613C160.475 233.534 160.74 259.056 160.997 283.732C161.194 302.493 161.391 321.887 160.98 334.52C160.732 342.146 160.5 351.159 160.244 360.702C159.465 390.453 158.489 427.479 156.443 452.565C154.929 471.129 153.225 501.796 152.104 522.089C151.685 529.672 151.351 535.68 151.137 538.984C151.351 541.227 155.613 586.118 159.371 604.631L157.282 605.068Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M164.729 591.543L157.754 549.553C157.369 547.585 157.412 545.556 157.899 543.579C162.881 523.038 168.855 475.005 166.561 453.265C165.799 446.042 166.826 435.908 169.899 420.408C172.338 408.091 175.728 358.629 175.574 337.72C175.488 326.679 174.512 312.369 173.477 297.219C171.688 271.072 169.659 241.424 171.405 217.895C173.836 185.123 164.832 93.124 164.738 92.199L166.869 91.985C166.963 92.91 175.984 185.098 173.537 218.049C171.799 241.432 173.828 270.995 175.608 297.074C176.643 312.257 177.628 326.602 177.713 337.703C177.876 359.194 174.512 408.134 172.004 420.827C169.026 435.899 167.973 446.144 168.701 453.051C171.012 475.005 165.003 523.414 159.988 544.093C159.577 545.77 159.543 547.49 159.868 549.185L166.852 591.209L164.729 591.543Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M185.536 184.046C184.552 181.487 184.013 174.195 183.199 163.154C182.712 156.53 182.155 149.023 181.368 140.542C179.305 118.357 175.616 97.276 175.582 97.071L177.687 96.703C177.722 96.917 181.428 118.083 183.499 140.353C184.286 148.861 184.843 156.376 185.331 163.009C186.101 173.468 186.657 181.025 187.53 183.285L185.536 184.046Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M194.709 144.582H192.569C192.569 140.834 189.787 129.63 187.1 118.803C185.337 111.716 183.522 104.381 182.281 98.441L184.378 98.005C185.611 103.911 187.425 111.22 189.18 118.289C192.021 129.741 194.709 140.568 194.709 144.582Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M246.305 318.491C237.515 303.324 222.572 244.311 220.552 229.298C218.84 216.588 213.354 200.917 213.302 200.763L213.242 200.48C213.217 200.121 210.649 164.678 206.943 151.746C203.331 139.121 189.979 101.736 189.842 101.36L191.854 100.641C191.991 101.017 205.36 138.462 208.997 151.155C212.677 164.011 215.151 197.373 215.356 200.189C215.904 201.781 221.005 216.725 222.666 229.007C224.617 243.506 239.664 302.768 248.154 317.412L246.305 318.491Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M195.078 154.203L194.436 152.166C204.775 148.914 209.782 144.66 212.162 141.664C214.695 138.472 214.849 136.007 214.849 135.981L216.989 136.067C216.963 136.52 216.347 147.501 195.078 154.203Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M190.036 170.85L189.48 168.779C204.15 164.859 211.125 159.629 214.395 155.932C217.869 152.003 217.964 148.948 217.964 148.922L220.103 148.931C220.103 149.496 219.761 162.899 190.036 170.85Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M187.982 185.776L187.699 183.653C214.685 180.041 220.172 163.677 220.223 163.506L222.269 164.148C222.037 164.875 216.406 181.976 187.982 185.776Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M191.313 197.108L191.227 194.969C216.544 193.967 221.731 179.169 221.783 179.023L223.811 179.7C223.588 180.367 218.094 196.056 191.313 197.108Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M198.965 205.532C197.493 205.532 195.952 205.48 194.309 205.369L194.446 203.238C207.353 204.085 214.268 201.184 217.786 198.59C221.526 195.834 222.314 192.847 222.322 192.822L224.402 193.326C224.274 193.849 221.218 205.532 198.965 205.532Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M198.177 215.96L198.092 213.82C210.365 213.341 216.801 210.423 220.028 208.06C223.452 205.561 224.068 203.147 224.076 203.122L226.165 203.593C226.062 204.055 223.375 214.976 198.177 215.96Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M209.236 224.199C206.719 224.199 203.929 223.925 200.848 223.3L201.276 221.203C221.954 225.423 228.1 212.747 228.159 212.61L230.102 213.509C230.042 213.637 224.924 224.199 209.236 224.199Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M212.375 239.087C209.114 239.087 205.485 238.445 201.488 236.87L202.276 234.876C222.586 242.879 232.223 225.41 232.626 224.665L234.517 225.675C234.423 225.838 227.156 239.087 212.375 239.087Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M215.972 254.009C212.694 254.009 209.04 253.358 205.008 251.766L205.796 249.772C221.904 256.114 231.412 246.237 234.75 241.684L236.471 242.95C233.681 246.768 226.911 254.009 215.972 254.009Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M220.703 268.873C217.46 268.873 213.831 268.24 209.834 266.665L210.621 264.67C223.297 269.66 231.881 264.756 236.854 259.758L238.369 261.264C235.151 264.499 229.228 268.873 220.703 268.873Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M223.81 281.078C221.679 281.078 219.377 280.804 216.92 280.17L217.451 278.099C227.225 280.624 234.192 276.952 238.317 273.426L239.704 275.052C236.246 278.014 230.94 281.078 223.81 281.078Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M226.098 295.139C224.326 295.139 222.443 294.951 220.449 294.523L220.894 292.426C232.808 294.968 240.434 288.284 243.909 284.081L245.561 285.442C242.351 289.336 235.906 295.139 226.098 295.139Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M223.332 308.362C222.631 308.362 221.912 308.345 221.184 308.319L221.27 306.18C239.278 306.924 249.052 297.955 253.272 292.554L254.958 293.872C250.661 299.384 240.938 308.362 223.332 308.362Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M227.911 324.081C226.875 324.081 225.814 324.038 224.727 323.961L224.889 321.83C245.936 323.414 256.523 308.042 260.101 301.349L261.992 302.359C258.398 309.069 248.093 324.081 227.911 324.081Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M232.627 323.386C229.528 320.391 226.695 289.887 226.071 282.766C220.798 276.792 210.759 231.361 209.252 224.437C206.18 221.724 205.341 214.431 204.75 207.165C204.631 205.641 204.502 204.066 204.374 203.707C203.723 201.816 193.718 153.518 192.58 148.014L194.677 147.578C199.127 169.061 205.914 201.559 206.402 203.005C206.608 203.596 206.702 204.708 206.882 206.985C207.275 211.838 208.003 220.868 210.853 222.973L211.178 223.213L211.264 223.607C215.963 245.269 224.231 278.555 227.774 281.448L228.125 281.73L228.159 282.184C229.734 300.371 232.387 320.177 234.099 321.837L232.627 323.386Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M254.811 311.531C251.755 310.837 246.234 302.903 245.156 301.328L246.928 300.122C249.41 303.768 253.647 309.074 255.29 309.442L254.811 311.531Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M185.656 378.699L183.559 378.262C188.095 356.291 185.536 310.835 184.166 286.417C183.773 279.39 183.482 274.314 183.499 272.029C183.567 257.821 180.554 224.647 180.529 224.313L182.66 224.116C182.694 224.45 185.715 257.727 185.638 272.038C185.63 274.263 185.912 279.313 186.306 286.297C187.684 310.818 190.252 356.454 185.656 378.699Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.181 154.469C110.131 154.469 89.1442 151.096 73.4902 144.703L74.2952 142.726C89.6922 149.008 110.405 152.329 134.181 152.329C159.113 152.329 178.371 149.325 193.032 143.145L193.863 145.114C178.927 151.405 159.413 154.469 134.181 154.469Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.182 172.552C107.658 172.552 91.0283 170.181 76.7773 164.369L77.5823 162.392C91.5503 168.092 107.949 170.42 134.173 170.42C158.301 170.42 174.238 169.214 189.335 162.854L190.166 164.823C174.742 171.319 158.592 172.552 134.182 172.552Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.181 185.785C110.165 185.785 94.2541 183.808 80.9531 179.178L81.6551 177.158C94.7071 181.703 110.422 183.646 134.181 183.646C157.196 183.646 172.397 182.601 186.818 177.098L187.58 179.101C172.85 184.724 157.453 185.785 134.181 185.785Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.183 202.236C111.288 202.236 96.0191 200.473 83.1211 196.356L83.7711 194.319C96.4471 198.368 111.519 200.097 134.183 200.097C155.846 200.097 171.098 199.138 184.972 194.225L185.682 196.245C171.526 201.252 156.077 202.236 134.183 202.236Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.183 217.894C111.288 217.894 96.0191 216.131 83.1211 212.015L83.7711 209.977C96.4471 214.026 111.528 215.755 134.183 215.755C155.846 215.755 171.098 214.796 184.972 209.883L185.682 211.903C171.526 216.919 156.077 217.894 134.183 217.894Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.181 231.816C111.226 231.816 95.9058 229.017 82.9648 222.461L83.9319 220.552C96.5559 226.946 111.586 229.676 134.181 229.676C155.784 229.676 170.993 228.161 184.807 220.415L185.851 222.281C171.635 230.258 156.135 231.816 134.181 231.816Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.139 247.17C109.421 247.17 91.918 244.491 77.377 238.465L78.198 236.488C92.466 242.394 109.721 245.03 134.148 245.03C157.308 245.03 174.76 243.643 190.226 236.736L191.099 238.688C175.274 245.758 157.574 247.17 134.139 247.17Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.13 263.826C108.599 263.826 90.523 261.053 75.502 254.839L76.323 252.862C91.07 258.964 108.899 261.686 134.13 261.686C158.061 261.686 176.095 260.257 192.083 253.118L192.956 255.07C176.625 262.362 158.343 263.826 134.13 263.826Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.13 277.8C108.599 277.8 90.523 275.027 75.502 268.813L76.323 266.836C91.07 272.939 108.899 275.66 134.13 275.66C158.061 275.66 176.095 274.231 192.083 267.093L192.956 269.044C176.625 276.337 158.343 277.8 134.13 277.8Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.131 294.037C107.265 294.037 88.2377 291.118 72.4297 284.579L73.2517 282.602C88.7857 289.03 107.556 291.897 134.131 291.897C159.329 291.897 178.33 290.391 195.165 282.868L196.038 284.819C178.852 292.496 159.603 294.037 134.131 294.037Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.123 310.486C105.99 310.486 86.0731 308.21 69.5371 303.109L70.1711 301.063C86.4931 306.096 106.212 308.347 134.123 308.347C160.587 308.347 180.546 307.166 198.238 301.268L198.914 303.297C180.949 309.288 160.801 310.486 134.123 310.486Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M134.131 326.357C106.734 326.357 87.3307 324.14 71.2227 319.176L71.8557 317.13C87.7497 322.035 106.956 324.217 134.131 324.217C159.902 324.217 179.33 323.062 196.559 317.327L197.236 319.356C179.724 325.184 160.107 326.357 134.131 326.357Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M129.997 339.339C104.739 339.134 86.6197 336.917 71.2227 332.167L71.8557 330.121C87.0397 334.803 104.979 336.985 130.014 337.191L129.997 339.339Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M139.624 339.338L139.607 337.198C161.912 337.027 180.125 335.803 196.558 330.325L197.235 332.354C180.536 337.917 162.126 339.167 139.624 339.338Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M129.457 355.009C105.253 354.778 87.8351 352.63 72.9941 348.051L73.6281 346.005C88.2631 350.524 105.492 352.639 129.483 352.87L129.457 355.009Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M139.624 355.009L139.607 352.869C161.201 352.698 178.85 351.5 194.787 346.193L195.463 348.222C179.261 353.622 161.415 354.838 139.624 355.009Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M112.281 368.515C90.5745 368.515 76.6495 363.242 76.4785 363.174L77.2485 361.18C77.4535 361.257 98.2005 369.097 128.56 365.382L128.816 367.505C122.928 368.215 117.39 368.515 112.281 368.515Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M113.94 384.675C104.765 384.675 97.1388 383.485 91.7468 382.278C83.2988 380.378 78.4628 378.067 78.2578 377.973L79.1908 376.047C79.3788 376.141 98.5938 385.197 126.701 381.756L126.958 383.879C122.354 384.444 117.997 384.675 113.94 384.675Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M112.7 411.381C95.1283 411.381 83.9763 406.708 83.4023 406.46L84.2504 404.492C84.4214 404.56 101.436 411.681 126.137 408.326L126.428 410.449C121.515 411.116 116.919 411.381 112.7 411.381Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M110.51 423.729C94.4696 423.729 85.9626 415.504 85.5176 415.059L87.0236 413.544C87.1516 413.673 100.393 426.391 124.717 419.51L125.299 421.573C119.813 423.122 114.883 423.729 110.51 423.729Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M109.49 439.108C96.745 439.108 89.145 434.658 88.709 434.401L89.813 432.569C89.95 432.655 104.106 440.88 125.991 434.529L126.59 436.584C120.188 438.432 114.445 439.108 109.49 439.108Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M106.75 453.586C96.0348 453.586 89.1618 449.735 88.7168 449.478L89.7868 447.62C89.9488 447.715 105.98 456.642 127.146 446.636L128.062 448.571C120.051 452.362 112.793 453.586 106.75 453.586Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M107.239 469.135C95.2046 469.135 88.1606 461.081 88.0586 460.952L89.6926 459.566C90.2836 460.25 104.346 476.221 127.489 459.395L128.748 461.123C120.488 467.132 113.239 469.135 107.239 469.135Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M108.178 482.802C96.1794 482.802 88.5964 475.167 88.4844 475.047L90.0254 473.558C90.6414 474.191 105.345 488.904 126.648 474.294L127.855 476.057C120.529 481.09 113.861 482.802 108.178 482.802Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M109.952 496.919C100.041 496.919 92.654 492.46 92.543 492.391L93.664 490.568C93.827 490.671 110.029 500.411 126.145 489.926L127.309 491.724C121.292 495.644 115.258 496.919 109.952 496.919Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M110.704 512.937C102.017 512.937 95.9143 509.333 95.8203 509.282L96.9243 507.45C97.0613 507.536 110.695 515.53 125.913 506.526L127 508.366C121.163 511.824 115.531 512.937 110.704 512.937Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M112.631 525.049C105.339 525.049 100.246 522.045 99.9121 521.839L101.016 520.008C101.487 520.29 112.708 526.863 125.623 519.22L126.71 521.06C121.635 524.073 116.773 525.049 112.631 525.049Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M113.821 540.678C108.138 540.678 104.166 538.752 103.91 538.633L104.86 536.715C104.945 536.758 113.932 541.072 123.98 536.159L124.922 538.085C120.908 540.045 117.082 540.678 113.821 540.678Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M119.829 557.017C109.378 557.017 101.453 554.467 101.059 554.338L101.727 552.301C101.838 552.336 113.067 555.939 126.077 554.552L126.308 556.683C124.066 556.915 121.892 557.017 119.829 557.017Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M117.571 569.375C105.794 569.375 98.7498 564.702 98.4238 564.48L99.6228 562.708L99.0238 563.589L99.6228 562.699C99.7078 562.759 108.301 568.425 122.107 566.996L122.329 569.127C120.669 569.298 119.077 569.375 117.571 569.375Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M117.75 581.883C104.176 581.883 94.5466 576.542 94.1016 576.285L95.1546 574.42C95.2746 574.488 106.992 580.976 122.346 579.529L122.543 581.66C120.9 581.806 119.299 581.883 117.75 581.883Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M118.861 595.687C104.054 595.687 90.4197 587.265 89.8027 586.879L90.9417 585.065C91.0957 585.159 106.784 594.831 122.113 593.401L122.31 595.532C121.163 595.635 120.008 595.687 118.861 595.687Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M95.3147 588.666L91.9277 597.036L93.9117 597.838L97.2977 589.468L95.3147 588.666Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M99.6456 600.541L97.6426 599.779C98.8236 596.664 100.75 591.554 100.887 591.101L102.975 591.571C102.958 591.691 102.898 591.991 99.6456 600.541Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M105.277 592.409L101.977 602.694L104.014 603.348L107.314 593.063L105.277 592.409Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M112.373 398.782C93.5769 398.782 81.6549 393.783 81.0469 393.518L81.8859 391.549C82.0659 391.626 100.296 399.252 126.769 395.658L127.06 397.78C121.813 398.491 116.892 398.782 112.373 398.782Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M155.434 368.515C150.324 368.515 144.787 368.224 138.898 367.505L139.155 365.382C169.513 369.097 190.26 361.265 190.465 361.18L191.236 363.174C191.065 363.234 177.131 368.515 155.434 368.515Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M153.772 384.675C149.716 384.675 145.359 384.444 140.754 383.879L141.011 381.756C155.69 383.553 167.826 381.91 175.418 380.207C183.652 378.367 188.47 376.073 188.522 376.047L189.455 377.973C189.249 378.067 184.413 380.378 175.966 382.278C170.574 383.493 162.948 384.675 153.772 384.675Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M155.015 411.381C150.787 411.381 146.2 411.108 141.287 410.449L141.578 408.326C166.33 411.69 183.303 404.569 183.465 404.492L184.312 406.46C183.739 406.708 172.587 411.381 155.015 411.381Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M157.206 423.732C152.832 423.732 147.894 423.116 142.416 421.567L142.998 419.504C167.391 426.403 180.563 413.667 180.692 413.538L182.198 415.053C181.753 415.507 173.245 423.732 157.206 423.732Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M158.223 439.108C153.267 439.108 147.524 438.432 141.131 436.584L141.73 434.529C163.615 440.88 177.763 432.655 177.908 432.569L179.013 434.401C178.567 434.658 170.967 439.108 158.223 439.108Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M160.955 453.586C154.912 453.586 147.654 452.362 139.643 448.571L140.559 446.636C151.617 451.866 161.177 451.857 167.254 450.933C173.853 449.923 177.884 447.646 177.918 447.62L178.988 449.478C178.552 449.735 171.67 453.586 160.955 453.586Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M160.475 469.137C154.467 469.137 147.226 467.134 138.975 461.134L140.233 459.406C150.007 466.509 159.345 468.683 167.99 465.867C174.4 463.779 177.987 459.619 178.021 459.577L179.656 460.963C179.553 461.083 172.509 469.137 160.475 469.137Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M159.534 482.804C153.851 482.804 147.183 481.092 139.848 476.068L141.055 474.305C162.358 488.915 177.063 474.202 177.679 473.569L179.219 475.058C179.117 475.169 171.525 482.804 159.534 482.804Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M157.762 496.919C152.455 496.919 146.421 495.644 140.396 491.724L141.56 489.926C157.676 500.411 173.878 490.671 174.041 490.568L175.162 492.391C175.059 492.46 167.673 496.919 157.762 496.919Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M157.01 512.937C152.183 512.937 146.551 511.824 140.705 508.366L141.792 506.526C157.053 515.547 170.644 507.527 170.781 507.45L171.885 509.282C171.8 509.333 165.697 512.937 157.01 512.937Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M155.082 525.051C150.94 525.051 146.087 524.084 140.994 521.071L142.081 519.231C155.005 526.866 166.218 520.301 166.688 520.019L167.792 521.85C167.467 522.039 162.375 525.051 155.082 525.051Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M153.893 540.678C150.633 540.678 146.807 540.045 142.793 538.085L143.734 536.159C153.825 541.089 162.761 536.758 162.855 536.715L163.805 538.633C163.548 538.761 159.577 540.678 153.893 540.678Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M147.885 557.017C145.814 557.017 143.648 556.915 141.414 556.675L141.645 554.544C154.672 555.939 165.876 552.336 165.987 552.301L166.655 554.338C166.261 554.467 158.335 557.017 147.885 557.017Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M150.144 569.375C148.637 569.375 147.045 569.298 145.385 569.127L145.607 566.996C159.413 568.434 168.006 562.759 168.092 562.699L169.29 564.471C168.956 564.702 161.921 569.375 150.144 569.375Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M149.956 581.883C148.407 581.883 146.815 581.814 145.172 581.66L145.368 579.529C160.706 580.967 172.44 574.488 172.56 574.42L173.613 576.285C173.168 576.534 163.53 581.883 149.956 581.883Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M148.851 595.684C147.704 595.684 146.557 595.633 145.402 595.522L145.599 593.39C160.928 594.82 176.616 585.157 176.77 585.054L177.909 586.869C177.292 587.262 163.649 595.684 148.851 595.684Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M172.371 588.668L170.387 589.47L173.771 597.842L175.754 597.04L172.371 588.668Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M168.457 601.471C165.213 592.921 164.755 591.691 164.729 591.58L165.782 591.374L166.818 591.101C166.955 591.554 169.278 597.594 170.459 600.709L168.457 601.471Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M162.432 592.425L160.395 593.079L163.697 603.363L165.734 602.709L162.432 592.425Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M155.339 398.782C150.82 398.782 145.907 398.491 140.652 397.78L140.943 395.658C167.415 399.252 185.646 391.626 185.825 391.549L186.664 393.518C186.048 393.783 174.125 398.782 155.339 398.782Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M152.824 71.855L150.89 70.9481C154.322 63.6561 155.033 33.0841 154.921 28.4871C154.707 19.7321 152.182 14.1 150.103 10.9247C147.86 7.49255 145.635 6.09745 145.609 6.08025L146.722 4.24866C147.124 4.49686 156.625 10.4454 157.061 28.4281C157.181 33.2891 156.47 64.101 152.824 71.855Z"
        fill={COLORS.grey.grey70}
      />
      <path
        d="M160.185 60.8481L158.602 59.4101C160.288 57.5611 161.803 45.7931 162.368 38.8091L164.499 38.9801C164.251 42.1121 162.856 57.9121 160.185 60.8481Z"
        fill={COLORS.grey.grey70}
      />
    </>
  );
};
