import './TreatmentFilters.scss';

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { getTagStatus, Tag } from '../../../../../../components';
import { ComplementaryTreatment } from '../../../../../../types/complementaryTreatment';
import { MedicineTreatment } from '../../../../../../types/medicineTreatment';

export type FilterStatus = 'ALL' | 'ONGOING' | 'STOPPED';
const filterStatuses: FilterStatus[] = ['ALL', 'ONGOING', 'STOPPED'];

interface TreatmentFiltersProps {
  groupedTreatments: Record<
    FilterStatus,
    MedicineTreatment[] | ComplementaryTreatment[]
  >;
  currentFilterStatus: FilterStatus;
  setFilterStatus: (filterStatus: FilterStatus) => void;
  testIdPrefix?: string;
}

export const TreatmentFilters: FunctionComponent<TreatmentFiltersProps> = ({
  groupedTreatments,
  currentFilterStatus,
  setFilterStatus,
  testIdPrefix,
}) => {
  const { t } = useTranslation();

  return (
    <div className="followup-filters-container">
      {filterStatuses.map((filterStatus) => (
        <Tag
          key={filterStatus}
          text={t(`followup.filters.${filterStatus}`, {
            count: groupedTreatments[filterStatus].length,
          })}
          status={getTagStatus(
            groupedTreatments[filterStatus].length === 0,
            currentFilterStatus === filterStatus,
          )}
          onClick={() => setFilterStatus(filterStatus)}
          testId={`${testIdPrefix}-filter-${filterStatus}`}
        />
      ))}
    </div>
  );
};
