import './AddConsultationNoteButtonAndPopupContainer.scss';
import './../../../../components/Modal/Modal.scss';

import { FunctionComponent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonClass,
  LongTextInput,
  Minus,
  Note,
  SnackBar,
  SnackBarType,
} from '../../../../components';
import { COLORS } from '../../../../theme/colors';
import { CreateOrEditConsultationNoteDto } from '../../../../types/consultationNotes';
export interface ConsultationNotesPopupForm {
  text: string;
}
interface AddConsultationNoteButtonAndPopupContainerProps {
  onSave: (note: CreateOrEditConsultationNoteDto) => void;
  onClose: () => void;
  isOpened: boolean;
  onButtonClick: () => void;
  isSaveConsultationNoteLoading: boolean;
  isSaveConsultationNoteError: boolean;
  form: UseFormReturn<ConsultationNotesPopupForm>;
}

export const AddConsultationNoteButtonAndPopupContainer: FunctionComponent<
  AddConsultationNoteButtonAndPopupContainerProps
> = ({
  onClose,
  onSave,
  onButtonClick,
  isOpened,
  isSaveConsultationNoteLoading,
  isSaveConsultationNoteError,
  form: { formState, reset, watch, control },
}) => {
  const { t } = useTranslation();
  const hasEnteredText = watch('text').length > 0;

  return (
    <>
      <>
        {isSaveConsultationNoteError && (
          <div className="modal-snack-bar-container">
            <SnackBar
              title={t('errors.unknownError.message')}
              snackBarType={SnackBarType.ERROR}
              className="modal-snack-bar"
            />
          </div>
        )}
        {hasEnteredText && !isOpened && (
          <div
            className="red-badge"
            data-testid={'consultation-note-red-badge'}
          />
        )}
        <button
          className="add-note-button"
          onClick={onButtonClick}
          data-testid={'add-a-note-button'}
        >
          <Note color={COLORS.white} className="add-note-icon" />
        </button>
      </>
      {isOpened && (
        <div
          className="popup-container shadow-medium"
          data-testid={'add-a-note-popup'}
        >
          <div className="popup-header">
            <h2 className="popup-title">{`${t(
              'clinicalFile.consultationNotes.newNote',
            )} :`}</h2>
            <button
              className="minus-button"
              onClick={onClose}
              data-testid={'consultation-note-popup-minus-button'}
            >
              <Minus color={COLORS.primary._500} />
            </button>
          </div>
          <div className="popup-content">
            <div className="popup-text-input">
              <Controller
                control={control}
                name="text"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <LongTextInput
                    placeholder={t(
                      'clinicalFile.consultationNotes.newNotePlaceholderWarning',
                    )}
                    inputTestId={'new-consultation-note-input'}
                    value={value ?? ''}
                    onChange={onChange}
                    resizable={false}
                  />
                )}
              />
            </div>
            <Button
              data-testid={'add-consultation-note-save-button'}
              buttonClass={ButtonClass.PRIMARY}
              text={t('save')}
              onClick={() => {
                onSave({ text: watch('text') });
                reset();
              }}
              disabled={!hasEnteredText || !formState.isValid}
              isLoading={isSaveConsultationNoteLoading}
            />
          </div>
        </div>
      )}
    </>
  );
};
