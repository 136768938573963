import type { AuthError } from 'firebase/auth';

import type { AuthErrorCode, FirebaseError } from '../../types/firebase.d';
import { AuthErrorCodesArray } from './firebase/constants';

const isFirebaseError = (error: unknown): error is FirebaseError => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'name' in error &&
    error.name === 'FirebaseError'
  );
};

export const isFirebaseAuthError = (error: unknown): error is AuthError => {
  return (
    isFirebaseError(error) &&
    AuthErrorCodesArray.includes(error.code as AuthErrorCode)
  );
};
