import './ComplementaryTreatments.scss';

import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AngleDown,
  Button,
  ButtonClass,
  ErrorApiMessage,
  SnackBar,
  SnackBarType,
} from '../../../../../../components';
import { AngleUp } from '../../../../../../components/icons/AngleUp';
import { COLORS } from '../../../../../../theme/colors';
import { ComplementaryTreatment } from '../../../../../../types/complementaryTreatment';
import { FourTimeMarkers } from '../../types';
import { ComplementaryTreatmentEditionModal } from '../EditionModals/ComplementaryTreatment/ComplementaryTreatmentEditionModal';
import { TreatmentFilters } from '../Filters/TreatmentFilters';
import { ComplementaryTreatmentInformationModal } from '../InformationModals/ComplementaryTreatmentInformationModal';
import {
  ComplementaryTreatmentEndDateTooltipContent,
  ComplementaryTreatmentStartDateTooltipContent,
} from '../TooltipContent/ComplementaryTreatmentTooltipContent';
import { TreatmentCard } from '../TreatmentCard/TreatmentCard';
import {
  getComplementaryTreatmentModificationTooltips,
  Loader,
} from '../utils';
import { AddComplementaryTreatmentButton } from './AddComplementaryTreatmentButton';
import { useComplementaryTreatments } from './useComplementaryTreatments.hook';

const DEFAULT_NUMBER_OF_DISPLAYED_COMPLEMENTARY_TREATMENTS = 4;

interface ComplementaryTreatmentProps {
  patientId: string;
  timeMarkers: FourTimeMarkers;
  isBackendError: boolean;
  setIsBackendError: (value: boolean) => void;
}

export const ComplementaryTreatments: FunctionComponent<
  ComplementaryTreatmentProps
> = ({ patientId, timeMarkers, isBackendError, setIsBackendError }) => {
  const {
    isLoading: isLoadingComplementaryTreatments,
    isError: isComplementaryTreatmentError,
    groupedComplementaryTreatments,
    isComplementaryTreatmentModalOpen,
    complementaryTreatmentFilter,
    setComplementaryTreatmentFilter,
    selectedComplementaryTreatment,
    setSelectedComplementaryTreatment,
    complementaryTreatmentToModify,
    setComplementaryTreatmentToModify,
    areAllComplementaryTreatmentsDisplayed,
    setAreAllComplementaryTreatmentsDisplayed,
    isAddComplementaryTreatmentModalOpened,
    setIsAddComplementaryTreatmentModalOpened,
  } = useComplementaryTreatments(patientId);

  const { t } = useTranslation();

  const noComplementaryTreatmentMessage = (
    <p
      className="no-treatment-message"
      data-testid="no-complementary-treatment-message"
    >
      {t('followup.complementaryTreatments.noComplementaryTreatmentMessage')}
    </p>
  );

  const numberOfHiddenComplementaryTreatments =
    groupedComplementaryTreatments[complementaryTreatmentFilter].length <=
    DEFAULT_NUMBER_OF_DISPLAYED_COMPLEMENTARY_TREATMENTS
      ? 0
      : groupedComplementaryTreatments[complementaryTreatmentFilter].length -
        DEFAULT_NUMBER_OF_DISPLAYED_COMPLEMENTARY_TREATMENTS;

  const showAllTreatmentsButtonLabel = areAllComplementaryTreatmentsDisplayed
    ? t('followup.complementaryTreatmentDisplayButton.mask')
    : t('followup.complementaryTreatmentDisplayButton.seeAll', {
        numberOfHiddenComplementaryTreatments,
      });

  const treatmentErrorMessage = (
    <ErrorApiMessage
      title={t('apiMessage.serverError.errorOccurred')}
      subtitle={t('apiMessage.serverError.tryAgainLater')}
      className="treatment-error"
    />
  );

  const complementaryTreatmentsFilters = (
    <div className="treatment-header">
      <div className="filters-container">
        <TreatmentFilters
          groupedTreatments={groupedComplementaryTreatments}
          currentFilterStatus={complementaryTreatmentFilter}
          setFilterStatus={setComplementaryTreatmentFilter}
          testIdPrefix="complementary-treatment"
        />
        {groupedComplementaryTreatments.ALL.length === 0 &&
          noComplementaryTreatmentMessage}
      </div>
      <AddComplementaryTreatmentButton
        name={t(
          'followup.complementaryTreatments.addComplementaryTreatmentButton',
        )}
        onClick={() => setIsAddComplementaryTreatmentModalOpened(true)}
      />
    </div>
  );

  const complementaryTreatmentElement = groupedComplementaryTreatments[
    complementaryTreatmentFilter
  ].map((complementaryTreatment: ComplementaryTreatment) => {
    const today = dayjs();
    const isTreatmentFinished =
      complementaryTreatment.endDate !== null &&
      dayjs(complementaryTreatment.endDate).isBefore(today);
    return (
      <TreatmentCard
        treatmentName={complementaryTreatment.therapy.name}
        key={complementaryTreatment.id}
        leftBound={timeMarkers[0]}
        rightBound={timeMarkers[3]}
        startDate={complementaryTreatment.startDate}
        endDate={complementaryTreatment.endDate}
        color={
          isTreatmentFinished ? COLORS.grey.grey70 : COLORS.tertiary.lightGreen
        }
        testId="complementary-treatment-lines-id"
        onClick={() =>
          setSelectedComplementaryTreatment(complementaryTreatment)
        }
        onClickModify={() =>
          setComplementaryTreatmentToModify(complementaryTreatment)
        }
        modifyButtonColor={COLORS.secondary.green}
        startDateTooltipContent={
          <ComplementaryTreatmentStartDateTooltipContent
            complementaryTreatment={complementaryTreatment}
          />
        }
        endDateTooltipContent={
          <ComplementaryTreatmentEndDateTooltipContent
            complementaryTreatment={complementaryTreatment}
          />
        }
        modificationTooltips={getComplementaryTreatmentModificationTooltips(
          complementaryTreatment,
        )}
        tooltipBorderColor={COLORS.secondary.green}
      />
    );
  });

  const complementaryTreatmentInformationModal = (
    <ComplementaryTreatmentInformationModal
      opened={isComplementaryTreatmentModalOpen}
      complementaryTreatment={selectedComplementaryTreatment}
      onClose={() => setSelectedComplementaryTreatment(null)}
      data-testid="complementary-treatment-modal"
      onClickModify={() =>
        setComplementaryTreatmentToModify(selectedComplementaryTreatment)
      }
    />
  );

  return (
    <>
      <div className="followup-title-and-card-container">
        <h2 className="followup-section-title">
          {t(
            'followup.complementaryTreatments.complementaryTreatmentSectionTitle',
          )}
        </h2>
        {isComplementaryTreatmentError
          ? treatmentErrorMessage
          : complementaryTreatmentsFilters}
        {isLoadingComplementaryTreatments ? (
          <Loader testId="activity-indicator-therapies" />
        ) : areAllComplementaryTreatmentsDisplayed ? (
          complementaryTreatmentElement
        ) : (
          complementaryTreatmentElement.slice(
            0,
            DEFAULT_NUMBER_OF_DISPLAYED_COMPLEMENTARY_TREATMENTS,
          )
        )}
        {numberOfHiddenComplementaryTreatments !== 0 && (
          <div className="medicine-treatment-limitation-button-container">
            <Button
              buttonClass={ButtonClass.TERTIARY}
              text={showAllTreatmentsButtonLabel}
              onClick={() =>
                setAreAllComplementaryTreatmentsDisplayed(
                  !areAllComplementaryTreatmentsDisplayed,
                )
              }
              RightIcon={
                areAllComplementaryTreatmentsDisplayed ? AngleUp : AngleDown
              }
              data-testid="complementary-treatment-limitation-button"
            />
          </div>
        )}
      </div>
      {complementaryTreatmentInformationModal}
      <ComplementaryTreatmentEditionModal
        type="ADD"
        opened={isAddComplementaryTreatmentModalOpened}
        onClose={() => {
          setIsBackendError(false);
          setIsAddComplementaryTreatmentModalOpened(false);
        }}
        title={t(
          'followup.complementaryTreatments.complementaryTreatmentEditionModal.addTreatment',
        )}
        patientId={patientId ?? ''}
        setIsBackendError={setIsBackendError}
      />
      {isBackendError && (
        <div className="snackbar-error-treatment-edition-modal-wrapper">
          <SnackBar
            title={t('errors.unknownError.message')}
            snackBarType={SnackBarType.ERROR}
            className="snackbar-error-treatment-edition-modal"
          />
        </div>
      )}
      <ComplementaryTreatmentEditionModal
        type="MODIFY"
        opened={complementaryTreatmentToModify !== null}
        onClose={() => {
          setIsBackendError(false);
          setComplementaryTreatmentToModify(null);
        }}
        title={t(
          'followup.complementaryTreatments.complementaryTreatmentEditionModal.editATreatment',
        )}
        patientId={patientId ?? ''}
        defaultValue={
          complementaryTreatmentToModify !== null
            ? complementaryTreatmentToModify
            : undefined
        }
        setIsBackendError={setIsBackendError}
      />
    </>
  );
};
