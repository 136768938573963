import { useQuery } from '@tanstack/react-query';

import { ComplementaryTherapy } from '../../types/complementaryTherapy';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

const getAllComplementaryTherapies = async () => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.COMPLEMENTARY_THERAPIES);
};

export const useAllComplementaryTherapies = () =>
  useQuery<ComplementaryTherapy[]>(
    ['complementaryTherapies'],
    getAllComplementaryTherapies,
    { retry: 0 },
  );
