import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CreateOrEditMedicalHistoryModal,
  CreateOrEditMedicalHistoryModalProps,
  MedicalHistoryForm,
} from './CreateOrEditMedicalHistoryModal';
import { ORDERED_MEDICAL_HISTORY_CATEGORIES } from './data';

interface CreateMedicalHistoryModalProps
  extends Omit<
    CreateOrEditMedicalHistoryModalProps,
    'defaultValues' | 'shouldShowIndex' | 'onSubmit' | 'saveButtonText'
  > {
  onPressNext: () => void;
  saveCurrentData: (data: MedicalHistoryForm) => void;
}

export const CreateMedicalHistoryModal: FunctionComponent<
  CreateMedicalHistoryModalProps
> = ({ onPressNext, saveCurrentData, ...props }) => {
  const { t } = useTranslation();

  return (
    <CreateOrEditMedicalHistoryModal
      {...props}
      testId="create-medical-history-modal"
      shouldShowIndex
      saveButtonText={t(
        props.categoryIndex === ORDERED_MEDICAL_HISTORY_CATEGORIES.length - 1
          ? 'save'
          : 'clinicalFile.createMedicalHistoryModal.nextButton',
      )}
      onSubmit={(data) => {
        saveCurrentData(data);
        onPressNext();
      }}
    />
  );
};
