import './PatientDetails.scss';

import { AppShell } from '@mantine/core';
import { signOut } from 'firebase/auth';
import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ActivityIndicator, ErrorApiMessage } from '../../components';
import { auth } from '../../firebaseConfig';
import { useSaveConsultationNote } from '../../services/api/consultation-notes';
import { usePatient } from '../../services/api/patient';
import {
  AddConsultationNoteButtonAndPopupContainer,
  ConsultationNotesPopupForm,
} from './components/AddNoteButton/AddConsultationNoteButtonAndPopupContainer';
import { Header } from './components/Header/Header';
import { Navbar } from './components/Navbar/Navbar';
import { NoteNotSavedModal } from './components/NoteNotSavedModal.tsx/NoteNotSavedModal';
import { PatientDetailsOutlet } from './outlet';
import { PatientDetailsPageName } from './types';

export const PatientDetails: FunctionComponent = () => {
  const { t } = useTranslation();
  const { patientId } = useParams<{ patientId: string }>();
  const location = useLocation();

  const lastPathSlashIndex = location.pathname.lastIndexOf('/');
  const pageName = location.pathname.substring(
    lastPathSlashIndex + 1,
  ) as PatientDetailsPageName;

  const { isLoading, data: patient, isError } = usePatient(patientId ?? '');

  const [isConsultationNotePopupOpened, setIsConsultationPopupOpened] =
    useState<boolean>(false);

  const {
    mutate: saveConsultationNote,
    isLoading: isSaveConsultationNoteLoading,
    isError: isSaveConsultationNoteError,
  } = useSaveConsultationNote(
    patientId ?? ' ',
    () => {
      setIsConsultationPopupOpened(false);
    },
    () => {},
  );

  const form = useForm<ConsultationNotesPopupForm>({
    mode: 'onBlur',
    defaultValues: { text: '' },
  });

  const hasEnteredText = form.watch('text').length > 0;

  const [noteNotSavedModalOpenedReason, setNoteNotSavedModalReason] = useState<
    'LOG_OUT' | 'NAVIGATE' | null
  >(null);

  const navigate = useNavigate();

  const handleNavigate = () => {
    if (hasEnteredText) {
      setNoteNotSavedModalReason('NAVIGATE');
    } else {
      navigate('/');
    }
  };

  const handleLogOut = async () => {
    if (hasEnteredText) {
      setNoteNotSavedModalReason('LOG_OUT');
    } else {
      await signOut(auth);
    }
  };

  if (isError)
    return (
      <ErrorApiMessage
        title={t('apiMessage.serverError.errorOccurred')}
        subtitle={t('apiMessage.serverError.tryAgainLater')}
        className="server-error-characterization"
      />
    );

  return isLoading || patient === undefined ? (
    <ActivityIndicator />
  ) : (
    <AppShell
      padding={0}
      header={
        <Header
          patient={patient}
          pageName={pageName}
          onBackButtonClick={handleNavigate}
          onLogOut={handleLogOut}
        />
      }
      navbar={<Navbar pageName={pageName} />}
    >
      <div className="patient-details-background">
        <PatientDetailsOutlet context={{ patient }} />
        <AddConsultationNoteButtonAndPopupContainer
          onButtonClick={() =>
            setIsConsultationPopupOpened(!isConsultationNotePopupOpened)
          }
          isOpened={isConsultationNotePopupOpened}
          onClose={() => setIsConsultationPopupOpened(false)}
          onSave={saveConsultationNote}
          isSaveConsultationNoteLoading={isSaveConsultationNoteLoading}
          isSaveConsultationNoteError={isSaveConsultationNoteError}
          form={form}
        />
      </div>
      <NoteNotSavedModal
        opened={noteNotSavedModalOpenedReason !== null}
        onClose={() => {
          setNoteNotSavedModalReason(null);
        }}
        onCancel={() => {
          setNoteNotSavedModalReason(null);
        }}
        onDelete={() => {
          if (noteNotSavedModalOpenedReason === 'NAVIGATE') {
            navigate('/');
          }
          if (noteNotSavedModalOpenedReason === 'LOG_OUT') {
            signOut(auth);
          }
        }}
      />
    </AppShell>
  );
};
