import type { DatePickerInputProps } from '@mantine/dates';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Calendar } from '../../../../../../../components';
import { DatePickerInput } from '../DatePicker/DatePickerInput';
import { ComplementaryTreatmentForm } from './ComplementaryTreatmentEditionModal';

type StartDateInputProps = {
  control?: Control<ComplementaryTreatmentForm, unknown> & DatePickerInputProps;
};
export function StartDateInput({ control, ...props }: StartDateInputProps) {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="startDate"
      rules={{ required: true }}
      render={({ field: { onChange, onBlur, value } }) => {
        const isStartDateEmpty = value === null;
        return (
          <DatePickerInput
            {...props}
            label={t(
              'followup.medicineTreatments.medicineTreatmentEditionModal.initiationDate',
            )}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            clearable={!isStartDateEmpty}
            rightSection={isStartDateEmpty ? <Calendar /> : null}
            styles={{
              rightSection: {
                pointerEvents: isStartDateEmpty ? 'none' : 'all',
              },
            }}
          />
        );
      }}
    />
  );
}
