import './Button.scss';

import {
  ButtonHTMLAttributes,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { Color, COLORS } from '../../theme/colors';
import { ActivityIndicator } from '../ActivityIndicator/ActivityIndicator';
import { Icon } from '../icons/types';

export type ButtonElement = ReactElement<ButtonProps>;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonClass: ButtonClass;
  LeftIcon?: Icon;
  RightIcon?: Icon;
  text: string;
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

export enum ButtonClass {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

const BUTTON_COLORS: Record<
  ButtonClass,
  { defaultColor: Color; hoverColor: Color }
> = {
  [ButtonClass.PRIMARY]: {
    defaultColor: COLORS.white,
    hoverColor: COLORS.white,
  },
  [ButtonClass.SECONDARY]: {
    defaultColor: COLORS.primary._500,
    hoverColor: COLORS.secondary.turquoise,
  },
  [ButtonClass.TERTIARY]: {
    defaultColor: COLORS.primary._500,
    hoverColor: COLORS.secondary.turquoise,
  },
};

export const Button: FunctionComponent<ButtonProps> = ({
  LeftIcon,
  RightIcon,
  text,
  buttonClass,
  className,
  onClick,
  disabled = false,
  isLoading = false,
  ...props
}) => {
  const initialColor = disabled
    ? COLORS.texts.second
    : BUTTON_COLORS[buttonClass].defaultColor;

  const [textColor, setTextColor] = useState<Color>(initialColor);

  useEffect(() => {
    setTextColor(
      disabled ?? false
        ? COLORS.texts.second
        : BUTTON_COLORS[buttonClass].defaultColor,
    );
  }, [buttonClass, disabled]);

  return (
    <button
      {...props}
      className={`button ${buttonClass} ${className ?? ''}`}
      onClick={onClick}
      onMouseEnter={() => setTextColor(BUTTON_COLORS[buttonClass].hoverColor)}
      onMouseLeave={() => setTextColor(BUTTON_COLORS[buttonClass].defaultColor)}
      disabled={disabled}
    >
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          {LeftIcon && <LeftIcon color={textColor} />}
          <p className="p1-bold" style={{ color: textColor }}>
            {text}
          </p>
          {RightIcon && <RightIcon color={textColor} />}
        </>
      )}
    </button>
  );
};
