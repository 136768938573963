import './MedicalHistory.scss';

import { ComponentProps, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '../../../../../../components';
import { Pen } from '../../../../../../components/icons/Pen';
import { COLORS } from '../../../../../../theme/colors';
import { MedicalHistory as MedicalHistoryType } from '../../../../../../types/medicalHistory';
import { ANTECEDENTS_BY_CATEGORY } from '../CreateMedicalHistoryModal/data';

interface MedicalHistoryProps extends ComponentProps<'div'> {
  medicalHistory: MedicalHistoryType;
  onClick?: () => void;
}

export const MedicalHistory: FunctionComponent<MedicalHistoryProps> = ({
  medicalHistory,
  onClick,
  ...props
}) => {
  const { t } = useTranslation();

  const sortedAntecedents = medicalHistory.values.sort((a1, a2) => {
    const a1Index = ANTECEDENTS_BY_CATEGORY[medicalHistory.category].findIndex(
      (antecedent) => antecedent === a1,
    );

    const a2Index = ANTECEDENTS_BY_CATEGORY[medicalHistory.category].findIndex(
      (antecedent) => antecedent === a2,
    );

    return a1Index - a2Index;
  });

  return (
    <div className="medical-history-item-section">
      <div {...props} className="medical-history-container">
        <p className="medical-history-category">
          {t(`clinicalFile.categoriesShort.${medicalHistory.category}`)}
        </p>
        <ul className="medical-history-antecedents">
          {sortedAntecedents.map((value) => (
            <li key={`${medicalHistory.category}-${value}`}>
              <p className="p1">{t(`clinicalFile.antecedents.${value}`)}</p>
            </li>
          ))}
        </ul>
        {medicalHistory.details !== null && (
          <p className="p2-italic">{medicalHistory.details}</p>
        )}
      </div>
      <IconButton
        Icon={Pen}
        color={COLORS.primary._500}
        data-testid={`pen-${medicalHistory.category}`}
        onClick={onClick}
      />
    </div>
  );
};
