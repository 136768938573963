import { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Calendar } from '../../../../../components';
import { MonthPickerInput } from '../../MonthPicker/MonthPickerInput';
import { AddPatientForm } from '../AddPatientModal';

interface FirstVisiteDateInputProps {
  control: Control<AddPatientForm>;
  testId: string;
}

export const FirstVisiteDateInput: FunctionComponent<
  FirstVisiteDateInputProps
> = ({ control, testId }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="firstVisitDate"
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        const isFirstVisitEmpty = value === null;
        return (
          <MonthPickerInput
            label={t(
              'patientList.addPatientModal.patientFollowup.dateOfFirstVisit',
            )}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            clearable={!isFirstVisitEmpty}
            rightSection={isFirstVisitEmpty ? <Calendar /> : null}
            styles={{
              rightSection: {
                pointerEvents: isFirstVisitEmpty ? 'none' : 'all',
              },
            }}
            isValid={error === undefined}
            data-testid={`${testId}-first-visit-input`}
          />
        );
      }}
      rules={{ required: true }}
    />
  );
};
