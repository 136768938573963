export enum MedicalHistoryCategory {
  OTHER_MEDICAL_OR_SURGICAL_HISTORY = 'OTHER_MEDICAL_OR_SURGICAL_HISTORY',
  MEDICATION_HISTORY = 'MEDICATION_HISTORY',
  PSYCHIATRIC_HISTORY = 'PSYCHIATRIC_HISTORY',
  ADDICTIVE_BEHAVIOUR_HISTORY = 'ADDICTIVE_BEHAVIOUR_HISTORY',
  LIFE_HISTORY = 'LIFE_HISTORY',
}

export enum MedicalHistoryAntecedent {
  NO_ANTECEDENTS = 'NO_ANTECEDENTS',
  OTHER = 'OTHER',
  DONT_KNOW = 'DONT_KNOW',

  CARDIOVASCULAR = 'CARDIOVASCULAR',
  RESPIRATORY = 'RESPIRATORY',
  NEUROLOGICAL = 'NEUROLOGICAL',
  DIGESTIVE = 'DIGESTIVE',
  UROLOGICAL_NEPHROLOGICAL = 'UROLOGICAL_NEPHROLOGICAL',
  GENITAL = 'GENITAL',
  HEPATIC = 'HEPATIC',
  ENT = 'ENT',
  OCULAR = 'OCULAR',
  DERMATOLOGICAL = 'DERMATOLOGICAL',
  IMMUNE = 'IMMUNE',
  ENDOCRINE = 'ENDOCRINE',
  HAEMATOLOGICAL = 'HAEMATOLOGICAL',
  ALLERGIC = 'ALLERGIC',
  LOCOMOTOR_RHEUMATOLOGICAL = 'LOCOMOTOR_RHEUMATOLOGICAL',

  PARACETAMOL = 'PARACETAMOL',
  PARACETAMOL_PLUS_CODEINE_OR_TRAMADOL_OR_OPIUM = 'PARACETAMOL_PLUS_CODEINE_OR_TRAMADOL_OR_OPIUM',
  IBUPROFEN = 'IBUPROFEN',
  IBUPROFEN_PLUS_CODEINE = 'IBUPROFEN_PLUS_CODEINE',
  GABAPENTINOIDS = 'GABAPENTINOIDS',
  OTHER_ANTI_EPILEPTICS = 'OTHER_ANTI_EPILEPTICS',
  NSAIDS = 'NSAIDS',
  ANTIDEPRESSANTS = 'ANTIDEPRESSANTS',
  WEAK_OPIOIDS = 'WEAK_OPIOIDS',
  LOCAL_ANAESTHETICS_OR_PATCHES = 'LOCAL_ANAESTHETICS_OR_PATCHES',
  STRONG_OPIOIDS = 'STRONG_OPIOIDS',
  CAPSAICIN = 'CAPSAICIN',
  STEROIDAL_ANTI_INFLAMMATORY_DRUGS = 'STEROIDAL_ANTI_INFLAMMATORY_DRUGS',
  TRIPTANS = 'TRIPTANS',
  BETA_BLOCKERS = 'BETA_BLOCKERS',
  MEDICAL_CANNABINOIDS = 'MEDICAL_CANNABINOIDS',

  PSYCHIATRIC_HOSPITALIZATION = 'PSYCHIATRIC_HOSPITALIZATION',
  PSYCHIATRIC_CONSULTATION = 'PSYCHIATRIC_CONSULTATION',
  DEPRESSION = 'DEPRESSION',
  POST_TRAUMATIC_STRESS = 'POST_TRAUMATIC_STRESS',
  PSYCHOSIS = 'PSYCHOSIS',
  BIPOLAR_DISORDER = 'BIPOLAR_DISORDER',
  ANXIETY_DISORDERS = 'ANXIETY_DISORDERS',
  EATING_DISORDERS = 'EATING_DISORDERS',

  TOBACCO = 'TOBACCO',
  ALCOHOL = 'ALCOHOL',
  CANNABIS = 'CANNABIS',
  DRUGS = 'DRUGS',
  BEHAVIOURAL_ADDICTIONS = 'BEHAVIOURAL_ADDICTIONS',
  COCAINE = 'COCAINE',
  ECSTASY = 'ECSTASY',
  HEROIN = 'HEROIN',

  SOCIAL_OR_FAMILY_ISOLATION = 'SOCIAL_OR_FAMILY_ISOLATION',
  DEATH_OF_A_CLOSE_RELATIVE = 'DEATH_OF_A_CLOSE_RELATIVE',
  DIVORCE = 'DIVORCE',
  LOSS_OF_EMPLOYMENT = 'LOSS_OF_EMPLOYMENT',
  BURN_OUT = 'BURN_OUT',
  ACCIDENT = 'ACCIDENT',
  PHYSICAL_VIOLENCE = 'PHYSICAL_VIOLENCE',
  SEXUAL_VIOLENCE = 'SEXUAL_VIOLENCE',
  PSYCHOLOGICAL_VIOLENCE = 'PSYCHOLOGICAL_VIOLENCE',
}

export interface CreateMedicalHistoryDto {
  history: {
    category: MedicalHistoryCategory;
    antecedents: MedicalHistoryAntecedent[];
    details?: string | null;
  }[];
}

export interface MedicalHistory {
  patientId: string;
  category: MedicalHistoryCategory;
  values: MedicalHistoryAntecedent[];
  details: string | null;
}

export interface UpdateMedicalHistoryDto {
  antecedents: MedicalHistoryAntecedent[];
  details?: string | null;
}
