import { ReactElement } from 'react';

export const join = (elements: ReactElement[], separator: ReactElement) =>
  elements.length === 0 ? (
    <></>
  ) : (
    elements.reduce((result, item) => (
      <>
        {result}
        {separator}
        {item}
      </>
    ))
  );
