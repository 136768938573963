import './Characterization.scss';

import { Tabs } from '@mantine/core';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { BodyMaps } from './BodyMaps/BodyMaps';
import { Charts } from './Charts/Charts';

export const Characterization: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Tabs
      defaultValue="charts"
      classNames={{
        tabsList: 'characterization-tabs-list',
        tab: 'characterization-tab',
        root: 'characterization-container',
        panel: 'characterization-panel',
      }}
    >
      <Tabs.List>
        <Tabs.Tab value="charts">
          <p className="text">{t('characterization.chartTab')}</p>
        </Tabs.Tab>
        <Tabs.Tab value="body-map">
          <p className="text">{t('characterization.bodyMapTab')}</p>
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="charts">{<Charts />}</Tabs.Panel>
      <Tabs.Panel value="body-map">{<BodyMaps />}</Tabs.Panel>
    </Tabs>
  );
};
