import './CreateMedicalHistoryModal.scss';

import { FunctionComponent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ArrowRight,
  Button,
  ButtonClass,
  Checkbox,
  LongTextInput,
  Modal,
  ModalProps,
} from '../../../../../../components';
import { ThemeColor } from '../../../../../../theme/colors';
import {
  MedicalHistory,
  MedicalHistoryAntecedent,
} from '../../../../../../types/medicalHistory';
import {
  ANTECEDENTS_BY_CATEGORY,
  ORDERED_MEDICAL_HISTORY_CATEGORIES,
} from './data';

export interface MedicalHistoryForm {
  antecedents: MedicalHistoryAntecedent[];
  details: string | null;
}

export interface CreateOrEditMedicalHistoryModalProps
  extends Omit<ModalProps, 'header' | 'onSubmit' | 'errorMessage'> {
  categoryIndex: number;
  onPressPrevious?: () => void;
  defaultValues?: MedicalHistory;
  isLoading?: boolean;
  isError?: boolean;
  onSubmit: (data: MedicalHistoryForm) => void;
  shouldShowIndex: boolean;
  saveButtonText: string;
}

export const CreateOrEditMedicalHistoryModal: FunctionComponent<
  CreateOrEditMedicalHistoryModalProps
> = ({
  opened,
  categoryIndex,
  onPressPrevious,
  defaultValues,
  isLoading,
  isError,
  onSubmit,
  shouldShowIndex,
  saveButtonText,
  ...props
}) => {
  const { t } = useTranslation();

  const category = ORDERED_MEDICAL_HISTORY_CATEGORIES[categoryIndex];
  const options = ANTECEDENTS_BY_CATEGORY[category];

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm<MedicalHistoryForm>({
    mode: 'onBlur',
    defaultValues: {
      antecedents: [],
    },
  });

  useEffect(() => {
    if (opened && defaultValues !== undefined) {
      reset({
        antecedents: defaultValues.values,
        details: defaultValues.details,
      });
    }
  }, [opened, reset, defaultValues]);

  return (
    <Modal
      {...props}
      opened={opened}
      testId="create-medical-history-modal"
      onPrevious={onPressPrevious}
      header={t('clinicalFile.createMedicalHistoryModal.title')}
      errorMessage={t('errors.unknownError.message')}
      hasError={isError}
    >
      <div className="create-medical-history-modal-container">
        <p className="create-medical-history-modal-title">
          {t(`clinicalFile.categories.${category}`)}
          {shouldShowIndex &&
            ` (${categoryIndex + 1}/${
              ORDERED_MEDICAL_HISTORY_CATEGORIES.length
            })`}
        </p>
        <Controller
          control={control}
          name="antecedents"
          rules={{
            required: true,
            validate: (selectedValues) => selectedValues.length > 0,
          }}
          render={({ field: { value: selectedValues, onChange } }) => (
            <div className="create-medical-history-modal-checkboxes-container">
              {options.map((option) => {
                const isOptionDisabled =
                  selectedValues.includes(
                    MedicalHistoryAntecedent.NO_ANTECEDENTS,
                  ) && option !== MedicalHistoryAntecedent.NO_ANTECEDENTS;

                return (
                  <div
                    className="create-medical-history-modal-checkbox-and-label-container"
                    key={option}
                  >
                    <Checkbox
                      id={option}
                      data-testid={`checkbox-${option}`}
                      color={ThemeColor.primaryBlue}
                      disabled={isOptionDisabled}
                      classNames={{
                        input: 'create-medical-history-modal-checkbox',
                      }}
                      checked={selectedValues.includes(option)}
                      onChange={(newValue) => {
                        if (newValue) {
                          if (
                            option === MedicalHistoryAntecedent.NO_ANTECEDENTS
                          ) {
                            onChange([MedicalHistoryAntecedent.NO_ANTECEDENTS]);
                          } else {
                            onChange([...selectedValues, option]);
                          }
                        } else {
                          onChange(
                            selectedValues.filter(
                              (currentOption) => currentOption !== option,
                            ),
                          );
                        }
                      }}
                    />
                    <label
                      htmlFor={option}
                      className={
                        isOptionDisabled
                          ? 'create-medical-history-modal-disabled-label'
                          : 'p2'
                      }
                    >
                      {t(`clinicalFile.antecedents.${option}`)}
                    </label>
                  </div>
                );
              })}
            </div>
          )}
        />
        <Controller
          control={control}
          name="details"
          rules={{ required: false }}
          render={({ field: { value, onChange } }) => (
            <LongTextInput
              label={t('clinicalFile.createMedicalHistoryModal.detailsLabel')}
              inputTestId="create-medical-history-modal-details-input"
              value={value ?? ''}
              onChange={onChange}
              resizable={true}
            />
          )}
        />
        <div className="create-medical-history-button-container">
          <Button
            buttonClass={ButtonClass.PRIMARY}
            text={saveButtonText}
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || !isDirty}
            isLoading={isLoading}
            RightIcon={ArrowRight}
            data-testid="create-medical-history-next-button"
          />
        </div>
      </div>
    </Modal>
  );
};
