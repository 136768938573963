import '../../theme/fonts.scss';
import './SnackBar.scss';

import { ComponentProps, FunctionComponent, useState } from 'react';

import { Color, COLORS } from '../../theme/colors';
import { Check } from '../icons/Check';
import { Cross } from '../icons/Cross';
import { Information } from '../icons/Information';
import { Icon } from '../icons/types';
import { Warning } from '../icons/Warning';

interface SnackBarProps extends ComponentProps<'div'> {
  title: string;
  description?: string;
  snackBarType: SnackBarType;
}

export enum SnackBarType {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
}

const SNACK_BAR_ELEMENTS: Record<
  SnackBarType,
  { backgroundColor: Color; Icon: Icon }
> = {
  [SnackBarType.ERROR]: {
    backgroundColor: COLORS.statuses.errorLight,
    Icon: Warning,
  },
  [SnackBarType.INFO]: {
    backgroundColor: COLORS.grey.grey50Background,
    Icon: Information,
  },
  [SnackBarType.WARNING]: {
    backgroundColor: COLORS.statuses.errorLight,
    Icon: Information,
  },
  [SnackBarType.SUCCESS]: {
    backgroundColor: COLORS.statuses.successLight,
    Icon: Check,
  },
};

export const SnackBar: FunctionComponent<SnackBarProps> = ({
  title,
  description,
  snackBarType,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const Icon = SNACK_BAR_ELEMENTS[snackBarType].Icon;
  return (
    <>
      {isVisible ? (
        <div
          className="snack-bar-container"
          style={{
            backgroundColor: SNACK_BAR_ELEMENTS[snackBarType].backgroundColor,
          }}
          {...props}
        >
          {<Icon color={COLORS.texts.default} />}
          <div className="snack-bar-text-container">
            <p className="p1-bold">{title}</p>
            {description && <p className="p2">{description}</p>}
          </div>
          <div
            onClick={() => setIsVisible(false)}
            className="snack-bar-cross-container"
          >
            <Cross color={COLORS.texts.default} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
