import './InformationModal.scss';

import { Tabs } from '@mantine/core';
import { FunctionComponent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalProps } from '../../../../../../components';

export interface TreatmentInformationModalProps
  extends Omit<ModalProps, 'header'> {
  title: string;
  latestInformation: ReactElement;
  history: ReactElement;
  testId?: string;
}

export const TreatmentInformationModal: FunctionComponent<
  TreatmentInformationModalProps
> = ({ title, latestInformation, history, onClose, opened, testId }) => {
  const { t } = useTranslation();

  return (
    <Modal opened={opened} header={title} onClose={onClose} testId={testId}>
      <Tabs
        defaultValue="most-recent-information"
        classNames={{
          tabsList: 'information-modal-tabs-list',
          tab: 'information-modal-tab',
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="most-recent-information">
            <p className="text">
              {t(
                'followup.treatmentInformationModal.tabNames.mostRecentInformation',
              )}
            </p>
          </Tabs.Tab>
          <Tabs.Tab value="history">
            <p className="text">
              {t('followup.treatmentInformationModal.tabNames.history')}
            </p>
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="most-recent-information">
          {latestInformation}
        </Tabs.Panel>
        <Tabs.Panel value="history">{history}</Tabs.Panel>
      </Tabs>
    </Modal>
  );
};
