import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { Patient, PatientDto } from '../../types/patient';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

const getPatient = async (patientId: string): Promise<Patient> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.PATIENT, {
    pathParameters: { ':patientId': patientId },
  });
};

export const usePatient = (patientId: string) =>
  useQuery<Patient>(['patient', patientId], () => getPatient(patientId));

export type SearchPatientsDTO = {
  patients: PatientWithLastActivityDate[];
  total: number;
};
export interface PatientWithLastActivityDate extends Patient {
  lastActivityDate: string | null;
}

const searchPatients = async (
  limit: number,
  offset: number,
  searchedName: string,
): Promise<SearchPatientsDTO> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.PATIENTS, {
    queryParameters: { offset, limit, searchedName },
  });
};

export const useSearchPatients = (
  limit: number,
  initialOffset: number,
  searchedName: string,
) =>
  useInfiniteQuery<SearchPatientsDTO>(
    ['searchPatients', searchedName],
    ({ pageParam: offset = initialOffset }) =>
      searchPatients(limit, offset, searchedName),
    {
      getNextPageParam: (lastPage, allPages) => {
        const offset: number = allPages.flatMap(
          ({ patients }) => patients,
        ).length;

        return offset >= lastPage.total ? undefined : offset;
      },
    },
  );

const createPatient = async (dto: PatientDto): Promise<void> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.POST, ENDPOINTS.PATIENTS, {
    data: dto,
  });
};

export const useNewPatient = (
  onSuccess: () => void,
  onError?: (error: unknown) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, PatientDto, unknown>(
    ['createPatient'],
    (data: PatientDto) => createPatient(data),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        queryClient.invalidateQueries(['allPatients']);
      },
      onError,
    },
  );
};
