import { useQuery } from '@tanstack/react-query';

import { Event } from '../../types/events';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

const getPatientEvents = async (patientId: string): Promise<Event[]> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.EVENTS, {
    pathParameters: {
      ':patientId': patientId,
    },
  });
};

export const useGetPatientEvents = (patientId: string) =>
  useQuery<Event[]>(['events', patientId], () => getPatientEvents(patientId));
