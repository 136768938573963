import { FunctionComponent } from 'react';
import type { OutletProps } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Outlet, useOutletContext } from 'react-router-dom';

import { Patient } from '../../types/patient';

interface PatientDetailsOutletContext {
  patient: Patient;
}

interface PatientDetailsOutletProps extends Omit<OutletProps, 'context'> {
  context: PatientDetailsOutletContext;
}

export const PatientDetailsOutlet: FunctionComponent<
  PatientDetailsOutletProps
> = (props) => <Outlet {...props} />;

type UsePatientDetailsOutletContext =
  typeof useOutletContext<PatientDetailsOutletContext>;

export const usePatientDetailsOutletContext = (
  ...args: Parameters<UsePatientDetailsOutletContext>
): ReturnType<UsePatientDetailsOutletContext> => useOutletContext(...args);
