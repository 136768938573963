import { JSX } from 'react';

import { COLORS } from '../../../../../../theme/colors';
import { ScoreType } from '../../../../../../types/scores';
import { SpiderChartPosition } from '../types';
import {
  SPIDER_CHART_DEFAULT_HEIGHT_IN_PX,
  SPIDER_CHART_DEFAULT_WIDTH_IN_PX,
} from './SpiderChart';

export const SPIDER_CHART_MIN_VALUE = 0;
export const SPIDER_CHART_MAX_VALUE = 85;

export const SPIDER_CHART_AXES: Record<SpiderChartPosition, ScoreType[]> = {
  [SpiderChartPosition.TOP_LEFT]: [
    ScoreType.PROMIS_PAIN_INTERFERENCE,
    ScoreType.NEUROQOL_EMOTIONAL_DYSCONTROL,
    ScoreType.PROMIS_FATIGUE,
    ScoreType.PROMIS_SLEEP_DISORDERS,
    ScoreType.PROMIS_DEPRESSION,
    ScoreType.PROMIS_ANXIETY,
  ],
  [SpiderChartPosition.TOP_RIGHT]: [
    ScoreType.PROMIS_COGNITIVE_FUNCTIONS,
    ScoreType.NEUROQOL_POSITIVE_AFFECT,
    ScoreType.PROMIS_PHYSICAL_FUNCTION,
    ScoreType.PROMIS_SOCIAL_SKILLS,
  ],
  [SpiderChartPosition.BOTTOM_LEFT]: [
    ScoreType.TSK4_KINESIOPHOBIA,
    ScoreType.EPICES_PRECARITY,
    ScoreType.ETA_SF_ALEXITHYMIA,
    ScoreType.PCS4_CATASTROPHISM,
  ],
  [SpiderChartPosition.BOTTOM_RIGHT]: [
    ScoreType.IPAQ_SF_PHYSICAL_ACTIVITY,
    ScoreType.IN_HOUSE_BALANCE,
    ScoreType.IN_HOUSE_ENDURANCE,
    ScoreType.IN_HOUSE_FLEXIBILITY,
    ScoreType.IN_HOUSE_MUSCULAR_STRENGTH,
  ],
};

export const getSpiderChartBackgrounds = (
  chartWidth = SPIDER_CHART_DEFAULT_WIDTH_IN_PX,
  chartHeight = SPIDER_CHART_DEFAULT_HEIGHT_IN_PX,
): Record<SpiderChartPosition, JSX.Element> => ({
  [SpiderChartPosition.TOP_LEFT]: (
    <g transform={`translate(${chartWidth / 2}, ${chartHeight / 2})`}>
      <circle cx="0" cy="0" r="120" fill={COLORS.tertiary.lightPeach} />
      <circle cx="0" cy="0" r="99" fill={COLORS.tertiary.lightOrange} />
      <circle cx="0" cy="0" r="85" fill={COLORS.tertiary.lightYellow} />;
      <circle cx="0" cy="0" r="78" fill={COLORS.tertiary.lightGreen} />
    </g>
  ),
  [SpiderChartPosition.TOP_RIGHT]: (
    <g transform={`translate(${chartWidth / 2}, ${chartHeight / 2})`}>
      <circle cx="0" cy="0" r="120" fill={COLORS.tertiary.lightGreen} />
      <circle cx="0" cy="0" r="64" fill={COLORS.tertiary.lightYellow} />
      <circle cx="0" cy="0" r="57" fill={COLORS.tertiary.lightOrange} />;
      <circle cx="0" cy="0" r="43" fill={COLORS.tertiary.lightPeach} />
    </g>
  ),
  [SpiderChartPosition.BOTTOM_LEFT]: (
    <g transform={`translate(${chartWidth / 2}, ${chartHeight / 2})`}>
      <circle cx="0" cy="0" r="120" fill={COLORS.tertiary.lightPeach} />
      <path
        transform="translate(-42.5, -85) scale(0.91)"
        d="M140 92.225C140 122.45 90.7682 186 46.9219 186C7.69084 186 4.20873e-08 109.275 0 92.225C-4.20873e-08 75.175 6.15237 0 47.6919 0C97.6913 0 140 62 140 92.225Z"
        fill={COLORS.tertiary.lightGreen}
      />
    </g>
  ),
  [SpiderChartPosition.BOTTOM_RIGHT]: (
    <g transform={`translate(${chartWidth / 2}, ${chartHeight / 2})`}>
      <circle cx="0" cy="0" r="120" fill={COLORS.tertiary.lightGreen} />
      <circle cx="0" cy="0" r="85" fill={COLORS.tertiary.lightYellow} />
      <circle cx="0" cy="0" r="57" fill={COLORS.tertiary.lightOrange} />;
      <circle cx="0" cy="0" r="28" fill={COLORS.tertiary.lightPeach} />
    </g>
  ),
});
