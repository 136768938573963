import { Medicine } from './medicine';

export enum TreatmentAge {
  UNKNOWN = 'UNKNOWN',
  SEVERAL_MONTHS = 'SEVERAL_MONTHS',
  SEVERAL_YEARS = 'SEVERAL_YEARS',
}

export enum DosageUnit {
  GRAMS = 'GRAMS',
  MILLIGRAMS = 'MILLIGRAMS',
  MICROGRAMS = 'MICROGRAMS',
  UNITS = 'UNITS',
}

export enum TreatmentType {
  BACKGROUND_TREATMENT = 'BACKGROUND_TREATMENT',
  CRISIS_TREATMENT = 'CRISIS_TREATMENT',
  ON_DEMAND = 'ON_DEMAND',
}

export interface MedicineTreatment {
  id: string;
  patientId: string;
  medicine: Medicine;
  dailyDosage: number | null;
  dailyDosageUnit: DosageUnit | null;
  type: TreatmentType;
  startDate: string | TreatmentAge;
  endDate: string | null;
  details: string | null;
  history: MedicineTreatmentHistory[];
}

export interface MedicineTreatmentHistoryData
  extends Omit<
    MedicineTreatment,
    'patientId' | 'history' | 'medicine' | 'id'
  > {}

export interface MedicineTreatmentHistory {
  updatedBy: string;
  medicineTreatmentId: string;
  data: MedicineTreatmentHistoryData;
  updatedAt: string;
}

export interface MedicineTreatmentDto {
  medicineCisCode: number;
  dailyDosage: number | null;
  dailyDosageUnit: DosageUnit | null;
  type: TreatmentType;
  startDate: string | null;
  details: string | null;
  endDate: string | null;
}
