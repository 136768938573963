import './DatePickerInput.scss';

import type { DatePickerInputProps } from '@mantine/dates';
// eslint-disable-next-line no-restricted-imports
import { DatePickerInput as MantineDatePickerInput } from '@mantine/dates';
import { FunctionComponent } from 'react';

import { COLORS } from '../../../../../../../theme/colors';
import { stripTimezone } from '../../../../../../../utils';

interface DatePickerProps extends DatePickerInputProps {
  label: string;
  isValid?: boolean;
}

export const DatePickerInput: FunctionComponent<DatePickerProps> = ({
  label,
  onChange,
  isValid = true,
  ...props
}) => {
  return (
    <div
      className="date-picker-input-container"
      style={
        !isValid
          ? {
              borderColor: COLORS.statuses.error,
              color: COLORS.statuses.error,
            }
          : {}
      }
    >
      <label className="date-picker-label">{label}</label>
      <div className="date-picker-wrapper">
        <MantineDatePickerInput
          classNames={{
            input: 'date-picker-input',
            wrapper: 'date-picker-wrapper',
            rightSection: 'date-picker-rightSection',
            day: 'day-select',
            calendar: 'calendar',
          }}
          locale="fr"
          valueFormat="DD/MM/YYYY"
          onChange={(newValue: Date | null) => {
            if (onChange === undefined) return;

            if (newValue === undefined || newValue === null) {
              return onChange(null);
            }

            onChange(stripTimezone(newValue));
          }}
          {...props}
        />
      </div>
    </div>
  );
};
