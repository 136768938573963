import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ArrowRight,
  Button,
  ButtonClass,
  LabeledText,
} from '../../../../../../../components';
import { formatDateFrench } from '../../../../../../../services/date/formatDateFrench';
import { ComplementaryTreatment } from '../../../../../../../types/complementaryTreatment';
import { isTreatmentAge } from '../../utils';

interface ComplementaryTreatmentLatestInformationProps {
  complementaryTreatment: ComplementaryTreatment;
  onClickModify: () => void;
}

export const ComplementaryTreatmentLatestInformation: FunctionComponent<
  ComplementaryTreatmentLatestInformationProps
> = ({ complementaryTreatment, onClickModify }) => {
  const { t } = useTranslation();

  return (
    <div className="information-modal-tab-container">
      <LabeledText
        label={t(
          'followup.complementaryTreatments.complementaryTreatmentModal.recentInformationTab.complementaryTherapy',
        )}
        text={complementaryTreatment.therapy.name}
      />

      <LabeledText
        label={t(
          'followup.complementaryTreatments.complementaryTreatmentModal.inCommon.prescriber',
        )}
        text={t(`followup.prescriber.${complementaryTreatment.prescriber}`)}
      />

      <div className="information-modal-horizontal-section">
        <LabeledText
          label={t('followup.inCommon.startDate')}
          text={
            isTreatmentAge(complementaryTreatment.startDate)
              ? t(`followup.treatmentAge.${complementaryTreatment.startDate}`)
              : formatDateFrench(complementaryTreatment.startDate)
          }
        />

        <LabeledText
          label={t('followup.inCommon.endDate')}
          text={
            complementaryTreatment.endDate === null
              ? t(
                  'followup.complementaryTreatments.complementaryTreatmentModal.inCommon.therapyInProgress',
                )
              : formatDateFrench(complementaryTreatment.endDate)
          }
        />
      </div>

      <LabeledText
        label={t(
          'followup.complementaryTreatments.complementaryTreatmentModal.inCommon.therapyDetails',
        )}
        text={
          complementaryTreatment.details === null ||
          complementaryTreatment.details === ''
            ? '-'
            : complementaryTreatment.details
        }
      />

      <div className="information-modal-modify-button">
        <Button
          buttonClass={ButtonClass.PRIMARY}
          text={t('followup.inCommon.modifyInformation')}
          RightIcon={ArrowRight}
          onClick={onClickModify}
          data-testid="information-modal-modify-button"
        />
      </div>
    </div>
  );
};
