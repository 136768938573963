import './CreateOrEditDiagnosisModal.scss';

import { FunctionComponent, useEffect } from 'react';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ActivityIndicator,
  ArrowRight,
  Button,
  ButtonClass,
  Calendar,
  ErrorApiMessage,
  FilterableInput,
  LongTextInput,
  Modal,
  ModalProps,
} from '../../../../../../components';
import { useGetDiagnoses } from '../../../../../../services/api/diagnosis';
import { DiagnosisValidation } from '../../../../../../types/diagnosis';
import { MonthPickerInput } from '../../../../../PatientList/components/MonthPicker/MonthPickerInput';
import { ModalDropDown } from '../../../Followup/components/EditionModals/ModalDropDown/ModalDropDown';
import {
  getDiagnosesDropdownItems,
  getDiagnosisValidationDropdownItems,
} from './data';

export interface CreateOrEditDiagnosisModalProps
  extends Omit<
    ModalProps,
    'header' | 'errorMessage' | 'hasError' | 'onSubmit'
  > {
  defaultValues: DefaultValues<CreateOrEditDiagnosisForm>;
  title: string;
  isLoadingCreateOrEdit: boolean;
  isCreateOrEditError: boolean;
  onSubmit: (data: CreateOrEditDiagnosisForm) => void;
  isAutocompleteDisabled: boolean;
}

export interface CreateOrEditDiagnosisForm {
  diagnosisId: string;
  validation: DiagnosisValidation;
  details: string | null;
  date: Date;
}

export const CreateOrEditDiagnosisModal: FunctionComponent<
  CreateOrEditDiagnosisModalProps
> = ({
  title,
  onClose,
  defaultValues,
  onSubmit,
  isLoadingCreateOrEdit,
  isCreateOrEditError,
  isAutocompleteDisabled,
  opened,
  ...props
}) => {
  const { t } = useTranslation();

  const {
    isLoading: isLoadingDiagnoses,
    data: diagnoses,
    isError: isDiagnosesError,
  } = useGetDiagnoses();

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    reset,
  } = useForm<CreateOrEditDiagnosisForm>({
    mode: 'onBlur',
  });

  const onCloseModal = () => {
    onClose();
  };

  useEffect(() => {
    if (opened) {
      reset(defaultValues);
    }
  }, [opened, reset, defaultValues]);

  return (
    <Modal
      {...props}
      opened={opened}
      onClose={onCloseModal}
      header={title}
      errorMessage={t('errors.unknownError.message')}
      hasError={isCreateOrEditError}
    >
      {isDiagnosesError ? (
        <ErrorApiMessage
          title={t('apiMessage.serverError.errorOccurred')}
          subtitle={t('apiMessage.serverError.tryAgainLater')}
        />
      ) : isLoadingDiagnoses ? (
        <ActivityIndicator testId="create-diagnosis-loader" />
      ) : (
        <>
          <div className="create-or-edit-diagnosis-modal-fields-container">
            <Controller
              name="diagnosisId"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { invalid },
              }) => (
                <FilterableInput
                  disabled={isAutocompleteDisabled}
                  isValid={!invalid}
                  label={t(
                    'clinicalFile.createOrEditDiagnosisModal.diagnosisDropdownLabel',
                  )}
                  data={getDiagnosesDropdownItems(diagnoses ?? [])}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  data-testid="diagnosis-autocomplete-input"
                />
              )}
            />
            <div className="create-or-edit-diagnosis-modal-validation-date-container">
              <Controller
                control={control}
                name="validation"
                rules={{ required: true }}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { invalid },
                }) => (
                  <ModalDropDown
                    label={t(
                      'clinicalFile.createOrEditDiagnosisModal.validationLabel',
                    )}
                    data={getDiagnosisValidationDropdownItems(t)}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    isValid={!invalid}
                    containerClassName="create-diagnosis-modal-validation-date-input"
                    data-testid="diagnosis-validation-input"
                  />
                )}
              />
              <Controller
                control={control}
                name="date"
                rules={{ required: true }}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { invalid },
                }) => (
                  <MonthPickerInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    isValid={!invalid}
                    label={t(
                      'clinicalFile.createOrEditDiagnosisModal.dateLabel',
                    )}
                    clearable={false}
                    rightSection={<Calendar />}
                    className="create-or-edit-diagnosis-modal-validation-date-input"
                    data-testid="diagnosis-date-input"
                  />
                )}
              />
            </div>
            <Controller
              control={control}
              name="details"
              rules={{ required: false }}
              render={({ field: { value, onChange, onBlur } }) => (
                <LongTextInput
                  value={value ?? ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={t(
                    'clinicalFile.createOrEditDiagnosisModal.detailsLabel',
                  )}
                  inputTestId="create-diagnosis-details-input"
                  resizable={true}
                />
              )}
            />
          </div>
          <div className="create-or-edit-diagnosis-modal-button-container">
            <Button
              buttonClass={ButtonClass.PRIMARY}
              text={t('save')}
              RightIcon={ArrowRight}
              disabled={!isValid || !isDirty}
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoadingCreateOrEdit}
              data-testid="save-diagnosis-button"
            />
          </div>
        </>
      )}
    </Modal>
  );
};
