import './i18next.d';

import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import fr from '../../translations/fr.json';

export const defaultNamespace = 'translation';
export const resources = {
  fr: {
    translation: fr,
  },
} as const;

export const initI18n = async (): Promise<void> => {
  const locale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  await use(initReactI18next).init({
    defaultNS: defaultNamespace,
    resources,
    lng: locale,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
  });
};
