import './ConsultationNoteCard.scss';

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '../../../../../../../components';
import { Pen } from '../../../../../../../components/icons/Pen';
import { formatDateFrench } from '../../../../../../../services/date/formatDateFrench';
import { COLORS } from '../../../../../../../theme/colors';
import { ConsultationNote } from '../../../../../../../types/consultationNotes';

interface ConsultationNoteCardProps {
  consultationNote: ConsultationNote;
  onButtonClick: () => void;
}

export const ConsultationNoteCard: FunctionComponent<
  ConsultationNoteCardProps
> = ({ consultationNote, onButtonClick }) => {
  const { t } = useTranslation();
  const date = formatDateFrench(consultationNote.createdAt.toString());
  const formattedDateAndHcpInfo = `${date} - ${
    consultationNote.hcp.firstName
  } ${consultationNote.hcp.lastName} (${t(
    `clinicalFile.consultationNotes.hcpRole.${consultationNote.hcp.role}`,
  )})`;

  return (
    <div className="consultation-note-container shadow-medium">
      <div className="consultation-note-content-container">
        <p className="consultation-note-card-title">
          {formattedDateAndHcpInfo}
        </p>
        <div className="text-container">
          <p className="p2">{consultationNote.text}</p>
        </div>
      </div>
      <IconButton
        Icon={Pen}
        color={COLORS.primary._500}
        data-testid={'consultation-note-pen-button'}
        onClick={onButtonClick}
      />
    </div>
  );
};
