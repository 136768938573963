import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  CreateMedicalHistoryDto,
  MedicalHistory,
  MedicalHistoryCategory,
  UpdateMedicalHistoryDto,
} from '../../types/medicalHistory';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

const createMedicalHistory = async (
  patientId: string,
  history: CreateMedicalHistoryDto,
): Promise<void> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.POST, ENDPOINTS.MEDICAL_HISTORY, {
    pathParameters: { ':patientId': patientId },
    data: history,
  });
};

export const useCreateMedicalHistory = (
  patientId: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, CreateMedicalHistoryDto, unknown>(
    ['createMedicalHistory'],
    (dto: CreateMedicalHistoryDto) => createMedicalHistory(patientId, dto),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        queryClient.invalidateQueries(['medicalHistory', patientId]);
      },
      onError,
    },
  );
};

const getMedicalHistory = async (
  patientId: string,
): Promise<MedicalHistory[]> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.MEDICAL_HISTORY, {
    pathParameters: { ':patientId': patientId },
  });
};

export const useGetMedicalHistory = (patientId: string) =>
  useQuery<MedicalHistory[]>(['medicalHistory', patientId], () =>
    getMedicalHistory(patientId),
  );

const modifyMedicalHistory = async (
  patientId: string,
  category: MedicalHistoryCategory,
  dto: UpdateMedicalHistoryDto,
): Promise<void> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.PUT, ENDPOINTS.UPDATE_MEDICAL_HISTORY, {
    pathParameters: { ':patientId': patientId, ':category': category },
    data: dto,
  });
};

export const useModifyMedicalHistory = (
  patientId: string,
  category: MedicalHistoryCategory,
  onSuccess: () => void,
  onError?: (error: unknown) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, UpdateMedicalHistoryDto, unknown>(
    ['modifyMedicalHistory'],
    (data: UpdateMedicalHistoryDto) =>
      modifyMedicalHistory(patientId, category, data),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        queryClient.invalidateQueries(['medicalHistory', patientId]);
      },
      onError,
    },
  );
};
