import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  MedicineTreatment,
  MedicineTreatmentDto,
} from '../../types/medicineTreatment';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

const getPatientMedicineTreatment = async (
  patientId: string,
): Promise<MedicineTreatment[]> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.MEDICINE_TREATMENTS, {
    pathParameters: { ':patientId': patientId },
  });
};

export const usePatientMedicineTreatments = (patientId: string) =>
  useQuery<MedicineTreatment[]>(
    ['medicineTreatments', patientId],
    () => getPatientMedicineTreatment(patientId),
    { retry: 0 },
  );

const createMedicineTreatment = async (
  patientId: string,
  dto: MedicineTreatmentDto,
): Promise<void> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.POST, ENDPOINTS.MEDICINE_TREATMENT, {
    data: dto,
    pathParameters: { ':patientId': patientId },
  });
};

export const useNewMedicineTreatment = (
  patientId: string,
  onSuccess: () => void,
  onError?: (error: unknown) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, MedicineTreatmentDto, unknown>(
    ['createMedicineTreatment'],
    (data: MedicineTreatmentDto) => createMedicineTreatment(patientId, data),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        queryClient.invalidateQueries(['medicineTreatments', patientId]);
      },
      onError,
    },
  );
};

const modifyMedicineTreatment = async (
  patientId: string,
  dto: MedicineTreatmentDto,
  medicineTreatmentId: string,
): Promise<void> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(
    HttpMethod.PUT,
    ENDPOINTS.UPDATE_MEDICINE_TREATMENT,
    {
      data: dto,
      pathParameters: {
        ':patientId': patientId,
        ':medicineTreatmentId': medicineTreatmentId,
      },
    },
  );
};

export const useModifyMedicineTreatment = (
  patientId: string,
  medicineTreatmentId: string,
  onSuccess: () => void,
  onError?: (error: unknown) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, MedicineTreatmentDto, unknown>(
    ['modifyMedicineTreatment'],
    (data: MedicineTreatmentDto) =>
      modifyMedicineTreatment(patientId, data, medicineTreatmentId),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        queryClient.invalidateQueries(['medicineTreatments', patientId]);
      },
      onError,
    },
  );
};
