import './Modal.scss';

import type { ModalProps as MantineModalProps } from '@mantine/core';
// eslint-disable-next-line no-restricted-imports
import { Modal as MantineModal } from '@mantine/core';
import { FunctionComponent } from 'react';

import { COLORS } from '../../theme/colors';
import { IconButton } from '../IconButton/IconButton';
import { ArrowLeft } from '../icons';
import { Cross } from '../icons/Cross';
import { SnackBar, SnackBarType } from '../SnackBar/Snackbar';

const MODAL_SIZE = 1000;

export interface ModalProps extends MantineModalProps {
  header?: string;
  testId?: string;
  onPrevious?: () => void;
  hasError?: boolean;
  errorMessage?: string;
  size?: number;
}

export const Modal: FunctionComponent<ModalProps> = ({
  header,
  children,
  onClose,
  onPrevious,
  testId = 'modal',
  hasError,
  errorMessage,
  size,
  ...props
}) => {
  return (
    <>
      {hasError && errorMessage && (
        <div className="modal-snack-bar-container">
          <SnackBar
            title={errorMessage}
            snackBarType={SnackBarType.ERROR}
            className="modal-snack-bar"
          />
        </div>
      )}
      <MantineModal
        {...props}
        withCloseButton={false}
        padding={0}
        size={size ?? MODAL_SIZE}
        data-testid={testId}
        onClose={() => {
          return;
        }}
        radius={12}
        centered
      >
        <div className="modal-close-button">
          {onPrevious ? (
            <IconButton
              Icon={ArrowLeft}
              color={COLORS.texts.second}
              onClick={onPrevious}
            />
          ) : (
            <div />
          )}
          <IconButton
            Icon={Cross}
            color={COLORS.texts.second}
            onClick={onClose}
          />
        </div>

        <div className="modal-content">
          {header && <h1 className="modal-title">{header}</h1>}
          {children}
        </div>
      </MantineModal>
    </>
  );
};
