import './Checkbox.scss';

import type { CheckboxProps as MantineCheckboxProps } from '@mantine/core';
// eslint-disable-next-line no-restricted-imports
import { Checkbox as MantineCheckbox } from '@mantine/core';
import { FunctionComponent } from 'react';

import { ThemeColor } from '../../theme/colors';

interface CheckboxProps
  extends Omit<MantineCheckboxProps, 'color' | 'size' | 'onChange'> {
  color: ThemeColor;
  onChange: (value: boolean) => void;
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  onChange,
  ...props
}) => {
  return (
    <MantineCheckbox
      {...props}
      size={16}
      onChange={(event) => onChange(event.currentTarget.checked)}
    />
  );
};
