import dayjs from 'dayjs';

import { ActivityIndicator } from '../../../../../components';
import {
  ComplementaryTreatment,
  ComplementaryTreatmentHistory,
} from '../../../../../types/complementaryTreatment';
import {
  MedicineTreatment,
  MedicineTreatmentHistory,
  TreatmentAge,
} from '../../../../../types/medicineTreatment';
import { ComplementaryTreatmentModificationTooltipContent } from './TooltipContent/ComplementaryTreatmentTooltipContent';
import { MedicineTreatmentModificationTooltipContent } from './TooltipContent/MedicineTreatmentTooltipContent';
import { ModificationTooltipProps } from './TreatmentCard/TreatmentCard';

export const isTreatmentAge = (
  date: string | TreatmentAge,
): date is TreatmentAge => {
  return [
    TreatmentAge.UNKNOWN,
    TreatmentAge.SEVERAL_YEARS,
    TreatmentAge.SEVERAL_MONTHS,
  ].includes(date as TreatmentAge);
};

export interface MedicineTreatmentHistoryWithChanges
  extends MedicineTreatmentHistory {
  hasDosageChanged: boolean;
  hasDosageUnitChanged: boolean;
  hasDetailsChanged: boolean;
}

interface ComplementaryTreatmentHistoryWithChanges
  extends ComplementaryTreatmentHistory {
  hasDetailsChanged: boolean;
}

export const enrichMedicineTreatmentModificationWithChanges = (
  modification: MedicineTreatmentHistory,
  index: number,
  history: MedicineTreatmentHistory[],
): MedicineTreatmentHistoryWithChanges => {
  const previousModification = history[index + 1];

  const hasDosageChanged =
    modification.data.dailyDosage !== previousModification.data.dailyDosage;
  const hasDosageUnitChanged =
    modification.data.dailyDosageUnit !==
    previousModification.data.dailyDosageUnit;
  const hasDetailsChanged =
    modification.data.details !== previousModification.data.details;

  return {
    ...modification,
    hasDosageChanged,
    hasDosageUnitChanged,
    hasDetailsChanged,
  };
};

export const hasMedicineTreatmentAnyChange = (
  modification: MedicineTreatmentHistoryWithChanges,
): boolean =>
  modification.hasDosageChanged ||
  modification.hasDosageUnitChanged ||
  modification.hasDetailsChanged;

export const isModificationWithinTreatmentLifeSpan = (
  modification:
    | MedicineTreatmentHistoryWithChanges
    | ComplementaryTreatmentHistoryWithChanges,
  treatment: MedicineTreatment | ComplementaryTreatment,
): boolean => {
  const modificationDate = dayjs(modification.updatedAt);
  return (
    (isTreatmentAge(treatment.startDate) ||
      modificationDate.isAfter(dayjs(treatment.startDate), 'day')) &&
    (treatment.endDate === null ||
      modificationDate.isBefore(dayjs(treatment.endDate), 'day'))
  );
};

export const getMedicineTreatmentTooltipContent = (
  modification: MedicineTreatmentHistoryWithChanges,
): ModificationTooltipProps => ({
  modificationDate: modification.updatedAt,
  tooltipContent: (
    <MedicineTreatmentModificationTooltipContent
      date={modification.updatedAt}
      dailyDosage={
        modification.hasDosageChanged || modification.hasDosageUnitChanged
          ? modification.data.dailyDosage
          : undefined
      }
      dailyDosageUnit={
        modification.hasDosageChanged || modification.hasDosageUnitChanged
          ? modification.data.dailyDosageUnit
          : undefined
      }
      details={
        modification.hasDetailsChanged ? modification.data.details : undefined
      }
    />
  ),
});

export const isLatestModificationOfDate = (
  modification: ModificationTooltipProps,
  index: number,
  allModifications: ModificationTooltipProps[],
): boolean =>
  allModifications.findIndex(({ modificationDate }) =>
    dayjs(modificationDate).isSame(dayjs(modification.modificationDate), 'day'),
  ) === index;

export const getMedicineTreatmentModificationTooltips = (
  medicineTreatment: MedicineTreatment,
): ModificationTooltipProps[] => {
  return medicineTreatment.history
    .slice(0, medicineTreatment.history.length - 1)
    .map((modification, index) =>
      enrichMedicineTreatmentModificationWithChanges(
        modification,
        index,
        medicineTreatment.history,
      ),
    )
    .filter(hasMedicineTreatmentAnyChange)
    .filter((modification) =>
      isModificationWithinTreatmentLifeSpan(modification, medicineTreatment),
    )
    .map(getMedicineTreatmentTooltipContent)
    .filter(isLatestModificationOfDate);
};

const enrichComplementaryTreatmentModificationWithChanges = (
  modification: ComplementaryTreatmentHistory,
  index: number,
  history: ComplementaryTreatmentHistory[],
): ComplementaryTreatmentHistoryWithChanges => {
  const previousModification = history[index + 1];

  const hasDetailsChanged =
    modification.data.details !== previousModification.data.details;

  return {
    ...modification,
    hasDetailsChanged,
  };
};

const hasComplementaryTreatmentAnyChange = (
  modification: ComplementaryTreatmentHistoryWithChanges,
): boolean => modification.hasDetailsChanged;

const getComplementaryTreatmentTooltipContent = (
  modification: ComplementaryTreatmentHistoryWithChanges,
): ModificationTooltipProps => ({
  modificationDate: modification.updatedAt,
  tooltipContent: (
    <ComplementaryTreatmentModificationTooltipContent
      date={modification.updatedAt}
      details={
        modification.hasDetailsChanged ? modification.data.details : undefined
      }
    />
  ),
});

export const getComplementaryTreatmentModificationTooltips = (
  medicineTreatment: ComplementaryTreatment,
): ModificationTooltipProps[] => {
  return medicineTreatment.history
    .slice(0, medicineTreatment.history.length - 1)
    .map((modification, index) =>
      enrichComplementaryTreatmentModificationWithChanges(
        modification,
        index,
        medicineTreatment.history,
      ),
    )
    .filter(hasComplementaryTreatmentAnyChange)
    .filter((modification) =>
      isModificationWithinTreatmentLifeSpan(modification, medicineTreatment),
    )
    .map(getComplementaryTreatmentTooltipContent)
    .filter(isLatestModificationOfDate);
};

export const Loader = ({ testId }: { testId?: string }) => {
  return (
    <div className="followup-loader-container">
      <ActivityIndicator testId={testId} />
    </div>
  );
};
