import './ModalDropDown.scss';

import type { SelectItem, SelectProps } from '@mantine/core';
// eslint-disable-next-line no-restricted-imports
import { Select } from '@mantine/core';

import { AngleDown } from '../../../../../../../components';
import { COLORS } from '../../../../../../../theme/colors';

interface ModalDropDownProps<DataType extends SelectItem> extends SelectProps {
  label: string;
  data: DataType[];
  isValid?: boolean;
  errorMessage?: string;
  containerClassName?: string;
}

export const ModalDropDown = <DataType extends SelectItem>({
  data,
  label,
  isValid = true,
  errorMessage,
  containerClassName,
  ...props
}: ModalDropDownProps<DataType>) => {
  return (
    <div className={`text-input-wrapper ${containerClassName ?? ''}`}>
      <div
        className={`${containerClassName ?? ''} ${
          props.disabled ? 'disabled-dropdown-container' : 'dropdown-container'
        }`}
        style={
          !isValid
            ? {
                borderColor: COLORS.statuses.error,
                color: COLORS.statuses.error,
              }
            : {}
        }
      >
        <label className="dropdown-label">{label}</label>
        <div className="select-wrapper">
          <Select
            {...props}
            data={data}
            classNames={{
              input: 'modal-Select-input',
              rightSection: 'modal-Select-rightSection',
              dropdown: 'modal-Select-dropdown',
              item: 'modal-Select-item',
              separatorLabel: 'modal-Select-label',
              separator: 'modal-Select-separator',
            }}
            rightSection={<AngleDown />}
          />
        </div>
      </div>
      {!isValid && errorMessage !== undefined && (
        <p
          className="text-input-error-message"
          data-testid="text-input-error-message"
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};
