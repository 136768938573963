import { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { treatmentTypeData } from '../../data';
import { ModalDropDown } from '../../ModalDropDown/ModalDropDown';
import { MedicineTreatmentForm } from '../types';

interface TreatmentTypeDropDownProps {
  control: Control<MedicineTreatmentForm>;
}

export const TreatmentTypeDropDown: FunctionComponent<
  TreatmentTypeDropDownProps
> = ({ control }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="type"
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <ModalDropDown
            label={t(
              'followup.medicineTreatments.medicineTreatmentEditionModal.treatmentType',
            )}
            data={treatmentTypeData}
            className="treatment-type-drop-down"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            isValid={error === undefined}
          />
        );
      }}
      rules={{ required: true }}
    />
  );
};
