import { BODY_MAP_COLOR_MAPPING } from './utils';

/* eslint-disable max-lines */

export const SquaresBack = ({ colors }: { colors: Record<number, string> }) => {
  return (
    <>
      <path
        d="M177.613 594.458C177.973 593.739 177.451 590.41 176.492 587.218C175.576 587.74 174.155 588.501 172.35 589.366L174.857 595.562L175.354 595.58C175.439 595.58 176.783 595.562 177.613 594.458Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[420] ?? 'WHITE']}
        id="420"
        data-testid="back-420"
      />
      <path
        d="M172.837 599.104C173.35 598.761 173.547 597.673 173.564 597.237L173.573 596.971L170.783 590.073C169.55 590.621 168.189 591.187 166.717 591.734C167.205 593.078 168.283 595.945 169.191 598.325L171.125 599C171.579 599.163 172.409 599.403 172.837 599.104Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[419] ?? 'WHITE']}
        id="419"
        data-testid="back-419"
      />
      <path
        d="M168.344 601.398C168.763 601.125 168.823 600.5 168.823 600.491L168.866 600.003L168.104 600.294C166.589 596.297 165.588 593.636 165.108 592.318C164.218 592.626 163.302 592.917 162.352 593.191L164.852 600.971L165.759 601.262C166.94 601.655 167.89 601.707 168.344 601.398Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[418] ?? 'WHITE']}
        id="418"
        data-testid="back-418"
      />
      <path
        d="M163.51 602.381L160.702 593.651C159.59 593.942 158.443 594.207 157.27 594.43C157.775 597.725 158.28 600.738 158.777 603.323C161.507 604.53 162.971 603.126 163.51 602.381Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[417] ?? 'WHITE']}
        id="417"
        data-testid="back-417"
      />
      <path
        d="M153.401 604.53C154.667 604.795 156.037 603.828 156.465 603.434L156.97 602.972C156.516 600.541 156.054 597.751 155.592 594.738C153.358 595.098 151.047 595.312 148.702 595.312C148.548 595.312 148.402 595.303 148.248 595.303C148.625 596.732 149.284 598.564 150.474 601.269C151.056 602.595 152.048 604.247 153.401 604.53Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[416] ?? 'WHITE']}
        id="416"
        data-testid="back-416"
      />
      <path
        d="M172.496 576.38C171.169 577.039 168.388 578.297 164.545 579.401L166.308 590.031C170.913 588.302 174.422 586.419 175.928 585.555C175.038 583.184 173.797 579.863 172.496 576.38Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[415] ?? 'WHITE']}
        id="415"
        data-testid="back-415"
      />
      <path
        d="M162.884 579.848C160.676 580.405 158.177 580.893 155.447 581.184C155.96 585.189 156.491 589.092 157.03 592.721C159.743 592.182 162.328 591.437 164.682 590.624L162.884 579.848Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[414] ?? 'WHITE']}
        id="414"
        data-testid="back-414"
      />
      <path
        d="M155.334 593.026C154.786 589.354 154.256 585.393 153.734 581.344C152.467 581.438 151.157 581.497 149.814 581.497C149.052 581.497 148.273 581.481 147.486 581.447C147.486 585.213 147.537 588.868 147.674 591.307C147.717 592.06 147.759 592.769 147.879 593.583C150.404 593.634 152.912 593.42 155.334 593.026Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[413] ?? 'WHITE']}
        id="413"
        data-testid="back-413"
      />
      <path
        d="M162.5 567.085L164.263 577.673C168.054 576.569 170.75 575.336 171.897 574.763C170.545 571.151 169.176 567.496 168.123 564.68C167.079 565.262 165.17 566.221 162.5 567.085Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[412] ?? 'WHITE']}
        id="412"
        data-testid="back-412"
      />
      <path
        d="M160.837 567.573C158.86 568.112 156.549 568.567 153.939 568.807C154.341 572.324 154.778 575.919 155.223 579.471C157.927 579.171 160.41 578.684 162.592 578.127L160.837 567.573Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[411] ?? 'WHITE']}
        id="411"
        data-testid="back-411"
      />
      <path
        d="M147.676 568.935C147.591 571.511 147.514 575.585 147.496 579.727C149.593 579.821 151.605 579.787 153.522 579.642C153.077 576.081 152.64 572.469 152.238 568.943C151.511 568.977 150.766 569.003 150.004 569.003C149.242 568.994 148.472 568.977 147.676 568.935Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[410] ?? 'WHITE']}
        id="410"
        data-testid="back-410"
      />
      <path
        d="M162.207 565.347C164.843 564.474 166.658 563.533 167.505 563.053C166.769 561.093 166.281 559.792 166.204 559.613C165.965 559.039 165.4 557.105 164.792 554.443C163.782 554.717 162.336 555.059 160.555 555.402L162.207 565.347Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[409] ?? 'WHITE']}
        id="409"
        data-testid="back-409"
      />
      <path
        d="M160.556 565.853L158.87 555.702C157.021 556.01 154.898 556.284 152.57 556.455C152.921 559.776 153.324 563.379 153.743 567.102C156.328 566.854 158.613 566.392 160.556 565.853Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[408] ?? 'WHITE']}
        id="408"
        data-testid="back-408"
      />
      <path
        d="M147.742 567.222C149.24 567.299 150.669 567.29 152.03 567.222C151.602 563.49 151.208 559.878 150.857 556.557C149.847 556.609 148.812 556.634 147.742 556.634C146.809 556.634 145.85 556.609 144.883 556.566C146.261 559.827 147.964 563.875 147.768 566.854C147.759 566.956 147.75 567.085 147.742 567.222Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[407] ?? 'WHITE']}
        id="407"
        data-testid="back-407"
      />
      <path
        d="M162.626 538.672C162.139 538.869 161.462 539.1 160.641 539.34C160.298 540.958 159.956 542.481 159.622 543.868C159.203 545.579 159.169 547.325 159.502 549.054L160.273 553.702C162.036 553.368 163.448 553.026 164.424 552.769C163.551 548.686 162.703 543.423 162.626 538.672Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[406] ?? 'WHITE']}
        id="406"
        data-testid="back-406"
      />
      <path
        d="M158.587 553.997L157.817 549.351C157.44 547.399 157.483 545.413 157.954 543.461C158.228 542.323 158.51 541.091 158.793 539.791C157.355 540.082 155.651 540.304 153.751 540.304C152.844 540.304 151.894 540.244 150.91 540.133C151.141 542.538 151.672 547.93 152.391 554.734C154.667 554.563 156.764 554.297 158.587 553.997Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[405] ?? 'WHITE']}
        id="405"
        data-testid="back-405"
      />
      <path
        d="M150.669 554.837C149.907 547.614 149.359 541.999 149.154 539.859C147.913 539.628 146.621 539.286 145.303 538.807C145.439 541.16 144.686 542.769 144.019 544.182C143.043 546.253 142.11 548.204 143.616 553.357C143.728 553.742 143.916 554.247 144.147 554.82C146.424 554.94 148.615 554.94 150.669 554.837Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[404] ?? 'WHITE']}
        id="404"
        data-testid="back-404"
      />
      <path
        d="M162.636 536.815C162.704 534.59 162.97 532.544 163.526 530.858C164.125 529.061 164.878 526.057 165.743 522.368C165.144 522.625 164.399 522.916 163.543 523.198C162.73 528.393 161.883 533.221 161.035 537.414C161.84 537.158 162.388 536.927 162.636 536.815Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[403] ?? 'WHITE']}
        id="403"
        data-testid="back-403"
      />
      <path
        d="M159.178 537.926C160.034 533.758 160.907 528.948 161.728 523.719C159.794 524.215 157.457 524.599 154.847 524.599C153.803 524.599 152.724 524.54 151.603 524.403C151.261 530.506 150.987 535.393 150.807 538.38C154.145 538.799 157.064 538.423 159.178 537.926Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[402] ?? 'WHITE']}
        id="402"
        data-testid="back-402"
      />
      <path
        d="M144.234 522.374C144.542 527.851 144.868 532.807 145.167 536.924C146.511 537.455 147.837 537.84 149.104 538.096C149.292 535.075 149.566 530.197 149.9 524.137C148.086 523.794 146.186 523.221 144.234 522.374Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[401] ?? 'WHITE']}
        id="401"
        data-testid="back-401"
      />
      <path
        d="M167.343 515.457C167.719 513.823 168.105 512.119 168.507 510.373C167.582 510.733 166.487 511.101 165.229 511.443C164.784 514.815 164.322 518.111 163.842 521.286C164.972 520.867 165.794 520.473 166.247 520.242C166.598 518.727 166.966 517.118 167.343 515.457Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[400] ?? 'WHITE']}
        id="400"
        data-testid="back-400"
      />
      <path
        d="M162.019 521.886C162.516 518.659 162.995 515.304 163.449 511.863C161.506 512.274 159.289 512.556 156.858 512.556C155.403 512.556 153.871 512.454 152.279 512.214C152.091 515.629 151.911 518.89 151.74 521.911C151.723 522.177 151.714 522.425 151.697 522.682C155.797 523.212 159.383 522.639 162.019 521.886Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[399] ?? 'WHITE']}
        id="399"
        data-testid="back-399"
      />
      <path
        d="M150.002 522.417C150.011 522.22 150.028 522.023 150.036 521.826C150.207 518.736 150.396 515.407 150.593 511.915C148.342 511.444 145.988 510.691 143.574 509.561C143.754 513.327 143.942 516.99 144.131 520.465C146.15 521.415 148.119 522.04 150.002 522.417Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[398] ?? 'WHITE']}
        id="398"
        data-testid="back-398"
      />
      <path
        d="M167.087 495.218C166.616 500.045 166.06 504.89 165.461 509.623C166.907 509.195 168.097 508.733 168.978 508.348C170.176 503.229 171.469 497.906 172.795 493.138C171.443 493.763 169.483 494.551 167.087 495.218Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[397] ?? 'WHITE']}
        id="397"
        data-testid="back-397"
      />
      <path
        d="M163.68 510.086C164.288 505.353 164.844 500.501 165.332 495.656C163.038 496.178 160.436 496.547 157.62 496.547C156.191 496.547 154.719 496.451 153.204 496.237C152.921 501.133 152.647 505.944 152.391 510.515C156.721 511.191 160.616 510.788 163.68 510.086Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[396] ?? 'WHITE']}
        id="396"
        data-testid="back-396"
      />
      <path
        d="M150.687 510.185C150.944 505.631 151.218 500.831 151.5 495.952C148.701 495.396 145.8 494.42 142.873 492.854C143.053 497.801 143.258 502.774 143.481 507.609C145.954 508.85 148.376 509.68 150.687 510.185Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[395] ?? 'WHITE']}
        id="395"
        data-testid="back-395"
      />
      <path
        d="M168.251 480.965C167.994 485.013 167.66 489.181 167.266 493.384C170.271 492.494 172.453 491.424 173.437 490.902C173.959 489.121 174.482 487.444 174.995 485.912C175.808 483.524 176.39 480.254 176.792 476.523C174.978 477.884 172.051 479.707 168.251 480.965Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[394] ?? 'WHITE']}
        id="394"
        data-testid="back-394"
      />
      <path
        d="M165.504 493.864C165.906 489.679 166.24 485.519 166.505 481.488C164.357 482.053 161.977 482.43 159.384 482.43C157.689 482.43 155.909 482.276 154.052 481.925C153.795 486.093 153.538 490.329 153.299 494.523C157.878 495.182 162.089 494.695 165.504 493.864Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[393] ?? 'WHITE']}
        id="393"
        data-testid="back-393"
      />
      <path
        d="M151.603 494.216C151.851 489.997 152.108 485.743 152.365 481.549C149.241 480.779 145.903 479.452 142.402 477.372C142.505 481.746 142.642 486.282 142.805 490.861C145.783 492.573 148.745 493.626 151.603 494.216Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[392] ?? 'WHITE']}
        id="392"
        data-testid="back-392"
      />
      <path
        d="M168.352 479.257C172.743 477.682 175.747 475.312 177.014 474.182C177.339 470.467 177.51 466.402 177.587 462.328C175.859 463.808 172.88 465.931 168.806 467.335C168.755 471.083 168.601 475.089 168.352 479.257Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[391] ?? 'WHITE']}
        id="391"
        data-testid="back-391"
      />
      <path
        d="M166.614 479.814C166.871 475.646 167.034 471.623 167.102 467.858C165.065 468.405 162.814 468.765 160.332 468.765C158.629 468.765 156.831 468.594 154.931 468.226C154.674 472.086 154.409 476.134 154.152 480.251C158.937 481.209 163.131 480.799 166.614 479.814Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[390] ?? 'WHITE']}
        id="390"
        data-testid="back-390"
      />
      <path
        d="M152.466 479.846C152.723 475.729 152.98 471.698 153.245 467.838C149.839 466.973 146.141 465.433 142.188 462.968C142.205 466.802 142.264 470.962 142.358 475.327C145.927 477.578 149.317 479.016 152.466 479.846Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[389] ?? 'WHITE']}
        id="389"
        data-testid="back-389"
      />
      <path
        d="M168.327 452.895C168.704 456.507 168.858 460.846 168.832 465.648C173.565 463.893 176.578 461.086 177.631 459.99C177.665 456.438 177.639 452.938 177.579 449.703C175.979 450.447 172.769 451.731 168.293 452.51C168.302 452.638 168.31 452.775 168.327 452.895Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[388] ?? 'WHITE']}
        id="388"
        data-testid="back-388"
      />
      <path
        d="M167.113 466.203C167.156 461.248 167.01 456.771 166.625 453.074C166.616 452.98 166.608 452.869 166.599 452.774C164.888 453.005 162.962 453.159 160.822 453.159C159.324 453.159 157.732 453.082 156.055 452.911C155.73 456.977 155.387 461.599 155.053 466.554C159.735 467.53 163.783 467.162 167.113 466.203Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[387] ?? 'WHITE']}
        id="387"
        data-testid="back-387"
      />
      <path
        d="M154.343 452.698C150.697 452.185 146.7 451.192 142.463 449.471C142.361 450.653 142.292 451.697 142.267 452.553C142.198 455.052 142.172 457.876 142.172 460.94C146.204 463.577 149.935 465.211 153.35 466.136C153.693 461.257 154.027 456.712 154.343 452.698Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[386] ?? 'WHITE']}
        id="386"
        data-testid="back-386"
      />
      <path
        d="M168.173 450.794C173.163 449.895 176.518 448.339 177.537 447.817C177.391 441.671 177.143 436.784 177.032 434.927C175.388 435.689 172.607 436.775 168.832 437.596C168.199 442.877 167.985 447.216 168.173 450.794Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[385] ?? 'WHITE']}
        id="385"
        data-testid="back-385"
      />
      <path
        d="M158.084 438.726C157.741 438.726 157.39 438.718 157.04 438.709C156.774 443.1 156.492 447.285 156.184 451.188C160.172 451.608 163.656 451.445 166.471 451.06C166.275 447.482 166.471 443.16 167.071 437.939C164.46 438.41 161.456 438.726 158.084 438.726Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[384] ?? 'WHITE']}
        id="384"
        data-testid="back-384"
      />
      <path
        d="M154.478 450.977C154.786 447.117 155.06 442.983 155.326 438.643C151.851 438.472 148.051 437.959 143.942 436.966C143.472 440.552 142.967 444.438 142.633 447.682C146.869 449.453 150.858 450.463 154.478 450.977Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[383] ?? 'WHITE']}
        id="383"
        data-testid="back-383"
      />
      <path
        d="M171.614 420.71C170.492 426.376 169.645 431.357 169.072 435.739C173 434.832 175.713 433.66 177.006 433.035C177.074 430.467 177.399 423.312 178.897 417.047C177.185 418.176 174.746 419.537 171.614 420.71Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[382] ?? 'WHITE']}
        id="382"
        data-testid="back-382"
      />
      <path
        d="M157.843 423.287C157.629 427.994 157.398 432.573 157.141 436.964C161.018 437.015 164.425 436.656 167.3 436.108C167.857 431.794 168.67 426.907 169.748 421.361C166.427 422.414 162.447 423.184 157.843 423.287Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[381] ?? 'WHITE']}
        id="381"
        data-testid="back-381"
      />
      <path
        d="M155.429 436.914C155.678 432.541 155.917 427.979 156.131 423.297C152.751 423.246 149.062 422.827 145.056 421.894C145.125 422.561 145.184 423.075 145.244 423.4C145.475 424.829 145.022 428.681 144.166 435.263C148.223 436.255 151.989 436.752 155.429 436.914Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[380] ?? 'WHITE']}
        id="380"
        data-testid="back-380"
      />
      <path
        d="M173.206 409.166C172.787 413.018 172.368 416.296 171.965 418.752C175.714 417.237 178.282 415.483 179.617 414.439C179.839 413.737 180.079 413.052 180.336 412.41C180.764 411.34 181.269 409.432 181.817 406.924C179.968 407.532 177.041 408.387 173.206 409.166Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[379] ?? 'WHITE']}
        id="379"
        data-testid="back-379"
      />
      <path
        d="M158.357 410.903C158.22 414.507 158.074 418.067 157.92 421.568C162.722 421.448 166.796 420.575 170.117 419.437C170.545 417.066 170.99 413.642 171.443 409.5C167.746 410.167 163.347 410.715 158.357 410.903Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[378] ?? 'WHITE']}
        id="378"
        data-testid="back-378"
      />
      <path
        d="M156.2 421.575C156.354 418.083 156.499 414.54 156.636 410.954C155.977 410.971 155.31 410.979 154.625 410.979C151.33 410.979 147.812 410.808 144.115 410.414C144.406 414.429 144.662 417.758 144.876 420.103C148.993 421.096 152.768 421.533 156.2 421.575Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[377] ?? 'WHITE']}
        id="377"
        data-testid="back-377"
      />
      <path
        d="M184.273 393.948C182.262 394.633 178.898 395.634 174.413 396.516C174.08 400.41 173.746 404.073 173.395 407.377C177.649 406.496 180.678 405.511 182.228 404.963C182.861 401.857 183.554 398.082 184.273 393.948Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[376] ?? 'WHITE']}
        id="376"
        data-testid="back-376"
      />
      <path
        d="M158.424 409.194C163.5 408.998 167.942 408.415 171.631 407.73C171.973 404.443 172.315 400.764 172.649 396.844C168.729 397.537 164.073 398.111 158.809 398.299C158.681 401.954 158.553 405.591 158.424 409.194Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[375] ?? 'WHITE']}
        id="375"
        data-testid="back-375"
      />
      <path
        d="M156.704 409.239C156.84 405.636 156.969 401.99 157.089 398.352C156.378 398.37 155.659 398.378 154.932 398.378C151.277 398.378 147.374 398.173 143.266 397.719C143.514 401.665 143.762 405.371 144.002 408.683C148.538 409.171 152.792 409.325 156.704 409.239Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[374] ?? 'WHITE']}
        id="374"
        data-testid="back-374"
      />
      <path
        d="M175.515 381.966C175.216 386.348 174.89 390.661 174.557 394.753C179.469 393.76 182.927 392.63 184.596 392.022C185.332 387.751 186.085 383.172 186.83 378.619C184.596 379.483 180.839 380.767 175.763 381.914C175.686 381.923 175.601 381.94 175.515 381.966Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[373] ?? 'WHITE']}
        id="373"
        data-testid="back-373"
      />
      <path
        d="M159.237 384.15C159.117 388.276 158.989 392.435 158.852 396.595C164.184 396.389 168.875 395.799 172.786 395.088C173.12 391.014 173.445 386.718 173.745 382.336C169.807 383.132 164.903 383.851 159.237 384.15Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[372] ?? 'WHITE']}
        id="372"
        data-testid="back-372"
      />
      <path
        d="M157.149 396.647C157.286 392.496 157.406 388.345 157.525 384.228C156.259 384.271 154.958 384.305 153.623 384.305C150.105 384.305 146.348 384.116 142.41 383.697C142.659 387.908 142.907 392.059 143.155 396.005C148.162 396.57 152.844 396.732 157.149 396.647Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[371] ?? 'WHITE']}
        id="371"
        data-testid="back-371"
      />
      <path
        d="M187.158 364.026C186.781 368.939 186.25 373.424 185.523 377.276C186.173 377.036 186.721 376.822 187.158 376.642C187.919 372.021 188.664 367.519 189.374 363.359C188.792 363.547 188.048 363.778 187.158 364.026Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[370] ?? 'WHITE']}
        id="370"
        data-testid="back-370"
      />
      <path
        d="M176.457 366.397C176.217 370.933 175.944 375.589 175.635 380.185C178.879 379.449 181.584 378.645 183.655 377.943C184.434 374.091 185.007 369.538 185.401 364.505C183.073 365.104 180.06 365.789 176.457 366.397Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[369] ?? 'WHITE']}
        id="369"
        data-testid="back-369"
      />
      <path
        d="M173.873 380.56C174.181 375.938 174.464 371.248 174.712 366.677C170.407 367.337 165.357 367.867 159.683 368.055C159.554 372.737 159.426 377.547 159.289 382.426C165.023 382.109 169.962 381.373 173.873 380.56Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[368] ?? 'WHITE']}
        id="368"
        data-testid="back-368"
      />
      <path
        d="M157.577 382.504C157.714 377.617 157.851 372.79 157.971 368.099C157.089 368.116 156.19 368.134 155.283 368.134C150.986 368.134 146.373 367.911 141.52 367.415C141.76 372.165 142.034 377.095 142.316 381.973C147.811 382.564 152.929 382.684 157.577 382.504Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[367] ?? 'WHITE']}
        id="367"
        data-testid="back-367"
      />
      <path
        d="M187.808 349.877C187.713 354.165 187.542 358.307 187.285 362.21C188.372 361.894 189.194 361.628 189.708 361.449C190.581 356.424 191.411 352.025 192.147 348.696C190.726 349.124 189.271 349.509 187.808 349.877Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[366] ?? 'WHITE']}
        id="366"
        data-testid="back-366"
      />
      <path
        d="M177.091 352.044C176.954 356.015 176.774 360.269 176.551 364.643C180.232 364.009 183.27 363.308 185.538 362.7C185.812 358.789 185.992 354.62 186.086 350.298C183.176 350.983 180.18 351.556 177.091 352.044Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[365] ?? 'WHITE']}
        id="365"
        data-testid="back-365"
      />
      <path
        d="M160.059 353.86C159.999 356.051 159.939 358.276 159.879 360.536C159.828 362.444 159.777 364.379 159.725 366.338C165.443 366.142 170.51 365.594 174.798 364.926C175.029 360.536 175.217 356.282 175.354 352.31C170.51 353.029 165.434 353.526 160.059 353.86Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[364] ?? 'WHITE']}
        id="364"
        data-testid="back-364"
      />
      <path
        d="M158.015 366.387C158.066 364.392 158.118 362.424 158.169 360.49C158.229 358.273 158.28 356.099 158.34 353.951C152.863 354.259 147.068 354.421 140.906 354.481C141.051 357.99 141.231 361.765 141.428 365.685C147.385 366.31 152.94 366.489 158.015 366.387Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[363] ?? 'WHITE']}
        id="363"
        data-testid="back-363"
      />
      <path
        d="M187.867 334.463C187.919 339.17 187.919 343.835 187.842 348.363C189.434 347.96 190.991 347.515 192.541 347.036C192.695 346.411 192.84 345.821 192.986 345.307C193.867 342.157 194.432 337.63 194.783 332.58C192.523 333.282 190.221 333.907 187.867 334.463Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[362] ?? 'WHITE']}
        id="362"
        data-testid="back-362"
      />
      <path
        d="M177.339 337.525C177.364 341.179 177.287 345.621 177.133 350.551C180.214 350.055 183.21 349.473 186.12 348.78C186.206 344.252 186.214 339.57 186.154 334.854C183.287 335.488 180.343 336.027 177.322 336.481C177.33 336.831 177.339 337.182 177.339 337.525Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[361] ?? 'WHITE']}
        id="361"
        data-testid="back-361"
      />
      <path
        d="M160.102 352.387C165.485 352.044 170.569 351.539 175.414 350.82C175.576 345.754 175.662 341.209 175.636 337.537C175.636 337.272 175.628 336.998 175.628 336.732C170.826 337.4 165.811 337.879 160.504 338.205C160.367 342.612 160.23 347.38 160.102 352.387Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[360] ?? 'WHITE']}
        id="360"
        data-testid="back-360"
      />
      <path
        d="M158.382 352.491C158.519 347.484 158.648 342.717 158.776 338.309C153.016 338.626 146.922 338.789 140.426 338.848C140.451 342.332 140.605 347.27 140.845 353.031C147.059 352.962 152.879 352.799 158.382 352.491Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[359] ?? 'WHITE']}
        id="359"
        data-testid="back-359"
      />
      <path
        d="M114.663 604.534C116.015 604.251 117.008 602.598 117.59 601.263C118.78 598.55 119.439 596.71 119.815 595.281C119.447 595.289 119.071 595.307 118.703 595.307C116.768 595.307 114.851 595.162 112.985 594.905C112.506 598.029 112.027 600.905 111.547 603.395L111.599 603.447C112.027 603.832 113.405 604.799 114.663 604.534Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[358] ?? 'WHITE']}
        id="358"
        data-testid="back-358"
      />
      <path
        d="M106.703 593.651L104.127 601.662L104.349 602.073C104.615 602.552 106.172 604.923 109.656 603.152L109.878 603.04C110.349 600.575 110.829 597.734 111.299 594.644C109.724 594.379 108.184 594.036 106.703 593.651Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[357] ?? 'WHITE']}
        id="357"
        data-testid="back-357"
      />
      <path
        d="M99.2397 600.528C99.2397 600.528 99.3077 601.136 99.7187 601.409C100.181 601.718 101.123 601.658 102.304 601.264L102.484 601.204L105.051 593.201C104.101 592.928 103.177 592.628 102.295 592.328C101.816 593.647 100.815 596.308 99.2997 600.305L99.2227 600.28L99.2397 600.528Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[356] ?? 'WHITE']}
        id="356"
        data-testid="back-356"
      />
      <path
        d="M94.5002 597.256C94.5172 597.684 94.7142 598.771 95.2272 599.113C95.6642 599.404 96.4852 599.173 96.9392 599.002L98.1292 598.566C99.0452 596.144 100.183 593.131 100.688 591.744C99.2162 591.196 97.8552 590.632 96.6222 590.084L94.4062 595.562H94.4312L94.5002 597.256Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[355] ?? 'WHITE']}
        id="355"
        data-testid="back-355"
      />
      <path
        d="M92.5554 595.562L95.0634 589.356C93.5914 588.655 92.3764 588.013 91.4774 587.525C90.5874 590.598 90.1084 593.756 90.4764 594.492C91.1434 595.373 92.1534 595.536 92.5554 595.562Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[354] ?? 'WHITE']}
        id="354"
        data-testid="back-354"
      />
      <path
        d="M120.577 581.397C119.567 581.456 118.566 581.491 117.599 581.491C116.666 581.491 115.758 581.465 114.86 581.414C114.338 585.505 113.79 589.502 113.242 593.199C115.51 593.507 117.838 593.653 120.175 593.559C120.295 592.754 120.337 592.052 120.38 591.308C120.526 588.851 120.577 585.18 120.577 581.397Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[353] ?? 'WHITE']}
        id="353"
        data-testid="back-353"
      />
      <path
        d="M113.156 581.303C110.092 581.037 107.293 580.532 104.845 579.925L102.766 590.632C105.445 591.556 108.432 592.395 111.564 592.934C112.095 589.288 112.634 585.342 113.156 581.303Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[352] ?? 'WHITE']}
        id="352"
        data-testid="back-352"
      />
      <path
        d="M103.184 579.488C99.6494 578.495 96.9794 577.365 95.4644 576.638C94.2834 579.804 93.0854 583.006 92.0234 585.856C93.7444 586.806 96.9884 588.483 101.13 590.041L103.184 579.488Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[351] ?? 'WHITE']}
        id="351"
        data-testid="back-351"
      />
      <path
        d="M120.569 579.695C120.551 575.535 120.474 571.453 120.389 568.885C119.37 568.953 118.369 568.997 117.41 568.997C117.051 568.997 116.717 568.98 116.366 568.971C115.955 572.506 115.527 576.135 115.074 579.713C116.837 579.799 118.677 579.806 120.569 579.695Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[350] ?? 'WHITE']}
        id="350"
        data-testid="back-350"
      />
      <path
        d="M107.217 567.746L105.18 578.222C107.594 578.821 110.358 579.335 113.371 579.592C113.816 576.031 114.253 572.428 114.655 568.901C111.839 568.722 109.348 568.285 107.217 567.746Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[349] ?? 'WHITE']}
        id="349"
        data-testid="back-349"
      />
      <path
        d="M103.52 577.775L105.565 567.282C102.981 566.503 101.038 565.613 99.839 564.971C98.804 567.735 97.469 571.305 96.082 575.028C97.469 575.687 100.062 576.791 103.52 577.775Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[348] ?? 'WHITE']}
        id="348"
        data-testid="back-348"
      />
      <path
        d="M123.197 556.531C121.999 556.599 120.818 556.633 119.671 556.633C119.02 556.633 118.378 556.624 117.745 556.607C117.394 559.928 116.992 563.531 116.572 567.263C117.771 567.288 119.02 567.263 120.321 567.177C120.313 567.057 120.304 566.947 120.304 566.844C120.099 563.866 121.81 559.8 123.197 556.531Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[347] ?? 'WHITE']}
        id="347"
        data-testid="back-347"
      />
      <path
        d="M107.551 566.033C109.64 566.573 112.087 567.009 114.861 567.189C115.288 563.457 115.682 559.854 116.042 556.524C113.662 556.396 111.471 556.148 109.537 555.857L107.551 566.033Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[346] ?? 'WHITE']}
        id="346"
        data-testid="back-346"
      />
      <path
        d="M103.169 554.589C102.655 557.08 102.159 558.937 101.851 559.648C101.765 559.853 101.243 561.24 100.447 563.354C101.525 563.927 103.374 564.8 105.899 565.579L107.842 555.599C105.916 555.248 104.333 554.88 103.169 554.589Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[345] ?? 'WHITE']}
        id="345"
        data-testid="back-345"
      />
      <path
        d="M122.768 538.62C122.768 538.602 122.768 538.577 122.777 538.56C121.655 539.005 120.551 539.347 119.481 539.604C119.302 541.512 118.737 547.324 117.941 554.89C119.858 554.95 121.869 554.916 123.941 554.77C124.163 554.214 124.343 553.726 124.454 553.349C125.96 548.206 125.036 546.246 124.052 544.174C123.35 542.728 122.571 541.076 122.768 538.62Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[344] ?? 'WHITE']}
        id="344"
        data-testid="back-344"
      />
      <path
        d="M113.661 540.295C112.283 540.295 111.008 540.184 109.861 540.004C110.126 541.22 110.391 542.384 110.648 543.453C111.119 545.405 111.17 547.399 110.785 549.368L109.852 554.152C111.744 554.434 113.892 554.674 116.211 554.803C116.956 547.793 117.495 542.272 117.718 539.953C116.297 540.192 114.936 540.295 113.661 540.295Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[343] ?? 'WHITE']}
        id="343"
        data-testid="back-343"
      />
      <path
        d="M103.494 552.895C104.641 553.186 106.233 553.546 108.167 553.888L109.108 549.052C109.442 547.323 109.408 545.577 108.989 543.874C108.672 542.582 108.355 541.169 108.038 539.672C106.755 539.381 105.728 539.038 105.009 538.764C104.949 543.54 104.255 548.804 103.494 552.895Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[342] ?? 'WHITE']}
        id="342"
        data-testid="back-342"
      />
      <path
        d="M119.482 537.82C120.603 537.529 121.75 537.153 122.914 536.648C123.222 532.514 123.539 527.542 123.847 522.073C122.083 522.894 120.355 523.476 118.686 523.879C119.019 529.887 119.293 534.739 119.482 537.82Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[341] ?? 'WHITE']}
        id="341"
        data-testid="back-341"
      />
      <path
        d="M112.558 524.608C110.478 524.608 108.578 524.36 106.918 524.009C107.748 529.23 108.613 534.032 109.477 538.174C111.719 538.576 114.595 538.756 117.788 538.191C117.599 535.161 117.326 530.283 116.992 524.223C115.451 524.488 113.962 524.608 112.558 524.608Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[340] ?? 'WHITE']}
        id="340"
        data-testid="back-340"
      />
      <path
        d="M102.15 522.573C102.903 525.919 103.571 528.667 104.102 530.37C104.667 532.184 104.932 534.444 105 536.909C105.48 537.106 106.395 537.456 107.654 537.781C106.798 533.595 105.942 528.786 105.129 523.583C103.956 523.257 102.955 522.898 102.15 522.573Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[339] ?? 'WHITE']}
        id="339"
        data-testid="back-339"
      />
      <path
        d="M118.575 521.817C118.583 521.929 118.583 522.031 118.592 522.143C120.321 521.706 122.118 521.047 123.958 520.123C124.147 516.648 124.335 512.985 124.515 509.227C122.289 510.331 120.115 511.11 118.01 511.632C118.207 515.227 118.395 518.651 118.575 521.817Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[338] ?? 'WHITE']}
        id="338"
        data-testid="back-338"
      />
      <path
        d="M105.188 512.096C105.65 515.571 106.138 518.96 106.643 522.213C109.39 522.855 112.941 523.214 116.896 522.512C116.887 522.316 116.87 522.119 116.861 521.922C116.69 518.841 116.502 515.511 116.314 512.019C114.302 512.404 112.368 512.558 110.553 512.558C108.611 512.558 106.822 512.379 105.188 512.096Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[337] ?? 'WHITE']}
        id="337"
        data-testid="back-337"
      />
      <path
        d="M99.4961 510.599C99.9331 512.542 100.352 514.442 100.746 516.257C101.071 517.729 101.379 519.15 101.679 520.502C102.381 520.836 103.451 521.289 104.829 521.717C104.341 518.508 103.861 515.17 103.416 511.755C101.91 511.412 100.6 511.002 99.4961 510.599Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[336] ?? 'WHITE']}
        id="336"
        data-testid="back-336"
      />
      <path
        d="M117.907 509.895C120.073 509.33 122.315 508.482 124.6 507.259C124.823 502.414 125.028 497.441 125.208 492.477C122.469 494.026 119.739 495.053 117.094 495.687C117.377 500.557 117.65 505.341 117.907 509.895Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[335] ?? 'WHITE']}
        id="335"
        data-testid="back-335"
      />
      <path
        d="M109.791 496.542C107.463 496.542 105.281 496.285 103.295 495.909C103.775 500.744 104.339 505.597 104.947 510.322C108.097 510.912 111.957 511.135 116.202 510.288C115.945 505.726 115.671 500.924 115.389 496.037C113.463 496.388 111.589 496.542 109.791 496.542Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[334] ?? 'WHITE']}
        id="334"
        data-testid="back-334"
      />
      <path
        d="M103.186 509.941C102.578 505.216 102.031 500.363 101.551 495.536C99.0177 494.92 96.8947 494.133 95.3457 493.456C96.6467 498.232 97.8967 503.505 99.0427 508.597C100.122 509.042 101.517 509.53 103.186 509.941Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[333] ?? 'WHITE']}
        id="333"
        data-testid="back-333"
      />
      <path
        d="M116.991 493.948C119.695 493.272 122.486 492.151 125.276 490.447C125.438 485.86 125.567 481.323 125.67 476.958C122.366 479.003 119.199 480.374 116.221 481.229C116.486 485.44 116.743 489.711 116.991 493.948Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[332] ?? 'WHITE']}
        id="332"
        data-testid="back-332"
      />
      <path
        d="M108.029 482.421C105.915 482.421 103.938 482.173 102.123 481.779C102.389 485.81 102.731 489.961 103.133 494.138C106.617 494.857 110.811 495.156 115.304 494.318C115.056 490.107 114.808 485.861 114.542 481.676C112.249 482.198 110.075 482.421 108.029 482.421Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[331] ?? 'WHITE']}
        id="331"
        data-testid="back-331"
      />
      <path
        d="M94.747 491.295C96.099 491.963 98.41 492.964 101.38 493.734C100.986 489.54 100.644 485.389 100.387 481.35C96.459 480.237 93.378 478.474 91.332 477.044C91.734 480.571 92.299 483.652 93.078 485.929C93.634 487.572 94.191 489.386 94.747 491.295Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[330] ?? 'WHITE']}
        id="330"
        data-testid="back-330"
      />
      <path
        d="M116.117 479.503C119.13 478.579 122.339 477.098 125.72 474.89C125.814 470.525 125.866 466.365 125.883 462.548C122.134 464.962 118.616 466.554 115.338 467.512C115.604 471.364 115.86 475.395 116.117 479.503Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[329] ?? 'WHITE']}
        id="329"
        data-testid="back-329"
      />
      <path
        d="M107.081 468.755C105.078 468.755 103.23 468.514 101.518 468.138C101.586 471.912 101.757 475.943 102.014 480.112C105.54 480.925 109.717 481.113 114.433 479.966C114.176 475.849 113.92 471.809 113.654 467.958C111.326 468.514 109.135 468.755 107.081 468.755Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[328] ?? 'WHITE']}
        id="328"
        data-testid="back-328"
      />
      <path
        d="M100.275 479.658C100.027 475.489 99.8646 471.467 99.7956 467.701C95.6106 466.485 92.4526 464.448 90.4746 462.865C90.5606 466.973 90.7576 471.064 91.0916 474.77C92.7856 476.157 95.9356 478.331 100.275 479.658Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[327] ?? 'WHITE']}
        id="327"
        data-testid="back-327"
      />
      <path
        d="M115.227 465.807C118.505 464.78 122.074 463.077 125.882 460.492C125.882 457.608 125.848 454.929 125.788 452.549C125.763 451.634 125.686 450.487 125.566 449.203C121.543 450.889 117.743 451.925 114.234 452.507C114.568 456.478 114.893 460.98 115.227 465.807Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[326] ?? 'WHITE']}
        id="326"
        data-testid="back-326"
      />
      <path
        d="M106.591 453.157C104.931 453.157 103.399 453.063 101.995 452.909C101.987 452.96 101.987 453.02 101.978 453.072C101.584 456.846 101.439 461.434 101.49 466.509C104.871 467.288 108.911 467.45 113.541 466.278C113.207 461.365 112.874 456.786 112.548 452.763C110.434 453.037 108.44 453.157 106.591 453.157Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[325] ?? 'WHITE']}
        id="325"
        data-testid="back-325"
      />
      <path
        d="M90.4419 460.641C91.9909 462.096 95.1659 464.62 99.7799 466.058C99.7369 461.094 99.8909 456.609 100.276 452.895C100.285 452.835 100.285 452.766 100.293 452.698C100.165 452.681 100.028 452.664 99.9079 452.646C95.5769 451.979 92.3419 450.806 90.4759 449.993C90.4159 453.34 90.3989 456.977 90.4419 460.641Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[324] ?? 'WHITE']}
        id="324"
        data-testid="back-324"
      />
      <path
        d="M114.108 450.797C117.625 450.207 121.417 449.137 125.397 447.416C125.063 444.215 124.567 440.424 124.113 436.915C124.113 436.88 124.104 436.855 124.104 436.821C120.227 437.805 116.607 438.353 113.277 438.584C113.534 442.872 113.808 446.963 114.108 450.797Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[323] ?? 'WHITE']}
        id="323"
        data-testid="back-323"
      />
      <path
        d="M112.413 451.047C112.105 447.169 111.831 443.027 111.565 438.679C110.804 438.713 110.059 438.73 109.332 438.73C106.464 438.73 103.871 438.499 101.551 438.148C102.142 443.326 102.33 447.623 102.125 451.175C105.146 451.5 108.604 451.543 112.413 451.047Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[322] ?? 'WHITE']}
        id="322"
        data-testid="back-322"
      />
      <path
        d="M100.431 450.961C100.628 447.409 100.423 443.095 99.8055 437.848C95.8775 437.104 92.8985 436.034 91.0155 435.212C90.9045 437.292 90.6565 442.128 90.5195 448.128C92.1115 448.864 95.5775 450.242 100.431 450.961Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[321] ?? 'WHITE']}
        id="321"
        data-testid="back-321"
      />
      <path
        d="M123.025 421.737C119.216 422.67 115.698 423.132 112.48 423.261C112.694 427.934 112.925 432.479 113.174 436.844C116.469 436.621 120.046 436.091 123.881 435.106C123.042 428.619 122.597 424.81 122.828 423.389C122.879 423.047 122.948 422.482 123.025 421.737Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[320] ?? 'WHITE']}
        id="320"
        data-testid="back-320"
      />
      <path
        d="M98.9414 421.781C99.9944 427.241 100.79 432.069 101.338 436.322C104.248 436.793 107.646 437.076 111.472 436.939C111.223 432.565 110.984 428.003 110.77 423.312C110.684 423.312 110.59 423.321 110.504 423.321C105.968 423.313 102.1 422.654 98.9414 421.781Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[319] ?? 'WHITE']}
        id="319"
        data-testid="back-319"
      />
      <path
        d="M89.2949 417.579C90.7499 423.99 91.0239 431.154 91.0749 433.345C92.7009 434.081 95.6029 435.202 99.5739 436.007C99.0089 431.693 98.1869 426.789 97.0919 421.225C96.6119 421.063 96.1499 420.9 95.7049 420.737C93.0519 419.736 90.9119 418.598 89.2949 417.579Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[318] ?? 'WHITE']}
        id="318"
        data-testid="back-318"
      />
      <path
        d="M111.967 410.973C112.104 414.542 112.25 418.077 112.404 421.552C115.699 421.423 119.302 420.918 123.197 419.925C123.411 417.58 123.659 414.285 123.95 410.339C120.013 410.785 116.273 410.981 112.781 410.981C112.507 410.981 112.241 410.973 111.967 410.973Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[317] ?? 'WHITE']}
        id="317"
        data-testid="back-317"
      />
      <path
        d="M110.692 421.585C110.538 418.093 110.392 414.532 110.255 410.938C105.325 410.809 100.935 410.33 97.1953 409.705C97.6653 413.993 98.1363 417.494 98.5733 419.839C101.928 420.875 105.976 421.594 110.692 421.585Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[316] ?? 'WHITE']}
        id="316"
        data-testid="back-316"
      />
      <path
        d="M88.6436 415.061C90.2956 416.242 93.0086 417.886 96.7226 419.238C96.3036 416.782 95.8666 413.409 95.4306 409.404C91.4676 408.668 88.3776 407.803 86.3066 407.144C86.8376 409.541 87.3166 411.364 87.7366 412.399C88.0696 413.238 88.3696 414.137 88.6436 415.061Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[315] ?? 'WHITE']}
        id="315"
        data-testid="back-315"
      />
      <path
        d="M112.404 398.381C112.105 398.381 111.822 398.373 111.523 398.373C111.643 402.019 111.771 405.656 111.908 409.259C115.683 409.294 119.748 409.114 124.079 408.609C124.31 405.297 124.558 401.591 124.815 397.645C120.398 398.15 116.239 398.381 112.404 398.381Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[314] ?? 'WHITE']}
        id="314"
        data-testid="back-314"
      />
      <path
        d="M110.187 409.225C110.05 405.622 109.922 401.984 109.802 398.338C104.427 398.21 99.7615 397.679 95.9785 397.063C96.3125 400.983 96.6545 404.655 96.9975 407.933C100.738 408.583 105.172 409.097 110.187 409.225Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[313] ?? 'WHITE']}
        id="313"
        data-testid="back-313"
      />
      <path
        d="M94.2148 396.773C94.0528 396.747 93.8728 396.713 93.7188 396.688C89.3448 395.857 86.0068 394.907 83.8418 394.197C84.5608 398.339 85.2538 402.122 85.8958 405.221C87.7528 405.854 90.9548 406.804 95.2428 407.634C94.8918 404.331 94.5488 400.667 94.2148 396.773Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[312] ?? 'WHITE']}
        id="312"
        data-testid="back-312"
      />
      <path
        d="M113.789 384.291C112.873 384.291 111.966 384.274 111.084 384.257C111.204 388.365 111.332 392.508 111.461 396.65C115.62 396.693 120.122 396.479 124.915 395.914C125.164 391.969 125.42 387.826 125.66 383.615C121.483 384.086 117.503 384.291 113.789 384.291Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[311] ?? 'WHITE']}
        id="311"
        data-testid="back-311"
      />
      <path
        d="M109.75 396.63C109.613 392.479 109.493 388.319 109.373 384.203C103.785 383.972 98.889 383.321 94.875 382.568C95.174 386.941 95.499 391.238 95.833 395.295C99.787 395.971 104.478 396.493 109.75 396.63Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[310] ?? 'WHITE']}
        id="310"
        data-testid="back-310"
      />
      <path
        d="M94.0684 394.986C93.7344 390.903 93.4094 386.606 93.1104 382.224C92.6054 382.122 92.1174 382.019 91.6464 381.908C87.0584 380.881 83.5584 379.734 81.2734 378.878C82.0174 383.431 82.7794 388.002 83.5064 392.264C85.5264 392.957 89.1474 394.061 94.0684 394.986Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[309] ?? 'WHITE']}
        id="309"
        data-testid="back-309"
      />
      <path
        d="M110.631 368.118C110.759 372.817 110.887 377.653 111.024 382.54C115.569 382.66 120.499 382.497 125.754 381.89C126.037 377.011 126.302 372.09 126.55 367.339C121.389 367.887 116.519 368.135 112.043 368.135C111.564 368.135 111.101 368.127 110.631 368.118Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[308] ?? 'WHITE']}
        id="308"
        data-testid="back-308"
      />
      <path
        d="M108.919 368.101C103.116 367.964 98.0574 367.468 93.8984 366.877C94.1464 371.456 94.4284 376.155 94.7364 380.777C98.9134 381.581 103.809 382.24 109.304 382.48C109.175 377.601 109.047 372.783 108.919 368.101Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[307] ?? 'WHITE']}
        id="307"
        data-testid="back-307"
      />
      <path
        d="M92.154 366.611C91.718 366.542 91.264 366.474 90.853 366.405C87.943 365.909 85.444 365.378 83.373 364.865C83.767 369.914 84.3321 374.485 85.1021 378.362C87.2331 379.029 89.878 379.756 92.985 380.415C92.677 375.819 92.403 371.164 92.154 366.611Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[306] ?? 'WHITE']}
        id="306"
        data-testid="back-306"
      />
      <path
        d="M83.2428 377.755C82.5238 373.869 81.9928 369.367 81.6158 364.42C80.4688 364.112 79.4938 363.83 78.7148 363.59C79.4338 367.767 80.1778 372.286 80.9398 376.916C81.5738 377.156 82.3438 377.447 83.2428 377.755Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[305] ?? 'WHITE']}
        id="305"
        data-testid="back-305"
      />
      <path
        d="M110.254 353.816C110.314 355.99 110.374 358.207 110.425 360.458C110.477 362.409 110.528 364.404 110.579 366.415C115.518 366.475 120.893 366.252 126.627 365.628C126.824 361.725 127.004 357.967 127.149 354.475C121.133 354.381 115.509 354.167 110.254 353.816Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[304] ?? 'WHITE']}
        id="304"
        data-testid="back-304"
      />
      <path
        d="M93.8132 365.114C98.1442 365.747 103.202 366.235 108.877 366.372C108.826 364.386 108.774 362.426 108.723 360.492C108.663 358.19 108.603 355.913 108.543 353.688C103.074 353.285 97.9992 352.738 93.2402 352.036C93.3772 356.144 93.5742 360.561 93.8132 365.114Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[303] ?? 'WHITE']}
        id="303"
        data-testid="back-303"
      />
      <path
        d="M91.5019 351.779C88.4299 351.292 85.4939 350.735 82.6699 350.11C82.7719 354.63 82.9609 358.969 83.2429 363.051C85.5879 363.642 88.5579 364.275 92.0669 364.849C91.8359 360.304 91.6389 355.888 91.5019 351.779Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[302] ?? 'WHITE']}
        id="302"
        data-testid="back-302"
      />
      <path
        d="M78.3898 361.68C79.1348 361.92 80.1788 362.236 81.4968 362.596C81.2228 358.522 81.0438 354.2 80.9488 349.715C79.2118 349.304 77.4998 348.876 75.8398 348.405C76.6188 351.82 77.4748 356.416 78.3898 361.68Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[301] ?? 'WHITE']}
        id="301"
        data-testid="back-301"
      />
      <path
        d="M109.818 338.163C109.955 342.562 110.083 347.338 110.212 352.345C115.492 352.696 121.141 352.919 127.21 353.004C127.449 347.244 127.595 342.314 127.62 338.831C121.27 338.745 115.347 338.523 109.818 338.163Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[300] ?? 'WHITE']}
        id="300"
        data-testid="back-300"
      />
      <path
        d="M92.9907 336.458C92.9817 336.817 92.9817 337.194 92.9737 337.545C92.9477 341.148 93.0247 345.599 93.1877 350.554C97.9457 351.265 103.03 351.821 108.508 352.223C108.371 347.216 108.243 342.441 108.106 338.041C102.722 337.656 97.6977 337.125 92.9907 336.458Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[299] ?? 'WHITE']}
        id="299"
        data-testid="back-299"
      />
      <path
        d="M91.2629 337.528C91.2629 337.092 91.2709 336.646 91.2799 336.21C88.2669 335.748 85.3829 335.226 82.5919 334.635C82.5409 339.368 82.5499 344.058 82.6349 348.603C85.4509 349.237 88.3869 349.793 91.4509 350.281C91.3049 345.471 91.2369 341.114 91.2629 337.528Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[298] ?? 'WHITE']}
        id="298"
        data-testid="back-298"
      />
      <path
        d="M80.8897 334.268C78.2537 333.677 75.7207 333.027 73.2637 332.316C73.6147 337.469 74.1797 342.108 75.0787 345.309C75.1987 345.745 75.3267 346.242 75.4547 346.764C77.2267 347.277 79.0497 347.748 80.9237 348.201C80.8467 343.665 80.8387 338.984 80.8897 334.268Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[297] ?? 'WHITE']}
        id="297"
        data-testid="back-297"
      />
      <path
        d="M195.322 319.406C192.798 320.202 190.221 320.903 187.594 321.52C187.714 325.286 187.799 329.103 187.859 332.903C190.256 332.33 192.592 331.679 194.903 330.96C195.117 327.228 195.245 323.266 195.322 319.406Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[296] ?? 'WHITE']}
        id="296"
        data-testid="back-296"
      />
      <path
        d="M176.902 323.54C177.099 327.588 177.244 331.414 177.304 334.949C180.334 334.486 183.278 333.947 186.146 333.305C186.094 329.497 186 325.679 185.889 321.896C182.97 322.547 179.983 323.086 176.902 323.54Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[295] ?? 'WHITE']}
        id="295"
        data-testid="back-295"
      />
      <path
        d="M160.804 325.186C160.77 328.507 160.71 331.605 160.616 334.361C160.59 335.123 160.564 335.911 160.539 336.698C165.828 336.364 170.818 335.885 175.594 335.217C175.534 331.674 175.388 327.848 175.191 323.791C170.621 324.416 165.845 324.87 160.804 325.186Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[294] ?? 'WHITE']}
        id="294"
        data-testid="back-294"
      />
      <path
        d="M158.904 334.307C158.99 331.594 159.05 328.555 159.084 325.286C155.018 325.517 150.79 325.662 146.365 325.757L146.408 337.251C150.722 337.166 154.856 337.02 158.827 336.798C158.853 335.95 158.878 335.111 158.904 334.307Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[293] ?? 'WHITE']}
        id="293"
        data-testid="back-293"
      />
      <path
        d="M139.592 333.636C139.763 332.857 139.617 332.086 139.164 331.402C138.633 330.604 138.722 330.013 137.662 329.575C136.583 329.13 135.373 328.844 134.324 328.663L134.033 328.612L133.973 325.881C137.662 325.881 141.213 325.856 144.653 325.796L144.696 337.29C143.292 337.307 140.85 337.325 139.403 337.342C139.412 335.459 139.472 334.166 139.592 333.636Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[292] ?? 'WHITE']}
        id="292"
        data-testid="back-292"
      />
      <path
        d="M123.941 325.77C127.168 325.847 130.505 325.881 133.972 325.881L134.032 328.612L133.741 328.663C132.692 328.844 131.482 329.13 130.402 329.575C129.342 330.013 128.933 330.604 128.402 331.402V337.325C127.144 337.307 125.122 337.29 123.898 337.265L123.941 325.77Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[291] ?? 'WHITE']}
        id="291"
        data-testid="back-291"
      />
      <path
        d="M109.518 325.135C109.552 328.456 109.612 331.555 109.707 334.302C109.732 335.064 109.758 335.852 109.784 336.639C113.712 336.896 117.855 337.084 122.203 337.204L122.245 325.709C117.778 325.598 113.541 325.409 109.518 325.135Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[290] ?? 'WHITE']}
        id="290"
        data-testid="back-290"
      />
      <path
        d="M107.808 325.014C102.698 324.638 97.9218 324.141 93.4198 323.517C93.2238 327.565 93.0778 331.399 93.0098 334.934C97.6998 335.61 102.69 336.132 108.056 336.526C108.03 335.799 108.013 335.071 107.988 334.361C107.902 331.553 107.842 328.404 107.808 325.014Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[289] ?? 'WHITE']}
        id="289"
        data-testid="back-289"
      />
      <path
        d="M91.7164 323.271C88.6354 322.809 85.6904 322.277 82.8494 321.688C82.7384 325.461 82.6614 329.287 82.6094 333.096C85.3994 333.686 88.2844 334.227 91.3054 334.689C91.3734 331.145 91.5194 327.32 91.7164 323.271Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[288] ?? 'WHITE']}
        id="288"
        data-testid="back-288"
      />
      <path
        d="M80.9082 332.736C80.9592 328.928 81.0452 325.102 81.1472 321.327C78.2372 320.685 75.4392 319.958 72.7422 319.17C72.8112 323.022 72.9392 326.976 73.1622 330.725C75.6532 331.453 78.2292 332.129 80.9082 332.736Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[287] ?? 'WHITE']}
        id="287"
        data-testid="back-287"
      />
      <path
        d="M187.54 319.983C190.202 319.35 192.796 318.631 195.346 317.818C195.406 314.232 195.415 310.783 195.432 307.736C195.44 306.341 195.44 305.048 195.457 303.867C192.702 304.689 189.877 305.399 186.984 306.024C187.19 310.509 187.386 315.199 187.54 319.983Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[286] ?? 'WHITE']}
        id="286"
        data-testid="back-286"
      />
      <path
        d="M185.283 306.384C182.27 306.992 179.172 307.506 175.979 307.934C176.305 312.829 176.587 317.554 176.818 322.039C179.908 321.576 182.912 321.029 185.831 320.387C185.685 315.585 185.497 310.878 185.283 306.384Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[285] ?? 'WHITE']}
        id="285"
        data-testid="back-285"
      />
      <path
        d="M160.839 309.36C160.856 314.41 160.856 319.246 160.812 323.671C165.819 323.354 170.57 322.901 175.115 322.276C174.884 317.791 174.592 313.058 174.275 308.154C169.987 308.684 165.521 309.078 160.839 309.36Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[284] ?? 'WHITE']}
        id="284"
        data-testid="back-284"
      />
      <path
        d="M159.099 323.775C155.025 324.006 150.789 324.16 146.355 324.246L146.305 309.91C150.764 309.824 155.025 309.679 159.125 309.456C159.151 314.514 159.142 319.35 159.099 323.775Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[283] ?? 'WHITE']}
        id="283"
        data-testid="back-283"
      />
      <path
        d="M144.654 324.279L144.603 309.943C141.171 309.994 137.644 310.02 133.981 310.02C130.54 310.02 127.22 309.986 124.019 309.917L123.967 324.245C127.185 324.313 130.523 324.356 133.99 324.356C137.661 324.365 141.213 324.339 144.654 324.279Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[282] ?? 'WHITE']}
        id="282"
        data-testid="back-282"
      />
      <path
        d="M122.296 309.88L122.245 324.208C117.768 324.088 113.533 323.9 109.501 323.626C109.459 319.201 109.458 314.365 109.475 309.315C113.532 309.572 117.811 309.761 122.296 309.88Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[281] ?? 'WHITE']}
        id="281"
        data-testid="back-281"
      />
      <path
        d="M107.765 309.197C103.032 308.863 98.564 308.427 94.345 307.879C94.02 312.783 93.729 317.516 93.498 322.001C97.965 322.635 102.716 323.131 107.791 323.499C107.748 319.074 107.748 314.238 107.765 309.197Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[280] ?? 'WHITE']}
        id="280"
        data-testid="back-280"
      />
      <path
        d="M91.7936 321.754C92.0246 317.269 92.3156 312.536 92.6406 307.649C89.4396 307.204 86.3676 306.699 83.4236 306.125C83.2266 310.636 83.0376 315.343 82.8926 320.153C85.7426 320.752 88.7036 321.292 91.7936 321.754Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[279] ?? 'WHITE']}
        id="279"
        data-testid="back-279"
      />
      <path
        d="M72.7163 317.584C75.4383 318.397 78.2533 319.133 81.1983 319.792C81.3433 314.99 81.5313 310.283 81.7283 305.781C78.5703 305.13 75.5323 304.403 72.6133 303.581C72.6223 304.839 72.6303 306.226 72.6393 307.732C72.6473 310.711 72.6643 314.083 72.7163 317.584Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[278] ?? 'WHITE']}
        id="278"
        data-testid="back-278"
      />
      <path
        d="M186.045 288.101C186.319 292.937 186.618 298.483 186.909 304.448C189.828 303.806 192.678 303.079 195.468 302.232C195.502 299.775 195.554 297.978 195.691 297.045C195.956 295.205 194.912 290.121 193.773 285.302C191.248 286.355 188.681 287.296 186.045 288.101Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[277] ?? 'WHITE']}
        id="277"
        data-testid="back-277"
      />
      <path
        d="M174.824 290.833C174.961 292.878 175.098 294.907 175.235 296.909C175.458 300.11 175.672 303.269 175.877 306.376C179.078 305.939 182.185 305.425 185.206 304.809C184.924 298.904 184.624 293.4 184.35 288.59C181.269 289.48 178.102 290.225 174.824 290.833Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[276] ?? 'WHITE']}
        id="276"
        data-testid="back-276"
      />
      <path
        d="M160.839 307.81C165.487 307.528 169.92 307.134 174.182 306.595C173.977 303.463 173.755 300.261 173.532 297.026C173.395 295.083 173.267 293.106 173.13 291.129C169.158 291.814 165.042 292.336 160.719 292.704C160.771 297.839 160.813 302.923 160.839 307.81Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[275] ?? 'WHITE']}
        id="275"
        data-testid="back-275"
      />
      <path
        d="M159.015 292.85C154.941 293.167 150.696 293.372 146.254 293.492L146.305 308.359C150.764 308.273 155.026 308.128 159.126 307.905C159.1 303.035 159.058 297.96 159.015 292.85Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[274] ?? 'WHITE']}
        id="274"
        data-testid="back-274"
      />
      <path
        d="M144.593 308.393L144.542 293.535C141.135 293.612 137.626 293.646 133.98 293.646C130.548 293.646 127.253 293.594 124.069 293.5L124.018 308.367C127.219 308.436 130.539 308.47 133.98 308.47C137.635 308.478 141.17 308.453 144.593 308.393Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[273] ?? 'WHITE']}
        id="273"
        data-testid="back-273"
      />
      <path
        d="M122.356 293.441C117.88 293.278 113.635 293.014 109.595 292.646C109.544 297.772 109.501 302.864 109.484 307.76C113.541 308.025 117.812 308.206 122.305 308.326L122.356 293.441Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[272] ?? 'WHITE']}
        id="272"
        data-testid="back-272"
      />
      <path
        d="M107.773 307.639C107.799 302.726 107.84 297.625 107.883 292.481C103.527 292.045 99.4015 291.488 95.4985 290.795C95.3535 292.892 95.2165 294.972 95.0715 297.026C94.8575 300.167 94.6435 303.274 94.4375 306.33C98.6315 306.877 103.074 307.305 107.773 307.639Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[271] ?? 'WHITE']}
        id="271"
        data-testid="back-271"
      />
      <path
        d="M92.7452 306.098C92.9412 303.085 93.1552 300.012 93.3692 296.905C93.5152 294.783 93.6602 292.634 93.8062 290.469C90.4942 289.836 87.3352 289.108 84.3052 288.278C84.0492 293.114 83.7582 298.634 83.4922 304.557C86.4452 305.139 89.5262 305.653 92.7452 306.098Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[270] ?? 'WHITE']}
        id="270"
        data-testid="back-270"
      />
      <path
        d="M82.6192 287.798C79.7602 286.959 77.0212 286.035 74.3682 285.008C73.2042 289.912 72.1082 295.184 72.3742 297.059C72.5022 297.957 72.5622 299.652 72.5962 301.963C75.5402 302.802 78.6052 303.546 81.7972 304.214C82.0622 298.223 82.3532 292.651 82.6192 287.798Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[269] ?? 'WHITE']}
        id="269"
        data-testid="back-269"
      />
      <path
        d="M190.761 272.352C190.77 271.967 190.753 270.957 190.71 269.528C188.921 270.264 187.106 270.94 185.266 271.548C185.266 271.65 185.266 271.77 185.266 271.873C185.258 274.107 185.54 279.156 185.934 286.149C185.934 286.209 185.942 286.269 185.942 286.329C188.467 285.541 190.941 284.634 193.363 283.616C192.841 281.476 192.327 279.464 191.934 277.95C190.804 273.525 190.65 272.883 190.761 272.352Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[268] ?? 'WHITE']}
        id="268"
        data-testid="back-268"
      />
      <path
        d="M174.713 289.118C178 288.493 181.175 287.74 184.265 286.841C184.257 286.644 184.239 286.448 184.231 286.259C183.846 279.463 183.572 274.499 183.563 272.12C180.405 273.096 177.153 273.9 173.789 274.559C174.071 279.446 174.388 284.325 174.713 289.118Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[267] ?? 'WHITE']}
        id="267"
        data-testid="back-267"
      />
      <path
        d="M160.555 276.409C160.581 278.797 160.607 281.185 160.632 283.573C160.658 286.038 160.684 288.52 160.709 290.993C164.997 290.617 169.08 290.103 173.017 289.419C172.692 284.634 172.375 279.764 172.093 274.868C168.395 275.527 164.561 276.032 160.555 276.409Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[266] ?? 'WHITE']}
        id="266"
        data-testid="back-266"
      />
      <path
        d="M146.245 291.782C150.687 291.662 154.932 291.457 158.998 291.131C158.972 288.615 158.946 286.099 158.921 283.591C158.895 281.254 158.869 278.909 158.852 276.573C154.821 276.915 150.61 277.12 146.193 277.249L146.245 291.782Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[265] ?? 'WHITE']}
        id="265"
        data-testid="back-265"
      />
      <path
        d="M144.534 291.824L144.482 277.29C141.101 277.367 137.618 277.41 133.989 277.41C130.574 277.41 127.296 277.359 124.129 277.256L124.078 291.789C127.262 291.883 130.548 291.935 133.98 291.935C137.627 291.943 141.127 291.901 144.534 291.824Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[264] ?? 'WHITE']}
        id="264"
        data-testid="back-264"
      />
      <path
        d="M122.366 291.73L122.417 277.197C117.967 277.026 113.756 276.752 109.767 276.358C109.742 278.763 109.716 281.177 109.69 283.582C109.665 286.021 109.639 288.478 109.613 290.925C113.644 291.302 117.881 291.567 122.366 291.73Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[263] ?? 'WHITE']}
        id="263"
        data-testid="back-263"
      />
      <path
        d="M107.901 290.771C107.926 288.374 107.952 285.969 107.978 283.573C108.003 281.116 108.029 278.651 108.055 276.195C103.998 275.767 100.181 275.211 96.5431 274.535C96.2521 279.43 95.9441 284.292 95.6191 289.076C99.4871 289.769 103.578 290.334 107.901 290.771Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[262] ?? 'WHITE']}
        id="262"
        data-testid="back-262"
      />
      <path
        d="M93.9262 288.751C94.2512 283.966 94.5592 279.096 94.8502 274.209C91.4272 273.524 88.1662 272.729 85.0592 271.813C85.0592 271.83 85.0592 271.847 85.0592 271.864C85.0672 274.115 84.8022 279.113 84.4342 286.038C84.4252 286.2 84.4172 286.363 84.4082 286.525C87.4302 287.373 90.6052 288.109 93.9262 288.751Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[261] ?? 'WHITE']}
        id="261"
        data-testid="back-261"
      />
      <path
        d="M82.7115 285.946C83.0795 279.056 83.3455 274.075 83.3365 271.875C83.3365 271.687 83.3365 271.49 83.3365 271.293C81.2915 270.651 79.2965 269.958 77.3625 269.204C77.3115 270.796 77.2855 271.935 77.3025 272.346C77.4055 272.876 77.2595 273.527 76.1215 277.943C75.7445 279.398 75.2655 281.281 74.7695 283.31C77.3195 284.302 79.9555 285.218 82.7115 286.031C82.7115 286.014 82.7115 285.98 82.7115 285.946Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[260] ?? 'WHITE']}
        id="260"
        data-testid="back-260"
      />
      <path
        d="M190.178 255.784C188.415 256.503 186.618 257.145 184.795 257.735C185.034 262.169 185.206 266.337 185.248 269.752C187.08 269.136 188.869 268.443 190.641 267.698C190.538 264.497 190.367 260.115 190.178 255.784Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[259] ?? 'WHITE']}
        id="259"
        data-testid="back-259"
      />
      <path
        d="M173.061 260.719C173.241 264.742 173.446 268.79 173.677 272.821C177.084 272.145 180.362 271.315 183.546 270.313C183.511 266.941 183.349 262.747 183.109 258.28C179.874 259.255 176.527 260.06 173.061 260.719Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[258] ?? 'WHITE']}
        id="258"
        data-testid="back-258"
      />
      <path
        d="M160.445 262.447C160.47 266.452 160.504 270.552 160.547 274.686C164.527 274.309 168.336 273.804 171.99 273.137C171.759 269.097 171.545 265.049 171.366 261.017C167.857 261.625 164.228 262.096 160.445 262.447Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[257] ?? 'WHITE']}
        id="257"
        data-testid="back-257"
      />
      <path
        d="M146.143 263.288L146.186 275.545C150.602 275.416 154.805 275.202 158.827 274.86C158.785 270.726 158.75 266.626 158.725 262.621C154.719 262.946 150.525 263.16 146.143 263.288Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[256] ?? 'WHITE']}
        id="256"
        data-testid="back-256"
      />
      <path
        d="M124.18 263.298L124.137 275.555C127.295 275.657 130.565 275.709 133.988 275.709C137.617 275.709 141.101 275.666 144.482 275.589L144.439 263.333C141.075 263.41 137.609 263.452 133.997 263.452C130.582 263.444 127.321 263.392 124.18 263.298Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[255] ?? 'WHITE']}
        id="255"
        data-testid="back-255"
      />
      <path
        d="M122.423 275.486L122.466 263.229C118.032 263.058 113.847 262.784 109.876 262.399C109.85 266.405 109.816 270.504 109.773 274.638C113.762 275.032 117.964 275.315 122.423 275.486Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[254] ?? 'WHITE']}
        id="254"
        data-testid="back-254"
      />
      <path
        d="M108.072 274.474C108.115 270.34 108.149 266.24 108.175 262.243C104.349 261.841 100.72 261.327 97.2635 260.711C97.0835 264.742 96.8775 268.782 96.6465 272.822C100.25 273.481 104.041 274.037 108.072 274.474Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[253] ?? 'WHITE']}
        id="253"
        data-testid="back-253"
      />
      <path
        d="M95.5604 260.38C92.0424 259.704 88.6954 258.899 85.5114 257.983C85.2724 262.451 85.1014 266.645 85.0664 270.043C88.1994 270.984 91.4944 271.789 94.9524 272.491C95.1744 268.451 95.3804 264.402 95.5604 260.38Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[252] ?? 'WHITE']}
        id="252"
        data-testid="back-252"
      />
      <path
        d="M83.8237 257.478C81.7947 256.854 79.8177 256.177 77.9007 255.45C77.7127 259.755 77.5407 264.146 77.4297 267.39C79.3467 268.151 81.3247 268.853 83.3617 269.504C83.4037 266.063 83.5757 261.895 83.8237 257.478Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[251] ?? 'WHITE']}
        id="251"
        data-testid="back-251"
      />
      <path
        d="M189.426 240.109C189.4 239.921 189.306 239.553 189.16 239.039C187.363 239.792 185.549 240.468 183.691 241.085C184.051 245.801 184.41 250.987 184.693 255.96C186.524 255.352 188.322 254.685 190.093 253.949C189.802 247.016 189.494 240.665 189.426 240.109Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[250] ?? 'WHITE']}
        id="250"
        data-testid="back-250"
      />
      <path
        d="M182.021 241.631C178.966 242.581 175.816 243.36 172.555 244.002C172.623 248.923 172.778 253.939 172.991 258.989C176.449 258.321 179.787 257.508 183.023 256.507C182.74 251.568 182.381 246.381 182.021 241.631Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[249] ?? 'WHITE']}
        id="249"
        data-testid="back-249"
      />
      <path
        d="M160.42 245.752C160.394 250.442 160.402 255.492 160.428 260.739C164.194 260.388 167.806 259.909 171.289 259.301C171.075 254.251 170.921 249.244 170.844 244.331C167.506 244.93 164.031 245.393 160.42 245.752Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[248] ?? 'WHITE']}
        id="248"
        data-testid="back-248"
      />
      <path
        d="M158.707 245.913C154.684 246.264 150.49 246.495 146.074 246.624L146.125 261.576C150.516 261.448 154.71 261.234 158.715 260.9C158.689 255.653 158.681 250.603 158.707 245.913Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[247] ?? 'WHITE']}
        id="247"
        data-testid="back-247"
      />
      <path
        d="M133.989 246.788C130.609 246.788 127.365 246.736 124.241 246.633L124.189 261.586C127.331 261.689 130.592 261.74 133.989 261.74C137.601 261.74 141.068 261.697 144.431 261.62L144.38 246.668C141.025 246.753 137.584 246.788 133.989 246.788Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[246] ?? 'WHITE']}
        id="246"
        data-testid="back-246"
      />
      <path
        d="M109.887 260.686C113.85 261.071 118.035 261.345 122.468 261.516L122.52 246.564C118.069 246.384 113.858 246.101 109.887 245.708C109.921 250.398 109.912 255.448 109.887 260.686Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[245] ?? 'WHITE']}
        id="245"
        data-testid="back-245"
      />
      <path
        d="M108.183 245.527C104.528 245.125 101.062 244.62 97.7581 244.004C97.6891 248.916 97.5351 253.923 97.3301 258.973C100.762 259.598 104.365 260.111 108.174 260.514C108.2 255.267 108.208 250.217 108.183 245.527Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[244] ?? 'WHITE']}
        id="244"
        data-testid="back-244"
      />
      <path
        d="M96.0555 243.679C92.7515 243.02 89.6105 242.249 86.6065 241.368C86.2475 246.092 85.8875 251.27 85.6055 256.226C88.7805 257.159 92.1185 257.963 95.6275 258.648C95.8415 253.598 95.9875 248.591 96.0555 243.679Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[243] ?? 'WHITE']}
        id="243"
        data-testid="back-243"
      />
      <path
        d="M84.9305 240.853C82.8845 240.211 80.9075 239.518 78.9815 238.764C78.7935 239.415 78.6735 239.894 78.6395 240.117C78.5705 240.664 78.2795 246.827 77.9805 253.64C79.9055 254.384 81.8835 255.078 83.9205 255.711C84.2115 250.738 84.5705 245.552 84.9305 240.853Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[242] ?? 'WHITE']}
        id="242"
        data-testid="back-242"
      />
      <path
        d="M182.287 223.974C182.304 224.12 182.903 230.753 183.562 239.329C185.291 238.738 186.994 238.087 188.671 237.385C187.285 232.806 184.418 224.248 183.878 223.247C183.896 223.281 183.913 223.307 183.93 223.324C183.87 223.238 183.81 223.093 183.75 222.896C182.971 223.298 182.184 223.683 181.396 224.051L182.287 223.974Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[241] ?? 'WHITE']}
        id="241"
        data-testid="back-241"
      />
      <path
        d="M180.609 224.428C178.033 225.584 175.397 226.559 172.667 227.372C172.513 232.174 172.47 237.155 172.53 242.256C175.756 241.615 178.863 240.827 181.885 239.877C181.285 232.063 180.729 225.798 180.609 224.428Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[240] ?? 'WHITE']}
        id="240"
        data-testid="back-240"
      />
      <path
        d="M170.947 227.872C167.686 228.762 164.298 229.447 160.729 229.961C160.566 233.983 160.481 238.751 160.438 244.031C164.04 243.672 167.498 243.201 170.827 242.593C170.767 237.552 170.802 232.631 170.947 227.872Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[239] ?? 'WHITE']}
        id="239"
        data-testid="back-239"
      />
      <path
        d="M146.023 231.228L146.075 244.913C150.5 244.776 154.702 244.545 158.725 244.194C158.768 238.965 158.853 234.223 159.007 230.201C154.933 230.714 150.628 231.039 146.023 231.228Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[238] ?? 'WHITE']}
        id="238"
        data-testid="back-238"
      />
      <path
        d="M134.031 231.431C130.616 231.431 127.39 231.362 124.291 231.234L124.24 244.92C127.364 245.022 130.591 245.082 133.98 245.082C137.575 245.082 141.015 245.04 144.353 244.963L144.302 231.285C141.041 231.388 137.626 231.431 134.031 231.431Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[237] ?? 'WHITE']}
        id="237"
        data-testid="back-237"
      />
      <path
        d="M122.589 231.169C117.924 230.938 113.619 230.544 109.596 229.996C109.759 234.01 109.845 238.761 109.887 243.999C113.859 244.401 118.07 244.692 122.537 244.863L122.589 231.169Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[236] ?? 'WHITE']}
        id="236"
        data-testid="back-236"
      />
      <path
        d="M108.175 243.8C108.132 238.519 108.038 233.752 107.875 229.746C104.246 229.19 100.865 228.488 97.6562 227.641C97.8102 232.365 97.8443 237.261 97.7843 242.26C101.071 242.884 104.529 243.389 108.175 243.8Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[235] ?? 'WHITE']}
        id="235"
        data-testid="back-235"
      />
      <path
        d="M95.9264 227.176C93.1534 226.38 90.5174 225.464 87.9924 224.428C87.8724 225.781 87.3244 231.926 86.7344 239.62C89.7044 240.502 92.8024 241.272 96.0724 241.931C96.1324 236.881 96.0894 231.943 95.9264 227.176Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[234] ?? 'WHITE']}
        id="234"
        data-testid="back-234"
      />
      <path
        d="M85.0578 239.102C85.7078 230.638 86.2987 224.124 86.3158 223.979L87.1028 224.047C86.1698 223.645 85.2458 223.226 84.3388 222.789C84.2698 223.037 84.1928 223.226 84.1248 223.32C84.1418 223.303 84.1588 223.277 84.1758 223.243C83.6448 224.219 80.8807 232.478 79.4688 237.117C81.2828 237.836 83.1398 238.495 85.0578 239.102Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[233] ?? 'WHITE']}
        id="233"
        data-testid="back-233"
      />
      <path
        d="M173.162 217.857C172.974 220.357 172.845 222.933 172.734 225.56C176.423 224.414 179.949 222.967 183.381 221.161C183.047 219.098 182.714 215.863 182.465 212.525C179.513 213.441 176.474 214.194 173.342 214.819C173.299 215.872 173.239 216.89 173.162 217.857Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[232] ?? 'WHITE']}
        id="232"
        data-testid="back-232"
      />
      <path
        d="M161.208 222.434C161.045 224.051 160.917 226.003 160.805 228.211C164.366 227.68 167.764 226.996 171.007 226.08C171.11 223.23 171.264 220.431 171.461 217.726C171.521 216.905 171.572 216.04 171.624 215.15C168.423 215.724 165.093 216.16 161.601 216.502C161.507 218.753 161.379 220.748 161.208 222.434Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[231] ?? 'WHITE']}
        id="231"
        data-testid="back-231"
      />
      <path
        d="M159.503 222.264C159.665 220.664 159.785 218.781 159.879 216.667C155.54 217.043 150.927 217.266 145.971 217.394L146.014 229.514C150.653 229.325 154.992 229 159.075 228.469C159.186 226.081 159.331 223.993 159.503 222.264Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[230] ?? 'WHITE']}
        id="230"
        data-testid="back-230"
      />
      <path
        d="M134.031 217.514C130.633 217.514 127.423 217.471 124.342 217.394L124.299 229.522C127.389 229.65 130.616 229.719 134.031 229.719C137.625 229.719 141.032 229.667 144.301 229.565L144.258 217.42C140.998 217.488 137.608 217.514 134.031 217.514Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[229] ?? 'WHITE']}
        id="229"
        data-testid="back-229"
      />
      <path
        d="M122.631 217.353C117.616 217.199 113.003 216.925 108.723 216.531C108.817 218.705 108.937 220.64 109.1 222.266C109.271 223.943 109.399 225.955 109.51 228.249C113.55 228.814 117.881 229.216 122.589 229.447L122.631 217.353Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[228] ?? 'WHITE']}
        id="228"
        data-testid="back-228"
      />
      <path
        d="M97.5885 225.853C100.78 226.717 104.161 227.436 107.79 227.993C107.687 225.87 107.559 223.995 107.396 222.428C107.225 220.708 107.097 218.672 107.003 216.352C103.451 215.993 100.121 215.539 96.9805 215C97.0325 215.942 97.0835 216.856 97.1515 217.721C97.3405 220.366 97.4855 223.08 97.5885 225.853Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[227] ?? 'WHITE']}
        id="227"
        data-testid="back-227"
      />
      <path
        d="M84.6914 221.057C88.1914 222.76 91.8724 224.19 95.8604 225.362C95.7584 222.803 95.6294 220.287 95.4414 217.847C95.3644 216.846 95.3044 215.793 95.2534 214.681C91.8464 214.039 88.6454 213.285 85.5984 212.412C85.3504 215.742 85.0254 218.969 84.6914 221.057Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[226] ?? 'WHITE']}
        id="226"
        data-testid="back-226"
      />
      <path
        d="M173.428 213.053C176.5 212.429 179.47 211.684 182.354 210.768C182.021 205.616 181.901 200.66 182.286 199.804C182.346 199.667 182.423 199.539 182.509 199.428C182.628 198.94 182.791 197.956 182.954 196.706C179.915 197.673 176.791 198.461 173.556 199.102C173.616 204.238 173.582 208.954 173.428 213.053Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[225] ?? 'WHITE']}
        id="225"
        data-testid="back-225"
      />
      <path
        d="M161.66 214.775C165.169 214.433 168.499 213.979 171.7 213.389C171.862 209.306 171.905 204.59 171.845 199.438C168.627 200.02 165.28 200.473 161.763 200.816C161.814 206.045 161.788 210.77 161.66 214.775Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[224] ?? 'WHITE']}
        id="224"
        data-testid="back-224"
      />
      <path
        d="M145.963 215.673C150.953 215.544 155.583 215.313 159.94 214.937C160.068 210.94 160.102 206.215 160.06 200.977C155.643 201.362 150.962 201.593 145.912 201.722L145.963 215.673Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[223] ?? 'WHITE']}
        id="223"
        data-testid="back-223"
      />
      <path
        d="M134.032 215.8C137.609 215.8 140.999 215.766 144.251 215.706L144.2 201.755C140.964 201.823 137.592 201.849 134.032 201.849C130.66 201.849 127.467 201.806 124.403 201.729L124.352 215.68C127.424 215.757 130.634 215.8 134.032 215.8Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[222] ?? 'WHITE']}
        id="222"
        data-testid="back-222"
      />
      <path
        d="M122.692 201.681C117.583 201.519 112.893 201.245 108.545 200.843C108.502 206.072 108.527 210.805 108.656 214.802C112.952 215.204 117.583 215.478 122.641 215.632L122.692 201.681Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[221] ?? 'WHITE']}
        id="221"
        data-testid="back-221"
      />
      <path
        d="M106.84 200.681C103.271 200.313 99.9241 199.859 96.7581 199.303C96.6981 204.455 96.7322 209.171 96.8952 213.254C100.036 213.81 103.374 214.264 106.943 214.632C106.823 210.617 106.789 205.893 106.84 200.681Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[220] ?? 'WHITE']}
        id="220"
        data-testid="back-220"
      />
      <path
        d="M85.776 199.809C86.152 200.656 86.041 205.56 85.707 210.67C88.694 211.543 91.835 212.296 95.165 212.93C95.011 208.83 94.976 204.114 95.045 198.987C91.561 198.328 88.283 197.541 85.168 196.625C85.313 197.917 85.45 198.944 85.57 199.449C85.656 199.561 85.724 199.68 85.776 199.809Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[219] ?? 'WHITE']}
        id="219"
        data-testid="back-219"
      />
      <path
        d="M184.725 179.676C180.959 180.968 177.125 181.978 173.102 182.774C173.308 187.866 173.453 192.771 173.53 197.367C176.868 196.691 180.069 195.852 183.185 194.825C183.493 192.266 183.818 189.219 184.058 186.968C184.357 184.118 184.623 181.661 184.811 180.275C184.777 180.086 184.751 179.881 184.725 179.676Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[218] ?? 'WHITE']}
        id="218"
        data-testid="back-218"
      />
      <path
        d="M161.438 184.419C161.591 189.571 161.694 194.51 161.754 199.097C165.272 198.747 168.617 198.293 171.827 197.694C171.759 193.098 171.614 188.176 171.409 183.075C168.225 183.649 164.921 184.094 161.438 184.419Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[217] ?? 'WHITE']}
        id="217"
        data-testid="back-217"
      />
      <path
        d="M145.852 185.284L145.904 200.014C150.954 199.885 155.635 199.654 160.043 199.269C159.992 194.681 159.881 189.743 159.726 184.582C155.421 184.941 150.825 185.164 145.852 185.284Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[216] ?? 'WHITE']}
        id="216"
        data-testid="back-216"
      />
      <path
        d="M134.032 200.141C137.592 200.141 140.956 200.115 144.191 200.047L144.14 185.317C140.93 185.377 137.584 185.411 134.032 185.411C130.685 185.411 127.51 185.368 124.463 185.291L124.412 200.021C127.467 200.098 130.66 200.141 134.032 200.141Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[215] ?? 'WHITE']}
        id="215"
        data-testid="back-215"
      />
      <path
        d="M122.7 199.971L122.752 185.233C117.762 185.079 113.157 184.805 108.878 184.419C108.724 189.589 108.612 194.536 108.561 199.132C112.9 199.535 117.591 199.817 122.7 199.971Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[214] ?? 'WHITE']}
        id="214"
        data-testid="back-214"
      />
      <path
        d="M106.856 198.958C106.908 194.362 107.019 189.415 107.173 184.262C103.647 183.903 100.334 183.466 97.2022 182.936C96.9962 188.045 96.8512 192.967 96.7832 197.571C99.9322 198.128 103.279 198.59 106.856 198.958Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[213] ?? 'WHITE']}
        id="213"
        data-testid="back-213"
      />
      <path
        d="M84.9807 194.782C88.1647 195.741 91.5027 196.562 95.0807 197.247C95.1577 192.634 95.3027 187.721 95.5087 182.611C91.3487 181.841 87.4977 180.899 83.8687 179.778C83.8087 180.223 83.7397 180.651 83.6797 181.045C83.8857 182.705 84.1337 185.496 84.3987 188.448C84.5617 190.357 84.7757 192.711 84.9807 194.782Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[212] ?? 'WHITE']}
        id="212"
        data-testid="back-212"
      />
      <path
        d="M185.873 174.385C186.327 171.877 186.892 168.761 187.491 165.432C186.738 165.723 185.984 165.988 185.223 166.254C185.445 169.292 185.659 172.031 185.873 174.385Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[211] ?? 'WHITE']}
        id="211"
        data-testid="back-211"
      />
      <path
        d="M183.546 166.83C179.977 167.994 176.322 168.91 172.479 169.637C172.684 173.497 172.873 177.315 173.027 181.038C177.007 180.25 180.79 179.232 184.504 177.94C184.188 175.064 183.888 171.332 183.546 166.83Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[210] ?? 'WHITE']}
        id="210"
        data-testid="back-210"
      />
      <path
        d="M160.975 171.209C161.129 175.112 161.266 178.955 161.378 182.704C164.861 182.37 168.156 181.925 171.332 181.352C171.177 177.62 170.989 173.794 170.784 169.934C167.66 170.473 164.399 170.893 160.975 171.209Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[209] ?? 'WHITE']}
        id="209"
        data-testid="back-209"
      />
      <path
        d="M145.799 172.039L145.842 183.568C150.806 183.448 155.385 183.225 159.673 182.857C159.562 179.108 159.416 175.257 159.271 171.354C155.068 171.705 150.601 171.919 145.799 172.039Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[208] ?? 'WHITE']}
        id="208"
        data-testid="back-208"
      />
      <path
        d="M144.131 183.602L144.088 172.082C140.887 172.142 137.549 172.176 134.031 172.176C130.71 172.176 127.544 172.142 124.514 172.065L124.471 183.576C127.518 183.653 130.685 183.696 134.031 183.696C137.583 183.696 140.93 183.662 144.131 183.602Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[207] ?? 'WHITE']}
        id="207"
        data-testid="back-207"
      />
      <path
        d="M122.76 183.517L122.803 172.006C117.967 171.86 113.499 171.603 109.34 171.235C109.186 175.121 109.05 178.964 108.938 182.696C113.192 183.09 117.779 183.363 122.76 183.517Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[206] ?? 'WHITE']}
        id="206"
        data-testid="back-206"
      />
      <path
        d="M107.636 171.082C104.169 170.74 100.917 170.321 97.8273 169.816C97.6223 173.667 97.4333 177.484 97.2793 181.208C100.403 181.747 103.699 182.192 107.225 182.551C107.345 178.803 107.482 174.968 107.636 171.082Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[205] ?? 'WHITE']}
        id="205"
        data-testid="back-205"
      />
      <path
        d="M96.1328 169.515C92.1868 168.805 88.5238 167.949 85.0568 166.922C84.7148 171.45 84.4148 175.19 84.0898 178.066C87.6758 179.187 91.4758 180.12 95.5848 180.89C95.7388 177.167 95.9188 173.367 96.1328 169.515Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[204] ?? 'WHITE']}
        id="204"
        data-testid="back-204"
      />
      <path
        d="M83.371 166.411C82.096 166.009 80.847 165.581 79.623 165.136C80.521 168.833 81.506 172.693 82.524 176.519C82.807 173.772 83.08 170.357 83.371 166.411Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[203] ?? 'WHITE']}
        id="203"
        data-testid="back-203"
      />
      <path
        d="M183.836 148.204C184.272 153.502 184.64 158.381 184.965 162.849C185.008 163.405 185.042 163.944 185.085 164.483C186.01 164.158 186.925 163.807 187.841 163.448C188.971 157.14 190.169 150.396 190.94 145.825C188.68 146.69 186.309 147.477 183.836 148.204Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[202] ?? 'WHITE']}
        id="202"
        data-testid="back-202"
      />
      <path
        d="M172.392 167.905C176.218 167.177 179.873 166.253 183.416 165.072C183.365 164.387 183.314 163.694 183.262 162.975C182.937 158.61 182.586 153.851 182.158 148.681C178.752 149.623 175.157 150.444 171.357 151.146C171.733 156.701 172.084 162.333 172.392 167.905Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[201] ?? 'WHITE']}
        id="201"
        data-testid="back-201"
      />
      <path
        d="M169.662 151.44C166.615 151.963 163.457 152.408 160.162 152.776C160.436 158.339 160.684 163.962 160.907 169.508C164.322 169.192 167.574 168.764 170.69 168.225C170.39 162.644 170.039 156.995 169.662 151.44Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[200] ?? 'WHITE']}
        id="200"
        data-testid="back-200"
      />
      <path
        d="M145.8 170.328C150.584 170.208 155.026 169.994 159.212 169.644C158.989 164.115 158.732 158.491 158.467 152.937C154.419 153.347 150.182 153.647 145.74 153.835L145.8 170.328Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[199] ?? 'WHITE']}
        id="199"
        data-testid="back-199"
      />
      <path
        d="M134.031 170.455C137.558 170.455 140.887 170.421 144.088 170.361L144.028 153.902C140.802 154.014 137.472 154.082 134.031 154.082C130.822 154.082 127.672 154.014 124.582 153.894L124.523 170.344C127.544 170.421 130.711 170.455 134.031 170.455Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[198] ?? 'WHITE']}
        id="198"
        data-testid="back-198"
      />
      <path
        d="M122.871 153.819C118.506 153.622 114.261 153.297 110.152 152.86C109.878 158.407 109.63 164.004 109.408 169.525C113.55 169.893 118.001 170.149 122.811 170.295L122.871 153.819Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[197] ?? 'WHITE']}
        id="197"
        data-testid="back-197"
      />
      <path
        d="M97.9219 168.095C100.995 168.608 104.247 169.028 107.705 169.37C107.928 163.841 108.185 158.235 108.45 152.689C105.189 152.312 102.022 151.85 98.9489 151.328C98.5729 156.874 98.2309 162.514 97.9219 168.095Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[196] ?? 'WHITE']}
        id="196"
        data-testid="back-196"
      />
      <path
        d="M85.1875 165.177C88.6455 166.213 92.2915 167.086 96.2285 167.796C96.5365 162.216 96.8875 156.584 97.2645 151.021C93.5065 150.336 89.8945 149.54 86.4625 148.633C86.0345 153.811 85.6835 158.596 85.3585 162.978C85.2905 163.731 85.2385 164.458 85.1875 165.177Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[195] ?? 'WHITE']}
        id="195"
        data-testid="back-195"
      />
      <path
        d="M83.4998 164.654C83.5428 164.063 83.5858 163.464 83.6368 162.848C83.9708 158.372 84.3308 153.476 84.7668 148.17C81.5568 147.271 78.5018 146.278 75.6178 145.191C75.6088 145.251 75.5998 145.311 75.5918 145.371C76.0708 149.308 77.3978 155.787 79.1438 163.131C80.5648 163.67 82.0198 164.175 83.4998 164.654Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[194] ?? 'WHITE']}
        id="194"
        data-testid="back-194"
      />
      <path
        d="M264.419 311.841L264.351 311.627C263.717 309.675 262.159 307.099 260.251 304.437C259.215 306.106 257.906 307.998 256.297 309.915C258.479 312.294 260.824 314.528 262.014 314.965C263.084 315.358 264.659 315.307 265.01 315.059C265.104 314.888 265.198 314.194 264.419 311.841Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[193] ?? 'WHITE']}
        id="193"
        data-testid="back-193"
      />
      <path
        d="M260.095 334.397L260.104 334.329C260.617 330.178 256.364 320.25 253.034 313.419C251.853 314.549 250.552 315.662 249.148 316.698C250.047 317.956 250.989 319.924 252.29 322.732C254.224 326.917 257.391 333.738 260.095 334.397Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[192] ?? 'WHITE']}
        id="192"
        data-testid="back-192"
      />
      <path
        d="M249.115 339.333C249.217 339.298 249.508 338.947 249.688 338.494C250.938 335.395 247.874 325.698 245.272 319.202C244.142 319.835 242.952 320.417 241.711 320.939C242.096 322.069 242.482 323.524 242.952 325.305C244.125 329.849 246.342 338.306 249.115 339.333Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[191] ?? 'WHITE']}
        id="191"
        data-testid="back-191"
      />
      <path
        d="M235.479 326.856C236.155 330.331 237.73 338.376 239.938 338.933C240.041 338.753 240.178 338.368 240.221 337.863C240.383 335.954 239.228 330.733 238.389 326.916C237.953 324.947 237.645 323.501 237.439 322.414C236.54 322.662 235.616 322.885 234.666 323.064C234.914 323.989 235.162 325.213 235.479 326.856Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[190] ?? 'WHITE']}
        id="190"
        data-testid="back-190"
      />
      <path
        d="M227.033 323.689C227.487 329.03 228.223 336.271 230.765 336.81C230.885 336.716 231.064 336.484 231.15 336.288C231.715 334.961 231.056 330.673 230.619 327.831C230.363 326.162 230.149 324.767 230.08 323.629C229.31 323.672 228.522 323.697 227.726 323.697C227.495 323.697 227.264 323.689 227.033 323.689Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[189] ?? 'WHITE']}
        id="189"
        data-testid="back-189"
      />
      <path
        d="M252.931 295.529C251.425 297.181 249.473 298.996 247.051 300.725C248.472 302.762 250.44 305.321 252.161 307.152L253.273 306.416C253.83 307.101 254.472 307.863 255.156 308.633C256.825 306.613 258.152 304.645 259.17 302.95C257.142 300.28 254.874 297.626 252.931 295.529Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[188] ?? 'WHITE']}
        id="188"
        data-testid="back-188"
      />
      <path
        d="M245.631 301.683C246.624 303.103 248.652 305.911 250.715 308.119L250.432 308.307C250.929 309.223 251.545 310.421 252.238 311.799C250.954 313.057 249.534 314.29 247.959 315.437C247.693 315.249 247.419 315.103 247.12 315.017C246.932 314.966 246.735 314.94 246.546 314.932C245.151 312.048 243.619 308.205 242.027 303.737C243.32 303.095 244.518 302.402 245.631 301.683Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[187] ?? 'WHITE']}
        id="187"
        data-testid="back-187"
      />
      <path
        d="M244.528 317.423L243.954 316.096L244.904 315.488C243.509 312.587 242.003 308.83 240.445 304.49C237.595 305.757 234.325 306.775 230.619 307.375C231.433 313.135 232.288 317.971 233.093 320.358C233.144 320.384 233.204 320.41 233.256 320.444C233.589 320.667 233.855 320.966 234.077 321.411C235.147 321.214 236.183 320.983 237.176 320.701C237.081 319.691 237.201 319.212 237.501 318.766L237.92 318.159L238.716 317.996C239.76 317.825 240.445 318.287 241.036 319.357C242.285 318.835 243.475 318.244 244.605 317.602C244.588 317.543 244.553 317.483 244.528 317.423Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[185] ?? 'WHITE']}
        id="185"
        data-testid="back-185"
      />
      <path
        d="M230.141 321.9C230.286 321.078 230.654 320.539 231.347 320.265C230.474 317.381 229.644 312.734 228.917 307.615C227.256 307.821 225.51 307.941 223.67 307.966C224.047 309.139 224.449 310.269 224.826 311.338C225.117 312.16 225.408 312.965 225.664 313.735C226.323 315.721 226.614 318.725 226.88 321.951C228.001 321.977 229.088 321.969 230.141 321.9Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[184] ?? 'WHITE']}
        id="184"
        data-testid="back-184"
      />
      <path
        d="M250.158 292.713C248.934 291.574 246.127 289.058 243.662 286.858C242.164 288.399 240.264 290.017 237.953 291.395C239.134 295.203 240.315 298.824 241.471 302.145C243.559 301.1 245.348 299.953 246.829 298.841C248.857 297.317 250.492 295.742 251.784 294.305C251.185 293.697 250.637 293.158 250.158 292.713Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[183] ?? 'WHITE']}
        id="183"
        data-testid="back-183"
      />
      <path
        d="M239.888 302.889C238.732 299.602 237.56 296.016 236.387 292.258C234.247 293.337 231.799 294.159 229.043 294.527C229.454 298.301 229.917 302.118 230.396 305.679C234.033 305.088 237.192 304.087 239.888 302.889Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[182] ?? 'WHITE']}
        id="182"
        data-testid="back-182"
      />
      <path
        d="M228.685 305.931C228.171 302.182 227.718 298.279 227.333 294.701C226.862 294.727 226.383 294.744 225.886 294.744C224.808 294.744 223.687 294.667 222.531 294.513C222.505 294.898 222.48 295.3 222.454 295.728C222.309 298.099 222.137 300.795 222.24 301.651C222.42 303.235 222.762 304.767 223.173 306.247C225.107 306.247 226.948 306.145 228.685 305.931Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[181] ?? 'WHITE']}
        id="181"
        data-testid="back-181"
      />
      <path
        d="M237.457 289.812C239.468 288.579 241.12 287.141 242.438 285.772C241.6 285.027 240.846 284.36 240.29 283.855C238.946 282.665 238.176 280.953 238.167 279.164C238.159 278.283 238.099 277.136 237.996 275.792C236.824 276.674 235.506 277.504 234.051 278.231C235.172 282.177 236.31 286.08 237.457 289.812Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[180] ?? 'WHITE']}
        id="180"
        data-testid="back-180"
      />
      <path
        d="M232.434 278.967C230.705 279.669 228.805 280.209 226.725 280.482C226.999 280.927 227.264 281.261 227.495 281.458L227.778 281.689L227.812 282.049C228.094 285.31 228.454 289.041 228.865 292.884C231.526 292.542 233.863 291.737 235.9 290.693C234.727 286.902 233.572 282.956 232.434 278.967Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[179] ?? 'WHITE']}
        id="179"
        data-testid="back-179"
      />
      <path
        d="M227.144 293.02C226.656 288.39 226.305 284.496 226.134 282.51C225.749 282.099 225.346 281.466 224.91 280.653C224.482 280.678 224.054 280.687 223.617 280.687C222.128 280.687 220.553 280.542 218.91 280.242C220.596 284.068 221.854 286.789 222.188 287.397C222.71 288.048 222.813 288.989 222.616 292.806C224.217 293.038 225.723 293.097 227.144 293.02Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[178] ?? 'WHITE']}
        id="178"
        data-testid="back-178"
      />
      <path
        d="M233.606 276.647C235.206 275.834 236.61 274.875 237.834 273.882C237.534 270.621 237.064 266.573 236.541 262.319C234.941 263.714 233.015 265.075 230.756 266.162C231.671 269.629 232.622 273.146 233.606 276.647Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[177] ?? 'WHITE']}
        id="177"
        data-testid="back-177"
      />
      <path
        d="M231.988 277.391C231.004 273.89 230.045 270.364 229.129 266.881C227.058 267.694 224.747 268.267 222.188 268.438C223.548 272.906 224.823 276.569 225.893 278.889C228.136 278.675 230.174 278.136 231.988 277.391Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[176] ?? 'WHITE']}
        id="176"
        data-testid="back-176"
      />
      <path
        d="M224.114 278.991C222.967 276.389 221.7 272.726 220.408 268.498C218.268 268.481 215.974 268.19 213.518 267.531C215.093 271.391 216.685 275.131 218.063 278.323C220.228 278.811 222.239 279.025 224.114 278.991Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[175] ?? 'WHITE']}
        id="175"
        data-testid="back-175"
      />
      <path
        d="M226.869 250.782C227.913 255.121 229.077 259.778 230.327 264.562C232.723 263.372 234.709 261.849 236.284 260.351C235.984 257.989 235.676 255.601 235.377 253.281C235 250.363 234.632 247.496 234.307 244.859C232.544 246.828 230.07 249.062 226.869 250.782Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[174] ?? 'WHITE']}
        id="174"
        data-testid="back-174"
      />
      <path
        d="M218.02 253.486C219.252 258.253 220.502 262.789 221.683 266.778C224.294 266.667 226.639 266.11 228.71 265.289C227.469 260.538 226.305 255.908 225.269 251.577C223.138 252.527 220.716 253.229 218.02 253.486Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[173] ?? 'WHITE']}
        id="173"
        data-testid="back-173"
      />
      <path
        d="M207.766 252.384C209.093 256.313 210.856 260.935 212.704 265.514C215.289 266.318 217.677 266.712 219.894 266.789C218.679 262.681 217.437 258.145 216.265 253.591C216.085 253.591 215.914 253.608 215.734 253.608C213.286 253.608 210.633 253.24 207.766 252.384Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[172] ?? 'WHITE']}
        id="172"
        data-testid="back-172"
      />
      <path
        d="M232.116 228.409C230.37 230.626 227.52 233.613 223.523 235.796C224.267 239.527 225.286 244.098 226.484 249.156C229.942 247.222 232.45 244.646 234.05 242.634C233.605 238.894 233.271 235.77 233.177 233.836C233.109 232.449 232.715 230.566 232.116 228.409Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[171] ?? 'WHITE']}
        id="171"
        data-testid="back-171"
      />
      <path
        d="M224.884 249.962C223.695 244.947 222.685 240.385 221.932 236.602C219.706 237.62 217.181 238.356 214.34 238.604C215.384 243.012 216.488 247.506 217.601 251.845C220.348 251.64 222.77 250.929 224.884 249.962Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[170] ?? 'WHITE']}
        id="170"
        data-testid="back-170"
      />
      <path
        d="M205.746 245.434C206.028 246.778 206.499 248.464 207.098 250.364C210.282 251.443 213.192 251.913 215.837 251.913C214.664 247.317 213.56 242.772 212.602 238.698C212.473 238.698 212.345 238.707 212.216 238.707C209.743 238.707 207.055 238.33 204.154 237.457C204.727 240.539 205.292 243.277 205.677 245.118L205.746 245.434Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[169] ?? 'WHITE']}
        id="169"
        data-testid="back-169"
      />
      <path
        d="M220.955 221.291C221.503 223.842 221.974 226.41 222.307 228.875C222.496 230.252 222.795 232.024 223.198 234.13C227.357 231.742 230.113 228.438 231.525 226.401C230.533 223.191 229.206 219.562 227.828 216.028C226.441 217.671 224.225 219.708 220.955 221.291Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[168] ?? 'WHITE']}
        id="168"
        data-testid="back-168"
      />
      <path
        d="M220.606 229.105C220.298 226.794 219.862 224.389 219.357 221.993C217.037 222.908 214.264 223.576 210.969 223.756C211.859 227.847 212.869 232.34 213.956 236.962C216.857 236.757 219.399 235.995 221.608 234.959C221.154 232.614 220.812 230.629 220.606 229.105Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[167] ?? 'WHITE']}
        id="167"
        data-testid="back-167"
      />
      <path
        d="M209.301 224.155C209.181 224.052 209.07 223.932 208.958 223.812C206.878 223.804 204.61 223.598 202.145 223.17C202.402 226.945 203.078 231.438 203.814 235.547C206.87 236.557 209.66 236.993 212.211 237.01C210.773 230.848 209.703 226.003 209.301 224.155Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[166] ?? 'WHITE']}
        id="166"
        data-testid="back-166"
      />
      <path
        d="M218.414 211.077C219.15 213.704 219.903 216.606 220.58 219.584C223.935 217.89 225.997 215.698 227.119 214.218C226.032 211.487 224.953 208.877 224.02 206.652C222.925 208.004 221.17 209.604 218.414 211.077Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[165] ?? 'WHITE']}
        id="165"
        data-testid="back-165"
      />
      <path
        d="M218.988 220.304C218.329 217.351 217.576 214.466 216.849 211.847C214.409 212.952 211.302 213.927 207.383 214.62C207.862 217.625 208.598 220.483 209.753 222.101C213.476 222.024 216.523 221.314 218.988 220.304Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[164] ?? 'WHITE']}
        id="164"
        data-testid="back-164"
      />
      <path
        d="M205.687 214.896C204.078 215.128 202.349 215.324 200.475 215.453C201.51 217.995 202.024 219.587 202.032 220.588C202.032 220.862 202.05 221.153 202.058 221.435C204.087 221.803 205.978 222.017 207.733 222.086C206.766 220.237 206.141 217.729 205.687 214.896Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[163] ?? 'WHITE']}
        id="163"
        data-testid="back-163"
      />
      <path
        d="M215.615 201.913C216.163 203.582 217.002 206.201 217.917 209.359C220.81 207.742 222.394 206.021 223.233 204.78C223.138 204.558 223.044 204.335 222.959 204.121C222.574 203.214 222.24 202.418 221.966 201.759C221.469 200.561 221.161 199.32 221.033 198.044C219.783 199.354 218.037 200.723 215.615 201.913Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[162] ?? 'WHITE']}
        id="162"
        data-testid="back-162"
      />
      <path
        d="M214.048 202.612C211.942 203.468 209.4 204.187 206.336 204.632C206.396 205.214 206.456 205.941 206.525 206.84C206.661 208.518 206.841 210.7 207.132 212.917C211.018 212.224 214.031 211.231 216.359 210.144C215.451 206.977 214.604 204.324 214.048 202.612Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[161] ?? 'WHITE']}
        id="161"
        data-testid="back-161"
      />
      <path
        d="M204.823 206.981C204.763 206.211 204.703 205.466 204.635 204.85C202.863 205.038 200.929 205.149 198.815 205.149C197.908 205.149 196.958 205.124 195.99 205.081C196.513 206.288 197.026 207.452 197.505 208.547C198.395 210.584 199.157 212.304 199.782 213.777C201.836 213.648 203.719 213.451 205.448 213.195C205.191 211.2 204.994 209.095 204.823 206.981Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[160] ?? 'WHITE']}
        id="160"
        data-testid="back-160"
      />
      <path
        d="M214.195 190.246C214.665 195.467 214.939 199.258 214.999 200.072C215.016 200.114 215.033 200.166 215.059 200.234C216.138 199.686 217.053 199.121 217.798 198.565C219.262 197.487 220.271 196.366 220.973 195.398C220.982 192.916 220.93 189.099 220.691 184.28C219.262 186.129 217.173 188.269 214.195 190.246Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[159] ?? 'WHITE']}
        id="159"
        data-testid="back-159"
      />
      <path
        d="M213.347 200.523L213.304 200.301C213.296 200.189 213.031 196.561 212.56 191.245C210.266 192.572 207.519 193.779 204.232 194.737C205.225 199.385 205.918 202.518 206.064 202.92C209.076 202.475 211.524 201.764 213.493 200.951C213.399 200.686 213.347 200.54 213.347 200.523Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[158] ?? 'WHITE']}
        id="158"
        data-testid="back-158"
      />
      <path
        d="M204.458 202.959C204.201 201.941 203.55 199.014 202.686 195C199.733 195.736 196.386 196.249 192.586 196.48C193.476 198.731 194.418 200.991 195.342 203.156C198.809 203.327 201.83 203.242 204.458 202.959Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[157] ?? 'WHITE']}
        id="157"
        data-testid="back-157"
      />
      <path
        d="M212.73 175.815C213.235 180.205 213.663 184.493 214.006 188.225C214.785 187.669 215.495 187.112 216.111 186.582C218.242 184.724 219.646 182.876 220.528 181.463C220.296 177.672 219.946 173.367 219.423 168.702C218.02 170.799 215.88 173.324 212.73 175.815Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[156] ?? 'WHITE']}
        id="156"
        data-testid="back-156"
      />
      <path
        d="M211.131 177.008C208.555 178.823 205.397 180.56 201.537 182.032C202.384 186.055 203.18 189.821 203.865 193.022C207.34 191.995 210.147 190.668 212.381 189.299C212.047 185.653 211.628 181.399 211.131 177.008Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[155] ?? 'WHITE']}
        id="155"
        data-testid="back-155"
      />
      <path
        d="M199.908 182.613C196.664 183.725 192.958 184.633 188.73 185.257C189.355 188.099 190.484 191.48 191.82 194.972C195.774 194.766 199.223 194.236 202.21 193.474C201.525 190.282 200.738 186.55 199.908 182.613Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[154] ?? 'WHITE']}
        id="154"
        data-testid="back-154"
      />
      <path
        d="M210.787 161.402C211.412 165.245 211.977 169.473 212.482 173.684C215.939 170.757 217.977 167.856 219.098 165.887C218.661 162.344 218.131 158.612 217.48 154.743C216.162 156.746 214.082 159.048 210.787 161.402Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[153] ?? 'WHITE']}
        id="153"
        data-testid="back-153"
      />
      <path
        d="M198.533 167.694C199.406 171.879 200.304 176.184 201.169 180.293C205.149 178.735 208.35 176.869 210.892 174.96C210.395 170.698 209.83 166.393 209.214 162.473C206.45 164.253 202.949 166.034 198.533 167.694Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[152] ?? 'WHITE']}
        id="152"
        data-testid="back-152"
      />
      <path
        d="M196.915 168.293C194.826 169.029 192.558 169.74 190.076 170.407C189.118 175.714 188.373 179.814 188.227 180.61C188.168 181.448 188.245 182.458 188.416 183.58C192.644 182.963 196.333 182.03 199.551 180.901C198.669 176.724 197.771 172.393 196.915 168.293Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[151] ?? 'WHITE']}
        id="151"
        data-testid="back-151"
      />
      <path
        d="M207.707 147.925C208.066 149.08 208.383 150.133 208.64 151.057C209.29 153.317 209.898 156.218 210.463 159.471C214.134 156.672 216.034 154.019 217.002 152.05C216.351 148.456 215.598 144.758 214.716 141.001C213.441 143.055 211.293 145.503 207.707 147.925Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[150] ?? 'WHITE']}
        id="150"
        data-testid="back-150"
      />
      <path
        d="M198.181 165.998C202.717 164.26 206.209 162.403 208.914 160.58C208.315 157.045 207.673 153.904 206.997 151.525C206.774 150.737 206.509 149.847 206.209 148.88C203.462 150.54 199.987 152.149 195.596 153.587C196.358 157.268 197.248 161.539 198.181 165.998Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[149] ?? 'WHITE']}
        id="149"
        data-testid="back-149"
      />
      <path
        d="M190.41 168.528C192.627 167.912 194.681 167.27 196.556 166.594C195.28 160.474 194.142 155.005 193.423 151.547C192.516 156.802 191.386 163.11 190.41 168.528Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[148] ?? 'WHITE']}
        id="148"
        data-testid="back-148"
      />
      <path
        d="M37.5565 336.808C40.0899 336.269 40.8345 329.036 41.2881 323.696C41.2368 323.696 41.1854 323.696 41.1341 323.696C40.1413 323.696 39.1827 323.644 38.2412 323.576C38.1813 324.714 37.9587 326.126 37.702 327.821C37.2655 330.663 36.5979 334.951 37.1713 336.277C37.2569 336.483 37.4366 336.714 37.5565 336.808Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[147] ?? 'WHITE']}
        id="147"
        data-testid="back-147"
      />
      <path
        d="M33.3142 326.856C33.6994 325.153 33.9958 323.895 34.2822 322.954C33.1859 322.757 32.1191 322.526 31.082 322.269C30.845 323.373 30.4795 324.854 29.9561 326.916C28.9881 330.734 27.6645 335.954 27.8424 337.863C27.8918 338.368 28.0498 338.745 28.1683 338.933C30.7166 338.377 32.5241 330.331 33.3142 326.856Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[146] ?? 'WHITE']}
        id="146"
        data-testid="back-146"
      />
      <path
        d="M19.5408 339.351C22.2882 338.307 24.4964 329.85 25.6861 325.306C26.1825 323.414 26.5762 321.899 26.987 320.744C25.746 320.204 24.0081 319.605 22.8869 318.955C20.2593 325.451 17.1096 335.354 18.3678 338.495C18.5561 338.949 19.4124 339.299 19.5408 339.351Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[145] ?? 'WHITE']}
        id="145"
        data-testid="back-145"
      />
      <path
        d="M8.31223 334.544C11.0168 333.894 14.1836 327.064 16.1179 322.887C17.513 319.874 18.4888 317.829 19.4559 316.588C18.0351 315.509 16.3901 314.224 15.2089 313.06C11.8624 319.898 7.44601 330.101 7.96811 334.329L8.31223 334.544Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[144] ?? 'WHITE']}
        id="144"
        data-testid="back-144"
      />
      <path
        d="M6.05036 314.977C7.29146 314.524 9.78206 312.101 12.0502 309.611C10.4326 307.642 9.12306 305.716 8.10456 304.039C6.06746 306.846 4.37286 309.585 3.70526 311.639L3.63676 311.853C2.86646 314.198 2.96056 314.892 3.08046 315.097C3.39706 315.32 4.98046 315.362 6.05036 314.977Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[143] ?? 'WHITE']}
        id="143"
        data-testid="back-143"
      />
      <path
        d="M39.9233 307.615C39.0987 312.888 38.1617 317.663 37.1777 320.531C37.5995 320.822 37.8525 321.267 37.9742 321.849C39.1362 321.934 40.3169 321.986 41.5538 321.977C41.8536 318.742 42.1629 315.729 42.8844 313.743C43.1656 312.973 43.4748 312.177 43.8028 311.347C44.2244 310.269 44.6648 309.139 45.0771 307.958C43.2873 307.915 41.5632 307.804 39.9233 307.615Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[142] ?? 'WHITE']}
        id="142"
        data-testid="back-142"
      />
      <path
        d="M30.2168 318.336L30.9443 318.447L31.4236 319.106C31.706 319.526 31.8344 319.971 31.7659 320.878C32.7759 321.178 33.413 321.286 34.5 321.5C34.714 321.106 34.9622 320.832 35.2789 320.618C35.5185 320.455 35.7838 320.353 36.0577 320.31C36.8366 317.845 37.1961 312.946 37.9835 307.375C34.2775 306.767 31.008 305.748 28.1579 304.49C26.5574 308.967 24.9996 312.818 23.5789 315.754L24.1095 316.096L23.5703 317.346C24.6915 318.005 26.7504 318.952 28 319.5C28.5649 318.567 29.2325 318.182 30.2168 318.336Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[141] ?? 'WHITE']}
        id="141"
        data-testid="back-141"
      />
      <path
        d="M26.574 303.737C25.2817 303.095 24.0834 302.402 22.9707 301.683C21.9266 303.18 19.7098 306.236 17.5273 308.487C17.0908 309.292 16.5781 310.293 15.9961 311.44C17.3056 312.758 18.7692 314.05 20.3954 315.257C20.5666 315.163 20.7463 315.077 20.9346 315.026C21.3112 314.915 21.6878 314.906 22.0387 314.975C23.4424 312.082 24.9735 308.23 26.574 303.737Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[139] ?? 'WHITE']}
        id="139"
        data-testid="back-139"
      />
      <path
        d="M13.2052 308.316C13.7787 307.657 14.3179 307.016 14.7972 306.426L16.2351 307.384C17.9982 305.544 20.0695 302.848 21.5587 300.734C18.9825 298.902 16.954 296.968 15.4134 295.239C13.5048 297.276 11.2538 299.895 9.2168 302.557C10.201 304.252 11.5277 306.245 13.2052 308.316Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[138] ?? 'WHITE']}
        id="138"
        data-testid="back-138"
      />
      <path
        d="M46.1275 295.735C46.0994 295.333 46.0715 294.947 46.0527 294.588C44.9977 294.699 43.9706 294.751 42.981 294.751C42.4487 294.751 41.9258 294.733 41.403 294.708C40.9828 298.285 40.4786 302.188 39.9277 305.929C41.6457 306.125 43.4572 306.237 45.3525 306.262C45.8006 304.782 46.1742 303.25 46.3702 301.658C46.473 300.802 46.2862 298.106 46.1275 295.735Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[137] ?? 'WHITE']}
        id="137"
        data-testid="back-137"
      />
      <path
        d="M38.2156 305.679C38.6949 302.127 39.1571 298.301 39.568 294.527C36.812 294.159 34.3641 293.328 32.2244 292.258C31.0518 296.007 29.8793 299.576 28.7324 302.854C31.4798 304.087 34.6295 305.088 38.2156 305.679Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[136] ?? 'WHITE']}
        id="136"
        data-testid="back-136"
      />
      <path
        d="M27.1559 302.109C28.3028 298.805 29.4839 295.194 30.665 291.402C28.2258 289.947 26.2401 288.218 24.708 286.6C22.1832 288.851 19.1875 291.53 17.9208 292.712C17.5271 293.08 17.0821 293.516 16.6113 293.995C18.8794 296.572 22.3287 299.67 27.1559 302.109Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[135] ?? 'WHITE']}
        id="135"
        data-testid="back-135"
      />
      <path
        d="M45.2847 280.686C44.8111 280.686 44.3469 280.669 43.8825 280.652C43.4183 281.465 42.9725 282.098 42.5547 282.509C42.369 284.495 41.9883 288.398 41.459 293.019C42.8333 293.079 44.2726 293.045 45.7861 292.882C45.5633 288.997 45.6747 288.055 46.2411 287.396C46.6033 286.797 47.9492 284.118 49.7512 280.344C48.2009 280.575 46.7054 280.686 45.2847 280.686Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[134] ?? 'WHITE']}
        id="134"
        data-testid="back-134"
      />
      <path
        d="M40.8248 281.683L41.1072 281.452C41.3468 281.255 41.6036 280.922 41.8775 280.476C39.8062 280.203 37.8976 279.672 36.1687 278.962C35.0389 282.959 33.8749 286.896 32.7109 290.687C34.7479 291.731 37.0845 292.536 39.7463 292.878C40.1572 289.035 40.5166 285.304 40.7991 282.043L40.8248 281.683Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[133] ?? 'WHITE']}
        id="133"
        data-testid="back-133"
      />
      <path
        d="M31.1455 289.811C32.2924 286.07 33.4393 282.176 34.5519 278.23C32.883 277.4 31.4023 276.424 30.0927 275.406C29.9729 276.912 29.8959 278.205 29.8959 279.172C29.8873 280.961 29.1085 282.672 27.7733 283.862C27.2769 284.299 26.6349 284.872 25.916 285.514C27.2769 286.96 29.0143 288.501 31.1455 289.811Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[132] ?? 'WHITE']}
        id="132"
        data-testid="back-132"
      />
      <path
        d="M48.4016 268.493C47.0373 272.721 45.6992 276.394 44.4883 278.995C46.2867 279.021 48.203 278.859 50.2357 278.465C51.6908 275.289 53.3718 271.549 55.0349 267.698C52.676 268.237 50.4712 268.484 48.4016 268.493Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[131] ?? 'WHITE']}
        id="131"
        data-testid="back-131"
      />
      <path
        d="M42.7072 278.889C43.7857 276.569 45.0524 272.906 46.4132 268.438C43.8541 268.267 41.5432 267.702 39.472 266.881C38.5562 270.364 37.5976 273.89 36.6133 277.391C38.4278 278.136 40.4648 278.675 42.7072 278.889Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[130] ?? 'WHITE']}
        id="130"
        data-testid="back-130"
      />
      <path
        d="M30.2715 273.458C31.6066 274.604 33.1815 275.717 35.0045 276.65C35.9803 273.158 36.9388 269.64 37.8547 266.165C35.364 264.959 33.2756 263.427 31.581 261.895C31.0503 266.114 30.5796 270.162 30.2715 273.458Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[129] ?? 'WHITE']}
        id="129"
        data-testid="back-129"
      />
      <path
        d="M53.0483 253.607C52.8605 253.607 52.682 253.598 52.4942 253.589C51.2711 258.143 49.9844 262.679 48.707 266.787C50.8328 266.719 53.1193 266.377 55.5676 265.692C57.4961 261.113 59.3359 256.491 60.7385 252.545C57.9876 253.281 55.4246 253.607 53.0483 253.607Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[128] ?? 'WHITE']}
        id="128"
        data-testid="back-128"
      />
      <path
        d="M43.3333 251.577C42.2976 255.908 41.1336 260.538 39.8926 265.289C41.9638 266.11 44.3004 266.667 46.9197 266.778C48.1007 262.789 49.3417 258.253 50.5827 253.486C47.8867 253.229 45.473 252.527 43.3333 251.577Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[127] ?? 'WHITE']}
        id="127"
        data-testid="back-127"
      />
      <path
        d="M38.2749 264.562C39.5245 259.778 40.6971 255.122 41.7327 250.783C38.2407 248.9 35.6046 246.409 33.8072 244.321C33.4648 247.111 33.0711 250.166 32.6689 253.29C32.3864 255.456 32.104 257.672 31.8301 259.881C33.4819 261.533 35.6217 263.253 38.2749 264.562Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[126] ?? 'WHITE']}
        id="126"
        data-testid="back-126"
      />
      <path
        d="M56.58 238.705C56.4441 238.705 56.3093 238.697 56.1734 238.697C55.1642 242.771 54.0013 247.324 52.7656 251.912C55.3717 251.912 58.2201 251.501 61.3308 250.568C61.9976 248.582 62.5211 246.828 62.8277 245.433L62.8993 245.116C63.2964 243.301 63.8821 240.631 64.4773 237.618C61.6468 238.372 59.0133 238.705 56.58 238.705Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[125] ?? 'WHITE']}
        id="125"
        data-testid="back-125"
      />
      <path
        d="M46.6715 236.602C45.9098 240.385 44.8999 244.938 43.7188 249.962C45.8414 250.929 48.2635 251.64 51.0025 251.854C52.1145 247.514 53.2196 243.021 54.2635 238.613C51.4305 238.356 48.8965 237.62 46.6715 236.602Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[124] ?? 'WHITE']}
        id="124"
        data-testid="back-124"
      />
      <path
        d="M42.1188 249.157C43.3171 244.098 44.327 239.528 45.0802 235.796C40.7665 233.434 37.7795 230.139 36.0934 227.888C35.4086 230.267 34.9636 232.338 34.8865 233.836C34.801 235.659 34.5014 238.552 34.082 242.019C35.6483 244.098 38.3015 247.026 42.1188 249.157Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[123] ?? 'WHITE']}
        id="123"
        data-testid="back-123"
      />
      <path
        d="M59.8394 223.812C59.7219 223.932 59.6043 224.052 59.4772 224.155C59.0502 226.004 57.9178 230.856 56.3945 237.019C58.9242 237.002 61.689 236.617 64.6816 235.735C65.4612 231.618 66.1954 227.091 66.4761 223.273C64.0831 223.633 61.8702 223.804 59.8394 223.812Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[122] ?? 'WHITE']}
        id="122"
        data-testid="back-122"
      />
      <path
        d="M49.2471 222.024C48.7421 224.404 48.3141 226.8 47.9981 229.103C47.7921 230.626 47.4501 232.62 46.9961 234.957C49.2041 236.001 51.7461 236.754 54.6481 236.96C55.7351 232.338 56.7451 227.844 57.6351 223.753C54.2881 223.574 51.5071 222.906 49.2471 222.024Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[121] ?? 'WHITE']}
        id="121"
        data-testid="back-121"
      />
      <path
        d="M45.0453 219.869C42.8799 218.431 41.3821 216.873 40.3978 215.623C39.0455 219.081 37.7189 222.667 36.709 225.86C37.9842 227.794 40.8515 231.517 45.4134 234.136C45.8156 232.031 46.1238 230.259 46.3035 228.881C46.6373 226.433 47.0993 223.874 47.6473 221.341C46.6633 220.87 45.7985 220.365 45.0453 219.869Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[120] ?? 'WHITE']}
        id="120"
        data-testid="back-120"
      />
      <path
        d="M60.8711 222.086C62.5997 222.017 64.4585 221.838 66.4477 221.53C66.4564 221.213 66.4749 220.888 66.4749 220.58C66.4846 219.621 67.0047 218.003 68.0547 215.393C66.2892 215.264 64.6442 215.102 63.1013 214.896C62.6094 217.729 61.9211 220.246 60.8711 222.086Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[119] ?? 'WHITE']}
        id="119"
        data-testid="back-119"
      />
      <path
        d="M51.7547 211.847C51.0187 214.484 50.2657 217.394 49.5977 220.372C52.0547 221.365 55.0927 222.058 58.8337 222.118C60.0057 220.509 60.7417 217.633 61.2207 214.62C57.3007 213.927 54.1947 212.952 51.7547 211.847Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[118] ?? 'WHITE']}
        id="118"
        data-testid="back-118"
      />
      <path
        d="M48.0141 219.651C48.6901 216.647 49.4611 213.72 50.1971 211.075C47.1241 209.432 45.301 207.635 44.2397 206.188C43.3153 208.388 42.2284 210.998 41.1328 213.754C42.1342 215.235 44.2483 217.76 48.0141 219.651Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[117] ?? 'WHITE']}
        id="117"
        data-testid="back-117"
      />
      <path
        d="M72.0173 205.113C71.3999 205.13 70.7824 205.139 70.1915 205.139C67.9582 205.139 65.9064 205.036 64.0168 204.839C63.9542 205.455 63.8906 206.2 63.8174 206.97C63.636 209.076 63.427 211.19 63.1543 213.184C64.7712 213.406 66.5238 213.586 68.4027 213.715C69.0573 212.123 69.8467 210.231 70.8004 207.98C71.1909 207.073 71.5993 206.114 72.0173 205.113Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[116] ?? 'WHITE']}
        id="116"
        data-testid="back-116"
      />
      <path
        d="M61.4694 212.917C61.7604 210.692 61.9404 208.518 62.0774 206.84C62.1544 205.933 62.2144 205.214 62.2654 204.632C59.2014 204.187 56.6684 203.468 54.5544 202.612C53.9894 204.324 53.1424 206.977 52.2344 210.152C54.5714 211.239 57.5844 212.224 61.4694 212.917Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[115] ?? 'WHITE']}
        id="115"
        data-testid="back-115"
      />
      <path
        d="M50.6847 209.357C51.6007 206.199 52.4387 203.579 52.9867 201.91C50.2137 200.55 48.3397 198.949 47.0727 197.486C46.9867 198.949 46.6787 200.387 46.1055 201.756C45.8316 202.415 45.4978 203.211 45.1126 204.119C45.0955 204.161 45.0698 204.213 45.0527 204.264C45.7802 205.514 47.3717 207.499 50.6847 209.357Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[114] ?? 'WHITE']}
        id="114"
        data-testid="back-114"
      />
      <path
        d="M72.4806 203.383C73.3768 201.192 74.2896 198.881 75.1765 196.562C71.7978 196.305 68.7828 195.817 66.0868 195.167C65.1999 199.181 64.5358 202.125 64.2695 203.143C66.6816 203.4 69.4034 203.494 72.4806 203.383Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[113] ?? 'WHITE']}
        id="113"
        data-testid="back-113"
      />
      <path
        d="M62.548 202.914C62.685 202.512 63.369 199.405 64.362 194.723C61.084 193.773 58.345 192.566 56.052 191.24C55.581 196.555 55.315 200.184 55.307 200.295L55.264 200.518C55.256 200.535 55.213 200.672 55.127 200.92C57.138 201.759 59.569 202.469 62.548 202.923C62.548 202.923 62.548 202.923 62.548 202.914Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[112] ?? 'WHITE']}
        id="112"
        data-testid="back-112"
      />
      <path
        d="M53.5597 200.207C53.5767 200.156 53.5937 200.104 53.6107 200.062C53.6707 199.249 53.9537 195.457 54.4157 190.236C51.0517 188.011 48.8347 185.589 47.4147 183.603C47.1747 188.216 47.1067 191.956 47.0977 194.55C48.0477 196.107 49.9307 198.358 53.5597 200.207Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[111] ?? 'WHITE']}
        id="111"
        data-testid="back-111"
      />
      <path
        d="M78.9465 185.05C75.1373 184.442 71.7544 183.595 68.7449 182.602C67.9015 186.513 67.0841 190.262 66.3828 193.472C69.1173 194.139 72.2253 194.644 75.741 194.893C77.0201 191.426 78.1737 188.011 78.9465 185.05Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[110] ?? 'WHITE']}
        id="110"
        data-testid="back-110"
      />
      <path
        d="M64.7228 193.022C65.3988 189.838 66.1778 186.081 67.0248 182.015C63.1818 180.551 60.0408 178.814 57.4648 177.008C56.9768 181.39 56.5568 185.653 56.2148 189.307C58.4568 190.677 61.2558 191.995 64.7228 193.022Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[109] ?? 'WHITE']}
        id="109"
        data-testid="back-109"
      />
      <path
        d="M52.4977 186.584C53.1147 187.123 53.8157 187.671 54.6037 188.227C54.9457 184.496 55.3737 180.208 55.8787 175.817C52.3607 173.044 50.1097 170.211 48.7237 168.003C48.2017 172.607 47.8417 176.861 47.5938 180.644C48.4068 182.108 49.9127 184.325 52.4977 186.584Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[108] ?? 'WHITE']}
        id="108"
        data-testid="back-108"
      />
      <path
        d="M79.4315 183.376C79.9014 181.313 80.1275 179.533 80.0102 178.206H80.0197C79.3776 175.938 78.5908 173.13 77.7691 170.066C75.6263 169.493 73.6197 168.894 71.7662 168.278C70.8896 172.317 69.9402 176.665 69.0098 180.885C72.0651 181.903 75.5175 182.759 79.4315 183.376Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[107] ?? 'WHITE']}
        id="107"
        data-testid="back-107"
      />
      <path
        d="M67.3909 180.273C68.2299 176.234 69.1199 171.954 70.0009 167.666C65.6189 166.006 62.1359 164.234 59.3879 162.462C58.7719 166.382 58.2069 170.687 57.7109 174.95C60.2439 176.858 63.4279 178.716 67.3909 180.273Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[106] ?? 'WHITE']}
        id="106"
        data-testid="back-106"
      />
      <path
        d="M56.1194 173.691C56.6244 169.471 57.1884 165.252 57.8134 161.409C54.1414 158.781 51.9764 156.213 50.7014 154.082C50.0504 157.891 49.5024 161.571 49.0664 165.08C50.0424 166.998 52.1474 170.327 56.1194 173.691Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[105] ?? 'WHITE']}
        id="105"
        data-testid="back-105"
      />
      <path
        d="M77.6257 168.149C76.5186 164.314 75.3811 160.197 74.4337 156.32C73.7752 159.068 72.9175 162.637 71.9805 166.574C73.7356 167.122 75.6212 167.644 77.6257 168.149Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[104] ?? 'WHITE']}
        id="104"
        data-testid="back-104"
      />
      <path
        d="M70.3554 165.976C71.2114 161.817 72.0584 157.691 72.8464 153.84C68.5494 152.325 65.1264 150.596 62.4124 148.833C62.1134 149.817 61.8394 150.724 61.6084 151.529C60.9324 153.908 60.2904 157.041 59.6914 160.584C62.3784 162.399 65.8534 164.247 70.3554 165.976Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[103] ?? 'WHITE']}
        id="103"
        data-testid="back-103"
      />
      <path
        d="M58.1486 159.466C58.7136 156.213 59.3206 153.312 59.9716 151.052C60.2456 150.102 60.5706 149.015 60.9386 147.817C57.0446 145.01 54.8276 142.185 53.5866 139.994C52.6796 143.803 51.9006 147.543 51.2246 151.198C52.0286 153.201 53.9036 156.239 58.1486 159.466Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[102] ?? 'WHITE']}
        id="102"
        data-testid="back-102"
      />
      <path
        d="M207.168 146.193C209.53 144.542 211.13 142.933 212.2 141.58C213.116 140.425 213.715 139.372 214.117 138.482C213.818 137.275 213.51 136.06 213.184 134.844C212.679 132.953 212.149 131.173 211.601 129.495C209.316 131.001 206.449 132.422 203.059 133.74C204.608 138.302 206.038 142.633 207.168 146.193Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[101] ?? 'WHITE']}
        id="101"
        data-testid="back-101"
      />
      <path
        d="M193.242 136.881C193.559 138.438 193.824 139.851 194.012 141.066L195.142 141.203C195.022 142.179 194.646 144.481 194.132 147.502L194.329 147.459C194.603 148.769 194.911 150.258 195.245 151.893C199.653 150.438 203.059 148.803 205.678 147.168C204.54 143.548 203.059 139.072 201.459 134.33C198.968 135.237 196.221 136.085 193.242 136.881Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[100] ?? 'WHITE']}
        id="100"
        data-testid="back-100"
      />
      <path
        d="M183.039 139.174C183.074 139.517 183.108 139.85 183.133 140.193C183.33 142.35 183.519 144.438 183.69 146.467C186.343 145.679 188.859 144.798 191.264 143.847C191.496 142.427 191.667 141.357 191.735 140.783L192.24 140.843C192.069 139.791 191.846 138.609 191.581 137.3C188.902 137.976 186.052 138.601 183.039 139.174Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[99] ?? 'WHITE']}
        id="99"
        data-testid="back-99"
      />
      <path
        d="M171.246 149.429C175.038 148.727 178.624 147.905 182.022 146.947C181.842 144.816 181.645 142.616 181.44 140.348C181.414 140.057 181.388 139.774 181.354 139.483C177.965 140.091 174.396 140.639 170.664 141.118C170.861 143.848 171.049 146.63 171.246 149.429Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[98] ?? 'WHITE']}
        id="98"
        data-testid="back-98"
      />
      <path
        d="M160.075 151.057C163.362 150.689 166.511 150.244 169.541 149.722C169.344 146.889 169.148 144.09 168.951 141.334C165.938 141.693 162.823 142.01 159.621 142.275C159.784 145.168 159.93 148.104 160.075 151.057Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[97] ?? 'WHITE']}
        id="97"
        data-testid="back-97"
      />
      <path
        d="M145.732 152.124C150.148 151.936 154.359 151.636 158.382 151.234C158.236 148.264 158.082 145.32 157.928 142.41C153.974 142.718 149.891 142.94 145.697 143.095L145.732 152.124Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[96] ?? 'WHITE']}
        id="96"
        data-testid="back-96"
      />
      <path
        d="M144.019 152.193L143.985 143.147C140.724 143.249 137.403 143.301 134.031 143.301C130.847 143.301 127.706 143.258 124.616 143.164L124.582 152.185C127.671 152.305 130.821 152.373 134.031 152.373C137.471 152.373 140.792 152.305 144.019 152.193Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[95] ?? 'WHITE']}
        id="95"
        data-testid="back-95"
      />
      <path
        d="M122.871 152.108L122.905 143.112C118.712 142.967 114.629 142.744 110.675 142.453C110.521 145.32 110.375 148.222 110.23 151.149C114.312 151.586 118.532 151.902 122.871 152.108Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[94] ?? 'WHITE']}
        id="94"
        data-testid="back-94"
      />
      <path
        d="M99.0684 149.601C102.132 150.132 105.281 150.585 108.534 150.97C108.679 148.06 108.825 145.168 108.979 142.317C105.778 142.061 102.662 141.753 99.6504 141.393C99.4444 144.098 99.2564 146.837 99.0684 149.601Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[93] ?? 'WHITE']}
        id="93"
        data-testid="back-93"
      />
      <path
        d="M97.3738 149.309C97.5618 146.57 97.7508 143.857 97.9468 141.187C94.2158 140.724 90.6468 140.185 87.2488 139.586C87.2228 139.843 87.1968 140.1 87.1718 140.348C86.9578 142.607 86.7688 144.781 86.5898 146.904C90.0128 147.82 93.6168 148.624 97.3738 149.309Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[92] ?? 'WHITE']}
        id="92"
        data-testid="back-92"
      />
      <path
        d="M84.911 146.433C85.083 144.413 85.271 142.334 85.468 140.194C85.493 139.886 85.528 139.586 85.553 139.278C82.592 138.722 79.785 138.123 77.131 137.472C76.643 139.697 76.233 141.726 75.916 143.472C78.749 144.55 81.753 145.543 84.911 146.433Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[91] ?? 'WHITE']}
        id="91"
        data-testid="back-91"
      />
      <path
        d="M73.7632 152.131C73.7727 152.08 73.7811 152.037 73.7991 151.985C72.7066 146.405 72.182 141.869 72.9146 140.302L75.2451 141.278C75.5164 139.977 75.832 138.556 76.175 137.05C72.9779 136.263 70.014 135.415 67.3225 134.508C65.6685 139.147 64.1422 143.529 62.959 147.09C65.7139 148.861 69.2467 150.599 73.7632 152.131Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[90] ?? 'WHITE']}
        id="90"
        data-testid="back-90"
      />
      <path
        d="M61.4877 146.046C62.5917 142.562 63.9777 138.351 65.4757 133.918C61.8817 132.548 58.8517 131.068 56.4547 129.484C55.9067 131.162 55.3767 132.942 54.8717 134.834C54.6487 135.681 54.4267 136.52 54.2207 137.358C55.0257 139.284 56.9597 142.631 61.4877 146.046Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[89] ?? 'WHITE']}
        id="89"
        data-testid="back-89"
      />
      <path
        d="M198 118.992C199.463 123.203 201.03 127.731 202.51 132.105C205.968 130.786 208.853 129.366 211.052 127.868C209.418 123.186 207.586 119.352 205.592 116.176C203.332 117.178 200.799 118.119 198 118.992Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[88] ?? 'WHITE']}
        id="88"
        data-testid="back-88"
      />
      <path
        d="M189.596 121.279C190.846 126.329 192.019 131.13 192.883 135.144C195.793 134.383 198.481 133.561 200.911 132.697C199.413 128.289 197.839 123.727 196.366 119.49C194.235 120.123 191.976 120.723 189.596 121.279Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[87] ?? 'WHITE']}
        id="87"
        data-testid="back-87"
      />
      <path
        d="M181.234 123.005C181.808 127.559 182.373 132.42 182.869 137.393C185.813 136.828 188.604 136.22 191.231 135.57C190.367 131.581 189.194 126.746 187.927 121.662C185.788 122.141 183.554 122.586 181.234 123.005Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[86] ?? 'WHITE']}
        id="86"
        data-testid="back-86"
      />
      <path
        d="M170.528 139.345C174.243 138.865 177.803 138.318 181.176 137.701C180.679 132.729 180.123 127.85 179.541 123.297C176.314 123.844 172.925 124.341 169.398 124.769C169.775 129.373 170.152 134.269 170.528 139.345Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[85] ?? 'WHITE']}
        id="85"
        data-testid="back-85"
      />
      <path
        d="M159.536 140.516C162.72 140.242 165.819 139.925 168.823 139.557C168.446 134.482 168.07 129.578 167.693 124.964C164.783 125.298 161.787 125.589 158.715 125.837C158.989 130.494 159.263 135.432 159.536 140.516Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[84] ?? 'WHITE']}
        id="84"
        data-testid="back-84"
      />
      <path
        d="M145.637 126.587L145.688 141.368C149.831 141.214 153.888 140.975 157.834 140.658C157.56 135.565 157.286 130.627 157.012 125.971C153.323 126.245 149.523 126.45 145.637 126.587Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[83] ?? 'WHITE']}
        id="83"
        data-testid="back-83"
      />
      <path
        d="M134.031 141.588C137.395 141.588 140.707 141.536 143.977 141.425L143.926 126.644C140.682 126.738 137.387 126.789 134.031 126.789C130.865 126.789 127.749 126.746 124.677 126.652L124.625 141.434C127.715 141.536 130.856 141.588 134.031 141.588Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[82] ?? 'WHITE']}
        id="82"
        data-testid="back-82"
      />
      <path
        d="M110.77 140.704C114.716 141.012 118.772 141.243 122.915 141.389L122.966 126.608C119.072 126.479 115.28 126.274 111.592 126.008C111.318 130.673 111.044 135.612 110.77 140.704Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[81] ?? 'WHITE']}
        id="81"
        data-testid="back-81"
      />
      <path
        d="M109.065 140.559C109.339 135.475 109.613 130.537 109.887 125.872C106.814 125.632 103.81 125.35 100.9 125.025C100.532 129.638 100.147 134.542 99.7695 139.618C102.783 139.986 105.881 140.294 109.065 140.559Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[80] ?? 'WHITE']}
        id="80"
        data-testid="back-80"
      />
      <path
        d="M87.4199 137.799C90.7999 138.398 94.3609 138.938 98.0749 139.417C98.4519 134.341 98.8289 129.446 99.2049 124.841C95.6789 124.422 92.2899 123.934 89.0539 123.395C88.4719 127.948 87.9159 132.826 87.4199 137.799Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[79] ?? 'WHITE']}
        id="79"
        data-testid="back-79"
      />
      <path
        d="M77.5156 135.737C80.1006 136.362 82.8476 136.952 85.7326 137.491C86.2286 132.519 86.7856 127.649 87.3676 123.095C85.0906 122.693 82.8906 122.257 80.7766 121.803C79.6046 126.681 78.4576 131.5 77.5156 135.737Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[78] ?? 'WHITE']}
        id="78"
        data-testid="back-78"
      />
      <path
        d="M75.8469 135.326C76.7799 131.149 77.9099 126.391 79.0999 121.435C76.6689 120.879 74.3579 120.28 72.1839 119.646C70.7039 123.9 69.1289 128.47 67.6309 132.878C70.1299 133.743 72.8779 134.564 75.8469 135.326Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[77] ?? 'WHITE']}
        id="77"
        data-testid="back-77"
      />
      <path
        d="M66.0308 132.293C67.5198 127.911 69.0868 123.374 70.5498 119.146C67.5628 118.222 64.8588 117.229 62.4708 116.176C60.4678 119.36 58.6448 123.186 57.0098 127.868C59.3118 129.443 62.3588 130.923 66.0308 132.293Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[76] ?? 'WHITE']}
        id="76"
        data-testid="back-76"
      />
      <path
        d="M195.21 105.477C194.662 105.751 194.115 106.025 193.541 106.281C194.585 109.226 195.938 113.077 197.427 117.34C200.08 116.518 202.502 115.653 204.651 114.738C201.818 110.638 198.659 107.719 195.21 105.477Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[75] ?? 'WHITE']}
        id="75"
        data-testid="back-75"
      />
      <path
        d="M186.574 109.05C187.302 112.046 188.064 115.127 188.817 118.165C188.937 118.627 189.048 119.098 189.159 119.56C191.487 119.021 193.704 118.448 195.775 117.84C194.32 113.663 192.985 109.889 191.958 106.979C190.28 107.706 188.483 108.399 186.574 109.05Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[74] ?? 'WHITE']}
        id="74"
        data-testid="back-74"
      />
      <path
        d="M181.004 121.224C183.246 120.822 185.42 120.386 187.491 119.932C187.38 119.478 187.269 119.025 187.157 118.571C186.413 115.576 185.66 112.537 184.941 109.576C183.237 110.124 181.466 110.637 179.617 111.134C180.062 114.18 180.533 117.587 181.004 121.224Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[73] ?? 'WHITE']}
        id="73"
        data-testid="back-73"
      />
      <path
        d="M169.261 123.001C172.753 122.565 176.117 122.077 179.318 121.529C178.855 117.943 178.385 114.588 177.948 111.566C174.953 112.311 171.786 112.979 168.465 113.569C168.722 116.513 168.987 119.672 169.261 123.001Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[72] ?? 'WHITE']}
        id="72"
        data-testid="back-72"
      />
      <path
        d="M158.072 115.067C158.244 117.908 158.432 120.93 158.612 124.097C161.667 123.848 164.654 123.549 167.556 123.215C167.29 119.911 167.025 116.787 166.777 113.869C163.978 114.322 161.068 114.725 158.072 115.067Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[71] ?? 'WHITE']}
        id="71"
        data-testid="back-71"
      />
      <path
        d="M145.63 124.862C149.473 124.717 153.239 124.503 156.91 124.22C156.722 121.079 156.542 118.075 156.371 115.251C152.888 115.619 149.293 115.901 145.596 116.089L145.63 124.862Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[70] ?? 'WHITE']}
        id="70"
        data-testid="back-70"
      />
      <path
        d="M134.033 125.08C137.371 125.08 140.674 125.029 143.918 124.926L143.884 116.171C140.666 116.308 137.379 116.393 134.033 116.393C130.866 116.393 127.768 116.325 124.721 116.196L124.686 124.943C127.759 125.038 130.883 125.08 134.033 125.08Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[69] ?? 'WHITE']}
        id="69"
        data-testid="back-69"
      />
      <path
        d="M122.971 124.882L123.006 116.118C119.308 115.938 115.713 115.664 112.23 115.305C112.059 118.129 111.879 121.125 111.691 124.266C115.362 124.54 119.128 124.745 122.971 124.882Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[68] ?? 'WHITE']}
        id="68"
        data-testid="back-68"
      />
      <path
        d="M101.047 123.267C103.948 123.6 106.935 123.891 109.991 124.14C110.179 120.981 110.359 117.969 110.53 115.127C107.534 114.793 104.624 114.408 101.826 113.955C101.577 116.865 101.312 119.98 101.047 123.267Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[67] ?? 'WHITE']}
        id="67"
        data-testid="back-67"
      />
      <path
        d="M99.3423 123.065C99.6163 119.744 99.8814 116.594 100.13 113.659C96.8094 113.085 93.6334 112.426 90.6384 111.69C90.2014 114.694 89.7393 118.041 89.2773 121.61C92.4873 122.149 95.8503 122.637 99.3423 123.065Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[66] ?? 'WHITE']}
        id="66"
        data-testid="back-66"
      />
      <path
        d="M81.5254 118.677C81.4134 119.139 81.3024 119.61 81.1914 120.072C83.2454 120.517 85.3764 120.937 87.5844 121.322C88.0554 117.701 88.5264 114.312 88.9624 111.274C87.1224 110.794 85.3504 110.289 83.6564 109.759C82.9714 112.677 82.2434 115.69 81.5254 118.677Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[65] ?? 'WHITE']}
        id="65"
        data-testid="back-65"
      />
      <path
        d="M72.7754 117.981C74.8984 118.588 77.1494 119.162 79.5194 119.701C79.6314 119.23 79.7514 118.751 79.8624 118.272C80.5894 115.242 81.3254 112.178 82.0274 109.225C80.1014 108.583 78.2704 107.898 76.5674 107.188C75.5314 110.089 74.2134 113.847 72.7754 117.981Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[64] ?? 'WHITE']}
        id="64"
        data-testid="back-64"
      />
      <path
        d="M63.4121 114.748C65.6891 115.716 68.2741 116.64 71.1241 117.504C72.5961 113.285 73.9481 109.45 74.9921 106.498C74.2561 106.164 73.5461 105.83 72.8531 105.488C69.4031 107.722 66.2451 110.64 63.4121 114.748Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[63] ?? 'WHITE']}
        id="63"
        data-testid="back-63"
      />
      <path
        d="M186.155 107.308C188.012 106.666 189.75 105.998 191.376 105.297C191.042 104.355 190.751 103.542 190.52 102.9C188.612 102.001 186.634 101.231 184.58 100.512C185.06 102.66 185.59 104.946 186.155 107.308Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[62] ?? 'WHITE']}
        id="62"
        data-testid="back-62"
      />
      <path
        d="M179.359 109.37C181.14 108.891 182.86 108.386 184.52 107.847C183.853 105.057 183.228 102.369 182.689 99.8696C181.037 99.3216 179.351 98.7997 177.613 98.2697C177.973 100.366 178.606 104.278 179.359 109.37Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[61] ?? 'WHITE']}
        id="61"
        data-testid="back-61"
      />
      <path
        d="M168.318 111.824C171.579 111.242 174.711 110.566 177.698 109.812C176.825 103.89 176.098 99.5326 175.798 97.7526C173.393 98.9596 170.552 100.055 167.359 101.005C167.633 104.018 167.958 107.681 168.318 111.824Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[60] ?? 'WHITE']}
        id="60"
        data-testid="back-60"
      />
      <path
        d="M157.972 113.326C160.933 112.984 163.826 112.573 166.625 112.102C166.274 108.054 165.957 104.459 165.683 101.481C163.099 102.191 160.308 102.807 157.338 103.329C157.527 106.256 157.741 109.629 157.972 113.326Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[59] ?? 'WHITE']}
        id="59"
        data-testid="back-59"
      />
      <path
        d="M145.561 104.787L145.595 114.364C149.233 114.167 152.793 113.885 156.268 113.517C156.046 109.854 155.832 106.524 155.643 103.606C152.451 104.119 149.079 104.513 145.561 104.787Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[58] ?? 'WHITE']}
        id="58"
        data-testid="back-58"
      />
      <path
        d="M143.882 114.45L143.848 104.907C140.681 105.112 137.403 105.224 134.03 105.224C130.855 105.224 127.757 105.121 124.761 104.941L124.727 114.467C127.791 114.596 130.898 114.673 134.03 114.673C137.351 114.681 140.638 114.604 143.882 114.45Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[57] ?? 'WHITE']}
        id="57"
        data-testid="back-57"
      />
      <path
        d="M123.015 114.399L123.05 104.83C119.523 104.573 116.151 104.188 112.959 103.691C112.77 106.601 112.556 109.931 112.334 113.577C115.809 113.945 119.378 114.219 123.015 114.399Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[56] ?? 'WHITE']}
        id="56"
        data-testid="back-56"
      />
      <path
        d="M110.632 113.388C110.863 109.699 111.077 106.344 111.265 103.417C108.296 102.912 105.505 102.313 102.912 101.62C102.638 104.598 102.321 108.167 101.979 112.19C104.778 112.661 107.671 113.054 110.632 113.388Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[55] ?? 'WHITE']}
        id="55"
        data-testid="back-55"
      />
      <path
        d="M90.8945 109.938C93.8815 110.674 97.0235 111.333 100.284 111.907C100.635 107.799 100.961 104.161 101.234 101.157C98.0415 100.232 95.1995 99.1706 92.7695 97.9896C92.4435 99.9236 91.7335 104.212 90.8945 109.938Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[54] ?? 'WHITE']}
        id="54"
        data-testid="back-54"
      />
      <path
        d="M89.2265 109.51C90.0045 104.212 90.6725 100.163 91.0145 98.0916C90.8355 98.1516 90.6465 98.2026 90.4585 98.2626C88.9265 98.7336 87.4204 99.2046 85.9474 99.6746C85.4084 102.2 84.7665 105.025 84.0645 108.012C85.7255 108.542 87.4455 109.039 89.2265 109.51Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[53] ?? 'WHITE']}
        id="53"
        data-testid="back-53"
      />
      <path
        d="M82.4302 107.488C83.0292 104.946 83.5772 102.515 84.0652 100.307C82.0362 101 80.0762 101.745 78.1852 102.592C77.9282 103.328 77.5772 104.304 77.1582 105.494C78.8012 106.196 80.5562 106.863 82.4302 107.488Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[52] ?? 'WHITE']}
        id="52"
        data-testid="back-52"
      />
      <path
        d="M166.76 94.6166C166.871 95.8066 167.016 97.3556 167.188 99.2126C169.473 98.5276 171.561 97.7666 173.418 96.9536C171.912 96.4736 170.38 95.9606 168.814 95.4036C168.103 95.1476 167.427 94.8906 166.76 94.6166Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[51] ?? 'WHITE']}
        id="51"
        data-testid="back-51"
      />
      <path
        d="M156.541 91.4937C156.635 92.8457 156.884 96.4067 157.217 101.559C160.179 101.029 162.952 100.404 165.519 99.6937C165.288 97.1427 165.1 95.1487 164.972 93.8307C162.883 92.8547 161.103 91.8017 159.571 90.7067C158.63 90.9887 157.62 91.2547 156.541 91.4937Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[50] ?? 'WHITE']}
        id="50"
        data-testid="back-50"
      />
      <path
        d="M145.552 103.055C149.027 102.773 152.365 102.362 155.523 101.849C155.198 96.8926 154.958 93.3746 154.855 91.8516C152.014 92.4076 148.847 92.8356 145.518 93.1356L145.552 103.055Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[49] ?? 'WHITE']}
        id="49"
        data-testid="back-49"
      />
      <path
        d="M134.031 103.52C137.378 103.52 140.656 103.4 143.84 103.186L143.805 93.2836C140.604 93.5226 137.301 93.6516 134.031 93.6516C130.95 93.6516 127.826 93.5396 124.805 93.3256L124.77 103.229C127.775 103.417 130.873 103.52 134.031 103.52Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[48] ?? 'WHITE']}
        id="48"
        data-testid="back-48"
      />
      <path
        d="M123.06 103.092L123.094 93.1806C119.773 92.8986 116.598 92.4876 113.748 91.9486C113.636 93.5316 113.397 97.0326 113.08 101.928C116.238 102.442 119.585 102.827 123.06 103.092Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[47] ?? 'WHITE']}
        id="47"
        data-testid="back-47"
      />
      <path
        d="M111.376 101.654C111.71 96.5947 111.949 93.0517 112.052 91.6137C110.785 91.3397 109.587 91.0407 108.491 90.7067C107.105 91.6907 105.504 92.6497 103.664 93.5477C103.536 94.8827 103.33 97.0227 103.074 99.8477C105.641 100.532 108.432 101.14 111.376 101.654Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[46] ?? 'WHITE']}
        id="46"
        data-testid="back-46"
      />
      <path
        d="M101.396 99.3755C101.585 97.3215 101.747 95.6265 101.867 94.3685C101.028 94.7285 100.172 95.0795 99.2398 95.4045C97.6818 95.9605 96.1498 96.4745 94.6348 96.9535C96.6378 97.8355 98.9058 98.6395 101.396 99.3755Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[45] ?? 'WHITE']}
        id="45"
        data-testid="back-45"
      />
      <path
        d="M152.168 82.3416C150.054 82.6756 147.803 82.9486 145.475 83.1546L145.509 91.4396C150.508 90.9856 154.676 90.2586 157.877 89.4106C155.001 87.0056 153.247 84.5156 152.168 82.3416Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[44] ?? 'WHITE']}
        id="44"
        data-testid="back-44"
      />
      <path
        d="M134.032 91.9296C137.541 91.9296 140.802 91.8016 143.797 91.5786L143.763 83.2936C140.579 83.5336 137.284 83.6536 134.032 83.6536C130.959 83.6536 127.852 83.5416 124.839 83.3276L124.805 91.6046C127.655 91.8186 130.728 91.9296 134.032 91.9296Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[43] ?? 'WHITE']}
        id="43"
        data-testid="back-43"
      />
      <path
        d="M123.102 91.4855L123.136 83.2005C120.62 82.9865 118.181 82.6955 115.904 82.3365C114.834 84.5105 113.079 87.0095 110.195 89.4055C113.499 90.2875 117.864 91.0405 123.102 91.4855Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[42] ?? 'WHITE']}
        id="42"
        data-testid="back-42"
      />
      <path
        d="M151.477 80.7265C149.636 81.0175 147.634 81.2575 145.477 81.4545L145.441 72.6385C147.205 72.4765 148.917 72.2875 150.561 72.0565C150.007 74.9542 150.433 77.9809 151.477 80.7265Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[41] ?? 'WHITE']}
        id="41"
        data-testid="back-41"
      />
      <path
        d="M143.763 81.5986L143.729 72.7826C140.554 73.0226 137.276 73.1426 134.032 73.1426C130.976 73.1426 127.887 73.0306 124.874 72.8256L124.84 81.6416C127.673 81.8386 130.737 81.9496 134.023 81.9496C137.532 81.9496 140.776 81.8206 143.763 81.5986Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[40] ?? 'WHITE']}
        id="40"
        data-testid="back-40"
      />
      <path
        d="M123.171 72.6876L123.137 81.5036C120.766 81.2976 118.584 81.0406 116.598 80.7326C117.641 77.9883 118.062 74.9598 117.514 72.0626C119.328 72.3106 121.228 72.5246 123.171 72.6876Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[39] ?? 'WHITE']}
        id="39"
        data-testid="back-39"
      />
      <path
        d="M161.301 57.4396C161.207 57.7476 161.112 58.0476 161.01 58.3296C163.62 58.5266 165.563 58.0386 166.907 56.8486C167.823 56.0356 168.507 54.8376 168.952 53.2626C166.838 54.8206 164.262 56.2326 161.301 57.4396Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[38] ?? 'WHITE']}
        id="38"
        data-testid="back-38"
      />
      <path
        d="M153.109 69.9447C153.563 69.8677 153.999 69.7817 154.427 69.6967C156.687 67.0777 157.842 61.8646 158.116 59.7166L158.262 58.5616C157.226 58.9116 156.147 59.2377 155.035 59.5367C154.538 63.7227 153.914 67.4287 153.109 69.9447Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[37] ?? 'WHITE']}
        id="37"
        data-testid="back-37"
      />
      <path
        d="M145.434 70.9336C147.496 70.7446 149.422 70.5136 151.194 70.2486C152.058 68.0316 152.743 64.3256 153.274 59.9866C150.792 60.5856 148.164 61.0736 145.408 61.4246L145.434 70.9336Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[36] ?? 'WHITE']}
        id="36"
        data-testid="back-36"
      />
      <path
        d="M134.032 71.4246C137.516 71.4246 140.751 71.2956 143.721 71.0736L143.687 61.6326C140.597 61.9666 137.37 62.1466 134.032 62.1466C130.891 62.1466 127.844 61.9836 124.917 61.6846L124.883 71.1076C127.707 71.3126 130.763 71.4246 134.032 71.4246Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[35] ?? 'WHITE']}
        id="35"
        data-testid="back-35"
      />
      <path
        d="M123.17 70.9835L123.204 61.5005C120.628 61.1835 118.163 60.7555 115.818 60.2335C116.357 64.5735 117.05 68.2535 117.932 70.4105C119.566 70.6325 121.312 70.8215 123.17 70.9835Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[34] ?? 'WHITE']}
        id="34"
        data-testid="back-34"
      />
      <path
        d="M109.948 59.7126C110.213 61.8616 111.377 67.0736 113.637 69.6926C114.398 69.8386 115.186 69.9836 116.016 70.1206C115.203 67.6556 114.561 63.9926 114.065 59.8156C112.584 59.4386 111.172 59.0196 109.811 58.5656L109.948 59.7126Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[33] ?? 'WHITE']}
        id="33"
        data-testid="back-33"
      />
      <path
        d="M101.157 56.8376C102.415 57.9596 104.204 58.4556 106.574 58.3446C106.472 58.0016 106.378 57.6256 106.283 57.2406C103.519 56.0766 101.105 54.7326 99.1113 53.2516C99.5563 54.8266 100.241 56.0246 101.157 56.8376Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[32] ?? 'WHITE']}
        id="32"
        data-testid="back-32"
      />
      <path
        d="M161.848 55.3297C164.912 53.9767 167.479 52.4026 169.439 50.6646C169.551 49.6806 169.602 48.6017 169.585 47.4207C169.568 46.1117 169.414 44.9726 169.131 43.9886C167.702 44.9216 165.716 45.7436 163.337 46.4536C162.969 49.3806 162.472 52.6417 161.848 55.3297Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[31] ?? 'WHITE']}
        id="31"
        data-testid="back-31"
      />
      <path
        d="M156.046 48.1165C155.841 51.3265 155.584 54.5955 155.25 57.6765C156.885 57.2145 158.442 56.6925 159.906 56.1275C160.531 53.7225 161.087 50.3585 161.541 46.9525C159.855 47.3895 158.006 47.7745 156.046 48.1165Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[30] ?? 'WHITE']}
        id="30"
        data-testid="back-30"
      />
      <path
        d="M145.355 49.3925L145.389 59.6975C148.23 59.3125 150.944 58.7995 153.477 58.1575C153.819 55.0505 154.085 51.7035 154.299 48.4005C151.491 48.8285 148.47 49.1615 145.355 49.3925Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[29] ?? 'WHITE']}
        id="29"
        data-testid="back-29"
      />
      <path
        d="M143.678 59.9125L143.644 49.5135C140.477 49.7185 137.242 49.8215 134.032 49.8215C131.011 49.8215 127.955 49.7275 124.96 49.5475L124.926 59.9635C127.853 60.2715 130.9 60.4435 134.032 60.4435C137.362 60.4435 140.588 60.2545 143.678 59.9125Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[28] ?? 'WHITE']}
        id="28"
        data-testid="back-28"
      />
      <path
        d="M123.214 59.7655L123.248 49.4355C120.312 49.2215 117.454 48.9305 114.766 48.5455C114.98 51.8915 115.263 55.2725 115.605 58.4055C118.01 58.9695 120.561 59.4325 123.214 59.7655Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[27] ?? 'WHITE']}
        id="27"
        data-testid="back-27"
      />
      <path
        d="M113.841 57.9596C113.507 54.8526 113.233 51.5316 113.028 48.2796C110.811 47.9286 108.731 47.5096 106.84 47.0386C107.011 50.3336 107.302 53.5946 107.739 55.9656C109.622 56.7186 111.667 57.3866 113.841 57.9596Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[26] ?? 'WHITE']}
        id="26"
        data-testid="back-26"
      />
      <path
        d="M98.6227 50.6646C100.506 52.3426 102.963 53.8657 105.89 55.1837C105.47 52.5567 105.231 49.4157 105.094 46.5647C102.552 45.8287 100.438 44.9646 98.9307 43.9886C98.6487 44.9726 98.4947 46.1117 98.4777 47.4207C98.4687 48.5937 98.5117 49.6716 98.6227 50.6646Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[25] ?? 'WHITE']}
        id="25"
        data-testid="back-25"
      />
      <path
        d="M164.058 39.6967C163.963 40.7667 163.792 42.5477 163.553 44.6357C165.727 43.9507 167.404 43.1977 168.5 42.4357C168.183 41.8627 167.806 41.3657 167.353 40.9637C166.249 40.0057 164.922 39.7567 164.058 39.6967Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[24] ?? 'WHITE']}
        id="24"
        data-testid="back-24"
      />
      <path
        d="M161.454 36.0226C160.025 36.5536 158.381 37.0246 156.558 37.4356C156.473 40.1396 156.336 43.2036 156.156 46.3876C158.244 46.0196 160.119 45.6086 161.779 45.1556C162.01 43.2806 162.199 41.4496 162.344 39.8146L160.983 39.9856L161.266 37.7866C161.326 37.1866 161.394 36.6046 161.454 36.0226Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[23] ?? 'WHITE']}
        id="23"
        data-testid="back-23"
      />
      <path
        d="M145.312 39.0936L145.346 47.6946C148.641 47.4466 151.672 47.0956 154.411 46.6676C154.599 43.5266 154.728 40.4886 154.822 37.7926C151.938 38.3576 148.701 38.7936 145.312 39.0936Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[22] ?? 'WHITE']}
        id="22"
        data-testid="back-22"
      />
      <path
        d="M134.033 48.1066C137.405 48.1066 140.623 48.0046 143.636 47.8156L143.602 39.2396C140.469 39.4706 137.225 39.5906 134.033 39.5906C131.02 39.5906 127.965 39.4876 125.003 39.2826L124.969 47.8496C127.828 48.0126 130.857 48.1066 134.033 48.1066Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[21] ?? 'WHITE']}
        id="21"
        data-testid="back-21"
      />
      <path
        d="M114.662 46.8226C117.281 47.1996 120.166 47.5156 123.256 47.7296L123.29 39.1366C120.097 38.8716 117.033 38.4866 114.252 37.9816C114.346 40.6776 114.474 43.7076 114.662 46.8226Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[20] ?? 'WHITE']}
        id="20"
        data-testid="back-20"
      />
      <path
        d="M106.763 45.2856C108.577 45.7566 110.64 46.1846 112.925 46.5616C112.737 43.4026 112.608 40.3556 112.514 37.6596C110.289 37.1976 108.295 36.6496 106.609 36.0336C106.668 36.6156 106.728 37.1976 106.814 37.7966L107.114 40.0396L106.643 39.9706C106.643 41.5966 106.686 43.4206 106.763 45.2856Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[19] ?? 'WHITE']}
        id="19"
        data-testid="back-19"
      />
      <path
        d="M105.024 44.7936C104.947 42.6536 104.93 40.8306 104.922 39.7096L104.87 39.7006C104.862 39.7006 102.482 39.4186 100.711 40.9586C100.257 41.3526 99.8805 41.8486 99.5645 42.4306C100.736 43.2616 102.593 44.0656 105.024 44.7936Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[18] ?? 'WHITE']}
        id="18"
        data-testid="back-18"
      />
      <path
        d="M156.695 28.2716C156.721 29.4436 156.704 32.1486 156.609 35.6746C158.604 35.2046 160.281 34.6826 161.608 34.1516C161.805 31.0956 161.702 28.2626 161.3 25.6526C159.93 26.1486 158.373 26.5936 156.644 26.9876C156.661 27.4066 156.686 27.8346 156.695 28.2716Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[17] ?? 'WHITE']}
        id="17"
        data-testid="back-17"
      />
      <path
        d="M145.311 37.3926C148.957 37.0676 152.158 36.6056 154.88 36.0486C154.991 32.3256 155.017 29.4756 154.982 28.3116C154.974 27.9776 154.957 27.6606 154.94 27.3356C152.012 27.9176 148.726 28.3546 145.277 28.6626L145.311 37.3926Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[16] ?? 'WHITE']}
        id="16"
        data-testid="back-16"
      />
      <path
        d="M134.031 37.8716C137.463 37.8716 140.656 37.7516 143.6 37.5296L143.566 28.7986C140.442 29.0306 137.215 29.1496 134.031 29.1496C131.027 29.1496 127.989 29.0476 125.036 28.8416L125.002 37.5716C127.8 37.7606 130.805 37.8716 134.031 37.8716Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[15] ?? 'WHITE']}
        id="15"
        data-testid="back-15"
      />
      <path
        d="M114.19 36.2475C116.826 36.7435 119.873 37.1545 123.296 37.4455L123.331 28.7155C120.07 28.4415 116.946 28.0475 114.113 27.5345C114.104 27.7995 114.087 28.0565 114.079 28.3215C114.044 29.5025 114.079 32.4385 114.19 36.2475Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[14] ?? 'WHITE']}
        id="14"
        data-testid="back-14"
      />
      <path
        d="M112.37 28.2685C112.378 27.9005 112.396 27.5495 112.413 27.1905C110.299 26.7455 108.39 26.2315 106.764 25.6415C106.362 28.2515 106.259 31.0845 106.456 34.1405C108.005 34.7645 110.025 35.3645 112.464 35.8945C112.361 32.2575 112.336 29.4665 112.37 28.2685Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[13] ?? 'WHITE']}
        id="13"
        data-testid="back-13"
      />
      <path
        d="M156.524 25.2477C158.253 24.8457 159.751 24.4007 160.992 23.9387C160.504 21.6187 159.759 19.4876 158.767 17.5616C157.705 17.8526 156.558 18.1266 155.352 18.3756C155.891 20.4036 156.302 22.6887 156.524 25.2477Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[12] ?? 'WHITE']}
        id="12"
        data-testid="back-12"
      />
      <path
        d="M144.713 19.8296C145.039 21.9606 145.235 24.3315 145.27 26.9585C148.907 26.6335 152.117 26.1715 154.839 25.6155C154.625 23.0135 154.205 20.7106 153.657 18.6906C150.901 19.1876 147.872 19.5636 144.713 19.8296Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[11] ?? 'WHITE']}
        id="11"
        data-testid="back-11"
      />
      <path
        d="M143.558 27.0936C143.532 24.4486 143.327 22.0786 142.984 19.9556C140.04 20.1606 137.019 20.2636 134.032 20.2636C131.225 20.2636 128.383 20.1696 125.61 19.9896C125.276 22.1126 125.071 24.4836 125.045 27.1276C127.827 27.3166 130.822 27.4276 134.032 27.4276C137.447 27.4276 140.631 27.3076 143.558 27.0936Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[10] ?? 'WHITE']}
        id="10"
        data-testid="back-10"
      />
      <path
        d="M123.343 27.0117C123.377 24.3847 123.565 22.0136 123.891 19.8736C120.903 19.6426 118.028 19.3006 115.374 18.8636C114.827 20.8926 114.424 23.2117 114.219 25.8137C116.855 26.3097 119.911 26.7207 123.343 27.0117Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[9] ?? 'WHITE']}
        id="9"
        data-testid="back-9"
      />
      <path
        d="M112.522 25.4675C112.736 22.8915 113.138 20.5975 113.66 18.5605C112.103 18.2695 110.63 17.9355 109.295 17.5675C108.302 19.5015 107.558 21.6245 107.07 23.9445C108.542 24.4835 110.365 25.0055 112.522 25.4675Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[8] ?? 'WHITE']}
        id="8"
        data-testid="back-8"
      />
      <path
        d="M152.913 10.4674C152.682 10.5445 152.451 10.6215 152.211 10.6985C153.178 12.3333 154.111 14.3361 154.881 16.7326C155.968 16.5096 156.97 16.2786 157.903 16.0306C157.227 14.9437 156.465 13.9167 155.609 12.9666C154.762 12.0337 153.854 11.2121 152.913 10.4674Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[7] ?? 'WHITE']}
        id="7"
        data-testid="back-7"
      />
      <path
        d="M153.16 17.0556C152.373 14.6757 151.423 12.7329 150.464 11.1666C148.179 11.7144 145.526 12.1166 142.812 12.382C143.446 14.0509 144.002 15.9596 144.43 18.1426C147.683 17.8856 150.601 17.5086 153.16 17.0556Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[6] ?? 'WHITE']}
        id="6"
        data-testid="back-6"
      />
      <path
        d="M127.596 12.5882C126.945 14.223 126.363 16.1146 125.918 18.3146C128.452 18.4686 131.156 18.5536 134.032 18.5536C137.113 18.5536 139.998 18.4596 142.677 18.2796C142.232 16.0806 141.65 14.1802 140.991 12.554C138.577 12.7423 136.181 12.8279 134.032 12.8279C132.038 12.8193 129.83 12.7508 127.596 12.5882Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[5] ?? 'WHITE']}
        id="5"
        data-testid="back-5"
      />
      <path
        d="M124.172 18.1935C124.591 16.0115 125.147 14.1113 125.772 12.4423C123.23 12.2112 120.722 11.8603 118.497 11.381C117.556 12.9473 116.623 14.8816 115.861 17.2265C118.326 17.6295 121.107 17.9625 124.172 18.1935Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[4] ?? 'WHITE']}
        id="4"
        data-testid="back-4"
      />
      <path
        d="M114.132 16.9206C114.869 14.5586 115.776 12.5815 116.717 10.9467C116.169 10.8012 115.647 10.6386 115.16 10.4674C114.218 11.2121 113.311 12.0337 112.472 12.9666C111.616 13.9167 110.854 14.9437 110.178 16.0306C111.359 16.3476 112.686 16.6466 114.132 16.9206Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[3] ?? 'WHITE']}
        id="3"
        data-testid="back-3"
      />
      <path
        d="M142.145 10.7589C145.038 10.4936 147.468 10.1085 149.445 9.65482C148.667 8.58502 147.913 7.75483 147.289 7.14713C143.993 5.71783 140.741 5.04163 138.285 4.71643C139.526 6.08583 140.921 8.03723 142.145 10.7589Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[2] ?? 'WHITE']}
        id="2"
        data-testid="back-2"
      />
      <path
        d="M128.312 10.9365C130.084 11.0392 131.976 11.1077 134.031 11.1077C136.29 11.1077 138.353 11.0306 140.27 10.9022C138.635 7.51294 136.76 5.51874 135.527 4.45744C134.637 4.41464 134.108 4.42313 134.09 4.42313H134.031H133.971C133.954 4.42313 133.636 4.41463 133.105 4.43173C131.864 5.48453 129.964 7.50443 128.312 10.9365Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[1] ?? 'WHITE']}
        id="1"
        data-testid="back-1"
      />
      <path
        d="M130.395 4.6366C128.307 4.8763 125.508 5.3812 122.564 6.4426C121.81 7.0845 120.689 8.1886 119.5 9.8576C121.425 10.2513 123.745 10.5851 126.441 10.8162C127.69 8.00879 129.128 6.0146 130.395 4.6366Z"
        fill={BODY_MAP_COLOR_MAPPING[colors[0] ?? 'WHITE']}
        id="0"
        data-testid="back-0"
      />
    </>
  );
};
